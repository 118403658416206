import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { Position } from './Position';

type Props = {
  player?: Player;
};

export const PositionCard: FC<Props> = (props: Props) => {
  const { player } = props;
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card variant="outlined" sx={{ height: 220 }}>
      <CardHeader
        title={t('position')}
        titleTypographyProps={{ variant: matchesXsOrSm ? 'body1' : 'subtitle1' }}
      />
      <CardContent sx={{ padding: '0' }}>
        <Stack
          direction={matchesXsOrSm ? 'row' : 'column'}
          spacing={2}
          sx={{
            width: matchesXsOrSm ? '90%' : '100%',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            {player ? (
              <Position position={player?.position || ''} showLegend={false} width={80} />
            ) : (
              <Skeleton
                variant="circular"
                width={90}
                height={90}
                sx={{
                  margin: matchesXsOrSm ? '20px' : 'auto',
                }}
              />
            )}
          </Box>
          {player ? (
            <Typography variant="h6">
              {t(`player_positions.${player?.position.toLowerCase()}`)}
            </Typography>
          ) : (
            <Skeleton variant="rounded" width="90%" height={30} sx={{ margin: 'auto' }} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

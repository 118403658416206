import { forwardRef, CSSProperties } from 'react';
import {
  Paper, Avatar, Box, Typography, Grid, Stack, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PlayerItem } from '../../data/types/PlayerType';

const FlagImg = styled('img')({
  width: 30,
});

export const PlayerCard = forwardRef<HTMLDivElement, PlayerItem & { style: CSSProperties }>(({
  photo, name, age, shirtNumber, country, style, ...rest
}, ref) => {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={11}
      ref={ref}
      // style={style}
      {...rest}
    >
      <Box sx={{ p: 1 }}>
        <Grid container gap={2}>
          <Grid item>
            <Avatar
              alt={name}
              src={photo}
              sx={{ width: 56, height: 56 }}
            />
          </Grid>
          <Grid item container direction="column" wrap="nowrap" xs>
            <Grid item xs>
              <Stack direction="row" spacing={1}>
                <FlagImg alt={`${country} flag`} src={`../assets/flags/${country.toUpperCase()}.svg`} />
                <Typography variant="h4">{name}</Typography>
              </Stack>
            </Grid>
            <Stack direction="row" spacing={2}>
              <Box>{`${t('age')}: ${age}`}</Box>
              <Box>{`${t('number')}: ${shirtNumber}`}</Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

import { Face } from '../players/Player';

export enum CoachLevel {
  BAD = 'BAD',
  WEAK = 'WEAK',
  REGULAR = 'REGULAR',
  GOOD = 'GOOD',
  VERY_GOOD = 'VERY_GOOD',
  EXCELLENT = 'EXCELLENT',
  FORMIDABLE = 'FORMIDABLE',
}

export enum CoachType {
  TECHNICAL = 'TECHNICAL',
  PHYSICAL = 'PHYSICAL',
  GRASSROOTS_COACH = 'GRASSROOTS_COACH',
  GOALKEEPER_COACH = 'GOALKEEPER_COACH',
}

export type Coach = {
  id: string;
  name: string;
  face: Face;
  level: CoachLevel;
  type: CoachType;
  age: number;
  salary: number;
};

export type Coaches = {
  technical: Coach;
  physical: Coach;
  goalkeeper: Coach;
  grassroots: Coach;
};

export type ChangeCoachCosts = {
  newSalary: number;
  transferFee: number;
};

import {
  Grid,
  LinearProgress,
  Stack,
  Typography,
  lighten,
} from '@mui/material';
import { FC } from 'react';
import { AttributeType } from './attributes';

type Props = {
  number: number,
  levelName: string,
  inverted?: boolean,
  type: AttributeType,
  isExperience?: boolean,
};

const getColor = (number: number, inverted?: boolean): string => {
  if (number < 25) {
    return inverted
      ? '#001C74' // Dark Blue
      : '#BF0023'; // Red
  }

  if (number >= 25 && number < 40) {
    return inverted
      ? '#007FE2' // Light Blue
      : '#eb7a2a'; // Orange
  }

  if (number >= 40 && number < 55) {
    return inverted
      ? '#218D01' // Dark Green
      : '#E2C000'; // Yellow
  }

  if (number >= 55 && number < 70) {
    return '#33D400'; // Light Green
  }

  if (number >= 70 && number < 85) {
    return inverted
      ? '#E2C000' // Yellow
      : '#218D01'; // Dark Green
  }

  if (number >= 86 && number < 96) {
    return inverted
      ? '#eb7a2a' // Orange
      : '#007FE2'; // Light Blue
  }

  return inverted
    ? '#BF0023' // Red
    : '#001C74'; // Dark Blue
};

export const AttributeBar: FC<Props> = (props: Props) => {
  const {
    number,
    levelName,
    inverted,
    type,
    isExperience,
  } = props;
  // The number comes from 1-16, so we need to multiply it by 6.25 to get a percentage
  // If it's physical, it's from 1-8, so we need to multiply it by 12.5
  let percentage = Math.round(
    number * (type === AttributeType.TECHNICAL || isExperience ? 6.25 : 12.5),
  );
  percentage = percentage > 100 ? 100 : percentage;
  const color = getColor(percentage, inverted);

  return (
    <Grid container alignItems="center" gap={0.5}>
      <Grid item xs={12}>
        <Stack direction="row">
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              position: 'absolute',
              marginTop: '0px',
              zIndex: '1',
              marginLeft: '10px',
              color: 'white',
              textShadow: '2px 1px 1px black',
              letterSpacing: '0.08333em',
              textTransform: 'uppercase',
            }}
          >
            {levelName}
          </Typography>
          <LinearProgress
            value={percentage}
            variant="determinate"
            sx={{
              width: '100%',
              height: '1.5rem',
              backgroundColor: lighten(color, 0.65),
              borderRadius: '0.25rem',
              '& .MuiLinearProgress-bar': {
                borderRadius: '0.25rem',
                backgroundColor: color,
              },
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginLeft: '0.5rem',
              marginTop: '-0.25rem',
              color,
            }}
          >
            {Math.round(number).toString().padStart(2, '0')}
          </Typography>
        </Stack>
      </Grid>
    </Grid>

  );
};

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getRegionalTournamentOverview } from '../api';
import { RegionalTournamentOverview } from '../types/seasons/RegionalTournamentOverview';

export const useRegionalTournamentOverview = () => {
  // Get the regional tournament ID from the path variable
  const { id } = useParams();

  const { isLoading, error, data } = useQuery<RegionalTournamentOverview, Error>({
    queryKey: ['regional-tournament-overview', id],
    queryFn: () => getRegionalTournamentOverview(id || ''),
  });

  return { isLoading, error, data };
};

import { MoreVertOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { Face, Position } from '../../common/Player';
import { Player } from '../../data/types';
import { useAvailableGrassrootsPlayers } from '../../data/hooks/useGrassroots';
import { NoPlayersAvailableOverlay } from './NoAvailablePlayerOverlay';
import { SearchNewPlayersDialog } from './SearchNewPlayersDialog';
import { AvailablePlayerDetails } from './AvailablePlayerDetails';
import { ConfirmHirePlayerDialog } from './ConfirmHirePlayerDialog';
import { ConfirmRejectPlayerDialog } from './ConfirmRejectPlayerDialog';

const LoadingSkeleton: FC = () => (
  <>
    {[...Array(3)].map(() => (
      <Box
        sx={{
          height: '30px',
          my: 1,
          mx: 1,
        }}
        key={Math.random()}
      >
        <Skeleton variant="rounded" height={30} />
      </Box>
    ))}
  </>
);

const attributesAverage = (player: Player): number => {
  const attributes = Object.values(player.technicalAttributes).reduce((acc, curr) => acc + curr, 0);
  return Math.round(attributes / Object.keys(player.technicalAttributes).length);
};

export const AvailablePlayers: FC = () => {
  const [optionsMenuOpen, setOptionMenuOpen] = useState<boolean>(false);
  const [openSearchPlayersDialog, setOpenSearchPlayersDialog] = useState<boolean>(false);
  const [openConfirmHireDialog, setOpenConfirmHireDialog] = useState<boolean>(false);
  const [openConfirmRejectDialog, setOpenConfirmRejectDialog] = useState<boolean>(false);
  const [openPlayerDetails, setOpenPlayerDetails] = useState<boolean>(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | undefined>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isLoading, data: players } = useAvailableGrassrootsPlayers();
  const columnsDefinition: GridColDef[] = [
    {
      field: 'face',
      headerName: '#',
      editable: false,
      sortable: false,
      pinnable: false,
      minWidth: 30,
      renderCell: (params: GridRenderCellParams) => (
        <Face
          body={params.value.body}
          hairStyle={params.value.hairStyle}
          hairColor={params.value.hairColor}
          eyeShape={params.value.eyeShape}
          eyeColor={params.value.eyeColor}
          noseShape={params.value.noseShape}
          mouthShape={params.value.mouthShape}
          beard={params.value.beard}
          eyebrows={params.value.eyebrows}
          width={25}
          height={25}
        />
      ),
    },
    {
      field: 'name',
      headerName: t('name') || 'Name',
      editable: false,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: 'age',
      headerName: t('age') || 'Age',
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
      minWidth: 20,
    },
    {
      field: 'position',
      headerName: t('position') || 'Position',
      editable: false,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Position position={params.value} showLegend width={25} />
      ),
    },
    {
      field: 'attributes_average',
      headerName: t('attributes') || 'Attributes',
      editable: false,
      minWidth: 90,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box component="img" src="/assets/icons/performance.svg" alt="technical" width={20} height={20} />
          <Typography variant="body1" fontWeight="bold">{attributesAverage(params.row)}</Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('actions') || 'Actions',
      editable: false,
      minWidth: 250,
      cellClassName: 'actions',
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label={t('view')}
          color="primary"
          onClick={() => {
            setSelectedPlayer(players?.find((p) => p.id === params.id));
            setOpenPlayerDetails(true);
          }}
        />,
        <GridActionsCellItem
          icon={<CheckIcon />}
          label={t('hire')}
          color="success"
          onClick={() => {
            setSelectedPlayer(players?.find((p) => p.id === params.id));
            setOpenConfirmHireDialog(true);
          }}
        />,
        <GridActionsCellItem
          icon={<CloseIcon color="error" />}
          label={t('reject')}
          onClick={() => {
            setSelectedPlayer(players?.find((p) => p.id === params.id));
            setOpenConfirmRejectDialog(true);
          }}
          color="error"
        />,
      ],
    },
  ];

  const handleCloseMenu = () => {
    setOptionMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOptionMenuOpen(true);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('available_players')}
        action={(
          <>
            <IconButton
              aria-label="settings"
              onClick={handleOpenMenu}
              aria-controls={optionsMenuOpen ? 'facility-basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={optionsMenuOpen ? 'true' : undefined}
            >
              <MoreVertOutlined />
            </IconButton>
            <Menu
              id="available-players-basic-menu"
              anchorEl={anchorEl}
              open={optionsMenuOpen}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => setOpenSearchPlayersDialog(true)}>{t('search_new_players')}</MenuItem>
            </Menu>
          </>
        )}
      />
      <CardContent>
        <DataGrid
          columns={columnsDefinition}
          rows={players || []}
          rowHeight={40}
          onRowClick={(params: GridRowParams) => {
            setSelectedPlayer(params.row as Player);
            setOpenPlayerDetails(true);
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          loading={isLoading}
          sx={{
            '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-virtualScroller': {
              minHeight: 120,
              marginTop: 1,
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
          }}
          slots={{
            loadingOverlay: LoadingSkeleton,
            noRowsOverlay: NoPlayersAvailableOverlay,
          }}
          slotProps={{
            noRowsOverlay: {
              onNewSearchClick: () => setOpenSearchPlayersDialog(true),
            },
          }}
          disableColumnFilter
          disableColumnMenu
        />
      </CardContent>

      <SearchNewPlayersDialog
        open={openSearchPlayersDialog}
        onClose={() => setOpenSearchPlayersDialog(false)}
      />

      {selectedPlayer !== undefined ? (
        <AvailablePlayerDetails
          player={selectedPlayer}
          open={openPlayerDetails}
          onClose={() => setOpenPlayerDetails(false)}
          onHire={() => setOpenConfirmHireDialog(true)}
          onReject={() => setOpenConfirmRejectDialog(true)}
        />
      ) : null}

      {selectedPlayer !== undefined ? (
        <ConfirmHirePlayerDialog
          player={selectedPlayer}
          open={openConfirmHireDialog}
          onClose={() => setOpenConfirmHireDialog(false)}
        />
      ) : null}

      {selectedPlayer !== undefined ? (
        <ConfirmRejectPlayerDialog
          player={selectedPlayer}
          open={openConfirmRejectDialog}
          onClose={() => setOpenConfirmRejectDialog(false)}
        />
      ) : null}

    </Card>
  );
};

import { FC } from 'react';
import {
  Card, CardContent, CardHeader, Grid, Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Player } from '../../data/types';
import { formatCurrency } from '../formatter';
import { formatShortDate } from '../formatter/date';

type Props = {
  player?: Player,
};

export const ContractTab: FC<Props> = (props: Props) => {
  const { player } = props;
  const { i18n } = useTranslation();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: 1,
        marginBottom: 1,
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader title={t('market_value')} titleTypographyProps={{ variant: 'body1' }} />
          <CardContent>
            <Typography variant="h5">
              {formatCurrency(player?.contract?.marketValue || 0, i18n.language)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader title={t('salary_per_week')} titleTypographyProps={{ variant: 'body1' }} />
          <CardContent>
            <Typography variant="h5">
              {formatCurrency(player?.contract?.salary || 0, i18n.language)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader title={t('end_of_contract')} titleTypographyProps={{ variant: 'body1' }} />
          <CardContent>
            <Typography variant="h5">
              {formatShortDate(player?.contract?.expiresAt || '', i18n.language)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader title={t('salary_renews_every_season')} titleTypographyProps={{ variant: 'body1' }} />
          <CardContent>
            <Typography variant="h5">
              {player?.contract?.renewSalaryEverySeason ? t('yes') : t('no')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  );
};

import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';
import { getLeagueOverview } from '../api';
import { LeagueOverview } from '../types/seasons/LeagueOverview';

export const useLeagueOverview = () => {
  // Get the league ID from the path variable
  const { id } = useParams();

  const { isLoading, error, data } = useQuery<LeagueOverview, Error>({
    queryKey: ['league-overview', id],
    queryFn: () => getLeagueOverview(id || ''),
  });

  return { isLoading, error, data };
};

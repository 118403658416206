import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

type Props = {
  variant: Variant,
  home?: number,
  away?: number
};

export const Score: FC<Props> = (props: Props) => {
  const { variant, home, away } = props;
  if (home === undefined || away === undefined) {
    return (
      <Typography variant={variant}>X</Typography>
    );
  }

  return (
    <Typography variant={variant}>{`${home} - ${away}`}</Typography>
  );
};

import { FC } from 'react';
import { ListItem } from '@mui/material';
import { Box } from '@mui/system';
import { Player } from '../../data/types/match/MatchDetails';
import { MatchPlayer } from './MatchPlayer';

type Props = {
  lineUpPlayers: Player[];
  firstEleven: boolean;
  player: Player;
  index: number;
  onClick: (player: Player) => void;
};

export const CompositeMatchPlayer: FC<Props> = ({
  lineUpPlayers,
  firstEleven,
  player,
  index,
  onClick,
}: Props) => {
  if (player.replacedPlayerId !== null && player.replacedPlayerId !== undefined && player.replacedPlayerId !== '') {
    return null;
  }

  const playerWhoEnteredMatch = lineUpPlayers.find((p) => p.id === player.replacedById);

  return playerWhoEnteredMatch !== undefined && playerWhoEnteredMatch !== null
    ? (
      <>
        <ListItem
          divider
          sx={{
            backgroundColor: index % 2 === 0 ? 'transparent' : '#eee',
            cursor: 'pointer',
          }}
          onClick={() => onClick(playerWhoEnteredMatch)}
        >
          <MatchPlayer player={playerWhoEnteredMatch} firstEleven={firstEleven} />
        </ListItem>
        <ListItem
          divider
          sx={{
            backgroundColor: index % 2 === 0 ? 'transparent' : '#eee',
            cursor: 'pointer',
          }}
          onClick={() => onClick(player)}
        >
          <Box sx={{ ml: 1, width: '100%' }}>
            <MatchPlayer player={player} firstEleven={firstEleven} />
          </Box>
        </ListItem>
      </>
    )
    : (
      <ListItem
        key={player.id}
        divider
        sx={{
          backgroundColor: index % 2 === 0 ? 'transparent' : '#eee',
          cursor: 'pointer',
        }}
        onClick={() => onClick(player)}
      >
        <MatchPlayer player={player} firstEleven={firstEleven} />
      </ListItem>
    );
};

import { Match } from './Match';

export enum Status {
  UPCOMING = 'UPCOMING',
  PLAYING = 'PLAYING',
  PLAYED = 'PLAYED',
}

export type Fixture = {
  number: number;
  status: Status;
  matches: Match[];
};

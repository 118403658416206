import { FC } from 'react';
import {
  Card, CardContent, Grid, CardHeader, Stack, Typography, Divider, Box, Skeleton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Match, Result, Status } from '../../data/types/seasons/Match';
import { formatLongDate, formatLongDateTime } from '../formatter/date';
import { LiveMatchIcon } from './LiveMatchIcon';
import { MatchDetails } from '../../data/types/match/MatchDetails';

type Props = {
  match: Match | MatchDetails;
  loading: boolean;
  link?: string;
  header?: JSX.Element;
  disableHeaderLoading: boolean;
  updateLiveMatch?: boolean;
  currentMinute?: number;
};

export const MatchCard: FC<Props> = (props: Props) => {
  const {
    match,
    loading,
    link,
    header,
    disableHeaderLoading,
    updateLiveMatch,
    currentMinute,
  } = props;
  const { i18n } = useTranslation();
  if (updateLiveMatch) {
    console.log('Will update live match');
  }

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={loading && !disableHeaderLoading
          ? (<Skeleton variant="rounded" width={300} />)
          : header}
      />

      <CardContent>
        <Link
          to={link || ''}
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={9.5}>
              <Stack direction="column">
                <Stack direction="row" sx={{ padding: 0.25, alignItems: 'center' }}>
                  {loading ? (
                    <Skeleton variant="circular" width="30px" height="30px" />
                  ) : (
                    <img src="/assets/crest.png" alt={match?.homeClub?.name} width="30" />
                  )}

                  {loading ? (
                    <Skeleton variant="rounded" width={400} sx={{ margin: 1 }} />
                  ) : (
                    <Typography
                      noWrap
                      variant="h6"
                      sx={{
                        marginLeft: 1,
                        marginTop: 0.5,
                        fontWeight: match?.status === Status.FINISHED && match?.result === Result.HOME_WON ? 'bold' : 'normal',
                        fontSize: '16px',
                        width: '80%',
                      }}
                    >
                      {match?.homeClub?.name}
                    </Typography>
                  )}

                  {match?.status === Status.FINISHED
                  || match?.status === Status.PLAYING
                  || match?.status as unknown as number === 3 ? (
                    <Typography
                      variant="h6"
                      sx={{
                        marginLeft: 1,
                        marginRight: 1,
                        marginTop: 0.5,
                        textAlign: 'right',
                        fontWeight: match?.status === Status.FINISHED && match?.result === Result.HOME_WON ? 'bold' : 'normal',
                        fontSize: '16px',
                        width: '20%',
                      }}
                    >
                      {match?.score?.home}
                      {match?.decisionByPenalties ? ` (${match?.penaltiesScore?.home})` : ''}
                    </Typography>
                    ) : null }

                </Stack>
                <Stack direction="row" sx={{ padding: 0.25, alignItems: 'center' }}>
                  {loading ? (
                    <Skeleton variant="circular" width="30px" height="30px" />
                  ) : (
                    <img src="/assets/crest.png" alt={match?.awayClub?.name} width="30" />
                  )}
                  {loading ? (
                    <Skeleton variant="rounded" width={400} sx={{ margin: 1 }} />
                  ) : (
                    <Typography
                      noWrap
                      variant="h6"
                      sx={{
                        marginLeft: 1,
                        marginTop: 0.5,
                        fontWeight: match?.status === Status.FINISHED && match?.result === Result.AWAY_WON ? 'bold' : 'normal',
                        fontSize: '16px',
                        width: '80%',
                      }}
                    >
                      {match?.awayClub?.name}
                    </Typography>
                  )}
                  {match?.status === Status.FINISHED || match?.status === Status.PLAYING ? (
                    <Typography
                      variant="h6"
                      sx={{
                        marginLeft: 1,
                        marginRight: 1,
                        marginTop: 0.5,
                        textAlign: 'right',
                        fontWeight: match?.status === Status.FINISHED && match?.result === Result.AWAY_WON ? 'bold' : 'normal',
                        fontSize: '16px',
                        width: '20%',
                      }}
                    >
                      {match?.score?.away}
                      {match?.decisionByPenalties ? ` (${match?.penaltiesScore?.away})` : ''}
                    </Typography>
                  ) : null }
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2.5}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  height: '100%',
                  float: 'left',
                }}
              />
              <Box sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {loading ? (
                  <Skeleton variant="rounded" width="100%" sx={{ margin: 1 }} />
                ) : (
                  <Typography
                    variant="caption"
                    sx={{
                      marginLeft: 1,
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap',
                      textTransform: 'capitalize',
                    }}
                  >
                    {match?.status === Status.SCHEDULED
                      ? formatLongDateTime(match?.date, i18n.language)
                      : null}
                    {match?.status === Status.PLAYING
                      ? (<LiveMatchIcon currentMinute={currentMinute} />)
                      : null}
                    {match?.status === Status.FINISHED
                      ? formatLongDate(match?.date, i18n.language)
                      : null}
                  </Typography>
                )}

              </Box>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
};

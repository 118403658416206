export enum Phase {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  GROUP_PHASE = 'GROUP_PHASE',
  FIRST_PHASE = 'FIRST_PHASE',
  SECOND_PHASE = 'SECOND_PHASE',
  THIRD_PHASE = 'THIRD_PHASE',
  ROUND_OF_16 = 'ROUND_OF_16',
  QUARTER_FINAL = 'QUARTER_FINAL',
  SEMI_FINAL = 'SEMI_FINAL',
  FINAL = 'FINAL',
}

import {
  Card, CardContent, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { FC } from 'react';

type Props = {
  stat: string | number,
  icon: string,
  description: string,
  compact?: boolean,
};

export const Stats: FC<Props> = (props: Props) => {
  const {
    stat,
    icon,
    description,
    compact,
  } = props;
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          sx={{
            width: '100%',
            alignItems: matchesXsOrSm ? 'center' : 'left',
          }}
          direction={matchesXsOrSm ? 'row' : 'column'}
          spacing={1}
        >
          {matchesXsOrSm ? (
            <img
              src={icon}
              width="50"
              height="50"
              alt={description}
            />
          ) : null}

          <Typography
            variant="body1"
            sx={{
              fontWeight: 'normal',
              whiteSpace: 'nowrap',
            }}
          >
            {description}
          </Typography>
          <Typography
            variant={compact ? 'h5' : 'h3'}
            sx={{
              textAlign: 'right',
              width: '100%',
            }}
          >
            {!matchesXsOrSm ? (
              <img
                src={icon}
                width="50"
                height="50"
                alt={description}
                style={{
                  float: 'left',
                  marginLeft: '0rem',
                }}
              />
            ) : null}
            {stat}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

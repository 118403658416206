import { Grid } from '@mui/material';
import { FC } from 'react';
import { useFinancialReport } from '../../data/hooks/useFinancialReport';
import { BalanceCard } from './BalanceCard';
import { TransactionsCard } from './TransactionsCard';

export const FinancesPage: FC = () => {
  const { isLoading, data: financialReport } = useFinancialReport();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <BalanceCard
          loading={isLoading}
          currentBalance={financialReport?.currentBalance}
          balanceOneWeekAgo={financialReport?.balanceOneWeekAgo}
          dates={Object.keys(financialReport?.balanceOverPreviousMonth || [])}
          amounts={Object.values(financialReport?.balanceOverPreviousMonth || [])}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TransactionsCard
          loading={isLoading}
          transactions={financialReport?.transactionsFromPreviousMonthToDate}
        />
      </Grid>
    </Grid>
  );
};

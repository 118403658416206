import {
  Box, styled, Typography,
} from '@mui/material';

import { Loader } from './Loader';

const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

type Props = {
  message: string;
};

export const PageLoader = ({ message }: Props): JSX.Element => (
  <Container>
    <Loader variant="big" />
    <Typography color="white" variant="h3">{message}</Typography>
  </Container>
);

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import { MoreVertOutlined } from '@mui/icons-material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../common/formatter';
import { Store } from '../../data/types/facilities/Store';
import { Status } from '../../data/types/facilities/Status';

type Props = {
  loading: boolean;
  stores?: Store[];
};

export const StoresDetails: FC<Props> = (props: Props) => {
  const { stores, loading } = props;
  const [optionsMenuOpen, setOptionMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const matchesLgAndDown = useMediaQuery(theme.breakpoints.down('xl'));
  const { i18n } = useTranslation();

  let numberOfStores = 0;
  let maintenanceCost = 0;

  stores?.forEach((store) => {
    if (store.status === Status.RUNNING) {
      numberOfStores++;
      maintenanceCost += store.maintenanceCost;
    }
  });

  const handleCloseMenu = () => {
    setOptionMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOptionMenuOpen(true);
  };

  return (
    <Card variant="outlined">
      {loading ? (
        <CardHeader title={<Skeleton variant="rounded" width="50%" />} />
      ) : (
        <CardHeader
          title={t('stores')}
          action={numberOfStores > 0 ? (
            <>
              <IconButton
                aria-label="settings"
                onClick={handleOpenMenu}
                aria-controls={optionsMenuOpen ? 'stores-basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsMenuOpen ? 'true' : undefined}
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu
                id="stores-basic-menu"
                anchorEl={anchorEl}
                open={optionsMenuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleCloseMenu}>{t('open_new_store')}</MenuItem>
                <MenuItem onClick={handleCloseMenu}>{t('close_one_store')}</MenuItem>
              </Menu>
            </>
          ) : null}
        />
      )}

      {loading ? (
        <CardContent>
          <Stack
            spacing={2}
            direction={matchesLgAndDown ? 'column' : 'row'}
            sx={{
              alignItems: 'center',
              justifyContent: matchesLgAndDown ? 'center' : 'left',
            }}
          >
            <Skeleton variant="rounded" width="240px" height="240px" />
            <Stack sx={{ width: '40%' }} spacing={2}>
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
            </Stack>
          </Stack>
        </CardContent>
      ) : (
        <CardContent>
          {numberOfStores === 0 ? (
            <Stack spacing={2} alignItems="center">
              <img src="/assets/facilities/no-facility.svg" alt="no-facility" width="300rem" />
              <Typography variant="h5" textAlign="center" noWrap>
                {t('no_stores')}
              </Typography>
              <Button variant="contained" sx={{ width: '100%' }}>
                {t('build_new_store')}
              </Button>
            </Stack>
          ) : (
            <Stack
              spacing={2}
              direction={matchesLgAndDown ? 'column' : 'row'}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Stack
                direction={matchesLgAndDown ? 'column' : 'row'}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Stack
                  direction="column"
                  spacing={matchesLgAndDown ? 2 : 6}
                  alignItems="center"
                  justifyContent={matchesLgAndDown ? 'center' : 'left'}
                >
                  <img
                    src="/assets/facilities/store.svg"
                    alt={t('stores') || ''}
                    width="200rem"
                  />

                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        justifyContent: matchesLgAndDown ? 'center' : 'left',
                      }}
                    >
                      <Typography variant="h6">{`${t('number_of_stores')}:`}</Typography>
                      <Typography variant="h6">{`${numberOfStores} ${t('stores')}`}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        justifyContent: matchesLgAndDown ? 'center' : 'left',
                      }}
                    >
                      <Typography variant="h6">{`${t('maintenance_cost')}:`}</Typography>
                      <Typography variant="h6">
                        {`${formatCurrency(maintenanceCost || 0, i18n.language)}`}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </CardContent>
      )}
    </Card>
  );
};

enum HairColor {
  BLOND = 'BLOND',
  BLONDISH = 'BLONDISH',
  BLACK = 'BLACK',
  BLACKISH = 'BLACKISH',
  BROWN = 'BROWN',
  BROWNISH = 'BROWNISH',
  RED = 'RED',
}

enum EyeColor {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  BLACK = 'BLACK',
  BROWN = 'BROWN',
}

enum NoseColor {
  BLACK = 'BLACK',
  WHITE = 'WHITE',
}

const HAIR_COLORS = {
  BLACK: '#232323',
  BLACKISH: '#3E3E3E',
  BLOND: '#E5C86B',
  BLONDISH: '#8A704A',
  BROWN: '#562D1D',
  BROWNISH: '#5F4E34',
  RED: '#A03D22',
};

const getHairColor = (color: HairColor) => HAIR_COLORS[color];

export {
  HairColor, EyeColor, NoseColor, getHairColor,
};

import { FC } from 'react';
import {
  Card, CardContent, CardHeader, Grid, Stack, Typography,
} from '@mui/material';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { formatShortDate } from '../formatter/date';

type Props = {
  player?: Player,
};

export const InjuryTab: FC<Props> = (props: Props) => {
  const { player } = props;

  return (
    <Grid container spacing={1} sx={{ padding: 1 }}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title={`${player?.name} ${t('is_injured_until')}`} />
          <CardContent>
            <Stack direction="row">
              <img src="/assets/icons/injury.svg" width="50" alt={t('injury') || ''} />
              <Typography variant="h5" sx={{ marginTop: 1, marginLeft: 2 }}>
                {formatShortDate(player?.injuryStatus?.injuredUntil || '')}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

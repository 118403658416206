export enum PhysicalTrainingType {
  GENERAL = 'GENERAL',
  STRENGTH = 'STRENGTH',
  SPEED = 'SPEED',
  STAMINA = 'STAMINA',
  FORM = 'FORM',
  REST = 'REST',
}

export enum Intensity {
  VERY_LOW = 'VERY_LOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
}

export type PhysicalTraining = {
  id: string;
  type: PhysicalTrainingType;
  date: string;
  intensity: Intensity;
};

import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { t } from 'i18next';
import { FC } from 'react';

type Props = {
  position: string;
  showLegend?: boolean;
  width?: number;
  typographyVariant?: Variant
};

const POSITION_ICONS: Map<string, string> = new Map<string, string>([
  ['GOALKEEPER', '/assets/icons/goalkeeper.png'],
  ['SIDE_BACK', '/assets/icons/defender.png'],
  ['CENTER_BACK', '/assets/icons/defender.png'],
  ['DEFENDING_MIDFIELD', '/assets/icons/defender.png'],
  ['MIDFIELD', '/assets/icons/midfielder.png'],
  ['FLANK_MIDFIELD', '/assets/icons/midfielder.png'],
  ['ATTACKING_MIDFIELD', '/assets/icons/midfielder.png'],
  ['STRIKER', '/assets/icons/striker.png'],
  ['WING', '/assets/icons/striker.png'],
]);

export const Position: FC<Props> = (props: Props) => {
  const {
    position,
    showLegend,
    width,
    typographyVariant,
  } = props;

  return (
    <Stack direction="row" alignItems="center">
      <Box
        component="img"
        src={POSITION_ICONS.get(position)}
        alt="Player"
        sx={{ width: width || 50 }}
      />
      {(showLegend) ? (<Typography variant={typographyVariant || 'body1'} sx={{ marginLeft: 1 }}>{t(`player_positions.${position.toLowerCase()}`)}</Typography>) : null}
    </Stack>
  );
};

import { AxiosResponse } from 'axios';

import { axiosInstance } from '../axios';
import { FirebaseUser } from '../types';

export const syncUser = (data: FirebaseUser): Promise<AxiosResponse> => (
  axiosInstance.post(
    '/user/sync',
    data,
  )
);

import { ReactElement, ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

type Props = {
  children: ReactNode;
};

// TODO: below is a reference to success and error colors:
/*
.danger-button {
  background-image: -webkit-linear-gradient(bottom, #B22222 50%, #DC143C 51%);
  box-shadow: inset 0 2px 0 0 white, inset 0 -4px 0 0 #8B0000;
  color: white !important;
 }

.success-button {
  background-image: -webkit-linear-gradient(bottom, #2aad2a 50%, #32CD32 51%);
  box-shadow: inset 0 2px 0 0 white, inset 0 -4px 0 0 #006400;
  color: white;
}
*/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const theme = createTheme({
  typography: {
    h3: {
      textTransform: 'uppercase',
    },
    h4: {
      textTransform: 'initial',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '20px',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1.2rem !important',
          fontWeight: 'bold !important',
          border: 'none',
          borderRadius: '0.75em 0 0.75em 0 !important',
          padding: '0.50em 1em',
          WebkitTapHighlightColor: 'transparent',
          willChange: 'transform',
          transition: 'transform 0.1s ease !important',
          userSelect: 'none',
          cursor: 'pointer',

          '&:disabled': {
            opacity: 0.6,
          },

          '&:active': {
            transform: 'translateY(0.2em)',
            transition: 'transform 0.1s ease',
          },

          '& .MuiButton-startIcon': {
            marginTop: '3px',
            float: 'left',
          },
          '& .MuiButton-endIcon': {
            marginTop: '3px',
            float: 'right',
          },
          '& *': {
            '& >*:nth-of-type(1)': {
              fontSize: '25px',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          minHeight: 45,
          minWidth: 100,
          backgroundColor: '#FFBC00',
          // boxShadow: 'inset 0 2px 0 0 white, inset 0 -4px 0 0 #FF9100',
          color: '#001663',
          '&:hover': {
            // boxShadow: 'inset 0 2px 0 0 white, inset 0 -4px 0 0 #FF9100',
            backgroundColor: '#FFBC00',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFBC00',
            opacity: 0.5,
          },
        },
        outlined: {
          minHeight: 45,
          minWidth: 100,
          backgroundColor: '#000E59',
          color: '#FFCD00',
          border: '2px solid #FFCD00',
          '&:hover': {
            backgroundColor: '#000E59',
            border: '2px solid #FFCD00',
          },
          '&.Mui-disabled': {
            color: '#FFCD00',
            border: '2px solid #FFCD00',
            opacity: 0.5,
          },
        },
        text: {
          backgroundColor: 'transparent',
          color: '#FFCD00',
          minWidth: 'unset',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          // '&:active': {
          //   boxShadow: theme.shadows[2],
          // },
          '&.Mui-disabled': {
            textTransform: 'uppercase',
            opacity: 0.5,
            // color: theme.palette.highlight.main,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#FFCD00',
          },
          '&.Mui-disabled': {
            color: '#FFCD00',
            opacity: 0.5,
          },
        },
        shrink: {
          padding: '0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            borderColor: '#FFCD00',
            opacity: 0.5,
          },
        },
        input: {
          '&.Mui-disabled': {
            color: '#FFCD00',
            WebkitTextFillColor: '#FFCD00',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: '#FFCD00',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#FFCD00',

          '&.Mui-focused': {
            borderRadius: '0 1em 0 1em',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '1em 0 1em 0',
          color: '#FFCD00',
          borderColor: '#FFCD00',
          '&.Mui-focused': {
            borderRadius: '0 1em 0 1em',
          },
          '& .MuiSvgIcon-root': {
            fill: '#ffcd00',
          },
        },
        notchedOutline: {
          border: '2px solid #FFCD00 !important',
          '& fieldset': {
            border: '2px solid #FFCD00 !important',
          },
          '& .MuiSvgIcon-root': {
            fill: '#ffcd00',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          '.MuiPaper-root': {
            backgroundColor: '#001764',
            borderRadius: '1.75rem 0 1.75rem 0',
            border: '2px solid #ffcd00',
            padding: '1rem',
          },
          '&.MuiDialogContentText-root': {
            color: '#ffcd00',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#001764',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#ffcd00',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#ffcd00 !important',
            '&.Mui-active': {
              color: '#cca400 !important',
            },
          },
          '& .MuiStepIcon-text': {
            fill: '#001764',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#ffcd00',
          '&.Mui-active': {
            color: '#cca400',
            fontWeight: 'bold',
          },
          '&.Mui-completed': {
            color: '#ffcd00',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#FFCD00',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '1.75rem 0 1.75rem 0',
          border: '2px solid #ffcd00',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
  },
});

export const Theme = ({ children }: Props): ReactElement => (
  <ThemeProvider theme={createTheme()}>{children}</ThemeProvider>
);

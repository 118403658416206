export enum QueryKeys {
  GET_COACH_CHANGE_COSTS = 'get-coach-change-costs',
  GET_FACILITY_RECONSTRUCTION_COSTS = 'get-facility-reconstruction-costs',
  GET_SOCIAL_CLUB_RECONSTRUCTION_COSTS = 'get-social-club-reconstruction-costs',
  GET_ALL_FACILITIES = 'get-all-facilities',
  GET_AVAILABLE_GRASSROOTS_PLAYERS = 'get-available-grassroots-players',
  GET_HIRED_GRASSROOTS_PLAYERS = 'get-hired-grassroots-players',
  SEARCH_GRASSROOTS_PLAYERS = 'search-grassroots-players',
  GET_COUNTRY_COMPETITIONS = 'get-country-competitions',
}

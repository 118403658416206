import { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Player } from '../../data/types';
import { Face, Position } from '../../common/Player';
import { Flag } from '../../common/Flag';
import { AttributeBar } from '../../common/Player/AttributeBar';
import {
  AttributeType,
  getAggressivenessName,
  getAttributeName,
  getHonestyName,
} from '../../common/Player/attributes';

type Props = {
  player: Player;
  open: boolean;
  onClose: () => void;
  onHire: () => void;
  onReject: () => void;
};

export const AvailablePlayerDetails: FC<Props> = ({
  player,
  open,
  onClose,
  onHire,
  onReject,
}: Props) => {
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <SwipeableDrawer
      anchor={matchesXsOrSm ? 'bottom' : 'right'}
      onClose={() => onClose()}
      onOpen={() => { }}
      open={open}
      disableBackdropTransition
      sx={{
        '& .MuiPaper-root': {
          overflowY: 'scroll',
        },
      }}
    >
      <Box sx={{ textAlign: matchesXsOrSm ? 'left' : 'right' }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          pl: matchesXsOrSm ? 2 : 5,
          pr: matchesXsOrSm ? 2 : 5,
          pb: 2,
        }}
      >
        <Face
          body={player?.face?.body}
          hairStyle={player?.face?.hairStyle}
          hairColor={player?.face?.hairColor}
          eyeShape={player?.face?.eyeShape}
          eyeColor={player?.face?.eyeColor}
          noseShape={player?.face?.noseShape}
          mouthShape={player?.face?.mouthShape}
          beard={player?.face?.beard}
          eyebrows={player?.face?.eyebrows}
          width={matchesXsOrSm ? 100 : 120}
          height={matchesXsOrSm ? 100 : 120}
        />

        <Stack direction="column" spacing={0.5}>
          <Typography variant="h6">{player.name}</Typography>
          <Typography variant="h6">{`${player.age} ${t('years_old')}`}</Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'center',
            }}
          >
            <Flag countryCode={player.country.code} width={30} />
            <Typography variant="h6">{player.country.name}</Typography>
          </Stack>
          <Position
            position={player.position}
            showLegend
            width={25}
            typographyVariant="h6"
          />
        </Stack>

      </Stack>

      <Stack direction="column" spacing={0} sx={{ p: 2 }}>
        <Typography variant="h6">{t('psychological_attributes')}</Typography>
        <Typography variant="overline">{t('intelligence')}</Typography>
        <AttributeBar
          number={player.psychologicalAttributes.intelligence}
          levelName={getAttributeName(player.psychologicalAttributes.intelligence)}
          type={AttributeType.PSYCHOLOGICAL}
        />

        <Typography variant="overline">{t('aggressiveness')}</Typography>
        <AttributeBar
          number={player.psychologicalAttributes.aggressiveness}
          levelName={getAggressivenessName(player.psychologicalAttributes.aggressiveness)}
          inverted
          type={AttributeType.PSYCHOLOGICAL}
        />

        <Typography variant="overline">{t('honesty')}</Typography>
        <AttributeBar
          number={player.psychologicalAttributes.honesty}
          levelName={getHonestyName(player.psychologicalAttributes.honesty)}
          type={AttributeType.PSYCHOLOGICAL}
        />

        <Typography variant="overline">{t('leadership')}</Typography>
        <AttributeBar
          number={player.psychologicalAttributes.leadership}
          levelName={getAttributeName(player.psychologicalAttributes.leadership)}
          type={AttributeType.PSYCHOLOGICAL}
        />

        <Typography variant="overline">{t('discipline')}</Typography>
        <AttributeBar
          number={player.psychologicalAttributes?.discipline}
          levelName={getAttributeName(player.psychologicalAttributes.discipline)}
          type={AttributeType.PSYCHOLOGICAL}
        />

        <Typography variant="h6" sx={{ mt: 3 }}>{t('physical_attributes')}</Typography>
        <Typography variant="overline">{t('strength')}</Typography>
        <AttributeBar
          number={player.physicalAttributes.strength}
          levelName={getAttributeName(player.physicalAttributes.strength)}
          type={AttributeType.PHYSICAL}
        />
        <Typography variant="overline">{t('stamina')}</Typography>
        <AttributeBar
          number={player.physicalAttributes.stamina}
          levelName={getAttributeName(player.physicalAttributes.stamina)}
          type={AttributeType.PHYSICAL}
        />
        <Typography variant="overline">{t('speed')}</Typography>
        <AttributeBar
          number={player.physicalAttributes.speed}
          levelName={getAttributeName(player.physicalAttributes.speed)}
          type={AttributeType.PHYSICAL}
        />
        <Typography variant="overline">{t('form')}</Typography>
        <AttributeBar
          number={player.physicalAttributes.form}
          levelName={getAttributeName(player.physicalAttributes.form)}
          type={AttributeType.PHYSICAL}
        />

        <Typography variant="h6" sx={{ mt: 3 }}>{t('technical_attributes')}</Typography>
        <Typography variant="overline">{t('reflexes')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.reflexes || 0}
          levelName={getAttributeName(player?.technicalAttributes?.reflexes || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">{t('positioning')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.positioning || 0}
          levelName={getAttributeName(player?.technicalAttributes?.positioning || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">
          {t('aerial_playing')}
        </Typography>
        <AttributeBar
          number={player?.technicalAttributes?.aerialPlaying || 0}
          levelName={getAttributeName(player?.technicalAttributes?.aerialPlaying || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">{t('tackling')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.tackling || 0}
          levelName={getAttributeName(player?.technicalAttributes?.tackling || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">{t('shooting')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.shooting || 0}
          levelName={getAttributeName(player?.technicalAttributes?.shooting || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">{t('creation')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.creation || 0}
          levelName={getAttributeName(player?.technicalAttributes?.creation || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">{t('technique')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.technique || 0}
          levelName={getAttributeName(player?.technicalAttributes?.technique || 0)}
          type={AttributeType.TECHNICAL}
        />
        <Typography variant="overline">{t('passing')}</Typography>
        <AttributeBar
          number={player?.technicalAttributes?.passing || 0}
          levelName={getAttributeName(player?.technicalAttributes?.passing || 0)}
          type={AttributeType.TECHNICAL}
        />

        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            size="small"
            fullWidth
            onClick={() => {
              onClose();
              onHire();
            }}
          >
            {t('hire')}
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            fullWidth
            onClick={() => {
              onClose();
              onReject();
            }}
          >
            {t('reject')}
          </Button>
        </Stack>
      </Stack>

    </SwipeableDrawer>
  );
};

import { FC, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { formatCurrency, formatShortDate } from '../../common/formatter';
import { Loader } from '../../common';
import { useGetStadiumReconstructEstimations, useReconstructStadium } from '../../data/hooks/useFacilities';
import { ApiError } from '../../data/types/ApiError';
import { QueryKeys } from '../../data/hooks/queryKeys';
import { Stadium } from '../../data/types/facilities/Stadium';

type Props = {
  stadium: Stadium;
  open: boolean;
  onClose: () => void;
};

export const ReconstructStadiumDialog: FC<Props> = (props: Props) => {
  const {
    stadium,
    open,
    onClose,
  } = props;
  const [northSeats, setNorthSeats] = useState<number>(0);
  const [northwestSeats, setNorthwestSeats] = useState<number>(0);
  const [northeastSeats, setNortheastSeats] = useState<number>(0);
  const [westSeats, setWestSeats] = useState<number>(0);
  const [eastSeats, setEastSeats] = useState<number>(0);
  const [southSeats, setSouthSeats] = useState<number>(0);
  const [southwestSeats, setSouthwestSeats] = useState<number>(0);
  const [southeastSeats, setSoutheastSeats] = useState<number>(0);
  const [newTotalCapacity, setNewTotalCapacity] = useState<number>(0);
  const [upgradeTurfQuality, setUpgradeTurfQuality] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({});
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const matchesXsOrSm = theme.breakpoints.down('md');
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading: isLoadingReconstructionEstimation,
    data: reconstructionEstimations,
  } = useGetStadiumReconstructEstimations(request);
  const {
    isLoading: isLoadingReconstructStadium,
    mutateFn: reconstructStadium,
  } = useReconstructStadium();

  const currentCapacity = stadium.northBleachers + stadium.northwestBleachers
    + stadium.northeastBleachers + stadium.westBleachers + stadium.eastBleachers
    + stadium.southBleachers + stadium.southwestBleachers + stadium.southeastBleachers;

  useEffect(() => {
    const newRequest = {
      northSeats,
      northwestSeats,
      northeastSeats,
      westSeats,
      eastSeats,
      southSeats,
      southwestSeats,
      southeastSeats,
      upgradeTurfQuality,
    };
    setRequest(newRequest);
    setNewTotalCapacity(
      northSeats + northwestSeats + northeastSeats
      + westSeats + eastSeats + southSeats
      + southwestSeats + southeastSeats,
    );
  }, [
    northSeats,
    northwestSeats,
    northeastSeats,
    westSeats,
    eastSeats,
    southSeats,
    southwestSeats,
    southeastSeats,
    upgradeTurfQuality,
  ]);

  const handleOnClose = () => {
    onClose();
  };

  const handleUpgradeTurf = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpgradeTurfQuality(event.target.checked);
  };

  const handleConfirmChangeStadium = () => {
    reconstructStadium({ ...request }).then(() => {
      enqueueSnackbar(t('stadium_reconstruction_started'), { variant: 'success' });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
      });
    }).catch((data: AxiosError) => {
      const errorResponse = data.response?.data as ApiError;
      enqueueSnackbar(t(errorResponse.error || ''), { variant: 'error' });
    }).finally(() => {
      onClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('reconstruct_stadium')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="left" alignItems="left" sx={{ paddingTop: 1 }}>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_maintenance_cost')}</Typography>
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
              {formatCurrency(stadium.maintenanceCost || 0, i18n.language)}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_capacity')}</Typography>
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
              {currentCapacity}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_turf_quality')}</Typography>
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
              {t(`turf_quality_${stadium.turfQuality.toLowerCase()}`)}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center" justifyItems="center" sx={{ mt: 1 }}>
            <InfoIcon color="disabled" />
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
              {t('upgrade_stadium_info')}
            </Typography>
          </Stack>

          <FormControl fullWidth>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('north')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={northSeats}
                onChange={(e) => setNorthSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('northwest')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={northwestSeats}
                onChange={(e) => setNorthwestSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('northeast')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={northeastSeats}
                onChange={(e) => setNortheastSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('west')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={westSeats}
                onChange={(e) => setWestSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('east')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={eastSeats}
                onChange={(e) => setEastSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('south')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={southSeats}
                onChange={(e) => setSouthSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('southwest')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={southwestSeats}
                onChange={(e) => setSouthwestSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('southeast')}</Typography>
              <TextField
                variant="outlined"
                type="number"
                value={southeastSeats}
                onChange={(e) => setSoutheastSeats(parseInt(e.target.value, 10))}
                size="small"
                sx={{ width: 130 }}
              />
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleUpgradeTurf} />}
                label={t('upgrade_turf_quality')}
              />
            </FormGroup>
          </FormControl>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('new_total_capacity')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {newTotalCapacity}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('reconstruction_costs')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(reconstructionEstimations?.reconstructionCost || 0, i18n.language)}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('new_maintenance_cost')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} height="100%" />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(reconstructionEstimations?.maintenanceCost || 0, i18n.language)}
              </Typography>
            )}

          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('reconstruction_finishes_on')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} height="100%" />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {reconstructionEstimations?.finishesOn !== undefined && reconstructionEstimations?.finishesOn !== ''
                  ? formatShortDate(reconstructionEstimations.finishesOn, i18n.language, false)
                  : ''}
              </Typography>
            )}
          </Stack>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} disabled={false}>{t('cancel')}</Button>
        <Button
          onClick={handleConfirmChangeStadium}
          variant="contained"
          color="primary"
          disabled={
            isLoadingReconstructionEstimation
            || isLoadingReconstructStadium
            || (newTotalCapacity === 0 && upgradeTurfQuality === false)
          }
        >
          {isLoadingReconstructionEstimation || isLoadingReconstructStadium ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

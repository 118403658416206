// source: live-match.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Type', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Zone', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Division', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Formation', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.LineUpPosition', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchResult', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatus', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchType', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Phase', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution', null, global);
goog.exportSymbol('proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.repeatedFields_, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.repeatedFields_, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.repeatedFields_, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.displayName = 'proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMatchIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string match_ids = 1;
 * @return {!Array<string>}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.prototype.getMatchIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.prototype.setMatchIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.prototype.addMatchIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchRequest.prototype.clearMatchIdsList = function() {
  return this.setMatchIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    match: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMatch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatus = {
  UNKNOWN_STATUS: 0,
  SCHEDULED: 1,
  READY_TO_START: 2,
  PLAYING: 3,
  FINISHED: 4
};

/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchResult = {
  UNKNOWN_RESULT: 0,
  HOME_WON: 1,
  DRAW: 2,
  AWAY_WON: 3
};

/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Formation = {
  UNKNOWN_FORMATION: 0,
  THREE_FIVE_TWO: 1,
  THREE_FOUR_THREE: 2,
  THREE_THREE_ONE_THREE: 3,
  FOUR_FOUR_TWO: 4,
  FOUR_FOUR_ONE_ONE: 5,
  FOUR_ONE_TWO_ONE_TWO: 6,
  FOUR_ONE_TWO_ONE_TWO_OFFENSIVE: 7,
  FOUR_ONE_TWO_TWO_ONE: 8,
  FOUR_ONE_THREE_TWO: 9,
  FOUR_THREE_THREE: 10,
  FOUR_THREE_ONE_TWO: 11,
  FOUR_ONE_TWO_THREE: 12,
  FOUR_THREE_TWO_ONE: 13,
  FOUR_TWO_THREE_ONE: 14,
  FOUR_TWO_TWO_TWO: 15,
  FOUR_FIVE_ONE: 16,
  FIVE_FOUR_ONE: 17,
  FIVE_THREE_TWO: 18
};

/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchType = {
  UNKNOWN_TYPE: 0,
  LEAGUE_MATCH: 1,
  REGIONAL_TOURNAMENT_MATCH: 2,
  NATIONAL_CUP_MATCH: 3,
  INTERNATIONAL_CUP_MATCH: 4,
  FRIENDLY_MATCH: 5,
  U20_LEAGUE: 6
};

/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Division = {
  UNKNOWN_DIVISION: 0,
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5,
  SIXTH: 6,
  SEVENTH: 7,
  EIGHTH: 8,
  NINTH: 9,
  TENTH: 10
};

/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Phase = {
  UNKNOWN_PHASE: 0,
  NOT_APPLICABLE: 1,
  FIRST_PHASE: 2,
  SECOND_PHASE: 3,
  THIRD_PHASE: 4,
  FORTH_PHASE: 5,
  FIFTH_PHASE: 6,
  GROUP_PHASE: 7,
  ROUND_OF_16: 8,
  QUARTER_FINAL: 9,
  SEMI_FINAL: 10,
  FINAL: 11
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, 0),
    away: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAway();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 home = 1;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.prototype.getHome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.prototype.setHome = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 away = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.prototype.getAway = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ScoreType.prototype.setAway = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: (f = msg.getHome()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.toObject(includeInstance, f),
    away: (f = msg.getAway()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.deserializeBinaryFromReader);
      msg.setHome(value);
      break;
    case 2:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.deserializeBinaryFromReader);
      msg.setAway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.serializeBinaryToWriter
    );
  }
  f = message.getAway();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalChances: jspb.Message.getFieldWithDefault(msg, 1, 0),
    yellowCards: jspb.Message.getFieldWithDefault(msg, 2, 0),
    redCards: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fouls: jspb.Message.getFieldWithDefault(msg, 4, 0),
    possession: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalPasses: jspb.Message.getFieldWithDefault(msg, 6, 0),
    rightPasses: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ballsWon: jspb.Message.getFieldWithDefault(msg, 8, 0),
    corners: jspb.Message.getFieldWithDefault(msg, 9, 0),
    offsides: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalChances(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYellowCards(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRedCards(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFouls(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPossession(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPasses(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRightPasses(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBallsWon(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCorners(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffsides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalChances();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYellowCards();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRedCards();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFouls();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPossession();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalPasses();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRightPasses();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBallsWon();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCorners();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOffsides();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional int32 goal_chances = 1;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getGoalChances = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setGoalChances = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 yellow_cards = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getYellowCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setYellowCards = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 red_cards = 3;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getRedCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setRedCards = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 fouls = 4;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getFouls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setFouls = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 possession = 5;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getPossession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setPossession = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_passes = 6;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getTotalPasses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setTotalPasses = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 right_passes = 7;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getRightPasses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setRightPasses = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 balls_won = 8;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getBallsWon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setBallsWon = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 corners = 9;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getCorners = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setCorners = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 offsides = 10;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.getOffsides = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats.prototype.setOffsides = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional ClubStats home = 1;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.getHome = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats, 1));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.setHome = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.clearHome = function() {
  return this.setHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.hasHome = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ClubStats away = 2;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.getAway = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats, 2));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.ClubStats|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.setAway = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.clearAway = function() {
  return this.setAway(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchStatistics.prototype.hasAway = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tiredness: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    face: (f = msg.getFace()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.toObject(includeInstance, f),
    injured: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    shirtNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    motivation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    energy: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    minutesPlayed: jspb.Message.getFieldWithDefault(msg, 10, 0),
    morale: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    lineupPosition: jspb.Message.getFieldWithDefault(msg, 11, 0),
    goalsScored: jspb.Message.getFieldWithDefault(msg, 12, 0),
    performance: jspb.Message.getFieldWithDefault(msg, 13, 0),
    freeKickTaker: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    cornerKickTaker: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    penaltyTaker: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    captain: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    yellowCards: jspb.Message.getFieldWithDefault(msg, 18, 0),
    redCards: jspb.Message.getFieldWithDefault(msg, 19, 0),
    assistsGiven: jspb.Message.getFieldWithDefault(msg, 20, 0),
    playing: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    matchPerformance: jspb.Message.getFieldWithDefault(msg, 22, 0),
    substituted: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    replacedBy: (f = msg.getReplacedBy()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject(includeInstance, f),
    sentOff: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    replacedPlayerId: jspb.Message.getFieldWithDefault(msg, 26, ""),
    enteredDuringMatch: jspb.Message.getBooleanFieldWithDefault(msg, 27, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTiredness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.deserializeBinaryFromReader);
      msg.setFace(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInjured(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShirtNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMotivation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnergy(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesPlayed(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMorale(value);
      break;
    case 11:
      var value = /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.LineUpPosition} */ (reader.readEnum());
      msg.setLineupPosition(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalsScored(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerformance(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFreeKickTaker(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCornerKickTaker(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPenaltyTaker(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCaptain(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYellowCards(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRedCards(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssistsGiven(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlaying(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMatchPerformance(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubstituted(value);
      break;
    case 24:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader);
      msg.setReplacedBy(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSentOff(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setReplacedPlayerId(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnteredDuringMatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTiredness();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.serializeBinaryToWriter
    );
  }
  f = message.getInjured();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getShirtNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMotivation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getEnergy();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getMinutesPlayed();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMorale();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLineupPosition();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getGoalsScored();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPerformance();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getFreeKickTaker();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCornerKickTaker();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPenaltyTaker();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCaptain();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getYellowCards();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getRedCards();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getAssistsGiven();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getPlaying();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getMatchPerformance();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getSubstituted();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getReplacedBy();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter
    );
  }
  f = message.getSentOff();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getReplacedPlayerId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getEnteredDuringMatch();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.LineUpPosition = {
  UNKNOWN_LINE_UP_POSITION: 0,
  GOALKEEPER: 1,
  RIGHT_SIDE_BACK_DEFENDER: 2,
  LEFT_DEFENDER: 3,
  CENTER_DEFENDER: 4,
  RIGHT_DEFENDER: 5,
  LEFT_SIDE_BACK_DEFENDER: 6,
  LEFT_DEFENDING_MIDFIELDER: 7,
  CENTER_DEFENDING_MIDFIELDER: 8,
  RIGHT_DEFENDING_MIDFIELDER: 9,
  LEFT_FLANK: 10,
  LEFT_MIDFIELDER: 11,
  CENTER_MIDFIELDER: 12,
  RIGHT_MIDFIELDER: 13,
  RIGHT_FLANK: 14,
  LEFT_ATTACKING_MIDFIELDER: 15,
  CENTER_ATTACKING_MIDFIELDER: 16,
  RIGHT_ATTACKING_MIDFIELDER: 17,
  LEFT_WING: 18,
  LEFT_STRIKER: 19,
  STRIKER: 20,
  RIGHT_STRIKER: 21,
  RIGHT_WING: 22
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hairStyle: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hairColor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eyeShape: jspb.Message.getFieldWithDefault(msg, 4, 0),
    eyeColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    noseShape: jspb.Message.getFieldWithDefault(msg, 6, 0),
    noseColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mouthShape: jspb.Message.getFieldWithDefault(msg, 8, 0),
    beard: jspb.Message.getFieldWithDefault(msg, 9, 0),
    eyebrows: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBody(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHairStyle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHairColor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEyeShape(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEyeColor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoseShape(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoseColor(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMouthShape(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeard(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEyebrows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHairStyle();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHairColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEyeShape();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEyeColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoseShape();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNoseColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMouthShape();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getBeard();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getEyebrows();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional int32 body = 1;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getBody = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setBody = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 hair_style = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getHairStyle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setHairStyle = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string hair_color = 3;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getHairColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setHairColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 eye_shape = 4;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getEyeShape = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setEyeShape = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string eye_color = 5;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getEyeColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setEyeColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 nose_shape = 6;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getNoseShape = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setNoseShape = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string nose_color = 7;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getNoseColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setNoseColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 mouth_shape = 8;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getMouthShape = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setMouthShape = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 beard = 9;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getBeard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setBeard = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 eyebrows = 10;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.getEyebrows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType.prototype.setEyebrows = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double tiredness = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getTiredness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setTiredness = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FaceType face = 4;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getFace = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType, 4));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.FaceType|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setFace = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.clearFace = function() {
  return this.setFace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.hasFace = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool injured = 5;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getInjured = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setInjured = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 shirt_number = 6;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getShirtNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setShirtNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double motivation = 7;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getMotivation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setMotivation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double energy = 8;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 minutes_played = 10;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getMinutesPlayed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setMinutesPlayed = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double morale = 9;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getMorale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setMorale = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional LineUpPosition lineup_position = 11;
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.LineUpPosition}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getLineupPosition = function() {
  return /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.LineUpPosition} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.LineUpPosition} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setLineupPosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int32 goals_scored = 12;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getGoalsScored = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setGoalsScored = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 performance = 13;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getPerformance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setPerformance = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool free_kick_taker = 14;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getFreeKickTaker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setFreeKickTaker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool corner_kick_taker = 15;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getCornerKickTaker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setCornerKickTaker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool penalty_taker = 16;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getPenaltyTaker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setPenaltyTaker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool captain = 17;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getCaptain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setCaptain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int32 yellow_cards = 18;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getYellowCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setYellowCards = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 red_cards = 19;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getRedCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setRedCards = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 assists_given = 20;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getAssistsGiven = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setAssistsGiven = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional bool playing = 21;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getPlaying = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setPlaying = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional int32 match_performance = 22;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getMatchPerformance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setMatchPerformance = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool substituted = 23;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getSubstituted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setSubstituted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional MatchPlayer replaced_by = 24;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getReplacedBy = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, 24));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setReplacedBy = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.clearReplacedBy = function() {
  return this.setReplacedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.hasReplacedBy = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool sent_off = 25;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getSentOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setSentOff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional string replaced_player_id = 26;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getReplacedPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setReplacedPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional bool entered_during_match = 27;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.getEnteredDuringMatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.prototype.setEnteredDuringMatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    formation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Formation} */ (reader.readEnum());
      msg.setFormation(value);
      break;
    case 3:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Formation formation = 2;
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Formation}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.getFormation = function() {
  return /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Formation} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Formation} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.setFormation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated MatchPlayer players = 3;
 * @return {!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer>}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, 3));
};


/**
 * @param {!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer>} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.LineUp.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    substitutionsDone: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject, includeInstance),
    programmedSubstitutionsList: jspb.Message.toObjectList(msg.getProgrammedSubstitutionsList(),
    proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubstitutionsDone(value);
      break;
    case 3:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    case 4:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.deserializeBinaryFromReader);
      msg.addProgrammedSubstitutions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubstitutionsDone();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter
    );
  }
  f = message.getProgrammedSubstitutionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 substitutions_done = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.getSubstitutionsDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.setSubstitutionsDone = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MatchPlayer players = 3;
 * @return {!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer>}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, 3));
};


/**
 * @param {!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer>} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * repeated ProgrammedSubstitution programmed_substitutions = 4;
 * @return {!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution>}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.getProgrammedSubstitutionsList = function() {
  return /** @type{!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution, 4));
};


/**
 * @param {!Array<!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution>} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.setProgrammedSubstitutionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.addProgrammedSubstitutions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Bench.prototype.clearProgrammedSubstitutionsList = function() {
  return this.setProgrammedSubstitutionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inPlayer: (f = msg.getInPlayer()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject(includeInstance, f),
    atMinute: jspb.Message.getFieldWithDefault(msg, 3, 0),
    done: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    outPlayer: (f = msg.getOutPlayer()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader);
      msg.setInPlayer(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAtMinute(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDone(value);
      break;
    case 5:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.deserializeBinaryFromReader);
      msg.setOutPlayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInPlayer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter
    );
  }
  f = message.getAtMinute();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDone();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOutPlayer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MatchPlayer in_player = 2;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.getInPlayer = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, 2));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.setInPlayer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.clearInPlayer = function() {
  return this.setInPlayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.hasInPlayer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 at_minute = 3;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.getAtMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.setAtMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool done = 4;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.getDone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.setDone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional MatchPlayer out_player = 5;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.getOutPlayer = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer, 5));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchPlayer|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.setOutPlayer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.clearOutPlayer = function() {
  return this.setOutPlayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.ProgrammedSubstitution.prototype.hasOutPlayer = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    zone: jspb.Message.getFieldWithDefault(msg, 3, 0),
    result: jspb.Message.getFieldWithDefault(msg, 4, ""),
    moveType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    attacker: (f = msg.getAttacker()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject(includeInstance, f),
    defender: (f = msg.getDefender()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject(includeInstance, f),
    goalkeeper: (f = msg.getGoalkeeper()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject(includeInstance, f),
    inPlayer: (f = msg.getInPlayer()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject(includeInstance, f),
    outPlayer: (f = msg.getOutPlayer()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject(includeInstance, f),
    attackingClub: (f = msg.getAttackingClub()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.toObject(includeInstance, f),
    defendingClub: (f = msg.getDefendingClub()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.toObject(includeInstance, f),
    atMinute: jspb.Message.getFieldWithDefault(msg, 13, 0),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Zone} */ (reader.readEnum());
      msg.setZone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoveType(value);
      break;
    case 6:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader);
      msg.setAttacker(value);
      break;
    case 7:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader);
      msg.setDefender(value);
      break;
    case 8:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader);
      msg.setGoalkeeper(value);
      break;
    case 9:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader);
      msg.setInPlayer(value);
      break;
    case 10:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader);
      msg.setOutPlayer(value);
      break;
    case 11:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.deserializeBinaryFromReader);
      msg.setAttackingClub(value);
      break;
    case 12:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.deserializeBinaryFromReader);
      msg.setDefendingClub(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAtMinute(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getZone();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMoveType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAttacker();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter
    );
  }
  f = message.getDefender();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter
    );
  }
  f = message.getGoalkeeper();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter
    );
  }
  f = message.getInPlayer();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter
    );
  }
  f = message.getOutPlayer();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter
    );
  }
  f = message.getAttackingClub();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.serializeBinaryToWriter
    );
  }
  f = message.getDefendingClub();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.serializeBinaryToWriter
    );
  }
  f = message.getAtMinute();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getOrderNumber();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Type = {
  UNKNOWN_TYPE: 0,
  MOVE: 1,
  SUBSTITUTION: 2
};

/**
 * @enum {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Zone = {
  UNKNOWN_ZONE: 0,
  BACK_LEFT: 1,
  BACK_CENTER: 2,
  BACK_RIGHT: 3,
  MIDDLE_LEFT: 4,
  MIDDLE_CENTER: 5,
  MIDDLE_RIGHT: 6,
  FORWARD_LEFT: 7,
  FORWARD_CENTER: 8,
  FORWARD_RIGHT: 9
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shirtNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    action: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShirtNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShirtNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 shirt_number = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.getShirtNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.setShirtNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string action = 3;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Type}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getType = function() {
  return /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Type} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Zone zone = 3;
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Zone}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getZone = function() {
  return /** @type {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Zone} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Zone} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setZone = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string result = 4;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string move_type = 5;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getMoveType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setMoveType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Player attacker = 6;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getAttacker = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player, 6));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setAttacker = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearAttacker = function() {
  return this.setAttacker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasAttacker = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Player defender = 7;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getDefender = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player, 7));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setDefender = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearDefender = function() {
  return this.setDefender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasDefender = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Player goalkeeper = 8;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getGoalkeeper = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player, 8));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setGoalkeeper = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearGoalkeeper = function() {
  return this.setGoalkeeper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasGoalkeeper = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Player in_player = 9;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getInPlayer = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player, 9));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setInPlayer = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearInPlayer = function() {
  return this.setInPlayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasInPlayer = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Player out_player = 10;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getOutPlayer = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player, 10));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Player|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setOutPlayer = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearOutPlayer = function() {
  return this.setOutPlayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasOutPlayer = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Club attacking_club = 11;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getAttackingClub = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club, 11));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setAttackingClub = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearAttackingClub = function() {
  return this.setAttackingClub(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasAttackingClub = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Club defending_club = 12;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getDefendingClub = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club, 12));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.Club|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setDefendingClub = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.clearDefendingClub = function() {
  return this.setDefendingClub(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.hasDefendingClub = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 at_minute = 13;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getAtMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setAtMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 order_number = 14;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.getOrderNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Commentary.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.toObject = function(includeInstance, msg) {
  var f, obj = {
    northSeats: jspb.Message.getFieldWithDefault(msg, 1, 0),
    northwestSeats: jspb.Message.getFieldWithDefault(msg, 2, 0),
    northeastSeats: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eastSeats: jspb.Message.getFieldWithDefault(msg, 4, 0),
    westSeats: jspb.Message.getFieldWithDefault(msg, 5, 0),
    southSeats: jspb.Message.getFieldWithDefault(msg, 6, 0),
    southwestSeats: jspb.Message.getFieldWithDefault(msg, 7, 0),
    southeastSeats: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNorthSeats(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNorthwestSeats(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNortheastSeats(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEastSeats(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWestSeats(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSouthSeats(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSouthwestSeats(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSoutheastSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNorthSeats();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNorthwestSeats();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNortheastSeats();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEastSeats();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWestSeats();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSouthSeats();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSouthwestSeats();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSoutheastSeats();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 north_seats = 1;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getNorthSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setNorthSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 northwest_seats = 2;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getNorthwestSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setNorthwestSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 northeast_seats = 3;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getNortheastSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setNortheastSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 east_seats = 4;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getEastSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setEastSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 west_seats = 5;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getWestSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setWestSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 south_seats = 6;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getSouthSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setSouthSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 southwest_seats = 7;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getSouthwestSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setSouthwestSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 southeast_seats = 8;
 * @return {number}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.getSoutheastSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.Attendance.prototype.setSoutheastSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstColor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secondColor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstColor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecondColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string first_color = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.prototype.getFirstColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.prototype.setFirstColor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string second_color = 2;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.prototype.getSecondColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.prototype.setSecondColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.toObject = function(opt_includeInstance) {
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    crest: (f = msg.getCrest()) && proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.toObject(includeInstance, f),
    isBot: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub;
  return proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    case 4:
      var value = new proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType;
      reader.readMessage(value,proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.deserializeBinaryFromReader);
      msg.setCrest(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCrest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType.serializeBinaryToWriter
    );
  }
  f = message.getIsBot();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abbreviation = 3;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.setAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CrestType crest = 4;
 * @return {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.getCrest = function() {
  return /** @type{?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType} */ (
    jspb.Message.getWrapperField(this, proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType, 4));
};


/**
 * @param {?proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.CrestType|undefined} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} returns this
*/
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.setCrest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.clearCrest = function() {
  return this.setCrest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.hasCrest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_bot = 5;
 * @return {boolean}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.getIsBot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.MatchClub.prototype.setIsBot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string match = 2;
 * @return {string}
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.prototype.getMatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse} returns this
 */
proto.br.com.elevenstars.service.live_match_updates.api.LiveMatchResponse.prototype.setMatch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.br.com.elevenstars.service.live_match_updates.api);

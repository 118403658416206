import { FC, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserClub } from '../../data/hooks/useClub';
import { formatCurrency } from '../../common/formatter';
import { SetSponsorDialog } from './SetSponsorDialog';

export const SponsorsCard: FC = () => {
  const [chooseSponsorDialogOpen, setChooseSponsorDialogOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const matchesLgAndDown = useMediaQuery(theme.breakpoints.down('xl'));

  const { isLoading, isFetching, data: club } = useUserClub();
  const loading = isLoading || isFetching;

  return (
    <Card sx={{ height: '100%' }} variant="outlined">
      <CardHeader title={t('sponsor')} />
      <CardContent sx={{ height: '100%' }}>
        {loading ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton variant="rounded" width="100%" height={200} />
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column" spacing={1.5}>
                <Skeleton variant="rounded" width="100%" height={40} />
                <Skeleton variant="rounded" width="100%" height={40} />
                <Skeleton variant="rounded" width="100%" height={40} />
                <Skeleton variant="rounded" width="100%" height={40} />
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Stack direction="column" justifyContent="center">
            {club?.sponsor === null ? (
              <Stack direction="column" alignItems="center" justifyItems="center" spacing={2}>
                <Typography variant="h5">{t('no_sponsor')}</Typography>
                <Button variant="contained" color="primary" onClick={() => setChooseSponsorDialogOpen(true)}>
                  {t('select_sponsor')}
                </Button>
              </Stack>
            ) : (
              <Stack direction={{ xs: 'column', xl: 'row' }} alignItems="center" justifyContent="space-between" spacing={matchesLgAndDown ? 0 : 2}>
                <img
                  src={`/assets/icons/sponsors/${club?.sponsor?.name.toLowerCase()}.svg`}
                  alt={club?.sponsor?.name}
                  width={matchesLgAndDown ? '70%' : '45%'}
                  height="100%"
                />

                <Stack
                  direction={{ xs: 'row', xl: 'column' }}
                  spacing={2}
                  justifyContent={{ xs: 'center', xl: 'space-between' }}
                  alignItems="center"
                  sx={{ width: '100%', mt: 2 }}
                >
                  <Typography variant={matchesLgAndDown ? 'body1' : 'h6'}>{t('weekly_payment')}</Typography>
                  <Typography variant={matchesLgAndDown ? 'body1' : 'h6'} fontWeight="bold">
                    {formatCurrency(club?.sponsor?.weeklyPayment || 0, i18n.language)}
                  </Typography>
                </Stack>

                <Stack
                  direction={{ xs: 'row', xl: 'column' }}
                  spacing={2}
                  justifyContent={{ xs: 'center', xl: 'space-between' }}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Typography variant={matchesLgAndDown ? 'body1' : 'h6'}>{t('victory_bonus')}</Typography>
                  <Typography variant={matchesLgAndDown ? 'body1' : 'h6'} fontWeight="bold">
                    {formatCurrency(club?.sponsor?.victoryBonus || 0, i18n.language)}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}

      </CardContent>

      <SetSponsorDialog
        open={chooseSponsorDialogOpen}
        onClose={() => setChooseSponsorDialogOpen(false)}
      />
    </Card>
  );
};

import { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Transaction } from '../../data/types/clubs/FinancialReport';
import { formatCurrency, formatLongDateTime } from '../../common/formatter';
import i18n from '../../i18n';

type Props = {
  loading: boolean;
  transactions?: Transaction[];
};

export const LoadingSkeleton: FC = () => (
  <>
    {[...Array(12)].map(() => (
      <Box
        sx={{
          height: '50px',
          my: 0,
          mx: 1,
        }}
        key={Math.random()}
      >
        <Skeleton variant="rounded" height={42} />
      </Box>
    ))}
  </>
);

export const TransactionsCard: FC<Props> = (props: Props) => {
  const { loading, transactions } = props;
  const columnsDefinition: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: t('date') || 'Date',
      editable: false,
      sortable: true,
      pinnable: true,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1" textTransform="capitalize">{formatLongDateTime(params.value, i18n.language)}</Typography>
      ),
    },
    {
      field: 'amount',
      headerName: t('amount') || 'Amount',
      editable: false,
      minWidth: 120,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1" textAlign="right">{formatCurrency(params.value, i18n.language)}</Typography>
      ),
    },
    {
      field: 'direction',
      headerName: t('type') || 'Type',
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={
              params.value === 'INCOME'
                ? '/assets/icons/entered-match.svg'
                : '/assets/icons/left-match.svg'
            }
            sx={{ width: 20, height: 20 }}
          />
          <Typography variant="body1">{t((params.value as string).toLowerCase())}</Typography>
        </Stack>
      ),
      minWidth: 120,
    },
    {
      field: 'description',
      headerName: t('description') || 'Description',
      editable: false,
      minWidth: 450,
      renderCell: (params: GridRenderCellParams) => <Typography variant="body1">{t(params.value)}</Typography>,
    },
  ];

  return (
    <Card variant="outlined">
      <CardHeader title={t('latest_transactions')} />
      <CardContent>
        <DataGrid
          columns={columnsDefinition}
          rows={transactions || []}
          rowHeight={40}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pageSizeOptions={[10, 20]}
          loading={loading}
          sx={{
            '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-virtualScroller': {
              minHeight: '350px',
              marginTop: 1,
            },
          }}
          slots={{
            loadingOverlay: LoadingSkeleton,
          }}
          disableColumnFilter
          disableColumnMenu
        />
      </CardContent>
    </Card>
  );
};

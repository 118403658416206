import {
  Card,
  CardContent,
  Grid,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import { Player } from '../../data/types';
import { PlayersList } from '../../common/Player/PlayersList';
import { PlayerDetails } from '../../common/Player/PlayerDetails';
import { usePlayers } from '../../data/hooks';

export const PlayersPage: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | undefined>();
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>();
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const { isFetching, data: players } = usePlayers();

  if (isFetching && selectedPlayer !== undefined) {
    // If we are loading the players again, remove the selected player
    setSelectedPlayer(undefined);
  } else if (!isFetching && selectedPlayer === undefined && !selectedPlayerId && players) {
    // We are done loading things and we don't have a selected player, so we select the first one
    setSelectedPlayer(players[0]);
    setSelectedPlayerId(players[0].id);
  } else if (!isFetching && selectedPlayer === undefined && selectedPlayerId && players) {
    // We are done loading things and we have a selected player from before, so we select it
    const player = players.find((p) => p.id === selectedPlayerId);

    // We need this check because the player might have been fired
    if (player) {
      setSelectedPlayer(player);
      setSelectedPlayerId(player?.id);
    } else {
      setSelectedPlayer(players[0]);
      setSelectedPlayerId(players[0].id);
    }
  }

  const handleOnPlayerClick = (player: Player) => {
    // if (ids === undefined || ids.length === 0) {
    //   // If enters here, it means the player have been fired
    //   if (players) {
    //     setSelectedPlayer(players[0]);
    //   }
    //   return;
    // }

    setSelectedPlayer(player);
    setSelectedPlayerId(player.id);
    setDrawerOpen(true);
  };

  return (
    <Grid container spacing={2} sx={{ padding: '1rem', height: '800px' }}>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          variant="outlined"
          sx={{
            height: '100%',
            overflow: 'hidden',
            marginBottom: '10px',
          }}
        >
          <CardContent>
            <PlayersList
              players={players || []}
              loading={isFetching}
              onPlayerClick={handleOnPlayerClick}
            />
          </CardContent>
        </Card>
      </Grid>
      {matchesXsOrSm ? (
        <SwipeableDrawer
          anchor="bottom"
          onOpen={() => setDrawerOpen(true)}
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          disableBackdropTransition
          sx={{
            '& .MuiPaper-root': {
              overflow: 'scroll',
            },
          }}
        >
          <PlayerDetails
            player={selectedPlayer}
            isGrassroots={false}
            closeDrawerCallback={() => {
              setDrawerOpen(false);
            }}
          />
        </SwipeableDrawer>
      ) : (
        <Grid item xs={12} md={6} lg={8}>
          <PlayerDetails isGrassroots={false} player={selectedPlayer || undefined} />
        </Grid>
      )}
    </Grid>
  );
};

import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { CreateClub } from './CreateClub';
import { ClubInfo } from './ClubInfo';
import { StandingsSummary } from './StandingsSummary';
import { MatchesOverview } from './MatchesOverview';
import { SponsorsCard } from './SponsorsCard';

export const Dashboard: FC = () => {
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);

  return (
    <>
      <CreateClub refreshDashboard={() => setTriggerRefresh(true)} />
      <Grid container spacing={2} sx={{ padding: '1rem' }}>
        <Grid item xs={12} md={6}>
          <ClubInfo />
        </Grid>
        <Grid item xs={12} md={6}>
          <StandingsSummary triggerRefresh={triggerRefresh} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SponsorsCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <MatchesOverview matchListType="last-matches" triggerRefresh={triggerRefresh} />
        </Grid>
        <Grid item xs={12} md={4}>
          <MatchesOverview matchListType="next-matches" triggerRefresh={triggerRefresh} />
        </Grid>
      </Grid>
    </>
  );
};

import { AxiosResponse } from 'axios';

import { axiosInstance } from '../axios';
import { Match } from '../types/seasons/Match';
import { LeagueOverview } from '../types/seasons/LeagueOverview';
import { RegionalTournamentOverview } from '../types/seasons/RegionalTournamentOverview';
import { NationalCupOverview } from '../types/seasons/NationalCupOverview';
import { CountryCompetitions } from '../types/seasons/CountryCompetition';

export const getUserClubLeagueStandings = (): Promise<AxiosResponse> => axiosInstance.get('/season/league/standings/short');

export const getUserClubMatches = (lastMatches: boolean): Promise<AxiosResponse> => (
  lastMatches ? axiosInstance.get('/season/matches/last') : axiosInstance.get('/season/matches/next')
);

export const getUserClubMatchesOfCurrentSeason = (): Promise<Match[]> => (
  axiosInstance.get('/season/matches/current-season')
    .then((response) => response.data as Match[])
);

export const getLeagueOverview = (id: string): Promise<LeagueOverview> => (
  axiosInstance.get(`/season/league/${id}`)
    .then((response) => response.data as LeagueOverview)
);

export const getRegionalTournamentOverview = (id: string): Promise<RegionalTournamentOverview> => (
  axiosInstance.get(`/season/regional-tournament/${id}`)
    .then((response) => response.data as RegionalTournamentOverview)
);

export const getNationalCupOverview = (id: string): Promise<NationalCupOverview> => (
  axiosInstance.get(`/season/national-cup/${id}`)
    .then((response) => response.data as NationalCupOverview)
);

export const getCountryCompetitions = (id: string): Promise<CountryCompetitions> => (
  axiosInstance.get(`/season/competition/country/${id}`)
    .then((response) => response.data as CountryCompetitions)
);

import { FC } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MatchDetails } from '../../data/types/match/MatchDetails';
import { Face } from '../../common';

type Props = {
  match: MatchDetails;
  loading: boolean;
};

export const ManOfTheMatch: FC<Props> = (props: Props) => {
  const { match, loading } = props;
  const { t } = useTranslation();

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={t('man_of_the_match')}
      />

      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Stack direction="row" spacing={0.5} alignItems="center">
              {loading ? (
                <Skeleton variant="circular" width="50px" height="50px" />
              ) : (
                <Face
                  body={match?.manOfTheMatch?.face?.body}
                  hairStyle={match?.manOfTheMatch?.face?.hairStyle}
                  hairColor={match?.manOfTheMatch?.face?.hairColor}
                  eyeShape={match?.manOfTheMatch?.face?.eyeShape}
                  eyeColor={match?.manOfTheMatch?.face?.eyeColor}
                  noseShape={match?.manOfTheMatch?.face?.noseShape}
                  mouthShape={match?.manOfTheMatch?.face?.mouthShape}
                  beard={match?.manOfTheMatch?.face?.beard}
                  eyebrows={match?.manOfTheMatch?.face?.eyebrows}
                  width={45}
                  height={45}
                />
              )}
              {loading ? (
                <Skeleton variant="rounded" width={400} sx={{ margin: 1 }} />
              ) : (
                <Typography noWrap variant="h6">
                  {match?.manOfTheMatch?.name}
                </Typography>
              )}

            </Stack>

            <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
              {loading ? (
                <Skeleton variant="circular" width="50px" height="50px" />
              ) : (
                <img src="/assets/crest.png" alt={match?.manOfTheMatch?.club?.name} width="50" />
              )}

              {loading ? (
                <Skeleton variant="rounded" width={400} sx={{ margin: 1 }} />
              ) : (
                <Typography noWrap variant="h6">
                  {match?.manOfTheMatch?.club?.name}
                </Typography>
              )}
            </Stack>
          </Stack>
          {loading ? (
            <Skeleton variant="circular" width="50px" height="50px" />
          ) : (
            <Stack direction="row" alignItems="center">
              <img src="/assets/icons/rank.svg" alt="performance" width="45" />
              <Typography variant="h4" fontWeight="bold">{match?.manOfTheMatch?.performance}</Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AllFacilities } from '../types/facilities/AllFacilities';
import {
  changeStadiumName,
  getAllFacilities,
  getFacilityReconstructionEstimations,
  getStadiumReconstructionEstimations,
  reconstructFacility,
  reconstructStadium,
} from '../api/facilities';
import { ReconstructionEstimations } from '../types/facilities/ReconstructionEstimations';
import { FacilityType } from '../types/facilities/FacilityType';
import { QueryKeys } from './queryKeys';
import { Level } from '../types/facilities/Level';
import { Size } from '../types/facilities/SocialClub';

export const useAllFacilities = () => {
  const { isFetching: isLoading, error, data } = useQuery<AllFacilities, Error>({
    queryKey: [QueryKeys.GET_ALL_FACILITIES],
    queryFn: getAllFacilities,
  });

  return { isLoading, error, data };
};

export const useGetFacilityReconstructionEstimations = (type: FacilityType, level: Level) => {
  const { isFetching, error, data } = useQuery<ReconstructionEstimations, Error>({
    queryKey: [QueryKeys.GET_FACILITY_RECONSTRUCTION_COSTS, type, level],
    queryFn: () => getFacilityReconstructionEstimations(type, level),
    cacheTime: 0, // No cache for this call
  });

  return { isLoading: isFetching, error, data };
};

export const useGetSocialClubReconstructEstimations = (size: Size) => {
  const { isFetching, error, data } = useQuery<ReconstructionEstimations, Error>({
    queryKey: [QueryKeys.GET_SOCIAL_CLUB_RECONSTRUCTION_COSTS, size],
    queryFn: () => getFacilityReconstructionEstimations(FacilityType.SOCIAL_CLUB, undefined, size),
    cacheTime: 0, // No cache for this call
  });

  return { isLoading: isFetching, error, data };
};

export const useGetStadiumReconstructEstimations = (params: any) => {
  const { isFetching, error, data } = useQuery<ReconstructionEstimations, Error>({
    queryKey: [QueryKeys.GET_SOCIAL_CLUB_RECONSTRUCTION_COSTS, params],
    queryFn: () => getStadiumReconstructionEstimations(params),
    cacheTime: 0, // No cache for this call
  });

  return { isLoading: isFetching, error, data };
};

export const useReconstructFacility = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (data: any) => reconstructFacility(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useChangeStadiumName = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (name: string) => changeStadiumName(name),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useReconstructStadium = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (data: any) => reconstructStadium(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

// @ts-nocheck ts is complaining about props which is not present on Box
import {
  Box, styled, keyframes,
} from '@mui/material';

const motion = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type Props = {
  color?: string;
  variant?: 'big' | 'small';
};

const DualRing = styled(Box, {
  shouldForwardProp: (prop: string) => !['variant', 'color'].includes(prop),
})<Props>(({ color, variant }) => ({
  display: 'inline-block',
  float: 'unset',
  width: variant === 'big' ? '64px' : '20px',
  height: variant === 'big' ? '64px' : '20px',
  ':after': {
    content: "' '",
    display: 'block',
    width: variant === 'big' ? '46px' : '14px',
    height: variant === 'big' ? '46px' : '14px',
    margin: '1px',
    borderRadius: '50%',
    border: variant === 'big' ? `5px solid ${color}` : `2px solid ${color}`,
    borderColor: `${color} transparent ${color} transparent`,
    animation: `${motion()} 1.2s linear infinite`,
  },
}));

export const Loader: React.FC<Props> = ({
  color = '#FFBC00',
  variant = 'small',
}) => (
  <DualRing
    color={color}
    variant={variant}
  />
);

import { FC, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Fixture, Status } from '../../data/types/seasons/Fixture';
import { Match } from '../../data/types/seasons/Match';
import { MatchCard } from '../Match/MatchCard';
import { LiveFixture } from './LiveFixture';

type Props = {
  fixtures: Fixture[];
  loading: boolean;
  loadingPlaceholders: number;
  jumpToLastFixture?: boolean;
  getMatchCardLink: Function;
  headerText: string;
};

export const Fixtures: FC<Props> = (props: Props) => {
  const {
    fixtures,
    loading,
    loadingPlaceholders,
    jumpToLastFixture,
    getMatchCardLink,
    headerText,
  } = props;
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [fixtureNumber, setFixtureNumber] = useState<number>(-1);
  const [currentFixtureStatus, setCurrentFixtureStatus] = useState<Status>();
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const previousFixtureHandle = () => {
    swiper?.slidePrev();
  };

  const nextFixtureHandle = () => {
    swiper?.slideNext();
  };

  if (!loading && jumpToLastFixture && fixtureNumber === -1 && (fixtures.length) > 0) {
    setFixtureNumber(fixtures.length);
    swiper?.slideTo(fixtures.length);
    setCurrentFixtureStatus(fixtures[fixtures.length - 1].status);
  }

  if (!loading && !jumpToLastFixture && fixtureNumber === -1 && (fixtures.length) > 0) {
    setFixtureNumber(fixtures[0].number);
    setCurrentFixtureStatus(fixtures[0].status);
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title={currentFixtureStatus === Status.PLAYING ? (
          <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <LiveFixture variant={matchesXsOrSm ? 'body2' : 'h6'} />
            {!matchesXsOnly ? (
              <Typography variant={matchesXsOrSm ? 'body2' : 'h6'} sx={{ marginLeft: 1 }}>
                {` - ${t('fixture_number')} #${fixtureNumber}`}
              </Typography>
            ) : null}
          </Stack>

        ) : (
          <Typography variant={matchesXsOrSm ? 'body2' : 'h6'} textAlign="center">
            {`${headerText} ${fixtureNumber > 0 ? `- ${t('fixture_number')} #${fixtureNumber}` : ''}`}
          </Typography>
        )}
        titleTypographyProps={{ variant: 'h6', textAlign: 'center' }}
        sx={{ paddingBottom: 0 }}
        avatar={(
          <IconButton onClick={previousFixtureHandle}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        )}
        action={(
          <IconButton onClick={nextFixtureHandle}>
            <KeyboardArrowRightIcon />
          </IconButton>
        )}
      />
      <CardContent>
        {loading ? (
          <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
            {new Array(loadingPlaceholders).fill(0).map(() => (
              <Grid item xs={12} lg={6} key={Math.random()}>
                <MatchCard match={{} as Match} loading disableHeaderLoading />
              </Grid>
            )) }
          </Grid>
        ) : null}

        {!loading && fixtures?.length === 0
          ? (
            <Box sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Typography variant="h3">{t('no_fixtures_found')}</Typography>
            </Box>
          )
          : null}

        <Swiper
          slidesPerView={1}
          onSwiper={(swiperInstance: SwiperClass) => {
            setSwiper(swiperInstance);
            swiperInstance.slideTo(fixtureNumber - 1);
          }}
          onSlideChange={(
            swiperInstance: SwiperClass,
          ) => {
            if (fixtures[swiperInstance.activeIndex] !== undefined) {
              setFixtureNumber(fixtures[swiperInstance.activeIndex].number);
              setCurrentFixtureStatus(fixtures[swiperInstance.activeIndex].status);
            }
          }}
        >
          {fixtures.map((fixture: Fixture) => (
            <SwiperSlide key={Math.random()}>
              <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
                {fixture?.matches.map((match: Match) => (
                  <Grid item xs={12} lg={6} key={match?.id}>
                    <MatchCard
                      match={match}
                      loading={loading}
                      link={getMatchCardLink(match)}
                      disableHeaderLoading
                    />
                  </Grid>
                ))}
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </CardContent>
    </Card>
  );
};

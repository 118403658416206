import { FC, useContext, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  // Stack,
} from '@mui/material';
import { t } from 'i18next';
// import { Standings } from '../../common/Standings';
// import { Fixtures } from '../../common/Fixtures/Fixtures';
// import { TopPlayers } from '../../common/Player/TopPlayers';
import { useRegionalTournamentOverview } from '../../data/hooks/useRegionalTournamentOverview';
import { Group } from '../../data/types/seasons/Group';
import { GroupDetails } from '../../common/Group/GroupDetails';
import { KnockoutDetails } from '../../common/Knockout/KnockoutDetails';
import { Bracket } from '../../data/types/seasons/Bracket';
import { TopPlayers } from '../../common/Player/TopPlayers';
import { Phase } from '../../data/types/seasons/Phase';
import { UserContext } from '../../context/UserContext';
import { Standing } from '../../data/types/seasons/Standings';

export const RegionalTournamentPage: FC = () => {
  const [groupTabIndex, setGroupTabIndex] = useState<number>(-1);
  const userContext = useContext(UserContext);

  const { isLoading, data: regionalTournament } = useRegionalTournamentOverview();

  const handleGroupTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setGroupTabIndex(newValue);
  };

  // Try to find the current club in the group and jump to that tab
  if (!isLoading && groupTabIndex === -1 && (regionalTournament?.groups?.length || 0) > 0) {
    regionalTournament?.groups.forEach((group: Group) => {
      group.standings.forEach((standings: Standing) => {
        if (userContext?.clubId === standings?.club?.id) {
          setGroupTabIndex((group?.number || 0) - 1);
        }
      });
    });
  }

  return (
    <Grid container spacing={2} sx={{ padding: 1, userSelect: 'none' }}>
      <Grid item xs={12} md={6}>
        {isLoading ? (
          <Card variant="outlined">
            <CardHeader
              title={(<Skeleton variant="rounded" width="100%" />)}
              sx={{ paddingBottom: 0 }}
            />
            <CardContent>
              <Skeleton variant="rounded" width="100%" height="40px" />
              <GroupDetails
                group={{} as Group}
                loading={isLoading}
              />
            </CardContent>
          </Card>
        ) : null}

        {!isLoading && regionalTournament?.phase === Phase.GROUP_PHASE ? (
          <Card variant="outlined">
            <CardHeader
              title={isLoading ? (<Skeleton variant="rounded" width="100%" />) : `${regionalTournament?.name} - ${t('group_phase')}`}
              titleTypographyProps={{
                variant: 'h6',
                textAlign: 'center',
              }}
              sx={{ paddingBottom: 0 }}
            />
            <CardContent>
              <Tabs
                value={groupTabIndex}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleGroupTabChange}
              >
                {regionalTournament?.groups?.map((group: Group) => (
                  <Tab label={`${t('group')} ${group?.number}`} key={group?.number} />
                ))}
              </Tabs>
              {regionalTournament?.groups?.map((group: Group) => (
                <Box
                  role="tabpanel"
                  hidden={groupTabIndex !== (group?.number || 0) - 1}
                  id={`group-tabpanel-${(group?.number || 0) - 1}`}
                  aria-labelledby={`group-tab-${(group?.number || 0) - 1}`}
                  key={group?.id}
                >
                  <GroupDetails
                    group={group}
                    loading={isLoading}
                  />
                </Box>
              ))}
            </CardContent>
          </Card>
        ) : null}

        {!isLoading && regionalTournament?.phase !== Phase.GROUP_PHASE ? (
          <KnockoutDetails
            competitionName={regionalTournament?.name || ''}
            roundOf16Brackets={regionalTournament?.roundOf16Brackets || []}
            quarterFinalsBrackets={regionalTournament?.quarterFinalsBrackets || []}
            semiFinalsBrackets={regionalTournament?.semiFinalsBrackets || []}
            finalBracket={regionalTournament?.finalBracket || {} as Bracket}
            loading={isLoading}
            loadingPlaceholders={8}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={t('top_scorers_and_top_assistants')}
            sx={{ paddingBottom: 0 }}
            titleTypographyProps={{
              variant: 'h6',
              textAlign: 'center',
            }}
          />
          <CardContent>
            <TopPlayers
              loading={isLoading}
              topScorers={regionalTournament?.topScorers || []}
              topAssistants={regionalTournament?.topAssistants || []}
            />
          </CardContent>
        </Card>
      </Grid>
      {regionalTournament?.phase !== Phase.GROUP_PHASE
        ? (
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardHeader
                title={`${regionalTournament?.name} - ${t('group_phase')}`}
                titleTypographyProps={{
                  variant: 'h6',
                  textAlign: 'center',
                }}
                sx={{ paddingBottom: 0 }}
              />
              <CardContent>
                <Tabs
                  value={groupTabIndex}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleGroupTabChange}
                >
                  {regionalTournament?.groups?.map((group: Group) => (
                    <Tab label={`${t('group')} ${group?.number}`} key={group?.number} />
                  ))}
                </Tabs>
                {regionalTournament?.groups?.map((group: Group) => (
                  <Box
                    role="tabpanel"
                    hidden={groupTabIndex !== (group?.number || 0) - 1}
                    id={`group-tabpanel-${(group?.number || 0) - 1}`}
                    aria-labelledby={`group-tab-${(group?.number || 0) - 1}`}
                    key={group?.id}
                  >
                    <GroupDetails
                      group={group}
                      loading={isLoading}
                    />
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        )
        : null}
    </Grid>
  );
};

import { useEffect, useState, SyntheticEvent } from 'react';
import {
  Grid, Button, Tabs, Tab, Paper, Box,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../../common';
import { useLineup, useCreateLineup, usePlayers } from '../../../data/hooks';
import { Field } from './Field';
import { ExtraPositions } from './ExtraPositions';
import { Bench } from './Bench';
import { ProgrammedSubstitution } from './ProgrammedSubstitution';
import { useLineupState, useLineupActions } from '../../../store';
import { useMatchDetails } from '../../../data/hooks/useMatchDetails';
import { MatchType } from '../../../data/types/seasons/Match';

export const Lineup = (): JSX.Element => {
  const {
    formation, firstElevenPlayers, captain, freeKickTaker,
    cornerKickTaker, penaltyTaker, firstElevenPlayersReady,
    benchPlayers, programmedSubstitutions,
  } = useLineupState();
  const { setStore, resetStore } = useLineupActions();
  const { createLineup, isCreating } = useCreateLineup();
  const { isFetching: isLoadingPlayers } = usePlayers();
  const { id: matchId } = useParams();
  const { isLoading, data } = useLineup(matchId);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const { isLoading: isLoadingMatch, data: match } = useMatchDetails(matchId || '');

  useEffect(() => {
    if (data) {
      setStore({
        formation: data?.formation,
        firstElevenPlayers: data?.firstElevenPlayers,
        firstElevenPlayersReady: true,
        captain: data?.captain,
        penaltyTaker: data?.penaltyTaker,
        freeKickTaker: data?.freeKickTaker,
        cornerKickTaker: data?.cornerKickTaker,
        benchPlayers: data?.benchPlayers?.map(({ id }) => id) ?? [],
        programmedSubstitutions: data?.programmedSubstitutions ?? [],
      });
    } else {
      resetStore();
    }
  }, [data, setStore, resetStore]);

  const onProceedClick = () => {
    createLineup({
      matchId,
      formation,
      firstElevenPlayers,
      captain,
      freeKickTaker,
      cornerKickTaker,
      penaltyTaker,
      benchPlayers: benchPlayers.map((p) => ({ id: p })),
      programmedSubstitutions,
    })
      .then(() => {
        enqueueSnackbar('Lineup created', { variant: 'success' });
        navigate('/match/list');
      })
      .catch((e) => {
        enqueueSnackbar(t(e?.response?.data?.message), { variant: 'error' });
      });
  };

  if (isLoading || isLoadingPlayers || isLoadingMatch) {
    return (
      <Loader />
    );
  }

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} md={8} sx={{ p: 1 }}>
        <Field matchType={match?.type || MatchType.FRIENDLY_MATCH} />
      </Grid>
      <Grid item xs md={4} sx={{ p: 1 }} wrap="nowrap" container direction="column">
        <Grid item xs>
          <Paper sx={{ p: 2 }}>
            <Tabs value={selectedTab} onChange={handleChange} sx={{ mb: 1 }}>
              <Tab label={t('lineup_extra_positions')} disabled={!firstElevenPlayersReady} />
              <Tab label={t('bench')} disabled={!firstElevenPlayersReady} />
              <Tab label={t('lineup_substitutions')} disabled={!firstElevenPlayersReady} />
            </Tabs>
            <Box role="tabpanel" hidden={selectedTab !== 0}>
              <ExtraPositions />
            </Box>
            <Box role="tabpanel" hidden={selectedTab !== 1}>
              <Bench
                matchType={match?.type || MatchType.FRIENDLY_MATCH}
              />
            </Box>
            <Box role="tabpanel" hidden={selectedTab !== 2}>
              <ProgrammedSubstitution
                matchType={match?.type || MatchType.FRIENDLY_MATCH}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item alignContent="flex-end" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={onProceedClick}
            disabled={!firstElevenPlayersReady}
            sx={{ width: '100%' }}
          >
            {isCreating ? <Loader /> : t('save_lineup')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

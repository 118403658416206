import { FC, useEffect, useState } from 'react';
import {
  Paper, Stack, Typography, FormControl, InputLabel, Select, MenuItem,
  Box, SelectChangeEvent,
} from '@mui/material';
import SoccerLineUp from 'react-soccer-lineup';

import { useTranslation } from 'react-i18next';
import { formations } from '../../../constants';
import { useLineupState, useLineupActions } from '../../../store';
import { generateSquadStructure, usePlayers } from '../../../data/hooks';
import { PlayerSelection } from './PlayerSelection';
import { Position } from '../../../data/types/players/Player';
import { MatchType } from '../../../data/types/seasons/Match';

type SelectedPosition = {
  position: Position;
};

type Props = {
  matchType: MatchType;
};

export const Field: FC<Props> = (props: Props): JSX.Element => {
  const { matchType } = props;
  const { formation, squad, firstElevenPlayers } = useLineupState();
  const { setFormation, setSquad } = useLineupActions();
  const { data: players } = usePlayers();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<SelectedPosition | null>(null);
  const { t } = useTranslation();

  const onPositionClick = (e: any, data: any) => {
    setAnchorEl(e.currentTarget);
    setSelectedPosition({
      position: data?.position as Position,
    });
  };

  useEffect(() => {
    const sq = generateSquadStructure(
      formation,
      firstElevenPlayers.map(({ id, position }) => ({
        ...players?.find((p) => p.id === id),
        position,
      })),
      onPositionClick,
    );
    setSquad(sq);
  }, [formation, firstElevenPlayers, setSquad, players]);

  const onChange = (e: SelectChangeEvent) => {
    setFormation(e.target.value);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h4">{t('lineup')}</Typography>
        <FormControl fullWidth>
          <InputLabel>{t('formation')}</InputLabel>
          <Select
            value={formation}
            label={t('formation')}
            onChange={onChange}
          >
            {
              formations.map(({ name, value }) => (
                <MenuItem key={name} value={value}>{name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <Box id="field-wrapper">
          <SoccerLineUp
            size="responsive"
            pattern="lines"
            // @ts-expect-error broken validation in 3rs party
            homeTeam={squad ? { squad } : undefined}
          />
        </Box>
        <PlayerSelection
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          position={selectedPosition?.position as Position}
          matchType={matchType}
        />
      </Stack>
    </Paper>
  );
};

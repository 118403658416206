import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fireGrassrootsPlayer,
  getAvailableGrassrootsPlayers,
  getGrassrootsPlayers,
  hireGrassrootsPlayer,
  promoteGrassrootsPlayer as promoteGrassrootsPlayerApi,
  rejectGrassrootsPlayer,
  searchGrassrootsPlayers,
} from '../api';
import { QueryKeys } from './queryKeys';
import { GrassrootsSearchType } from '../types/players/GrassrootsSearchType';

export const useAvailableGrassrootsPlayers = () => {
  const { isFetching, error, data } = useQuery({
    queryKey: [QueryKeys.GET_AVAILABLE_GRASSROOTS_PLAYERS],
    queryFn: getAvailableGrassrootsPlayers,
  });

  return {
    isLoading: isFetching,
    error,
    data,
  };
};

export const useHiredGrassrootsPlayers = () => {
  const { isFetching, error, data } = useQuery({
    queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
    queryFn: getGrassrootsPlayers,
  });

  return {
    isLoading: isFetching,
    error,
    players: data,
  };
};

export const useSearchPlayers = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (type: GrassrootsSearchType) => searchGrassrootsPlayers(type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_AVAILABLE_GRASSROOTS_PLAYERS],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useHirePlayer = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (playerId: string) => hireGrassrootsPlayer(playerId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_AVAILABLE_GRASSROOTS_PLAYERS],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useRejectPlayer = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (playerId: string) => rejectGrassrootsPlayer(playerId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_AVAILABLE_GRASSROOTS_PLAYERS],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useFireGrassrootsPlayer = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (playerId: string) => fireGrassrootsPlayer(playerId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const usePromoteGrassrootsPlayer = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: promoteGrassrootsPlayer,
  } = useMutation({
    mutationFn: (playerId: string) => promoteGrassrootsPlayerApi(playerId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ['players'],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    promoteGrassrootsPlayer,
  };
};

import {
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatShortDate } from '../../common/formatter';
import { PhysicalTraining } from '../../data/types/training/PhysicalTraining';

type Props = {
  loading: boolean;
  isNextTraining?: boolean;
  training?: PhysicalTraining;
};

const LoadingSkeleton: FC = () => (
  <Grid item xs={12} lg={6}>
    <Stack direction="row" spacing={2}>
      <Skeleton variant="rounded" height={150} width={150} />
      <Stack direction="column" width="90%" spacing={1}>
        <Skeleton variant="rounded" height={22} width="100%" />
        <Skeleton variant="rounded" height={22} width="100%" />
        <Skeleton variant="rounded" height={22} width="100%" />

        <Skeleton variant="rounded" height={22} width="100%" />
        <Skeleton variant="rounded" height={22} width="100%" />
      </Stack>
    </Stack>
  </Grid>
);

export const PhysicalTrainingOverview: FC<Props> = (props: Props) => {
  const { loading, isNextTraining, training } = props;

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.only('xs'));
  const { i18n } = useTranslation();

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <Grid item xs={12} lg={6}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        {matchesXs ? (<Typography variant="h5" fontWeight="bold" textAlign="center">{t(isNextTraining ? 'next_training' : 'previous_training')}</Typography>) : null}
        <img src={`assets/icons/training/${training?.type.toLowerCase()}.svg`} alt="" width={matchesXs ? '100%' : '150px'} />
        <Stack direction="column" spacing={{ xs: 3, sm: 1 }} justifyContent={{ xs: 'space-between', sm: 'left' }}>
          {!matchesXs ? (<Typography variant="body1" fontWeight="bold">{t(isNextTraining ? 'next_training' : 'previous_training')}</Typography>) : null}

          <Stack direction="column" spacing={matchesXs ? 0 : 0.5}>
            <Typography variant={matchesXs ? 'h6' : 'body1'}>{t('type_and_intensity')}</Typography>
            {matchesXs ? (
              <>
                <Typography variant="h5" fontWeight="bold">
                  {t(training?.type.toLowerCase() || '')}
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  {`intensity_${t(training?.intensity.toLowerCase() || '')}`}
                </Typography>
              </>
            ) : (
              <Typography variant="body1" fontWeight="bold">
                {`${t(training?.type.toLowerCase() || '')} - ${t(`intensity_${training?.intensity.toLowerCase() || ''}`)}`}
              </Typography>
            )}

          </Stack>

          <Stack direction="column" spacing={matchesXs ? 0 : 0.5}>
            <Typography variant={matchesXs ? 'h6' : 'body1'}>{t('date')}</Typography>
            <Typography variant={matchesXs ? 'h5' : 'body1'} fontWeight="bold">{formatShortDate(training?.date || '', i18n.language, false)}</Typography>
          </Stack>

        </Stack>
      </Stack>
    </Grid>
  );
};

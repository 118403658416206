import { FC, useState } from 'react';
import {
  Box, Grid, Typography,
} from '@mui/material';

import { LoginForm } from './LoginForm';
import { SignupForm } from './SignupForm';
import { Logo, LanguageSwitch } from '../../common';

export const Login: FC = () => {
  const [isRegistration, setIsRegistration] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  return (
    <Box
      sx={{
        height: '100%',
        pr: 2,
        pl: 2,
      }}
    >
      <Grid container sx={{ height: '100%' }} justifyContent="center">
        <Grid
          item
          container
          direction="column"
          gap={2}
          justifyContent="center"
          sx={{
            maxWidth: 400,
          }}
        >
          <Grid item>
            <Logo type="yellow" width="250px" />
          </Grid>
          <Grid item sx={{ alignSelf: 'flex-end' }}>
            <LanguageSwitch />
          </Grid>
          {
            isRegistration ? (
              <SignupForm
                onError={setError}
                backToLogin={() => setIsRegistration(false)}
              />
            ) : (
              <LoginForm
                onError={setError}
                goToSignup={() => setIsRegistration(true)}
              />
            )
          }
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

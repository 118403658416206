import axios from 'axios';

import { authInterceptor, correlationIdInterceptor } from './interceptors';

declare const process: {
  env: {
    REACT_APP_ENV: string;
  };
};

const baseURL: string = {
  stage: 'https://api-stage.elevenstars.com.br',
  production: 'https://stage.elevenstars.com.br:443', // TODO: change when prod url is known
}[process.env.REACT_APP_ENV!]!;

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(authInterceptor, Promise.reject);
axiosInstance.interceptors.request.use(correlationIdInterceptor, Promise.reject);

import { FC } from 'react';
import {
  LabelList,
  PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart,
} from 'recharts';
import { Player } from '../../data/types';

type Props = {
  player: Player,
  showLegend: boolean,
  showUntilOneHundered: boolean
  showInnerLabel: boolean
};

export const TechnicalAttributesChart: FC<Props> = (props: Props) => {
  const {
    player, showLegend, showUntilOneHundered, showInnerLabel,
  } = props;
  const data = [
    {
      attribute: 'CHU',
      value: player.technicalAttributes.shooting,
    },
    {
      attribute: 'POS',
      value: player.technicalAttributes.positioning,
    },
    {
      attribute: 'DES',
      value: player.technicalAttributes.tackling,
    },
    {
      attribute: 'REF',
      value: player.technicalAttributes.reflexes,
    },
    {
      attribute: 'ARM',
      value: player.technicalAttributes.creation,
    },
    {
      attribute: 'PAS',
      value: player.technicalAttributes.passing,
    },
    {
      attribute: 'TEC',
      value: player.technicalAttributes.technique,
    },
    {
      attribute: 'CAB',
      value: player.technicalAttributes.aerialPlaying,
    },
  ];

  return (
    <RadarChart
      cx={300}
      cy={250}
      outerRadius={150}
      width={500}
      height={500}
      data={data}
    >
      <PolarGrid strokeWidth="4px" />
      {showLegend && (<PolarAngleAxis dataKey="attribute" />)}
      <PolarRadiusAxis
        angle={25}
        domain={showUntilOneHundered ? [0, 16] : [0, 16]}
      />
      <Radar
        name={player.name}
        dataKey="value"
        stroke="#232323"
        fill="#1976d2"
        fillOpacity={0.85}
      >
        {showInnerLabel && (<LabelList dataKey="attribute" position="insideTop" angle={0} />)}
      </Radar>
    </RadarChart>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Loader } from '../../common';
import { ApiError } from '../../data/types/ApiError';
import { Player } from '../../data/types';
import { useHirePlayer } from '../../data/hooks/useGrassroots';
import { QueryKeys } from '../../data/hooks/queryKeys';

type Props = {
  player: Player;
  open: boolean;
  onClose: () => void;
};

export const ConfirmHirePlayerDialog: FC<Props> = ({ player, open, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateFn: hirePlayer } = useHirePlayer();

  const handleOnClose = () => {
    onClose();
  };

  const handleConfirmHire = () => {
    hirePlayer(player.id).then(() => {
      enqueueSnackbar(t('hire_grassroots_player_success'), { variant: 'success' });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_AVAILABLE_GRASSROOTS_PLAYERS],
      });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
      });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
      });
    }).catch((data: AxiosError) => {
      const errorResponse = data.response?.data as ApiError;
      enqueueSnackbar(t(errorResponse.error || ''), { variant: 'error' });
    }).finally(() => {
      handleOnClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('hire_grassroots_player')}
      </DialogTitle>
      <DialogContentText variant="h6" sx={{ p: 3 }}>
        {t('hire_grassroots_player_confirmation', { name: player.name })}
      </DialogContentText>
      <DialogActions>
        <Button
          onClick={handleOnClose}
          disabled={isLoading}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleConfirmHire}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * @fileoverview gRPC-Web generated client stub for br.com.elevenstars.service.live_match_updates.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: live-match.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as live$match_pb from './live-match_pb';


export class LiveMatchServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorConnect = new grpcWeb.MethodDescriptor(
    '/br.com.elevenstars.service.live_match_updates.api.LiveMatchService/Connect',
    grpcWeb.MethodType.SERVER_STREAMING,
    live$match_pb.LiveMatchRequest,
    live$match_pb.LiveMatchResponse,
    (request: live$match_pb.LiveMatchRequest) => {
      return request.serializeBinary();
    },
    live$match_pb.LiveMatchResponse.deserializeBinary
  );

  connect(
    request: live$match_pb.LiveMatchRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<live$match_pb.LiveMatchResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/br.com.elevenstars.service.live_match_updates.api.LiveMatchService/Connect',
      request,
      metadata || {},
      this.methodDescriptorConnect);
  }

}


import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

type Props = {
  countryCode: string,
  width: number,
  sx?: SxProps
};

export const Flag: FC<Props> = (props: Props) => {
  const { countryCode, width, sx } = props;

  return (
    <Box sx={{
      width: `${width}px`,
      height: `${width}px`,
      backgroundImage: `url('/assets/flags/${countryCode}.svg')`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: `${Math.round(width / 2)}px`,
      border: '1px solid #222',
      ...sx,
    }}
    />
  );
};

import { axiosInstance } from '../axios';
import { AllFacilities } from '../types/facilities/AllFacilities';
import { FacilityType } from '../types/facilities/FacilityType';
import { Level } from '../types/facilities/Level';
import { ReconstructionEstimations } from '../types/facilities/ReconstructionEstimations';
import { Size } from '../types/facilities/SocialClub';
import { Stadium } from '../types/facilities/Stadium';

export const getAllFacilities = (): Promise<AllFacilities> => (
  axiosInstance.get('/facilities/all')
    .then((response) => response.data)
);

export const getFacilityReconstructionEstimations = (
  type: FacilityType,
  level?: Level,
  size?: Size,
): Promise<ReconstructionEstimations> => (
  axiosInstance.get('/facilities/reconstruction/estimation', { params: { type, level, size } })
    .then((response) => response.data as ReconstructionEstimations)
);

export const reconstructFacility = (data: any): Promise<any> => (
  axiosInstance.patch('/facilities/reconstruction/reconstruct', data)
    .then((response) => response.data)
);

export const changeStadiumName = (name: string): Promise<Stadium> => (
  axiosInstance.patch('/facilities/stadium/change-name', { name })
    .then((response) => response.data as Stadium)
);

export const getStadiumReconstructionEstimations = (
  params: any,
): Promise<ReconstructionEstimations> => (
  axiosInstance.get('/facilities/stadium/upgrade/estimation', { params: { ...params } })
    .then((response) => response.data as ReconstructionEstimations)
);

export const reconstructStadium = (data: any): Promise<any> => (
  axiosInstance.patch('/facilities/stadium/upgrade', data)
    .then((response) => response.data)
);

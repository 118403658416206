import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  stage: {
    apiKey: 'AIzaSyCMLcQT42CyNg31NVlax2YCn9lQxp73Dhc',
    authDomain: 'eleven-stars-players-stage.firebaseapp.com',
    projectId: 'eleven-stars-players-stage',
    storageBucket: 'eleven-stars-players-stage.appspot.com',
    messagingSenderId: '1082038144218',
    appId: '1:1082038144218:web:efc1554a3a3ee1a31829e3',
    measurementId: 'G-7RK8R02SFL',
    databaseURL: 'https://eleven-stars-players-stage.firebaseio.com',
  },
  production: {
    apiKey: 'AIzaSyDvKE3Uk_lEsOjNeuyjuyrACkznT_vZE0I',
    authDomain: 'eleven-stars-game.firebaseapp.com',
    projectId: 'eleven-stars-game',
    storageBucket: 'eleven-stars-game.appspot.com',
    messagingSenderId: '344452595415',
    appId: '1:344452595415:web:b8c900b4905bdf6175fa92',
    measurementId: 'G-T4DMR7VN8D',
  },
}[process.env.REACT_APP_ENV!]!;

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

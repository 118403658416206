import { FC } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
} from '@mui/material';
import { t } from 'i18next';
import { useLeagueOverview } from '../../data/hooks/useLeagueOverview';
import { Standings } from '../../common/Standings';
import { Fixtures } from '../../common/Fixtures/Fixtures';
import { TopPlayers } from '../../common/Player/TopPlayers';
import { Match } from '../../data/types/seasons/Match';

export const LeagueOverviewPage: FC = () => {
  const { isLoading, data: league } = useLeagueOverview();

  return (
    <Grid container spacing={2} sx={{ padding: 1, userSelect: 'none' }}>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={league?.name}
            sx={{ paddingBottom: 0 }}
            titleTypographyProps={{
              variant: 'h6',
              textAlign: 'center',
            }}
          />
          <CardContent>
            <Standings
              standings={league?.standings || []}
              placeholderSize={14}
              loading={isLoading}
            />
          </CardContent>
        </Card>
        <Card sx={{ marginTop: 2 }} variant="outlined">
          <CardHeader
            title={t('top_scorers_and_top_assistants')}
            sx={{ paddingBottom: 0 }}
            titleTypographyProps={{
              variant: 'h6',
              textAlign: 'center',
            }}
          />
          <CardContent>
            <TopPlayers
              loading={isLoading}
              topScorers={league?.topScorers || []}
              topAssistants={league?.topAssistants || []}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="column" spacing={2}>
          <Fixtures
            fixtures={league?.pastFixtures || []}
            loading={isLoading}
            loadingPlaceholders={8}
            getMatchCardLink={(match: Match) => `/match/details/${match.id}`}
            jumpToLastFixture
            headerText={t('last_fixtures')}
          />
          <Fixtures
            fixtures={league?.nextFixtures || []}
            loading={isLoading}
            loadingPlaceholders={8}
            getMatchCardLink={() => null}
            headerText={t('next_fixtures')}
          />

        </Stack>
      </Grid>
    </Grid>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvailableSponsors, useChooseSponsor } from '../../data/hooks/useSponsor';
import { Sponsor } from '../../data/types/clubs/Sponsor';
import { formatCurrency } from '../../common/formatter';
import { Loader } from '../../common';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SetSponsorDialog: FC<Props> = (props: Props) => {
  const { open, onClose } = props;
  const [chosenSponsor, setChosenSponsor] = useState<Sponsor>();
  const { t, i18n } = useTranslation();
  const { isFetching: loading, data: availableSponsors } = useAvailableSponsors();
  const { isAssigningSponsor, chooseSponsor } = useChooseSponsor();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleChooseSponsorConfirmation = () => {
    if (chosenSponsor) {
      chooseSponsor(chosenSponsor.id).then(() => {
        queryClient.invalidateQueries({
          queryKey: ['get-user-club'],
          exact: true,
        });
        queryClient.fetchQuery({
          queryKey: ['get-user-club'],
        });
        enqueueSnackbar(t('sponsor_assigned'), { variant: 'success' });
        onClose();
      });
    }
  };

  const handleChooseSponsor = (id: string) => {
    setChosenSponsor(availableSponsors?.find((sponsor) => sponsor?.id === id));
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('choose_sponsor')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{ paddingTop: 1 }}>
          <Typography variant="h5" fontWeight="bold">{t('choose_sponsor_wisely')}</Typography>
          <FormControl fullWidth>
            <InputLabel>{t('sponsor')}</InputLabel>
            <Select
              label={t('sponsor')}
              onChange={(e) => handleChooseSponsor(e.target.value as string)}
              disabled={loading}
            >
              {availableSponsors?.map((sponsor) => (
                <MenuItem key={sponsor?.id} value={sponsor?.id}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <img src={`/assets/icons/sponsors/${sponsor?.name.toLowerCase()}.svg`} alt={sponsor?.name} width="60" height="40" />
                    <Typography variant="h6">{sponsor?.name}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant="h5">{t('weekly_payment')}</Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatCurrency(chosenSponsor?.weeklyPayment || 0, i18n.language)}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5" sx={{ mt: 2 }}>{t('victory_bonus')}</Typography>
          <Typography variant="h5" fontWeight="bold">
            {formatCurrency(chosenSponsor?.victoryBonus || 0, i18n.language)}
          </Typography>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('cancel')}</Button>
        <Button onClick={handleChooseSponsorConfirmation} autoFocus variant="contained" color="primary">
          {isAssigningSponsor ? <Loader /> : t('choose')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useState, useMemo } from 'react';
import {
  Box, Typography, Menu, MenuItem,
} from '@mui/material';
import { Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const LanguageSwitch = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { options: { supportedLngs } } = i18n;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const languageList = useMemo(() => {
    if (Array.isArray(supportedLngs)) {
      return supportedLngs.filter((l: string) => l !== 'cimode');
    }

    return [];
  }, [supportedLngs]);

  const handleSelectorClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelectorClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (lang: string) => {
    setAnchorEl(null);
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          outline: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minWidth: '80px',
        }}
        onClick={handleSelectorClick}
      >
        <Language />
        <Typography sx={{ textTransform: 'uppercase', ml: '0.5rem' }}>{i18n.language}</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSelectorClose}
      >
        {
          languageList.map((o: string) => (
            <MenuItem
              key={o}
              onClick={() => handleSelection(o)}
            >
              <Typography>{o}</Typography>
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

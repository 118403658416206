import { useQuery } from '@tanstack/react-query';
import { getUserClubInfo } from '../api';
import { Club } from '../types/clubs/Club';

export const useUserClub = () => {
  const {
    isLoading,
    isFetching,
    error,
    data,
  } = useQuery<Club, Error>({
    queryKey: ['get-user-club'],
    queryFn: () => getUserClubInfo(),
  });

  return {
    isLoading,
    isFetching,
    error,
    data,
  };
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { GrassrootsSearchType } from '../../data/types/players/GrassrootsSearchType';
import { Loader } from '../../common';
import { formatCurrency } from '../../common/formatter';
import { useSearchPlayers } from '../../data/hooks/useGrassroots';
import { ApiError } from '../../data/types/ApiError';
import { QueryKeys } from '../../data/hooks/queryKeys';

type Props = {
  open: boolean;
  onClose: () => void;
};

const searchTypes: Map<GrassrootsSearchType, number> = new Map<GrassrootsSearchType, number>([
  [GrassrootsSearchType.LOCAL, 25000],
  [GrassrootsSearchType.REGIONAL, 75000],
  [GrassrootsSearchType.NATIONAL, 150000],
  [GrassrootsSearchType.INTERNATIONAL, 250000],
]);

export const SearchNewPlayersDialog: FC<Props> = ({ open, onClose }: Props) => {
  const [searchType, setSearchType] = useState<GrassrootsSearchType>(GrassrootsSearchType.LOCAL);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const matchesXsOrSm = theme.breakpoints.down('md');
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateFn: searchPlayers } = useSearchPlayers();

  const handleOnClose = () => {
    onClose();
  };

  const handleConfirmSearch = () => {
    searchPlayers(searchType).then(() => {
      enqueueSnackbar(t('search_done_successfully'), { variant: 'success' });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_AVAILABLE_GRASSROOTS_PLAYERS],
      });
    }).catch((data: AxiosError) => {
      const errorResponse = data.response?.data as ApiError;
      enqueueSnackbar(t(errorResponse.error || ''), { variant: 'error' });
    }).finally(() => {
      handleOnClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('search_for_new_players')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="left" alignItems="left" sx={{ paddingTop: 1 }}>

          <Stack direction="row" spacing={1} alignItems="center">
            <InfoIcon color="disabled" />
            <Typography variant="caption" color="text.secondary">
              {t('search_new_players_explanation')}
            </Typography>
          </Stack>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} size="small" aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell>{t('search_type')}</TableCell>
                  <TableCell>{t('cost')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(searchTypes.entries()).map((row) => (
                  <TableRow
                    key={row[0]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {t(row[0].toLowerCase())}
                    </TableCell>
                    <TableCell>{formatCurrency(row[1], i18n.language)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <FormControl fullWidth>
            <InputLabel sx={{ mt: 1 }}>{t('select_search_type')}</InputLabel>
            <Select
              id="grassroots-search-type"
              label={t('select_search_type')}
              defaultValue={searchType}
              value={searchType}
              onChange={(e) => setSearchType(e.target.value as GrassrootsSearchType)}
              sx={{ mt: 1 }}
            >
              {Array.from(searchTypes.keys()).map((type) => (
                <MenuItem key={type} value={type}>
                  <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t(type.toLowerCase())}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} disabled={false}>{t('cancel')}</Button>
        <Button
          onClick={handleConfirmSearch}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Division } from '../seasons/Division';
import {
  MatchType,
  Result,
  Score,
  Status,
} from '../seasons/Match';
import { Phase } from '../seasons/Phase';
import { Face } from '../players/Player';

type Crest = {
  firstColor: string;
  secondColor: string;
};

type Club = {
  abbreviation: string;
  bot: boolean;
  crest: Crest;
  id: string;
  name: string;
};

type Attendance = {
  eastSeats: number;
  northSeats: number;
  northeastSeats: number;
  northwestSeats: number;
  southSeats: number;
  southeastSeats: number;
  southwestSeats: number;
  westSeats: number;
};

type Bench = {
  id: string;
  players: Player[];
  programmedSubstitutions: ProgrammedSubstitution[];
  substitutionsDone: number;
};

type LineUp = {
  id: string;
  players: Player[];
  formation: Formation;
};

export type Player = {
  id: string;
  captain: boolean;
  cornerKickTake: boolean;
  energy: number;
  face: Face;
  freeKickTaker: boolean;
  grassroots: boolean;
  injured: boolean;
  lineupPosition: LineUpPosition;
  matchPerformance: number;
  minutesPlayed: number;
  name: string;
  penaltyTaker: boolean;
  performance: number;
  playing: boolean;
  replacedById: string;
  replacedPlayerId: string;
  sentOff: boolean;
  shirtNumber: number;
  substituted: boolean;
  tiredness: number;
  enteredDuringMatch: boolean;
  stats: PlayerStats;
};

type PlayerStats = {
  totalPasses: number;
  successfulPasses: number;
  totalTackles: number;
  successfulTackles: number;
  totalDribbles: number;
  successfulDribbles: number;
  totalShots: number;
  onTargetShots: number;
  totalSaves: number;
  successfulSaves: number;
  goalsScored: number;
  assistsGiven: number;
  yellowCards: number;
  redCards: number;
  foulsCommitted: number;
  foulsSuffered: number;
  totalPassesInterception: number;
  successfulPassesInterception: number;
  totalPassesBlocking: number;
  successfulPassesBlocking: number;
  totalPassesReception: number;
  successfulPassesReception: number;
  totalShootsBlocking: number;
  successfulShootsBlocking: number;
  totalCrosses: number;
  successfulCrosses: number;
  totalCrossesBlocking: number;
  successfulCrossesBlocking: number;
  totalCrossesInterception: number;
  successfulCrossesInterception: number;
};

type ProgrammedSubstitution = {};

type ClubStats = {
  corners: number;
  fouls: number;
  goalChances: number;
  offsides: number;
  possession: number;
  redCards: number;
  yellowCards: number;
};

type Statistics = {
  away: ClubStats;
  home: ClubStats;
};

type CommentaryPlayer = {
  action: PlayerAction;
  name: string;
  shirtNumber: number;
};

type ManOfTheMatch = {
  id: string;
  name: string;
  face: Face;
  shirtNumber: number;
  performance: number;
  club: ManOfTheMatchClub;
};

type ManOfTheMatchClub = {
  id: string;
  name: string;
  crest: Crest;
};

type CommentaryClub = {
  id: string;
  name: string;
};

export enum PlayerAction {
  SAVED_THE_SHOT = 'SAVED_THE_SHOT',
  GAVE_WRONG_PASS = 'GAVE_WRONG_PASS',
  WRONG_CROSS = 'WRONG_CROSS',
  DRIBBLED_DEFENDER = 'DRIBBLED_DEFENDER',
  GOT_DRIBBLED = 'GOT_DRIBBLED',
  LOST_THE_BALL = 'LOST_THE_BALL',
  TOOK_FREE_KICK = 'TOOK_FREE_KICK',
  TOOK_CORNER_KICK = 'TOOK_CORNER_KICK',
  MISSED_THE_GOAL = 'MISSED_THE_GOAL',
  SCORED_THE_GOAL = 'SCORED_THE_GOAL',
  GAVE_ASSIST = 'GAVE_ASSIST',
  COMMITTED_FOUL = 'COMMITTED_FOUL',
  FOULED = 'FOULED',
  INJURED = 'INJURED',
  GOT_YELLOW_CARD = 'GOT_YELLOW_CARD ',
  GOT_SECOND_YELLOW_CARD = 'GOT_SECOND_YELLOW_CARD',
  GOT_RED_CARD = 'GOT_RED_CARD',
  OFFSIDE = 'OFFSIDE',
  STOLE_THE_BALL = 'STOLE_THE_BALL',
  CARRIED_THE_BALL_FREE = 'CARRIED_THE_BALL_FREE',
  PASSED_THE_BALL = 'PASSED_THE_BALL',
  PASSED_THE_BALL_FREE = 'PASSED_THE_BALL_FREE',
  PASSED_LONG_BALL = 'PASSED_LONG_BALL',
  PASSED_LONG_BALL_FREE = 'PASSED_LONG_BALL_FREE',
  PASSED_LONG_BALL_FACE_2_FACE_WITH_GOALKEEPER = 'PASSED_LONG_BALL_FACE_2_FACE_WITH_GOALKEEPER',
  PASSED_LONG_BALL_FACE_2_FACE_WITH_GOALKEEPER_FREE = 'PASSED_LONG_BALL_FACE_2_FACE_WITH_GOALKEEPER_FREE',
  INTERCEPTED_PASS = 'INTERCEPTED_PASS',
  BLOCKED_PASS = 'BLOCKED_PASS',
  DID_NOT_INTERCEPTED_PASS = 'DID_NOT_INTERCEPTED_PASS',
  DID_NOT_BLOCKED_PASS = 'DID_NOT_BLOCKED_PASS',
  SUBSTITUTED = 'SUBSTITUTED',
  LEFT_MATCH_WITHOUT_SUB = 'LEFT_MATCH_WITHOUT_SUB',
  ENTERED_THE_MATCH = 'ENTERED_THE_MATCH',
  CONCEDED_THE_GOAL = 'CONCEDED_THE_GOAL',
  DID_NOTHING = 'DID_NOTHING',
  PALMED_TO_CORNER_KICK = 'PALMED_TO_CORNER_KICK',
  CROSSED_THE_BALL = 'CROSSED_THE_BALL',
  RECEIVED_SHORT_PASS = 'RECEIVED_SHORT_PASS',
  RECEIVED_SHORT_PASS_FREE = 'RECEIVED_SHORT_PASS_FREE',
  RECEIVED_LONG_PASS = 'RECEIVED_LONG_PASS',
  RECEIVED_LONG_PASS_FREE = 'RECEIVED_LONG_PASS_FREE',
  RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER = 'RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER',
  RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_FREE = 'RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_FREE',
  DID_NOT_TRAP_LONG_PASS = 'DID_NOT_TRAP_LONG_PASS',
  DID_NOT_TRAP_SHORT_PASS = 'DID_NOT_TRAP_SHORT_PASS',
  BLOCKED_SHOOT = 'BLOCKED_SHOOT',
  STARTED_COUNTER_ATTACK = 'STARTED_COUNTER_ATTACK',
}

export enum MoveType {
  CORNER_KICK = 'CORNER_KICK',
  FOUL = 'FOUL',
  INJURY = 'INJURY',
  SUBSTITUTION = 'SUBSTITUTION',
  CROSS = 'CROSS',
  SHORT_PASS = 'SHORT_PASS',
  LONG_PASS = 'LONG_PASS',
  LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER = 'LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER',
  DRIBBLE_DEFENDER = 'DRIBBLE_DEFENDER',
  CARRY_THE_BALL = 'CARRY_THE_BALL',
  SHOOT_ON_GOAL_FROM_MID_RANGE = 'SHOOT_ON_GOAL_FROM_MID_RANGE',
  SHOOT_ON_GOAL_FROM_SHORT_RANGE = 'SHOOT_ON_GOAL_FROM_SHORT_RANGE',
  SHOOT_ON_GOAL_FROM_LONG_RANGE = 'SHOOT_ON_GOAL_FROM_LONG_RANGE',
  HEADER_ON_GOAL = 'HEADER_ON_GOAL',
  FREE_KICK = 'FREE_KICK',
  PENALTY_KICK = 'PENALTY_KICK',
  COMMENTARY = 'COMMENTARY',
  RECEIVE_SHORT_PASS = 'RECEIVE_SHORT_PASS',
  RECEIVE_LONG_PASS = 'RECEIVE_LONG_PASS',
  RECEIVE_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER = 'RECEIVE_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER',
  COUNTER_ATTACK = 'COUNTER_ATTACK',
  NO_ONE_TO_PLAY = 'NO_ONE_TO_PLAY',
}

export enum MoveResult {
  GOAL = 'GOAL',
  MISSED_GOAL = 'MISSED_GOAL',
  GOALKEEPER_SAVED = 'GOALKEEPER_SAVED',
  LOST_THE_BALL = 'LOST_THE_BALL',
  DRIBBLED_DEFENDER = 'DRIBBLED_DEFENDER',
  SHORT_PASS_DONE = 'SHORT_PASS_DONE',
  SHORT_PASS_DONE_FREE = 'SHORT_PASS_DONE_FREE',
  SHORT_PASS_INTERCEPTED = 'SHORT_PASS_INTERCEPTED',
  SHORT_PASS_BLOCKED = 'SHORT_PASS_BLOCKED',
  LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_DONE = 'LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_DONE',
  LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_DONE_FREE = 'LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_DONE_FREE',
  LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_NOT_TRAPPED = 'LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_NOT_TRAPPED',
  LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_INTERCEPTED = 'LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_INTERCEPTED',
  LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_BLOCKED = 'LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_BLOCKED',
  LONG_PASS_DONE = 'LONG_PASS_DONE',
  LONG_PASS_DONE_FREE = 'LONG_PASS_DONE_FREE',
  LONG_PASS_INTERCEPTED = 'LONG_PASS_INTERCEPTED',
  LONG_PASS_BLOCKED = 'LONG_PASS_BLOCKED',
  FOULED = 'FOULED',
  INJURED = 'INJURED',
  YELLOW_CARD = 'YELLOW_CARD',
  SECOND_YELLOW_CARD = 'SECOND_YELLOW_CARD',
  RED_CARD = 'RED_CARD',
  CARRIED_THE_BALL_FREE = 'CARRIED_THE_BALL_FREE',
  CROSS_DONE = 'CROSS_DONE',
  CROSS_INTERCEPTED = 'CROSS_INTERCEPTED',
  BAD_CROSS = 'BAD_CROSS',
  NO_ATTACKER_TO_PLAY = 'NO_ATTACKER_TO_PLAY',
  NO_DEFENDER_TO_PLAY = 'NO_DEFENDER_TO_PLAY',
  SUBSTITUTION = 'SUBSTITUTION',
  OFFSIDE = 'OFFSIDE',
  CORNER_KICK = 'CORNER_KICK',
  NO_ONE_INSIDE_THE_BOX = 'NO_ONE_INSIDE_THE_BOX',
  RECEIVED_SHORT_PASS = 'RECEIVED_SHORT_PASS',
  RECEIVED_SHORT_PASS_FREE = 'RECEIVED_SHORT_PASS_FREE',
  RECEIVED_LONG_PASS = 'RECEIVED_LONG_PASS',
  RECEIVED_LONG_PASS_FREE = 'RECEIVED_LONG_PASS_FREE',
  RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER = 'RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER',
  RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_FREE = 'RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_FREE',
  SHOOT_BLOCKED = 'SHOOT_BLOCKED',
  COUNTER_ATTACK = 'COUNTER_ATTACK',
}

export enum Zone {
  BACK_LEFT = 'BACK_LEFT',
  BACK_CENTER = 'BACK_CENTER',
  BACK_RIGHT = 'BACK_RIGHT',
  MIDDLE_LEFT = 'MIDDLE_LEFT',
  MIDDLE_CENTER = 'MIDDLE_CENTER',
  MIDDLE_RIGHT = 'MIDDLE_RIGHT',
  FORWARD_LEFT = 'FORWARD_LEFT',
  FORWARD_CENTER = 'FORWARD_CENTER',
  FORWARD_RIGHT = 'FORWARD_RIGHT',
}

export enum CommentaryType {
  MOVE = 'MOVE',
  SUBSTITUTION = 'SUBSTITUTION',
  INJURY = 'INJURY',
  END_OF_MATCH = 'END_OF_MATCH',
  END_BY_WALKOVER = 'END_BY_WALKOVER',
}

export type Commentary = {
  atMinute: number;
  attacker: CommentaryPlayer;
  attackingClub: CommentaryClub;
  defender: CommentaryPlayer;
  defendingClub: CommentaryClub;
  goalkeeper: CommentaryPlayer;
  id: string;
  inPlayer: CommentaryPlayer;
  moveType: MoveType;
  orderNumber: number;
  outPlayer: CommentaryPlayer;
  result: MoveResult;
  type: CommentaryType;
  zone: Zone;
};

export enum LineUpPosition {
  UNKNOWN = 'UNKNOWN',
  GOALKEEPER = 'GOALKEEPER',
  RIGHT_SIDE_BACK_DEFENDER = 'RIGHT_SIDE_BACK_DEFENDER',
  LEFT_DEFENDER = 'LEFT_DEFENDER',
  CENTER_DEFENDER = 'CENTER_DEFENDER',
  RIGHT_DEFENDER = 'RIGHT_DEFENDER',
  LEFT_SIDE_BACK_DEFENDER = 'LEFT_SIDE_BACK_DEFENDER',
  LEFT_DEFENDING_MIDFIELDER = 'LEFT_DEFENDING_MIDFIELDER',
  CENTER_DEFENDING_MIDFIELDER = 'CENTER_DEFENDING_MIDFIELDER',
  RIGHT_DEFENDING_MIDFIELDER = 'RIGHT_DEFENDING_MIDFIELDER',
  LEFT_FLANK = 'LEFT_FLANK',
  LEFT_MIDFIELDER = 'LEFT_MIDFIELDER',
  CENTER_MIDFIELDER = 'CENTER_MIDFIELDER',
  RIGHT_MIDFIELDER = 'RIGHT_MIDFIELDER',
  RIGHT_FLANK = 'RIGHT_FLANK',
  LEFT_ATTACKING_MIDFIELDER = 'LEFT_ATTACKING_MIDFIELDER',
  CENTER_ATTACKING_MIDFIELDER = 'CENTER_ATTACKING_MIDFIELDER',
  RIGHT_ATTACKING_MIDFIELDER = 'RIGHT_ATTACKING_MIDFIELDER',
  LEFT_WING = 'LEFT_WING',
  LEFT_STRIKER = 'LEFT_STRIKER',
  STRIKER = 'STRIKER',
  RIGHT_STRIKER = 'RIGHT_STRIKER',
  RIGHT_WING = 'RIGHT_WING',
}

enum Formation {
  THREE_FIVE_TWO = 'THREE_FIVE_TWO',
  THREE_FOUR_THREE = 'THREE_FOUR_THREE',
  THREE_THREE_ONE_THREE = 'THREE_THREE_ONE_THREE',
  FOUR_FOUR_TWO = 'FOUR_FOUR_TWO',
  FOUR_FOUR_ONE_ONE = 'FOUR_FOUR_ONE_ONE',
  FOUR_ONE_TWO_ONE_TWO = 'FOUR_ONE_TWO_ONE_TWO',
  FOUR_ONE_TWO_ONE_TWO_OFFENSIVE = 'FOUR_ONE_TWO_ONE_TWO_OFFENSIVE',
  FOUR_ONE_TWO_TWO_ONE = 'FOUR_ONE_TWO_TWO_ONE',
  FOUR_ONE_THREE_TWO = 'FOUR_ONE_THREE_TWO',
  FOUR_THREE_THREE = 'FOUR_THREE_THREE',
  FOUR_THREE_ONE_TWO = 'FOUR_THREE_ONE_TWO',
  FOUR_ONE_TWO_THREE = 'FOUR_ONE_TWO_THREE',
  FOUR_THREE_TWO_ONE = 'FOUR_THREE_TWO_ONE',
  FOUR_TWO_THREE_ONE = 'FOUR_TWO_THREE_ONE',
  FOUR_TWO_TWO_TWO = 'FOUR_TWO_TWO_TWO',
  FOUR_FIVE_ONE = 'FOUR_FIVE_ONE',
  FIVE_FOUR_ONE = 'FIVE_FOUR_ONE',
  FIVE_THREE_TWO = 'FIVE_THREE_TWO',
}

export type MatchDetails = {
  id: string;
  date: string;
  currentMinute: number;
  awayClub: Club;
  homeClub: Club;
  decisionByPenalties: boolean;
  division: Division;
  finishedByWO: boolean;
  fixtureId: string;
  score: Score;
  penaltiesScore?: Score;
  phase: Phase;
  result: Result;
  seasonId: string;
  status: Status;
  type: MatchType;
  attendance: Attendance;
  awayBench: Bench;
  homeBench: Bench;
  awayLineUp: LineUp;
  homeLineUp: LineUp;
  statistics: Statistics;
  homeBestPlayer: Player;
  awayBestPlayer: Player;
  commentaries?: Commentary[];
  manOfTheMatch: ManOfTheMatch;
};

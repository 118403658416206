import { EyeColor, HairColor, NoseColor } from '../../../common/Player/constants';
import { Country } from '../Country';

export type Face = {
  body: number;
  hairStyle: number;
  hairColor: HairColor;
  eyeShape: number;
  eyeColor: EyeColor;
  noseShape: number;
  noseColor: NoseColor;
  mouthShape: number;
  beard: number;
  eyebrows: number;
};

export type Suspensions = {
  league: boolean;
  nationalCup: boolean;
  internationalCup: boolean;
  regionalTournament: boolean;
  u20League: boolean;
};

export type YellowCards = {
  league: number;
  nationalCup: number;
  internationalCup: number;
  regionalTournament: number;
  u20League: number;
};

export type Contract = {
  originalDuration: number;
  expiresAt: string;
  marketValue: number;
  salary: number;
  renewSalaryEverySeason: boolean;
};

export type ContractProposal = {
  duration: number;
  salary: number;
  renewSalaryEverySeason: boolean;
};

export type TechnicalAttributes = {
  reflexes: number;
  aerialPlaying: number;
  positioning: number;
  tackling: number;
  shooting: number;
  technique: number;
  creation: number;
  passing: number;
};

export type PhysicalAttributes = {
  strength: number;
  speed: number;
  stamina: number;
  form: number;
};

export type PsychologicalAttributes = {
  intelligence: number;
  aggressiveness: number;
  honesty: number;
  leadership: number;
  experience: number;
  integration: number;
  discipline: number;
  morale: number;
  fame: number;
  ambition: number;
};

export type Stats = {
  averagePasses: number;
  successfulPassesPercentage: number;
  averageTackles: number;
  successfulTacklesPercentage: number;
  averageDribbles: number;
  successfulDribblesPercentage: number;
  averageShots: number;
  shotsOnTargetPercentage: number;
  averageSaves: number;
  successfulSavesPercentage: number;
  averageYellowCards: number;
  averageRedCards: number;
  averageFoulsCommitted: number;
  averageFoulsSuffered: number;
  averageCrossesInterception: number;
  successfulCrossesInterceptionPercentage: number;
  goalsScored: number;
  assistsGiven: number;
  matchesPlayed: number;
  manOfTheMatch: number;
  averageGoalsScored: number;
  averageAssistsGiven: number;
};

export type InjuryStatus = {
  injured: boolean;
  injuredUntil: string;
};

export enum Position {
  GOALKEEPER = 'GOALKEEPER',
  SIDE_BACK = 'SIDE_BACK',
  CENTER_BACK = 'CENTER_BACK',
  DEFENDING_MIDFIELD = 'DEFENDING_MIDFIELD',
  MIDFIELD = 'MIDFIELD',
  FLANK_MIDFIELD = 'FLANK_MIDFIELD',
  ATTACKING_MIDFIELD = 'ATTACKING_MIDFIELD',
  STRIKER = 'STRIKER',
  WING = 'WING',
}

export enum GrassrootsStatus {
  AVAILABLE = 'AVAILABLE',
  HIRED = 'HIRED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum PreferredLeg {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export type Player = {
  id: string;
  suspensions: Suspensions;
  yellowCards: YellowCards;
  name: string;
  face: Face;
  age: number;
  contract: Contract;
  contractProposal: ContractProposal;
  technicalAttributes: TechnicalAttributes;
  physicalAttributes: PhysicalAttributes;
  psychologicalAttributes: PsychologicalAttributes;
  position: Position;
  shirtNumber: number;
  stats: Stats;
  country: Country;
  isGrassroots: boolean;
  grassroots: boolean;
  grassrootsStatus: GrassrootsStatus;
  injuryStatus: InjuryStatus;
  tiredness: number;
  preferredLeg: PreferredLeg;
  retirementAge: number;
};

import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Loader } from '../../common';
import { useChangeStadiumName } from '../../data/hooks/useFacilities';
import { ApiError } from '../../data/types/ApiError';
import { QueryKeys } from '../../data/hooks/queryKeys';

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
};

const isNameValid = (name: string): boolean => name.length > 0 && name.length <= 30;

export const ChangeStadiumNameDialog: FC<Props> = (props: Props) => {
  const {
    name,
    open,
    onClose,
  } = props;
  const [newName, setNewName] = useState<string>(name || '');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading,
    mutateFn: changeStadiumName,
  } = useChangeStadiumName();

  useEffect(() => { setNewName(name); }, [name]);

  const handleOnClose = () => {
    setNewName('');
    onClose();
  };

  const handleConfirmChangeName = () => {
    changeStadiumName(newName).then(() => {
      enqueueSnackbar(t('stadium_name_changed'), { variant: 'success' });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
      });
    }).catch((data: AxiosError) => {
      const errorResponse = data.response?.data as ApiError;
      enqueueSnackbar(t(errorResponse.error || ''), { variant: 'error' });
    }).finally(() => {
      onClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('change_stadium_name')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="left" alignItems="left" sx={{ paddingTop: 1 }}>

          <FormControl fullWidth>
            <TextField
              label={t('new_stadium_name')}
              variant="outlined"
              onChange={(e) => setNewName(e.target.value)}
              value={newName}
              autoFocus
            />
          </FormControl>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} disabled={false}>{t('cancel')}</Button>
        <Button
          onClick={handleConfirmChangeName}
          variant="contained"
          color="primary"
          disabled={
            newName === name
            || newName === undefined
            || !isNameValid(newName)
          }
        >
          {isLoading ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

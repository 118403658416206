import { FC } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Player } from '../../data/types';
import { Position } from './Position';
import { Shirt } from './Shirt';
import { Face } from '../Face/Face';

export const LoadingSkeleton: FC = () => (
  <>
    {[...Array(10)].map(() => (
      <Box
        sx={{
          height: '80px',
          my: 0,
          mx: 1,
        }}
        key={Math.random()}
      >
        <Skeleton variant="rounded" height={65} />
      </Box>
    ))}
  </>
);

type Props = {
  loading: boolean;
  players: Player[];
  onPlayerClick: (player: Player) => void;
};

export const PlayersList: FC<Props> = ({ players, loading, onPlayerClick }: Props) => {
  const { t } = useTranslation();
  const columnsDefinition: GridColDef[] = [
    {
      field: 'face',
      headerName: '#',
      editable: false,
      sortable: false,
      pinnable: false,
      width: 75,
      renderCell: (params: GridRenderCellParams) => (
        <Face
          body={params.value.body}
          hairStyle={params.value.hairStyle}
          hairColor={params.value.hairColor}
          eyeShape={params.value.eyeShape}
          eyeColor={params.value.eyeColor}
          noseShape={params.value.noseShape}
          mouthShape={params.value.mouthShape}
          beard={params.value.beard}
          eyebrows={params.value.eyebrows}
          width={50}
          height={50}
        />
      ),
    },
    {
      field: 'name',
      headerName: t('name') as string,
      editable: false,
      minWidth: 160,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: 'age',
      headerName: t('age') as string,
      editable: false,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
      width: 50,
    },
    {
      field: 'position',
      headerName: t('position') as string,
      editable: false,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams) => <Position position={params.value} showLegend />,
    },
    {
      field: 'shirtNumber',
      headerName: t('shirtNumber') as string,
      editable: false,
      width: 75,
      renderCell: (params: GridRenderCellParams) => <Shirt number={params.value} />,
    },
  ];

  return (
    <DataGrid
      columns={columnsDefinition}
      rows={players}
      rowHeight={80}
      onRowClick={(params: GridRowParams) => {
        onPlayerClick(params.row as Player);
      }}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      loading={loading}
      sx={{
        '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-virtualScroller': {
          minHeight: players.length > 0 ? '800px' : '120px',
          marginTop: 1,
        },
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
      }}
      slots={{
        loadingOverlay: LoadingSkeleton,
      }}
      disableColumnFilter
      disableColumnMenu
    />
  );
};

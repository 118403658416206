import { useMemo } from 'react';
import {
  Checkbox, Box, Typography, List, ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePlayers } from '../../../data/hooks';
import { useLineupState, useLineupActions } from '../../../store';
import { PlayerWithStatus } from './PlayerWithStatus';
import { MatchType } from '../../../data/types/seasons/Match';

export const Bench = ({ matchType }: { matchType: MatchType }): JSX.Element => {
  const { data: players } = usePlayers();
  const { benchPlayers, firstElevenPlayers } = useLineupState();
  const { setBenchPlayers } = useLineupActions();
  const { t } = useTranslation();

  const benchPlayersSelection = useMemo(() => (
    players?.filter((p) => !firstElevenPlayers.flatMap((ep) => ep.id).includes(p.id))
  ), [firstElevenPlayers, players]);

  const onChange = (checked: boolean, id: string) => {
    if (checked) {
      setBenchPlayers([...benchPlayers, id]);
    } else {
      setBenchPlayers(benchPlayers.filter((p) => p !== id));
    }
  };

  return (
    <Box>
      <Typography variant="h5">{t('bench_setup_explanation')}</Typography>
      <List>
        {
          benchPlayersSelection?.map((m) => (
            <ListItem
              key={m.id}
              disablePadding
              secondaryAction={(
                <Checkbox
                  edge="end"
                  onChange={(e) => onChange(e.target.checked, m.id)}
                  checked={benchPlayers?.indexOf(m.id) !== -1 ?? false}
                  inputProps={{ 'aria-labelledby': m.name }}
                />
              )}
            >
              <PlayerWithStatus
                player={m}
                firstElevenPlayers={firstElevenPlayers}
                matchType={matchType}
              />
            </ListItem>
          ))
        }
      </List>
    </Box>
  );
};

import { CURRENCIES, EN_GB } from '../constants';

export const formatCurrency = (amount: number, locale: string = EN_GB): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: CURRENCIES[locale],

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (for whole numbers, will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount);
};

import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Coach, CoachLevel, CoachType } from '../../data/types/clubs/Coach';
import { formatCurrency } from '../../common/formatter';
import { useChangeCoach, useGetCoachChangeCosts } from '../../data/hooks/useCoaches';
import { Loader } from '../../common';
import { ApiError } from '../../data/types/ApiError';
// import { Loader } from '../../common';

type Props = {
  coach: Coach;
  type: CoachType;
  open: boolean;
  onClose: () => void;
};

const coachLevels = [
  CoachLevel.BAD,
  CoachLevel.WEAK,
  CoachLevel.REGULAR,
  CoachLevel.GOOD,
  CoachLevel.VERY_GOOD,
  CoachLevel.EXCELLENT,
  CoachLevel.FORMIDABLE,
];

export const ChangeCoachDialog: FC<Props> = (props: Props) => {
  const {
    coach,
    open,
    onClose,
    type,
  } = props;
  const [coachLevel, setCoachLevel] = useState<CoachLevel>(coach?.level || CoachLevel.BAD);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const matchesXsOrSm = theme.breakpoints.down('md');
  const { isLoading: isLoadingChangeCoach, mutateFn: changeCoach } = useChangeCoach();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading: isLoadingChangeCoachCosts, data: changeCoachCosts } = useGetCoachChangeCosts(
    type,
    coachLevel || CoachLevel.BAD,
  );

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: ['get-coach-change-costs', type, coachLevel],
    });
  }, [coachLevel, type, queryClient]);

  const handleOnCoachLevelChange = (level: CoachLevel) => {
    setCoachLevel(level);
  };

  const handleOnClose = () => {
    setCoachLevel(coach?.level || CoachLevel.BAD);
    onClose();
  };

  const handleConfirmChangeCoach = () => {
    changeCoach({ type, level: coachLevel || CoachLevel.BAD }).then(() => {
      onClose();
      enqueueSnackbar(t('coach_changed_successfully'), { variant: 'success' });
    }).catch((data: AxiosError) => {
      const errorResponse = data.response?.data as ApiError;
      enqueueSnackbar(t(errorResponse.error || ''), { variant: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('change_coach')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="left" alignItems="left" sx={{ paddingTop: 1 }}>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('coach_type')}</Typography>
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
              {t(type.toLocaleLowerCase())}
            </Typography>
          </Stack>

          {coach?.id ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_coach')}</Typography>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {coach?.name}
              </Typography>
            </Stack>
          ) : null}

          {coach?.id ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_salary')}</Typography>
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(coach?.salary || 0, i18n.language)}
              </Typography>
            </Stack>
          ) : null}

          <FormControl fullWidth>
            <InputLabel sx={{ mt: 1 }}>{t('select_new_coach_level')}</InputLabel>
            <Select
              id="coach-level"
              label={t('select_new_coach_level')}
              defaultValue={coach.level}
              value={coachLevel}
              onChange={(e) => handleOnCoachLevelChange(e.target.value as CoachLevel)}
              sx={{ mt: 1 }}
            >
              {coachLevels.map((level) => (
                <MenuItem key={level} value={level}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Box
                      component="img"
                      src={`/assets/icons/levels/${level.toLowerCase()}.svg`}
                      alt={t(level.toLowerCase()) || 'Type'}
                      sx={{
                        width: matchesXsOrSm ? '55px' : '70px',
                        height: matchesXsOrSm ? '45px' : '50px',
                      }}
                    />
                    <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t(level.toLowerCase())}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('transfer_fee')}</Typography>
            {isLoadingChangeCoachCosts ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(changeCoachCosts?.transferFee || 0, i18n.language)}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('new_weekly_salary')}</Typography>
            {isLoadingChangeCoachCosts ? (
              <Skeleton variant="text" width={100} height="100%" />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(changeCoachCosts?.newSalary || 0, i18n.language)}
              </Typography>
            )}

          </Stack>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} disabled={isLoadingChangeCoach}>{t('cancel')}</Button>
        <Button
          onClick={handleConfirmChangeCoach}
          variant="contained"
          color="primary"
          disabled={isLoadingChangeCoach || coachLevel === coach.level || coachLevel === undefined}
        >
          {isLoadingChangeCoach ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useQuery } from '@tanstack/react-query';

import { getUserClubMatchesOfCurrentSeason } from '../api';
import { Match } from '../types/seasons/Match';

export const useMatchesCurrentSeason = () => {
  // const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery<Match[], Error>({
    queryKey: ['matches-current-season'],
    queryFn: getUserClubMatchesOfCurrentSeason,
  });

  return { isLoading, error, data };
};

import { axiosInstance } from '../axios';
import { Intensity, PhysicalTraining, PhysicalTrainingType } from '../types/training/PhysicalTraining';
import { TechnicalTraining, TechnicalTrainingType } from '../types/training/TechnicalTraining';
import { TrainingOverview } from '../types/training/TrainingOverview';

export type ChangePhysicalTrainingRequest = {
  type: PhysicalTrainingType;
  intensity: Intensity;
};

export const getTrainingOverview = (): Promise<TrainingOverview> => (
  axiosInstance.get('/training/overview')
    .then((response) => response.data)
);

export const changeTechnicalTraining = (
  type: TechnicalTrainingType,
): Promise<TechnicalTraining> => (
  axiosInstance.patch('/training/setup/technical-training', { type })
    .then((response) => response.data)
);

export const changePhysicalTraining = (
  request: ChangePhysicalTrainingRequest,
): Promise<PhysicalTraining> => (
  axiosInstance.patch('/training/setup/physical-training', { type: request.type, intensity: request.intensity })
    .then((response) => response.data)
);

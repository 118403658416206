import { FC, useState } from 'react';
import {
  Box,
  Card, CardContent, CardHeader, Skeleton, Tab, Tabs,
} from '@mui/material';
import { t } from 'i18next';
import { Bracket } from '../../data/types/seasons/Bracket';
import { Brackets } from '../Bracket/Brackets';

type Props = {
  competitionName: string;
  loading: boolean;
  firstPhaseBrackets?: Bracket[];
  secondPhaseBrackets?: Bracket[];
  thirdPhaseBrackets?: Bracket[];
  roundOf16Brackets: Bracket[];
  quarterFinalsBrackets: Bracket[];
  semiFinalsBrackets: Bracket[];
  finalBracket: Bracket;
  loadingPlaceholders: number;
};

export const KnockoutDetails: FC<Props> = (props: Props) => {
  const {
    competitionName,
    loading,
    firstPhaseBrackets,
    secondPhaseBrackets,
    thirdPhaseBrackets,
    roundOf16Brackets,
    quarterFinalsBrackets,
    semiFinalsBrackets,
    finalBracket,
    loadingPlaceholders,
  } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const roundOf16Index = firstPhaseBrackets === undefined ? 0 : 3;
  const quarterFinalsIndex = secondPhaseBrackets === undefined ? 1 : 4;
  const semiFinalsIndex = thirdPhaseBrackets === undefined ? 2 : 5;
  const finalBracketIndex = thirdPhaseBrackets === undefined ? 3 : 6;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={loading ? (<Skeleton variant="rounded" width="100%" />) : `${competitionName} - ${t('knockout_phase')}`}
        titleTypographyProps={{
          variant: 'h6',
          textAlign: 'center',
        }}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        <Tabs
          value={tabIndex}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          sx={{ paddingBottom: 1 }}
        >
          {firstPhaseBrackets !== undefined ? (<Tab label={t('first_phase')} />) : null}
          {secondPhaseBrackets !== undefined ? (<Tab label={t('second_phase')} />) : null}
          {thirdPhaseBrackets !== undefined ? (<Tab label={t('third_phase')} />) : null}
          <Tab label={t('round_of_16')} />
          <Tab label={t('quarter_finals')} />
          <Tab label={t('semi_finals')} />
          <Tab label={t('final')} />
        </Tabs>

        {firstPhaseBrackets !== undefined ? (
          <Box
            role="tabpanel"
            hidden={tabIndex !== 0}
            id="first-phase-tab"
            aria-labelledby="first-phase-tab"
          >
            <Brackets
              brackets={firstPhaseBrackets}
              loading={loading}
              loadingPlaceholders={loadingPlaceholders}
            />
          </Box>
        ) : null}

        {secondPhaseBrackets !== undefined ? (
          <Box
            role="tabpanel"
            hidden={tabIndex !== 1}
            id="second-phase-tab"
            aria-labelledby="second-phase-tab"
          >
            <Brackets
              brackets={secondPhaseBrackets}
              loading={loading}
              loadingPlaceholders={loadingPlaceholders}
            />
          </Box>
        ) : null}

        {thirdPhaseBrackets !== undefined ? (
          <Box
            role="tabpanel"
            hidden={tabIndex !== 2}
            id="third-phase-tab"
            aria-labelledby="third-phase-tab"
          >
            <Brackets
              brackets={thirdPhaseBrackets}
              loading={loading}
              loadingPlaceholders={loadingPlaceholders}
            />
          </Box>
        ) : null}

        <Box
          role="tabpanel"
          hidden={tabIndex !== roundOf16Index}
          id="round-of-16-tab-0"
          aria-labelledby="round-of-16-tab-0"
        >
          <Brackets
            brackets={roundOf16Brackets}
            loading={loading}
            loadingPlaceholders={loadingPlaceholders}
          />
        </Box>

        <Box
          role="tabpanel"
          hidden={tabIndex !== quarterFinalsIndex}
          id="quarter-finals-tab-1"
          aria-labelledby="quarter-finals-tab-1"
        >
          <Brackets
            brackets={quarterFinalsBrackets}
            loading={loading}
            loadingPlaceholders={loadingPlaceholders}
          />
        </Box>

        <Box
          role="tabpanel"
          hidden={tabIndex !== semiFinalsIndex}
          id="semi-finals-tab-2"
          aria-labelledby="semi-finals-tab-2"
        >
          <Brackets
            brackets={semiFinalsBrackets}
            loading={loading}
            loadingPlaceholders={loadingPlaceholders}
          />
        </Box>

        <Box
          role="tabpanel"
          hidden={tabIndex !== finalBracketIndex}
          id="final-tab-3"
          aria-labelledby="final-tab-3"
        >
          <Brackets
            brackets={[finalBracket]}
            loading={loading}
            loadingPlaceholders={loadingPlaceholders}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

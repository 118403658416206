type Squad = {
  df?: string[];
  cdm?: string[];
  cm?: string[];
  cam?: string[];
  fw?: string[];
  gk?: {};
};

type Formation = {
  name: string;
  value: string;
  squad: Squad;
};

/**
 * Formation structure means: if 3-5-2 is selected
 * - 3 - in it the back
 * - 5 - in the middle
 * - 2 - in front (attack)
 * And always one Keeper is present
 * In total: 11 plyers on the field
 */
export const formations: Formation[] = [{
  name: '3-5-2',
  value: 'THREE_FIVE_TWO',
  squad: {
    df: ['LEFT_DEFENDER', 'CENTER_DEFENDER', 'RIGHT_DEFENDER'],
    cm: ['LEFT_FLANK', 'LEFT_DEFENDING_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_DEFENDING_MIDFIELDER', 'RIGHT_FLANK'],
    fw: ['LEFT_STRIKER', 'RIGHT_STRIKER'],
  },
}, {
  name: '3-4-3',
  value: 'THREE_FOUR_THREE',
  squad: {
    df: ['LEFT_DEFENDER', 'CENTER_DEFENDER', 'RIGHT_DEFENDER'],
    cm: ['LEFT_FLANK', 'LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER', 'RIGHT_FLANK'],
    fw: ['LEFT_WING', 'STRIKER', 'RIGHT_WING'],
  },
}, {
  name: '3-1-3-3',
  value: 'THREE_THREE_ONE_THREE',
  squad: {
    df: ['LEFT_DEFENDER', 'CENTER_DEFENDER', 'RIGHT_DEFENDER'],
    cm: ['CENTER_DEFENDING_MIDFIELDER'],
    cam: ['LEFT_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    fw: ['LEFT_WING', 'STRIKER', 'RIGHT_WING'],
  },
}, {
  name: '4-4-2',
  value: 'FOUR_FOUR_TWO',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_FLANK', 'LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER', 'RIGHT_FLANK'],
    fw: ['LEFT_STRIKER', 'RIGHT_STRIKER'],
  },
}, {
  name: '4-4-1-1',
  value: 'FOUR_FOUR_ONE_ONE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_FLANK', 'LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER', 'RIGHT_FLANK'],
    cam: ['CENTER_ATTACKING_MIDFIELDER'],
    fw: ['STRIKER'],
  },
}, {
  name: '4-1-2-1-2', // 'df', 'cdm', 'cm', 'cam', 'fw'
  value: 'FOUR_ONE_TWO_ONE_TWO',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cdm: ['CENTER_DEFENDING_MIDFIELDER'],
    cm: ['LEFT_FLANK', 'RIGHT_FLANK'],
    cam: ['CENTER_MIDFIELDER'],
    fw: ['LEFT_STRIKER', 'RIGHT_STRIKER'],
  },
}, // { name: '4-1-2-1-2', OFFENSIVE },
{
  name: '4-1-2-2-1',
  value: 'FOUR_ONE_TWO_TWO_ONE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cdm: ['CENTER_DEFENDING_MIDFIELDER'],
    cm: ['LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    cam: ['LEFT_ATTACKING_MIDFIELDER', 'RIGHT_ATTACKING_MIDFIELDER'],
    fw: ['STRIKER'],
  },
}, {
  name: '4-1-3-2',
  value: 'FOUR_ONE_THREE_TWO',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cdm: ['CENTER_DEFENDING_MIDFIELDER'],
    cm: ['LEFT_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    cam: ['LEFT_ATTACKING_MIDFIELDER', 'RIGHT_ATTACKING_MIDFIELDER'],
  },
}, {
  name: '4-3-3',
  value: 'FOUR_THREE_THREE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    fw: ['LEFT_WING', 'STRIKER', 'RIGHT_WING'],
  },
}, {
  name: '4-3-1-2',
  value: 'FOUR_THREE_ONE_TWO',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    cam: ['CENTER_ATTACKING_MIDFIELDER'],
    fw: ['LEFT_STRIKER', 'RIGHT_STRIKER'],
  },
}, {
  name: '4-1-2-3',
  value: 'FOUR_ONE_TWO_THREE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cdm: ['CENTER_DEFENDING_MIDFIELDER'],
    cm: ['LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    fw: ['LEFT_WING', 'STRIKER', 'RIGHT_WING'],
  },
}, {
  name: '4-3-2-1',
  value: 'FOUR_THREE_TWO_ONE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    cam: ['LEFT_ATTACKING_MIDFIELDER', 'RIGHT_ATTACKING_MIDFIELDER'],
    fw: ['STRIKER'],
  },
}, {
  name: '4-2-3-1',
  value: 'FOUR_TWO_THREE_ONE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    cam: ['LEFT_ATTACKING_MIDFIELDER', 'CENTER_ATTACKING_MIDFIELDER', 'RIGHT_ATTACKING_MIDFIELDER'],
    fw: ['STRIKER'],
  },
}, {
  name: '4-2-2-2',
  value: 'FOUR_TWO_TWO_TWO',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cdm: ['LEFT_DEFENDING_MIDFIELDER', 'RIGHT_DEFENDING_MIDFIELDER'],
    cm: ['LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    fw: ['LEFT_STRIKER', 'RIGHT_STRIKER'],
  },
}, {
  name: '4-5-1',
  value: 'FOUR_FIVE_ONE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_FLANK', 'LEFT_DEFENDING_MIDFIELDER', 'CENTER_MIDFIELDER', 'RIGHT_DEFENDING_MIDFIELDER', 'RIGHT_FLANK'],
    fw: ['STRIKER'],
  },
}, {
  name: '5-4-1',
  value: 'FIVE_FOUR_ONE',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'CENTER_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_FLANK', 'LEFT_MIDFIELDER', 'RIGHT_MIDFIELDER', 'RIGHT_FLANK'],
    fw: ['STRIKER'],
  },
}, {
  name: '5-3-2',
  value: 'FIVE_THREE_TWO',
  squad: {
    df: ['LEFT_SIDE_BACK_DEFENDER', 'LEFT_DEFENDER', 'CENTER_DEFENDER', 'RIGHT_DEFENDER', 'RIGHT_SIDE_BACK_DEFENDER'],
    cm: ['LEFT_MIDFIELDER', 'CENTER_DEFENDING_MIDFIELDER', 'RIGHT_MIDFIELDER'],
    fw: ['LEFT_STRIKER', 'RIGHT_STRIKER'],
  },
},
];

import {
  LinearProgress, Stack, Typography, lighten,
} from '@mui/material';
import { FC } from 'react';

const getColor = (number: number): string => {
  if (number < 50) {
    return '#ba2c27';
  }

  if (number >= 50 && number < 70) {
    return '#eb7a2a';
  }

  if (number >= 70 && number < 80) {
    return '#4fde43';
  }

  if (number >= 80) {
    return '#19ED04';
  }

  return '#19ED04';
};

  type Props = {
    energy: number;
    width: string;
    showNumber?: boolean;
  };
export const EnergyBar: FC<Props> = (props: Props) => {
  const { energy, width, showNumber } = props;
  const energyInPercentage = Math.round(energy * 100);
  const color = getColor(energyInPercentage);

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <LinearProgress
        value={Math.round(energyInPercentage)}
        variant="determinate"
        sx={{
          height: '1rem',
          width,
          backgroundColor: lighten(color, 0.85),
          borderRadius: '0.25rem',
          '& .MuiLinearProgress-bar': {
            borderRadius: '0.25rem',
            backgroundColor: color,
          },
        }}
      />
      {showNumber ? (
        <Typography variant="body1" sx={{ color, fontWeight: 'bold' }}>
          {`${energyInPercentage}%`}
        </Typography>
      ) : null}
    </Stack>
  );
};

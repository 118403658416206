import { Club } from '../clubs/Club';

export type Score = {
  home: number,
  away: number
};

export enum Result {
  HOME_WON = 'HOME_WON',
  DRAW = 'DRAW',
  AWAY_WON = 'AWAY_WON',
}

export enum Status {
  SCHEDULED = 'SCHEDULED',
  PLAYING = 'PLAYING',
  FINISHED = 'FINISHED',
}

export enum MatchType {
  LEAGUE_MATCH = 'LEAGUE_MATCH',
  REGIONAL_TOURNAMENT_MATCH = 'REGIONAL_TOURNAMENT_MATCH',
  NATIONAL_CUP_MATCH = 'NATIONAL_CUP_MATCH',
  INTERNATIONAL_CUP_MATCH = 'INTERNATIONAL_CUP_MATCH',
  FRIENDLY_MATCH = 'FRIENDLY_MATCH',
}

export type Match = {
  id: string,
  homeClub: Club,
  awayClub: Club,
  score: Score,
  competitionName: string,
  competitionId: string,
  result: Result,
  status: Status,
  matchType: MatchType,
  date: string,
  decisionByPenalties: boolean,
  penaltiesScore?: Score,
};

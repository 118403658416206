import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { Player } from '../../data/types';
import { Shirt } from './Shirt';

type Props = {
  player?: Player;
};

export const ShirtCard: FC<Props> = (props: Props) => {
  const { player } = props;
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card variant="outlined" sx={{ height: 220 }}>
      <CardHeader
        title={t('shirtNumber')}
        titleTypographyProps={{ variant: matchesXsOrSm ? 'body1' : 'subtitle1' }}
      />
      <CardContent sx={{ width: '100%', padding: '0' }}>
        <Box sx={{ width: '117px', margin: 'auto' }}>
          <Box sx={{ width: '117px' }}>
            {player ? (
              <Shirt number={player?.shirtNumber || 0} />
            ) : (
              <Skeleton
                variant="circular"
                width={117}
                height={117}
                sx={{
                  margin: matchesXsOrSm ? '20px' : 'auto',
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

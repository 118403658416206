import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getNationalCupOverview } from '../api';
import { NationalCupOverview } from '../types/seasons/NationalCupOverview';

export const useNationalCupOverview = () => {
  // Get the regional tournament ID from the path variable
  const { id } = useParams();

  const { isLoading, error, data } = useQuery<NationalCupOverview, Error>({
    queryKey: ['national-cup-overview', id],
    queryFn: () => getNationalCupOverview(id || ''),
  });

  return { isLoading, error, data };
};

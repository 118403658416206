import { FC } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { Flag } from '../Flag';

type Props = {
  player?: Player;
};

export const CountryCard: FC<Props> = (props: Props) => {
  const { player } = props;
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card variant="outlined" sx={{ height: '220px' }}>
      <CardHeader
        title={t('country')}
        titleTypographyProps={{ variant: matchesXsOrSm ? 'body1' : 'subtitle1' }}
      />
      <CardContent sx={{ padding: 0 }}>
        <Stack
          direction={matchesXsOrSm ? 'row' : 'column'}
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {player ? (
            <Flag countryCode={player?.country?.code || ''} width={80} />
          ) : (
            <Skeleton
              variant="circular"
              width={90}
              height={90}
              sx={{
                margin: matchesXsOrSm ? '0' : 'auto',
              }}
            />
          )}
          {player ? (
            <Typography variant="h6">{player?.country?.name}</Typography>
          ) : (
            <Skeleton
              variant="rounded"
              width="90%"
              height={30}
              sx={{ margin: 'auto', marginTop: '12px !important' }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

import { FC } from 'react';
import { HairColor, getHairColor } from '../Player/constants';

type Props = {
  type: number;
  color: HairColor;
};

export const Eyebrows: FC<Props> = (props: Props) => {
  const { type, color } = props;
  const colorAsHex = getHairColor(color);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 150 200"
      enableBackground="new 0 0 150 200"
      xmlSpace="preserve"
      style={{
        position: 'absolute',
        top: 0,
      }}
    >
      {type === 1 ? (
        <g fill={colorAsHex}>
          <path d="M97.491 80.089c-.696 1.83-1.467 1.615-9.042 3.059-7.17 1.366-10.478 4.274-10.696 1.021-.167-2.513 1.542-3.89 9.021-4.321 7.825-.452 11.616-2.124 10.717.241zM49.059 80.089c.697 1.83 1.467 1.615 9.042 3.059 7.169 1.366 10.478 4.274 10.696 1.021.169-2.513-1.541-3.89-9.021-4.321-7.825-.452-11.616-2.124-10.717.241z" />
        </g>
      ) : null}

      {type === 2 ? (
        <g fill={colorAsHex}>
          <path d="M45.516 87.127c.263.912 2.935-3.157 10.52-3.313 7.584-.155 10.457.466 11.566-2.694 1.051-2.998-2.422-3.152-9.512-2.107-9.885 1.459-12.967 6.758-12.574 8.114zM101.844 87.127c-.263.912-2.935-3.157-10.519-3.313-7.584-.154-10.457.466-11.565-2.694-1.054-2.998 2.422-3.152 9.511-2.107 9.883 1.459 12.965 6.758 12.573 8.114z" />
        </g>
      ) : null}

      {type === 3 ? (
        <g fill={colorAsHex}>
          <path d="m67.264 82.279-.259-.125c.296.763.317 1.826.272 2.679-.006-.808-.094-1.747-.272-2.313-.022-.074-.077-.156-.271-.228.057.315.104.685.141 1.012a5.812 5.812 0 0 0-.332-1.076 3.312 3.312 0 0 0-.486-.241c.175.417.434 1.143.402 2.093 0 0-.318-1.556-.779-2.354l-.279-.092c.029.011.428.833.428 1.566 0 0-.296-1.035-.549-1.432-2.861-.928-11.217-2.111-12.881-1.871-3.805.551-7.668 4.573-7.668 5.056 0 .481 4.592-1.558 7.511-1.56 2.441-.002 14.202 4.285 14.894 2.844-.001.002.915-1.86.128-3.958zM78.799 83.348l.258-.134c-.277.773-.271 1.837-.201 2.687-.016-.808.049-1.75.213-2.32.02-.076.074-.16.268-.238-.051.316-.086.688-.113 1.016.078-.454.215-.856.303-1.085.166-.107.338-.193.486-.258a4.906 4.906 0 0 0-.346 2.106s.275-1.566.723-2.379c.078-.03.186-.068.279-.103-.029.013-.412.849-.391 1.581 0 0 .271-1.044.516-1.451 2.869-1.027 10.59-2.671 12.283-2.489 3.869.417 8.602 4.471 8.615 4.952.012.482-5.4-1.565-8.354-1.464-2.473.083-13.553 4.948-14.295 3.532 0 .001-.982-1.828-.244-3.953z" />
        </g>
      ) : null}

      {type === 4 ? (
        <g fill={colorAsHex}>
          <path d="m68.908 83.232-.248-.108c.283.653.305 1.564.262 2.294-.006-.691-.091-1.497-.262-1.98-.021-.063-.074-.133-.26-.194.057.269.102.586.136.866a4.664 4.664 0 0 0-.315-.921 3.59 3.59 0 0 0-.465-.207c.168.356.413.979.385 1.793 0 0-.305-1.333-.747-2.016a31.743 31.743 0 0 1-.266-.079c.027.01.411.713.411 1.342 0 0-.283-.886-.525-1.227-2.732-.794-10.374-2.811-11.966-2.604-3.632.472-7.663 4.919-7.663 5.333 0 .412 4.727-2.338 7.515-2.339 2.333-.001 13.225 4.672 13.886 3.439-.001-.001.874-1.596.122-3.392zM79.477 84.146l.243-.116c-.262.662-.257 1.573-.19 2.303-.016-.692.044-1.5.199-1.988.021-.064.072-.136.255-.203-.048.271-.084.59-.109.87a4.63 4.63 0 0 1 .288-.931c.155-.091.317-.164.457-.221a3.874 3.874 0 0 0-.329 1.804s.265-1.342.686-2.039l.263-.087c-.025.011-.389.728-.369 1.354 0 0 .256-.895.486-1.243 2.708-.882 10.283-3.144 11.88-2.989 3.646.353 7.812 4.668 7.824 5.082.013.413-4.797-2.183-7.583-2.094-2.331.073-13.076 5.097-13.774 3.886-.002-.001-.925-1.567-.227-3.388z" />
        </g>
      ) : null}

      {type === 5 ? (
        <g fill={colorAsHex}>
          <path d="m68.514 81.686-.248-.068c.283.417.305 1 .262 1.466-.006-.441-.091-.956-.262-1.266-.021-.041-.074-.085-.26-.124.057.172.102.375.136.554a2.394 2.394 0 0 0-.315-.589 4.319 4.319 0 0 0-.465-.132c.168.228.413.626.385 1.146 0 0-.305-.852-.747-1.288l-.266-.051c.027.007.411.456.411.858 0 0-.283-.567-.525-.785-2.732-.507-10.374-1.796-11.966-1.664-3.632.302-7.663 3.145-7.663 3.408s4.727-1.494 7.515-1.495c2.333 0 13.225 2.986 13.886 2.198-.001 0 .874-1.02.122-2.168zM79.083 82.271l.242-.074c-.262.424-.256 1.006-.189 1.473-.016-.443.043-.959.199-1.271.021-.041.072-.087.254-.13a4.75 4.75 0 0 0-.109.556 2.32 2.32 0 0 1 .289-.595c.154-.058.316-.104.457-.141-.156.23-.381.634-.33 1.152 0 0 .266-.857.686-1.303.074-.017.174-.037.264-.056-.025.007-.389.465-.369.865 0 0 .256-.571.486-.794 2.707-.563 10.283-2.01 11.879-1.911 3.646.226 7.813 2.984 7.824 3.248.014.264-4.797-1.396-7.582-1.339-2.332.047-13.076 3.259-13.775 2.484-.001.001-.924-1.001-.226-2.164z" />
        </g>
      ) : null}
    </svg>
  );
};

import {
  Popover, List, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePlayers } from '../../../data/hooks';
import { useLineupActions, useLineupState } from '../../../store';
import { MatchType } from '../../../data/types/seasons/Match';
import { PlayerWithStatus } from './PlayerWithStatus';
import { Position } from '../../../data/types/players/Player';

type Props = {
  anchorEl: HTMLButtonElement | null,
  onClose: () => void;
  position: Position;
  matchType: MatchType;
};

export const PlayerSelection = ({
  anchorEl, onClose, position, matchType,
}: Props): JSX.Element => {
  const { data } = usePlayers();
  const { setFirstElevenPlayers } = useLineupActions();
  const { firstElevenPlayers } = useLineupState();
  const { t } = useTranslation();

  const onItemSelected = (id: string) => {
    const newPlayers = firstElevenPlayers
      .filter((p) => (p.id !== id))
      .filter((p) => p.position !== position);

    newPlayers.push({ id, position });
    setFirstElevenPlayers(newPlayers);
    onClose();
  };

  return (
    <Popover
      sx={{ maxHeight: 300 }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Typography
        sx={{
          px: 2,
          height: 30,
          backgroundColor: 'grey',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t(`${position?.toLowerCase()}`)}
      </Typography>
      <List component="nav">
        {
          data?.map((player) => (
            <PlayerWithStatus
              key={player.id}
              player={player}
              onItemSelected={onItemSelected}
              matchType={matchType}
              firstElevenPlayers={firstElevenPlayers}
            />
          ))
        }
      </List>
    </Popover>
  );
};

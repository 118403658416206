import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllFacilities } from '../../data/hooks/useFacilities';
import { Level } from '../../data/types/facilities/Level';

const grassrootsAcademyLevels = {
  NONE: '/assets/facilities/no-facility.svg',
  BAD: '/assets/facilities/grassroots-academy/1.svg',
  WEAK: '/assets/facilities/grassroots-academy/2.svg',
  REGULAR: '/assets/facilities/grassroots-academy/3.svg',
  GOOD: '/assets/facilities/grassroots-academy/4.svg',
  EXCELLENT: '/assets/facilities/grassroots-academy/5.svg',
  FORMIDABLE: '/assets/facilities/grassroots-academy/6.svg',
};

export const GrassrootsAcademy: FC = () => {
  const { isLoading, data: facilities } = useAllFacilities();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesLgAndDown = useMediaQuery(theme.breakpoints.down('xl'));
  const grassrootsAcademy = facilities?.grassrootsAcademy;
  const matchesXsOrSm = theme.breakpoints.down('md');

  return (
    <Card variant="outlined">
      <CardHeader title={t('grassroots_academy')} />
      <CardContent>
        {isLoading ? (
          <Stack
            spacing={2}
            direction={matchesLgAndDown ? 'column' : 'row'}
            sx={{
              alignItems: 'center',
              justifyContent: matchesLgAndDown ? 'center' : 'left',
            }}
          >
            <Skeleton variant="rounded" width="240px" height="240px" />
            <Stack sx={{ width: '40%' }} spacing={2}>
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
            </Stack>
          </Stack>
        ) : (
          <Stack
            spacing={4}
            direction={{
              xs: 'column',
              lg: 'row',
            }}
            alignItems="center"
            justifyContent="left"
          >
            <Box
              component="img"
              src={grassrootsAcademyLevels[grassrootsAcademy?.level || 'NONE'] || '/assets/facilities/no-facility.svg'}
              alt={t(grassrootsAcademy?.level?.toLowerCase() || '')}
              sx={{
                width: '240px',
                height: '240px',
              }}
            />

            <Stack direction="column">

              {/* Level */}
              <Stack direction="row" spacing={1}>
                <Typography variant="h6">
                  {`${t('facility_level')}:`}
                </Typography>
                {grassrootsAcademy?.level !== Level.NONE
                  ? (
                    <Box
                      component="img"
                      src={`/assets/icons/levels/${grassrootsAcademy?.level?.toLowerCase()}.svg`}
                      alt={t(grassrootsAcademy?.level?.toLowerCase() || 'Type')}
                      sx={{
                        width: matchesXsOrSm ? '25px' : '50px',
                        height: matchesXsOrSm ? '25px' : '30px',
                      }}
                    />
                  ) : null}
                <Typography variant="h6">
                  {t(`facility_level_${grassrootsAcademy?.level.toLowerCase()}`)}
                </Typography>
              </Stack>

              {/* Occupancy */}
              <Stack direction="row" spacing={1}>
                <Typography variant="h6">
                  {`${t('occupancy')}:`}
                </Typography>
                <Typography variant="h6">
                  {`${grassrootsAcademy?.currentOccupation} ${t('of')} ${grassrootsAcademy?.totalCapacity}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

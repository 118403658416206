import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
// import { ClientReadableStream } from 'grpc-web';
import { ClientReadableStream } from 'grpc-web';
import { Commentary, MatchDetails } from '../../data/types/match/MatchDetails';
import { MatchCard } from '../../common/Match/MatchCard';
import { MatchClubPlayers } from './MatchClubPlayers';
import { Status } from '../../data/types/seasons/Match';
import { StatsAndCommentaries } from './StatsAndCommentaries';
import { useMatchCommentaries, useMatchDetails } from '../../data/hooks/useMatchDetails';
import { createLiveMatchClient } from '../../data/types/proto/config';
import { LiveMatchRequest, LiveMatchResponse } from '../../data/types/gen/live-match_pb';
import { LiveMatchServiceClient } from '../../data/types/gen/Live-matchServiceClientPb';
import { auth } from '../../common';
import { ManOfTheMatch } from './ManOfTheMatch';

export const MatchDetailsPage: FC = () => {
  const [commentaries, setCommentaries] = useState<Commentary[]>();
  const [match, setMatch] = useState<MatchDetails>();
  const [liveMatchClient, setLiveMatchClient] = useState<LiveMatchServiceClient>();
  const [stream, setStream] = useState<ClientReadableStream<LiveMatchResponse>>();

  const { id } = useParams();
  const { isLoading: isLoadingCommentaries, data: commentariesData } = useMatchCommentaries(id || '');
  const { isLoading, data } = useMatchDetails(id || '');

  const listenLiveMatch = (
    client: LiveMatchServiceClient,
    request: LiveMatchRequest,
    userToken: string,
  ) => {
    const response = client.connect(request, { Authorization: `Bearer ${userToken}` });

    response.on('data', (update: LiveMatchResponse) => {
      const details: MatchDetails = JSON.parse(update.getMatch());

      // Update the match details and append the latest commentaries
      setMatch(details);

      details.commentaries?.forEach(
        (commentary: Commentary) => {
          commentaries?.push(commentary);
        },
      );

      setCommentaries(commentaries);
    });

    setLiveMatchClient(client);
    setStream(response);
  };

  if (!isLoading && !match) {
    setMatch(data);
  }

  if (!isLoadingCommentaries && commentaries === undefined && commentariesData !== undefined) {
    setCommentaries(commentariesData);
  }

  if (!isLoading && match?.status === Status.PLAYING && !liveMatchClient) {
    const client = createLiveMatchClient();
    const request = new LiveMatchRequest();
    request.addMatchIds(match.id);

    auth.currentUser?.getIdToken(true).then((idToken) => {
      listenLiveMatch(client, request, idToken);
    });
  }

  // On component unmount, close the stream
  useEffect(() => () => {
    if (stream) {
      stream.cancel();
    }
  }, [stream]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} order={{ xs: 2, sm: 2, md: 1 }}>
        <MatchClubPlayers
          clubName={match?.homeClub?.name || ''}
          lineUpPlayers={match?.homeLineUp?.players || []}
          benchPlayers={match?.homeBench?.players || []}
          homeClub
          loading={isLoading}
        />
      </Grid>

      <Grid item xs={12} md={4} order={{ xs: 1, sm: 1, md: 2 }}>
        <Stack spacing={2}>
          {match?.finishedByWO ? (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" textAlign="center">{t('match_ended_by_wo')}</Typography>
              </CardContent>
            </Card>
          ) : null}
          <MatchCard
            match={match || {} as MatchDetails}
            loading={isLoading}
            header={match?.status === Status.PLAYING
              ? (<Typography variant="h6" textAlign="center">{t('live_match')}</Typography>)
              : (<Typography variant="h6" textAlign="center">{t('match_ended')}</Typography>)}
            disableHeaderLoading={false}
            updateLiveMatch={false}
            currentMinute={match?.currentMinute}
          />
          {match?.status === Status.FINISHED
            ? (<ManOfTheMatch match={match || {} as MatchDetails} loading={isLoading} />)
            : null}
          <StatsAndCommentaries
            match={match || {} as MatchDetails}
            commentaries={commentaries || []}
            loading={isLoading}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4} order={{ xs: 3, sm: 3, md: 3 }}>
        <MatchClubPlayers
          clubName={match?.awayClub?.name || ''}
          lineUpPlayers={match?.awayLineUp?.players || []}
          benchPlayers={match?.awayBench?.players || []}
          homeClub={false}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

import { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { Match } from '../../data/types/seasons/Match';
import { getUserClubMatches } from '../../data/api/season';
import { formatLongDateTime } from '../../common/formatter';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Score } from '../../common/Score';
import { getCompetitionLink, getMatchLink } from '../../common/links';

const LAST_MATCHES_TYPE = 'last-matches';

type Props = {
  matchListType: 'last-matches' | 'next-matches',
  triggerRefresh: boolean;
};

export const MatchesOverview: FC<Props> = (props: Props) => {
  const { matchListType, triggerRefresh } = props;
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const matchesResponse = getUserClubMatches(matchListType === LAST_MATCHES_TYPE);

    matchesResponse.then((response) => {
      setMatches((response?.data?.matches ?? []) as Match[]);
      // TODO: handle error
    }).finally(() => {
      setLoading(false);
    });
  }, [matchListType, triggerRefresh]);

  return (
    <Card sx={{ height: '100%' }} variant="outlined">
      <CardContent sx={{ height: '100%' }}>
        <Typography variant="h6">
          {
            matchListType === LAST_MATCHES_TYPE
              ? t('matches_overview.last_matches')
              : t('matches_overview.next_matches')
          }
        </Typography>

        {loading && (
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton variant="rounded" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rounded" sx={{ marginTop: '0.5rem' }} />
                </Grid>
                <Grid item xs={4}>

                  <Grid container>
                    <Grid item xs={12}>
                      <Skeleton
                        variant="circular"
                        width={80}
                        height={80}
                        sx={{
                          marginTop: '0.5rem',
                          marginLeft: '1rem',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rounded" sx={{ marginTop: '0.5rem' }} />
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={4}>
                  <Skeleton
                    variant="rounded"
                    sx={{
                      marginTop: '1.3rem',
                      height: '3rem',
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Skeleton
                        variant="circular"
                        width={80}
                        height={80}
                        sx={{
                          marginTop: '0.5rem',
                          marginLeft: '1.5rem',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rounded" sx={{ marginTop: '0.5rem' }} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {matches.length === 0 && !loading ? (
          <Typography
            variant="h4"
            sx={{
              padding: '3.9rem',
              textAlign: 'center',
            }}
          >
            {t('matches_overview.matches_not_found')}
          </Typography>
        ) : null}

        <Grid container sx={{ mt: 2 }}>
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
          >
            {matches.map((match: Match) => (
              <SwiperSlide key={match.id}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Grid container>
                    <Grid item xs={12}>
                      {loading ? (
                        <Skeleton variant="rounded" />
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {formatLongDateTime(match?.date, i18n.language)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Link to={getCompetitionLink(match)} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Stack direction="row" sx={{ justifyContent: 'center' }}>
                          <Typography variant="body1">
                            {match?.competitionName}
                          </Typography>
                          <OpenInNewIcon sx={{ marginLeft: 1, width: '20px' }} />
                        </Stack>
                      </Link>
                    </Grid>
                    <Grid item xs={4}>

                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <img src="/assets/crest.png" alt={match?.homeClub?.name} width="80" />
                        </Grid>
                        <Grid item xs={12}>
                          {match?.homeClub?.abbreviation}
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                      {matchListType === LAST_MATCHES_TYPE ? (
                        <Score variant="h2" home={match?.score?.home} away={match?.score?.away} />
                      ) : (
                        <Score variant="h2" />
                      )}
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <img src="/assets/crest.png" alt={match?.awayClub?.name} width="80" />
                        </Grid>
                        <Grid item xs={12}>
                          {match?.awayClub?.abbreviation}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <Button
                      href={getMatchLink(match)}
                      variant="contained"
                      color={matchListType === LAST_MATCHES_TYPE ? 'secondary' : 'warning'}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {matchListType === LAST_MATCHES_TYPE
                        ? t('matches_overview.game_recap')
                        : t('matches_overview.set_line_up')}
                    </Button>

                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
          <Grid item xs={12} sx={{ marginTop: '0.5rem' }}>
            {matches.length > 0 ? (
              <Link to="/match/list">
                <Button
                  variant="contained"
                  sx={{ width: '100%' }}
                >
                  {matchListType === LAST_MATCHES_TYPE
                    ? t('matches_overview.see_last_matches')
                    : t('matches_overview.see_next_matches')}
                </Button>
              </Link>
            ) : null}
          </Grid>
        </Grid>

      </CardContent>
    </Card>

  );
};

import {
  useMemo, useState, ChangeEvent, useEffect,
} from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent,
  Grid, Typography, Select, MenuItem, Stack, FormControl, InputLabel,
  TextField,
  Button,
} from '@mui/material';
import { SwapHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Face } from '../../../common/Player';
import { EyeColor, HairColor } from '../../../common/Player/constants';
import { useLineupState, useLineupActions } from '../../../store';
import { usePlayers } from '../../../data/hooks';
import { ProgrammedSubs } from '../../../data/types/match';
import { PlayerWithStatus } from './PlayerWithStatus';
import { Player } from '../../../data/types';
import { MatchType } from '../../../data/types/seasons/Match';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  atMinute: number;
  inPlayerId: string;
  outPlayerId: string;
  index: number;
  matchType: MatchType;
};

export const AddSubstitutionDialog = ({
  isOpen, onClose, atMinute, inPlayerId, outPlayerId, index, matchType,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    firstElevenPlayers,
  } = useLineupState();
  const { data: players } = usePlayers();
  const [state, setState] = useState<Partial<ProgrammedSubs>>();
  const { setSubstitution } = useLineupActions();

  useEffect(() => {
    setState({
      atMinute,
      inPlayerId,
      outPlayerId,
    });
  }, [atMinute, inPlayerId, outPlayerId]);

  const firstElevenPlayersObjects = useMemo(() => (
    firstElevenPlayers.map(({ id }) => (players?.find((p) => p.id === id)))
  ), [firstElevenPlayers, players]);

  const benchPlayersSelection = useMemo(() => (
    players?.filter((p) => !firstElevenPlayers.flatMap((ep) => ep.id).includes(p.id))
  ), [firstElevenPlayers, players]);

  const handleClose = () => {
    onClose();
    setState(undefined);
  };

  const handleSetSubs = () => {
    setSubstitution(state as ProgrammedSubs, index);
    handleClose();
  };

  const handleMuniteChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (parseInt(e.target.value || '0', 10) <= 90) {
      setState((prevState) => ({
        ...prevState,
        atMinute: parseInt(e.target.value, 10),
      }));
    }
  };

  const handlePlayerChange = (id: string, key: 'inPlayerId' | 'outPlayerId') => {
    setState((prevState) => ({
      ...prevState,
      [key]: id,
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('lineup_program_substitution')}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" gap={2} wrap="nowrap" sx={{ mt: 2 }}>
          <Grid item>
            <TextField
              id="outlined-number"
              label={t('lineup_program_substitution_minute')}
              type="number"
              value={state?.atMinute}
              onChange={handleMuniteChange}
              helperText={t('lineup_program_substitution_minute_explanation')}
            />
          </Grid>
          <Grid item container wrap="nowrap" alignItems="center">
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel>{t('lineup_program_substitution_player_out')}</InputLabel>
                <Select
                  label={t('lineup_program_substitution_player_out')}
                  value={state?.outPlayerId}
                  onChange={(e) => handlePlayerChange(e.target.value as string, 'outPlayerId')}
                >
                  {
                    firstElevenPlayersObjects.map((p) => (
                      <MenuItem
                        key={p?.id}
                        value={p?.id}
                      >
                        <Stack direction="row" gap={2} alignItems="center">
                          <Face
                            body={p?.face.body || 0}
                            hairStyle={p?.face?.hairStyle || 0}
                            hairColor={p?.face?.hairColor || HairColor.BLACK}
                            eyeShape={p?.face?.eyeShape || 0}
                            eyeColor={p?.face?.eyeColor || EyeColor.BLACK}
                            noseShape={p?.face?.noseShape || 0}
                            mouthShape={p?.face?.mouthShape || 0}
                            beard={p?.face?.beard || 0}
                            eyebrows={p?.face?.eyebrows || 0}
                            width={22}
                            height={22}
                          />
                          <Typography display="inline">
                            {`${p?.name} - ${p?.shirtNumber} - ${t(`${p?.position.toLowerCase()}_short`)}`}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <SwapHoriz />
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel>{t('lineup_program_substitution_player_in')}</InputLabel>
                <Select
                  label={t('lineup_program_substitution_player_in')}
                  value={state?.inPlayerId}
                  onChange={(e) => handlePlayerChange(e.target.value as string, 'inPlayerId')}
                >
                  {
                    benchPlayersSelection?.map((p) => (
                      <MenuItem
                        key={p?.id}
                        value={p?.id}
                      >
                        <PlayerWithStatus
                          player={p as Player}
                          matchType={matchType}
                          firstElevenPlayers={firstElevenPlayers}
                        />
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button variant="contained" onClick={handleSetSubs}>{t('set')}</Button>
      </DialogActions>
    </Dialog>
  );
};

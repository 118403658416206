import { useMemo } from 'react';
import {
  Stack, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useLineupActions, useLineupState } from '../../../store';
import { usePlayers } from '../../../data/hooks';
import { Face } from '../../../common/Player';
import { EyeColor, HairColor } from '../../../common/Player/constants';

export const ExtraPositions = (): JSX.Element => {
  const {
    captain,
    cornerKickTaker,
    penaltyTaker,
    freeKickTaker,
    firstElevenPlayers,
    firstElevenPlayersReady,
  } = useLineupState();
  const { setExtraPosition } = useLineupActions();
  const { data: players } = usePlayers();
  const { t } = useTranslation();

  const firstElevenPlayersObjects = useMemo(() => (
    firstElevenPlayers.map(({ id }) => (players?.find((p) => p.id === id)))
  ), [firstElevenPlayers, players]);

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('select_players_for')}</Typography>
      <FormControl fullWidth>
        <InputLabel>{t('captain')}</InputLabel>
        <Select
          label={t('captain')}
          value={captain}
          onChange={(e) => setExtraPosition('captain', e.target.value)}
          disabled={!firstElevenPlayersReady}
        >
          {
            firstElevenPlayersObjects.map((p) => (
              <MenuItem
                key={p?.id}
                value={p?.id}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Face
                    body={p?.face.body || 0}
                    hairStyle={p?.face?.hairStyle || 0}
                    hairColor={p?.face?.hairColor || HairColor.BLACK}
                    eyeShape={p?.face?.eyeShape || 0}
                    eyeColor={p?.face?.eyeColor || EyeColor.BLACK}
                    noseShape={p?.face?.noseShape || 0}
                    mouthShape={p?.face?.mouthShape || 0}
                    beard={p?.face?.beard || 0}
                    eyebrows={p?.face?.eyebrows || 0}
                    width={40}
                    height={40}
                  />
                  <Typography display="inline">
                    {`${p?.name} - ${p?.shirtNumber} - ${t(`${p?.position.toLowerCase()}_short`)}`}
                  </Typography>
                </Stack>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('penalty_kick_taker')}</InputLabel>
        <Select
          label={t('penalty_kick_taker')}
          value={penaltyTaker}
          onChange={(e) => setExtraPosition('penaltyTaker', e.target.value)}
          disabled={!firstElevenPlayersReady}
        >
          {
            firstElevenPlayersObjects.map((p) => (
              <MenuItem
                key={p?.id}
                value={p?.id}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Face
                    body={p?.face.body || 0}
                    hairStyle={p?.face?.hairStyle || 0}
                    hairColor={p?.face?.hairColor || HairColor.BLACK}
                    eyeShape={p?.face?.eyeShape || 0}
                    eyeColor={p?.face?.eyeColor || EyeColor.BLACK}
                    noseShape={p?.face?.noseShape || 0}
                    mouthShape={p?.face?.mouthShape || 0}
                    beard={p?.face?.beard || 0}
                    eyebrows={p?.face?.eyebrows || 0}
                    width={40}
                    height={40}
                  />
                  <Typography display="inline">
                    {`${p?.name} - ${p?.shirtNumber} - ${t(`${p?.position.toLowerCase()}_short`)}`}
                  </Typography>
                </Stack>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('free_kick_taker')}</InputLabel>
        <Select
          label={t('free_kick_taker')}
          value={freeKickTaker}
          onChange={(e) => setExtraPosition('freeKickTaker', e.target.value)}
          disabled={!firstElevenPlayersReady}
        >
          {
            firstElevenPlayersObjects.map((p) => (
              <MenuItem
                key={p?.id}
                value={p?.id}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Face
                    body={p?.face.body || 0}
                    hairStyle={p?.face?.hairStyle || 0}
                    hairColor={p?.face?.hairColor || HairColor.BLACK}
                    eyeShape={p?.face?.eyeShape || 0}
                    eyeColor={p?.face?.eyeColor || EyeColor.BLACK}
                    noseShape={p?.face?.noseShape || 0}
                    mouthShape={p?.face?.mouthShape || 0}
                    beard={p?.face?.beard || 0}
                    eyebrows={p?.face?.eyebrows || 0}
                    width={40}
                    height={40}
                  />
                  <Typography display="inline">
                    {`${p?.name} - ${p?.shirtNumber} - ${t(`${p?.position.toLowerCase()}_short`)}`}
                  </Typography>
                </Stack>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('corner_kick_taker')}</InputLabel>
        <Select
          label={t('corner_kick_taker')}
          value={cornerKickTaker}
          onChange={(e) => setExtraPosition('cornerKickTaker', e.target.value)}
          disabled={!firstElevenPlayersReady}
        >
          {
            firstElevenPlayersObjects.map((p) => (
              <MenuItem
                key={p?.id}
                value={p?.id}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Face
                    body={p?.face.body || 0}
                    hairStyle={p?.face?.hairStyle || 0}
                    hairColor={p?.face?.hairColor || HairColor.BLACK}
                    eyeShape={p?.face?.eyeShape || 0}
                    eyeColor={p?.face?.eyeColor || EyeColor.BLACK}
                    noseShape={p?.face?.noseShape || 0}
                    mouthShape={p?.face?.mouthShape || 0}
                    beard={p?.face?.beard || 0}
                    eyebrows={p?.face?.eyebrows || 0}
                    width={40}
                    height={40}
                  />
                  <Typography display="inline">
                    {`${p?.name} - ${p?.shirtNumber} - ${t(`${p?.position.toLowerCase()}_short`)}`}
                  </Typography>
                </Stack>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Stack>
  );
};

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import { FC, useState } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MoreVertOutlined } from '@mui/icons-material';
import { Stadium } from '../../data/types/facilities/Stadium';
import { formatCurrency, formatShortDate } from '../../common/formatter';
import { Status } from '../../data/types/facilities/Status';
import { ChangeStadiumNameDialog } from './ChangeStadiumNameDialog';
import { ReconstructStadiumDialog } from './ReconstructStadiumDialog';

type Props = {
  stadium?: Stadium;
  loading: boolean;
};

export const StadiumDetails: FC<Props> = (props: Props) => {
  const { stadium, loading } = props;
  const [optionsMenuOpen, setOptionMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openChangeNameDialog, setOpenChangeNameDialog] = useState<boolean>(false);
  const [openModifyStadiumDialog, setOpenModifyStadiumDialog] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const theme = useTheme();
  const matchesMdAndDown = useMediaQuery(theme.breakpoints.down('lg'));

  const handleCloseMenu = () => {
    setOptionMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOptionMenuOpen(true);
  };

  const handleOpenChangeStadiumNameDialog = () => {
    setOpenChangeNameDialog(true);
    handleCloseMenu();
  };

  const handleOpenModifyStadiumDialog = () => {
    setOpenModifyStadiumDialog(true);
    handleCloseMenu();
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={loading ? (<Skeleton variant="rounded" width="50%" />) : stadium?.name}
        action={(
          <>
            <IconButton
              aria-label="settings"
              onClick={handleOpenMenu}
              aria-controls={optionsMenuOpen ? 'stadium-basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={optionsMenuOpen ? 'true' : undefined}
            >
              <MoreVertOutlined />
            </IconButton>
            <Menu
              id="stadium-basic-menu"
              anchorEl={anchorEl}
              open={optionsMenuOpen}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {stadium?.status === Status.RUNNING ? (
                <MenuItem onClick={handleOpenModifyStadiumDialog}>{t('modify_stadium')}</MenuItem>
              ) : null}
              <MenuItem onClick={handleOpenChangeStadiumNameDialog}>{t('change_stadium_name')}</MenuItem>
              <MenuItem onClick={handleCloseMenu}>{t('change_ticket_pricing')}</MenuItem>
            </Menu>
          </>
        )}
      />
      <CardContent>
        <Stack direction={matchesMdAndDown ? 'column' : 'row'} alignItems="center" spacing={2}>
          {loading ? (<Skeleton variant="rounded" width="240px" height="240px" />)
            : (<img src="/assets/facilities/stadium.png" alt={t('stadium') || ''} width="340" />)}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="rounded" width="100%" />
              ) : (
                <Typography variant="h5">{t('general_information')}</Typography>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('maintenance_cost')}</Typography>
                  <Typography variant="h6">{formatCurrency(stadium?.maintenanceCost || 0, i18n.language)}</Typography>
                </>
              )}

            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('turf_quality')}</Typography>
                  <Typography variant="h6">{t(`turf_quality_${stadium?.turfQuality.toLowerCase()}`)}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('last_improvement')}</Typography>
                  <Typography variant="h6">{formatShortDate(stadium?.lastImprovement || '')}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('stadium_status')}</Typography>
                  <Typography variant="h6">{t(`stadium_status_${stadium?.status.toLowerCase()}`)}</Typography>
                </>
              )}
            </Grid>
            {stadium?.status === Status.UNDER_MAINTENANCE ? (
              <Grid item xs={6} xl={3}>
                <Typography variant="body1">{t('under_maintenance_until')}</Typography>
                <Typography variant="h6">{formatShortDate(stadium?.underMaintenanceUntil)}</Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="rounded" width="100%" />
              ) : (
                <Typography variant="h5">{t('seats_distribution')}</Typography>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('north_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.northBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('northwest_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.northwestBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('northeast_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.northeastBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('east_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.eastBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('west_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.westBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('south_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.southBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('southwest_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.southwestBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              {loading ? (
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width="100%" />
                  <Skeleton variant="rounded" width="100%" />
                </Stack>
              ) : (
                <>
                  <Typography variant="body1">{t('southeast_bleachers')}</Typography>
                  <Typography variant="h6">{`${stadium?.southeastBleachers} ${t('seats')}`}</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Stack>
      </CardContent>

      <ChangeStadiumNameDialog
        open={openChangeNameDialog}
        name={stadium?.name || ''}
        onClose={() => setOpenChangeNameDialog(false)}
      />

      {stadium ? (
        <ReconstructStadiumDialog
          open={openModifyStadiumDialog}
          stadium={stadium}
          onClose={() => setOpenModifyStadiumDialog(false)}
        />
      ) : null}
    </Card>
  );
};

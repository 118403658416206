import { useQuery } from '@tanstack/react-query';
import { Commentary, MatchDetails } from '../types/match/MatchDetails';
import { getMatchCommentaries, getMatchDetails } from '../api/match';

export const useMatchDetails = (id: string) => {
  const { isFetching, error, data } = useQuery<MatchDetails, Error>({
    queryKey: ['get-matches-details'],
    queryFn: () => getMatchDetails(id),
    cacheTime: 0, // Disable cache
  });

  return { isLoading: isFetching, error, data };
};

export const useMatchCommentaries = (id: string) => {
  const { isFetching, error, data } = useQuery<Commentary[], Error>({
    queryKey: ['get-match-commentaries'],
    queryFn: () => getMatchCommentaries(id),
    cacheTime: 0, // Disable cache
  });

  return { isLoading: isFetching, error, data };
};

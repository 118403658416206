import {
  ChangeEvent,
  FC,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import firebase from 'firebase/compat';
import { t } from 'i18next';
import { auth, Loader } from '../../common';
import { createClub, getAvailableCountries } from '../../data/api/club';
import { Country, ErrorResponse } from '../../data/types';

const StepContent = styled(Box)({
  paddingTop: '2rem',
  paddingBottom: '1rem',
  width: '100%',
});

const Flag = styled('img')({
  width: '30px',
  marginRight: '10px',
});

const NUMBER_OF_STEPS = 3; // Starts from 0, the step 3 is the confirmation page
const ABBREVIATION_SIZE = 3;

type Props = {
  refreshDashboard: () => void,
};

export const CreateClub: FC<Props> = (props: Props) => {
  const { refreshDashboard } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState<NodeJS.Timer>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [enabledCountries, setEnabledCountries] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [abbreviation, setAbbreviation] = useState<string>('');
  const [nextStepButtonDisabled, setNextStepButtonDisabled] = useState<boolean>(true);
  const [creationInProgress, setCreationInProgress] = useState<boolean>(false);
  const [creationSuccessful, setCreationSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    getAvailableCountries()
      .then((response) => {
        setEnabledCountries(response.data as Country[]);
      })
      .catch(() => {
        setError(t('registration_not_available') || '');
        setNextStepButtonDisabled(true);
      });
  }, []);

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevState) => prevState - 1);
    setError('');
  };

  const handleFinishRegistration = () => {
    setCreationInProgress(true);

    createClub({
      name,
      abbreviation,
      countryId: selectedCountry,
      regionId: selectedRegion,
    }).catch((err) => {
      if (axios.isAxiosError(err)) {
        setError((err.response?.data as ErrorResponse).error);
        return;
      }

      setError(String(err));
    }).finally(() => {
      setTokenRefreshInterval(setInterval(() => {
        auth?.currentUser?.getIdTokenResult(true).then((token: firebase.auth.IdTokenResult) => {
          if (token.claims.club_id !== undefined) {
            setCreationSuccessful(true);
            setCreationInProgress(false);

            clearInterval(tokenRefreshInterval);
            refreshDashboard();
          }
        });
      }, 1000));
    });
  };

  const handleNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value.replace(/\d+/, 'X');
    setName(enteredName);
    setNextStepButtonDisabled(enteredName.length === 0);
  };

  const handleAbbreviationChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredAbbreviation = event.target.value.toUpperCase().replace(/\d+/, 'X');
    setAbbreviation(enteredAbbreviation);
    setNextStepButtonDisabled(enteredAbbreviation.length < ABBREVIATION_SIZE);
  };

  const handleCountryChanged = (event: SelectChangeEvent) => {
    setSelectedCountry(event.target.value);
  };

  const handleRegionChanged = (event: SelectChangeEvent) => {
    setSelectedRegion(event.target.value);
  };

  const getSelectedCountry = () => enabledCountries
    .find((country) => country.id === selectedCountry);

  const getSelectedRegion = () => getSelectedCountry()?.regions
    ?.find((region) => region.id === selectedRegion);

  // Just open this dialog if the user does not have club_id claim, but runs only once
  useEffect(() => {
    auth.currentUser?.getIdTokenResult(true).then((token: firebase.auth.IdTokenResult) => {
      if (!open && !creationSuccessful && token.claims.club_id === undefined) {
        setOpen(true);
      }
    });
  }, [open, creationSuccessful]);

  useEffect(() => {
    if (activeStep === 0) {
      setNextStepButtonDisabled(name.length === 0);
    } else if (activeStep === 1) {
      setNextStepButtonDisabled(abbreviation.length < ABBREVIATION_SIZE);
    } else if (activeStep === 2) {
      setNextStepButtonDisabled(selectedCountry.length === 0 || selectedRegion.length === 0);
    }
  }, [activeStep, name, abbreviation, selectedCountry, selectedRegion]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle>{t('finish_your_registration')}</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key="club-name">
            <StepLabel>{t('club_name')}</StepLabel>
          </Step>
          <Step key="club-abbreviation">
            <StepLabel>{t('club_abbreviation')}</StepLabel>
          </Step>
          <Step key="country-and-region">
            <StepLabel>{t('country_and_region')}</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <StepContent>
            <TextField
              autoFocus
              autoComplete="off"
              label={t('club_name')}
              variant="outlined"
              fullWidth
              value={name}
              onChange={handleNameChanged}
            />
          </StepContent>
        )}
        {activeStep === 1 && (
          <StepContent>
            <TextField
              autoFocus
              autoComplete="off"
              label={t('abbreviation')}
              variant="outlined"
              fullWidth
              value={abbreviation}
              inputProps={{ maxLength: 3 }}
              onChange={handleAbbreviationChanged}
            />
          </StepContent>
        )}
        {activeStep === 2 && (
          <StepContent>
            <Grid container spacing={2.5} direction="row">
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel id="country-label">{t('country')}</InputLabel>
                  <Select
                    label={t('country')}
                    fullWidth
                    onChange={handleCountryChanged}
                    value={selectedCountry}
                    labelId="country-label"
                  >
                    {enabledCountries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        <Flag src={`assets/flags/${country.code}.svg`} alt={country.name} />
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel id="region-label">{t('region')}</InputLabel>
                  <Select
                    label={t('region')}
                    fullWidth
                    onChange={handleRegionChanged}
                    value={selectedRegion}
                    labelId="region-label"
                  >
                    {getSelectedCountry()?.regions?.map((region) => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </StepContent>
        )}
        {activeStep === 3 && creationSuccessful && (
          <StepContent>
            <Typography variant="h4">{t('registration_finished')}</Typography>
          </StepContent>
        )}
        {activeStep === 3 && !creationSuccessful && (
          <StepContent>
            <Grid container spacing={2.5} direction="row">
              <Grid item xs={12} md={4}>
                <Grid container direction="column">
                  <Grid item xs={6} md={4} justifyContent="center">
                    <Typography variant="h5">
                      {`${t('name')}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="h5">{name}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container direction="column" sx={{ marginTop: '10px' }}>
                  <Grid item xs={8} md={4} justifyContent="center">
                    <Typography variant="h5">{`${t('abbreviation')}:`}</Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography variant="h5">{abbreviation}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container direction="column" sx={{ marginTop: '10px' }}>
                  <Grid item xs={4} md={4} justifyContent="center">
                    <Typography variant="h5">Country & Region:</Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography variant="h5">
                      {`${getSelectedCountry()?.name} - ${getSelectedRegion()?.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            </Grid>
          </StepContent>
        )}
      </DialogContent>
      <DialogActions>
        {creationSuccessful ? (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              refreshDashboard();
            }}
          >
            OK
          </Button>
        ) : (
          <>
            <Button
              onClick={handlePreviousStep}
              variant="outlined"
              disabled={activeStep === 0 || creationInProgress}
            >
              {t('previous')}
            </Button>
            {activeStep === NUMBER_OF_STEPS ? (
              <Button
                onClick={handleFinishRegistration}
                autoFocus
                variant="contained"
                disabled={creationInProgress}
              >
                {creationInProgress ? <Loader variant="small" color="#000E59" /> : 'Finish'}
              </Button>
            ) : (
              <Button
                onClick={handleNextStep}
                autoFocus
                variant="contained"
                disabled={nextStepButtonDisabled}
              >
                {t('next')}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useTranslation } from 'react-i18next';

export const GoogleLogin = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Button
      startIcon={<GoogleIcon />}
      fullWidth
      variant="contained"
      disabled
      sx={{
        backgroundColor: '#222222 !important',
        '&:hover': {
          boxShadow: 'none',
        },
      }}
    >
      {t('login_with_google')}
    </Button>
  );
};

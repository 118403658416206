import 'dayjs/locale/pt-br'; // import locale
import 'dayjs/locale/en-gb'; // import locale
import 'dayjs/locale/en'; // import locale
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  DATETIME_LONG_FORMAT,
  DATE_LONG_FORMAT,
  DATE_SHORT_FORMAT,
  DATE_TIME_SHORT_FORMAT,
  EN_GB,
  TIMEZONES,
} from '../constants';

dayjs.extend(utc); // use plugin
dayjs.extend(timezone); // use plugin
dayjs.extend(relativeTime); // use plugin

export const formatLongDateTime = (date: string, locale: string = EN_GB): string => {
  dayjs.locale(locale.toLowerCase());

  return dayjs(date)
    .tz((TIMEZONES[locale]))
    .format(DATETIME_LONG_FORMAT[locale]);
};

export const formatLongDate = (
  date: string,
  locale: string = EN_GB,
  withTimeZone: boolean = true,
): string => {
  dayjs.locale(locale.toLowerCase());

  let dateObject = dayjs(date);
  if (withTimeZone) {
    dateObject = dateObject.tz((TIMEZONES[locale]));
  }

  return dateObject.format(DATE_LONG_FORMAT[locale]);
};

export const formatShortDate = (
  date: string,
  locale: string = EN_GB,
  withTimeZone: boolean = true,
): string => {
  dayjs.locale(locale.toLowerCase());

  let dateObject = dayjs(date);
  if (withTimeZone) {
    dateObject = dateObject.tz((TIMEZONES[locale]));
  }

  return dateObject.format(DATE_SHORT_FORMAT[locale]);
};

export const formatShortDateTime = (date: string, locale: string = EN_GB): string => {
  dayjs.locale(locale.toLowerCase());

  return dayjs(date)
    .tz((TIMEZONES[locale]))
    .format(DATE_TIME_SHORT_FORMAT[locale]);
};

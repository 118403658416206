import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

const LOGO_TYPES = new Map<string, string>([
  [
    'default',
    '/assets/logo.svg',
  ],
  [
    'yellow',
    '/assets/logo-yellow.svg',
  ],
]);

interface Props {
  type: string;
  width: string;
  sx?: SxProps<Theme>;
  padding?: string;
  wrapperWidth?: string;
}

interface WrapperProps {
  sx?: SxProps<Theme>;
  width?: string;
}

const LogoWrapper = styled('div')<WrapperProps>`
  width: ${(props) => props.width};
  text-align: center;
  pointer-events: none;
  user-select: none;
`;

LogoWrapper.defaultProps = {
  sx: {},
  width: '100%',
};

interface LogoImageProps {
  width: string;
  padding?: string;
}

const LogoImage = styled('img')<LogoImageProps>`
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  pointer-events: none;
  user-select: none;
`;

const Logo = (props: Props): JSX.Element => {
  const {
    type, width, padding, sx, wrapperWidth,
  } = props;
  const logo = LOGO_TYPES.has(type)
    ? LOGO_TYPES.get(type)
    : LOGO_TYPES.get('default');

  return (
    <LogoWrapper sx={sx} width={wrapperWidth}>
      <LogoImage
        src={logo}
        alt="Eleven Stars"
        width={width}
        padding={padding}
      />
    </LogoWrapper>
  );
};

Logo.defaultProps = {
  sx: {},
  padding: '1rem',
  wrapperWidth: '100%',
};

export { Logo };

import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Loader } from '../../common';
import { useChangeTechnicalTraining } from '../../data/hooks/useTraining';
import { formatShortDate } from '../../common/formatter';
import { TechnicalTrainingType } from '../../data/types/training/TechnicalTraining';

type Props = {
  open: boolean;
  onClose: () => void;
  nextTrainingDate: string;
  currentTrainingType: TechnicalTrainingType;
};

const trainingTypes = [
  TechnicalTrainingType.REFLEXES,
  TechnicalTrainingType.POSITIONING,
  TechnicalTrainingType.AERIAL_PLAYING,
  TechnicalTrainingType.TACKLING,
  TechnicalTrainingType.SHOOTING,
  TechnicalTrainingType.TECHNIQUE,
  TechnicalTrainingType.CREATION,
  TechnicalTrainingType.PASSING,
];

export const ChangeTechnicalTrainingDialog: FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    nextTrainingDate,
    currentTrainingType,
  } = props;
  const [trainingType, setTrainingType] = useState<TechnicalTrainingType>(currentTrainingType);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateFn: changeTraining } = useChangeTechnicalTraining();

  useEffect(() => {
    setTrainingType(currentTrainingType);
  }, [currentTrainingType]);

  const handleOnChange = (type: TechnicalTrainingType) => {
    setTrainingType(type);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = () => {
    changeTraining(trainingType).then(() => {
      onClose();
      queryClient.fetchQuery({
        queryKey: ['training-get-overview'],
      });
      enqueueSnackbar(t('training_setup_successfully'), { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(t('error_while_setting_training'), { variant: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('change_technical_training')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="center" alignItems="center" sx={{ paddingTop: 1 }}>
          <FormControl fullWidth>
            <InputLabel>{t('training')}</InputLabel>
            <Select
              label={t('training')}
              value={trainingType}
              onChange={(e) => handleOnChange(e.target.value as TechnicalTrainingType)}
            >
              {trainingTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <img src={`/assets/icons/training/${type.toLowerCase()}.svg`} alt={t(type.toLowerCase()) || 'Type'} width="70" height="50" />
                    <Typography variant="h6">{t(type.toLowerCase())}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="h6">
            {`${t('next_training_will_be')}: ${formatShortDate(nextTrainingDate, i18n.language, false)}`}
          </Typography>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>{t('cancel')}</Button>
        <Button
          onClick={handleOnConfirm}
          variant="contained"
          color="primary"
        // disabled={!isValidNumber(shirtNumber)}
        >
          {isLoading ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

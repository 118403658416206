import { FC, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
  DataGrid,
} from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useUserClub } from '../../data/hooks/useClub';
import { Flag } from '../../common/Flag';
import { useCountryCompetitions } from '../../data/hooks/useSeason';
import { Phase } from '../../data/types/seasons/Phase';

const LoadingSkeleton: FC = () => (
  <>
    {[...Array(8)].map(() => (
      <Box
        sx={{
          height: '30px',
          my: 1,
          mx: 1,
        }}
        key={Math.random()}
      >
        <Skeleton variant="rounded" height={30} />
      </Box>
    ))}
  </>
);

export const CountryCompetitionsPage: FC = () => {
  const [country, setCountry] = useState<string>();
  const { isFetching, data: userClub } = useUserClub();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isFetching: isFetchingCountryCompetitions,
    data: countryCompetitions,
  } = useCountryCompetitions(country || '');
  const regionalTournamentColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name') || 'Name',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('actions') || 'Actions',
      editable: false,
      minWidth: 50,
      cellClassName: 'actions',
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label={t('view')}
          color="primary"
          onClick={() => {
            navigate(`/regional-tournament/${params.id}`);
          }}
        />,
      ],
    },
  ];

  const leaguesColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name') || 'Name',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('actions') || 'Actions',
      editable: false,
      minWidth: 50,
      cellClassName: 'actions',
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label={t('view')}
          color="primary"
          onClick={() => {
            navigate(`/league/${params.id}`);
          }}
        />,
      ],
    },
  ];

  if (userClub?.country.id !== undefined && country === undefined) {
    setCountry(userClub.country.id);
  }

  return (
    <Grid container spacing={2} sx={{ padding: 1, userSelect: 'none' }}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          {isFetching || isFetchingCountryCompetitions ? (
            <>
              <Skeleton variant="circular" height={40} width={40} />
              <Skeleton variant="rounded" height={30} width={250} />
            </>
          ) : (
            <>
              <Flag countryCode={userClub?.country.code || 'BR'} width={40} />
              <Typography variant="h4" fontWeight="bold">{userClub?.country.name}</Typography>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card variant="outlined">
          <CardHeader title={t('national_cup')} />
          <CardContent>
            <Stack spacing={1} direction="column">
              {isFetching || isFetchingCountryCompetitions ? (
                <>
                  <Skeleton variant="rounded" height={35} />
                  <Skeleton variant="rounded" height={35} />
                  <Skeleton variant="rounded" height={35} />
                  <Skeleton variant="rounded" height={35} />
                </>
              ) : (
                <>
                  <Typography variant="h6">{`${t('name')}: ${countryCompetitions?.nationalCup.name}`}</Typography>
                  <Typography variant="h6">
                    {`${t('current_phase')}: ${t(countryCompetitions?.nationalCup.phase.toLowerCase() || '')}`}
                  </Typography>
                  <Typography variant="h6">
                    {`${t('status')}: ${countryCompetitions?.nationalCup.phase === Phase.FINAL ? t('ended') : t('in_progress')}`}
                  </Typography>
                  <Link to={`/national-cup/${countryCompetitions?.nationalCup.id}`} style={{ width: '100%' }}>
                    <Button variant="contained" color="primary" fullWidth>
                      {t('view')}
                    </Button>
                  </Link>
                </>
              )}

            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card variant="outlined">
          <CardHeader title={t('regional_tournaments')} />
          <CardContent sx={{ height: 355, overflowY: 'scroll' }}>
            <DataGrid
              columns={regionalTournamentColumns}
              rows={countryCompetitions?.regionalTournaments || []}
              rowHeight={40}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              loading={isFetching || isFetchingCountryCompetitions}
              sx={{
                '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-virtualScroller': {
                  minHeight: 120,
                  marginTop: 1,
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
              }}
              slots={{
                loadingOverlay: LoadingSkeleton,
              }}
              disableColumnFilter
              disableColumnMenu
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card variant="outlined">
          <CardHeader title={t('leagues')} />
          <CardContent sx={{ height: 355, overflowY: 'scroll' }}>
            <DataGrid
              columns={leaguesColumns}
              rows={countryCompetitions?.leagues || []}
              rowHeight={40}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 50, page: 0 },
                },
              }}
              pageSizeOptions={[50, 100]}
              loading={isFetching || isFetchingCountryCompetitions}
              sx={{
                '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-virtualScroller': {
                  minHeight: 120,
                  marginTop: 1,
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
              }}
              slots={{
                loadingOverlay: LoadingSkeleton,
              }}
              disableColumnFilter
              disableColumnMenu
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

import { FC, useState } from 'react';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { MoreVertOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Player } from '../../data/types';
import { Face } from '../Face/Face';
import { TechnicalAttributesChart } from './TechnicalAttributesChart';
import 'swiper/css';
import { CountryCard } from './CountryCard';
import { PositionCard } from './PositionCard';
import { ShirtCard } from './ShirtCard';
import { AttributesTab } from './AttributesTab';
import { StatsTab } from './StatsTab';
import { ContractTab } from './ContractTab';
import { YellowCardsTab } from './YellowCardsTab';
import { SuspensionsTab } from './SuspensionsTab';
import { InjuryTab } from './InjuryTab';
import { ChangeShirtNumberDialog } from './ChangeShirtNumberDialog';
import { FirePlayerDialog } from './FirePlayerDialog';
import { ConfirmPromotePlayerDialog } from '../../components/Grassroots/ConfirmPromotePlayerDialog';

type Props = {
  player?: Player;
  isGrassroots: boolean;
  closeDrawerCallback?: Function;
};

export const PlayerDetails: FC<Props> = (props: Props) => {
  const { player, isGrassroots, closeDrawerCallback } = props;
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [optionsMenuOpen, setOptionMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXl = useMediaQuery(theme.breakpoints.only('xl'));
  const [openChangeShirtNumberDialog, setOpenChangeShirtNumberDialog] = useState<boolean>(false);
  const [openFirePlayerDialog, setOpenFirePlayerDialog] = useState<boolean>(false);
  const [openPromotePlayerDialog, setOpenPromotePlayerDialog] = useState<boolean>(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    swiper?.slideTo(newValue);
  };

  const handleCloseMenu = () => {
    setOptionMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOptionMenuOpen(true);
  };

  const handleCloseDrawer = () => {
    if (closeDrawerCallback !== undefined) {
      closeDrawerCallback();
    }
  };

  const handleOpenChangeShirtNumberDialog = () => {
    setOpenChangeShirtNumberDialog(true);
    handleCloseMenu();
  };

  const handleOpenFirePlayerDialog = () => {
    setOpenFirePlayerDialog(true);
    handleCloseMenu();
  };

  const handleOpenPromotePlayerDialog = () => {
    setOpenPromotePlayerDialog(true);
    handleCloseMenu();
  };

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        overflow: matchesXsOrSm ? 'auto' : 'hidden',
        '& .recharts-surface': {
          width: '180px !important',
          height: '180px !important',
          ml: -1.5,
          mt: 0.8,
        },

        '& .recharts-wrapper': {
          transform: 'scale(1.6)',
          width: '180px !important',
          height: '180px !important',
        },

        '@media screen and (max-width: 900px)': {
          '& .recharts-surface': {
            width: '130px !important',
            height: '130px !important',
            ml: -1.45,
            mt: 1.5,
          },
          '& .recharts-wrapper': {
            transform: 'scale(1.9)',
            width: '130px !important',
            height: '130px !important',
            paddingLeft: '0.5rem',
          },
        },
      }}
    >
      <CardHeader
        avatar={
          matchesXsOrSm ? (
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon />
            </IconButton>
          ) : null
        }
        title={
          player ? (
            `${player?.name}, ${player?.age} ${!matchesXsOrSm ? t('years_old') : ''}`
          ) : (
            <Skeleton variant="rounded" width="100%" height={30} />
          )
        }
        titleTypographyProps={{
          variant: 'h6',
          textAlign: 'center',
        }}
        action={
          (
            <>
              <IconButton
                aria-label="settings"
                onClick={handleOpenMenu}
                aria-controls={optionsMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsMenuOpen ? 'true' : undefined}
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={optionsMenuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleOpenChangeShirtNumberDialog}>{t('change_shirt_number')}</MenuItem>
                <MenuItem onClick={handleOpenFirePlayerDialog}>{t('fire_player')}</MenuItem>
                {isGrassroots ? (<MenuItem onClick={handleOpenPromotePlayerDialog}>{t('promote_player')}</MenuItem>) : null}
                {/* <MenuItem onClick={handleCloseMenu}>{t('sell_player')}</MenuItem> */}
              </Menu>
            </>
          )
        }
        sx={{
          textAlign: matchesXsOrSm ? 'center' : 'left',
        }}
      />
      <CardContent>
        <Grid container className="player-details" spacing={2} sx={{ minHeight: '800px' }}>
          {matchesXl ? (
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                <Card variant="outlined" sx={{ height: 220 }}>
                  <CardContent>
                    {player ? (
                      <Face
                        body={player?.face?.body}
                        hairStyle={player?.face?.hairStyle}
                        hairColor={player?.face?.hairColor}
                        eyeShape={player?.face?.eyeShape}
                        eyeColor={player?.face?.eyeColor}
                        noseShape={player?.face?.noseShape}
                        mouthShape={player?.face?.mouthShape}
                        beard={player?.face?.beard}
                        eyebrows={player?.face?.eyebrows}
                        width={matchesXsOrSm ? 150 : 180}
                        height={matchesXsOrSm ? 150 : 180}
                      />
                    ) : (
                      <Skeleton
                        variant="circular"
                        width={180}
                        height={180}
                        sx={{ float: 'right' }}
                      />
                    )}
                  </CardContent>
                </Card>
                <Card
                  variant="outlined"
                  sx={{ paddingRight: 2.5, overflow: 'hidden', height: 220 }}
                >
                  <CardContent>
                    {player ? (
                      <TechnicalAttributesChart
                        player={player}
                        showLegend
                        showUntilOneHundered={false}
                        showInnerLabel={false}
                      />
                    ) : (
                      <Skeleton variant="circular" width={180} height={180} />
                    )}
                  </CardContent>
                </Card>

                <Grid item xs={12} xl={2} sx={{ height: 220 }}>
                  <CountryCard player={player} />
                </Grid>
                <Grid item xs={12} xl={2} sx={{ height: 220 }}>
                  <PositionCard player={player} />
                </Grid>
                <Grid item xs={12} xl={2} sx={{ height: 200 }}>
                  <ShirtCard player={player} />
                </Grid>
              </Stack>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                  <Card variant="outlined">
                    <CardContent>
                      {player ? (
                        <Face
                          body={player?.face?.body}
                          hairStyle={player?.face?.hairStyle}
                          hairColor={player?.face?.hairColor}
                          eyeShape={player?.face?.eyeShape}
                          eyeColor={player?.face?.eyeColor}
                          noseShape={player?.face?.noseShape}
                          mouthShape={player?.face?.mouthShape}
                          beard={player?.face?.beard}
                          eyebrows={player?.face?.eyebrows}
                          width={matchesXsOrSm ? 150 : 180}
                          height={matchesXsOrSm ? 150 : 180}
                        />
                      ) : (
                        <Skeleton
                          variant="circular"
                          width={180}
                          height={180}
                          sx={{ float: 'right' }}
                        />
                      )}
                    </CardContent>
                  </Card>
                  <Card variant="outlined" sx={{ paddingRight: 2.5, overflow: 'hidden' }}>
                    <CardContent>
                      {player ? (
                        <TechnicalAttributesChart
                          player={player}
                          showLegend
                          showUntilOneHundered={false}
                          showInnerLabel={false}
                        />
                      ) : (
                        <Skeleton variant="circular" width={180} height={180} />
                      )}
                    </CardContent>
                  </Card>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <CountryCard player={player} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <PositionCard player={player} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ShirtCard player={player} />
              </Grid>
            </>
          )}

          <Grid item xs={12} sx={{ userSelect: 'none' }}>
            <Tabs
              value={tabIndex}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
            >
              <Tab label={t('attributes')} />
              {player?.injuryStatus?.injured ? (
                <Tab label={<Badge color="error" variant="dot">{t('injury')}</Badge>} />
              ) : null}

              <Tab label={t('stats')} />
              <Tab label={t('contract')} />
              <Tab label={t('yellow_cards')} />
              <Tab label={t('suspensions')} />
            </Tabs>
            <Swiper
              autoHeight
              slidesPerView={1}
              onSwiper={(swiperInstance: SwiperClass) => setSwiper(swiperInstance)}
              onSlideChange={(swiperInstance: SwiperClass) => {
                setTabIndex(swiperInstance.activeIndex);
              }}
            >
              <SwiperSlide>
                <AttributesTab player={player} />
              </SwiperSlide>
              {player?.injuryStatus?.injured ? (
                <SwiperSlide>
                  <InjuryTab player={player} />
                </SwiperSlide>
              ) : null}
              <SwiperSlide>
                <StatsTab player={player} />
              </SwiperSlide>
              <SwiperSlide>
                <ContractTab player={player} />
              </SwiperSlide>
              <SwiperSlide>
                <YellowCardsTab player={player} />
              </SwiperSlide>
              <SwiperSlide>
                <SuspensionsTab player={player} />
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </CardContent>

      {player ? (
        <ChangeShirtNumberDialog
          open={openChangeShirtNumberDialog}
          onClose={() => setOpenChangeShirtNumberDialog(false)}
          player={player}
        />
      ) : null}

      {player ? (
        <FirePlayerDialog
          open={openFirePlayerDialog}
          onClose={() => setOpenFirePlayerDialog(false)}
          player={player}
        />
      ) : null}

      {player ? (
        <ConfirmPromotePlayerDialog
          open={openPromotePlayerDialog}
          onClose={() => setOpenPromotePlayerDialog(false)}
          player={player}
        />
      ) : null}

    </Card>
  );
};

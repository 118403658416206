import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LineUpPosition, Player } from '../../data/types/match/MatchDetails';
import { Face, Shirt } from '../../common/Player';
import { EnergyBar } from './EnergyBar';
import { Stats } from '../../common/Player/Stats';

type Props = {
  player?: Player;
  open: boolean;
  onClose: () => void;
  drawerPosition: 'left' | 'right' | 'bottom';
};

export const PlayerStats: FC<Props> = (props: Props) => {
  const {
    player,
    open,
    onClose,
    drawerPosition,
  } = props;
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <SwipeableDrawer
      anchor={matchesXsOrSm ? 'bottom' : drawerPosition}
      onClose={() => onClose()}
      onOpen={() => { }}
      open={open}
      disableBackdropTransition
      sx={{
        maxWidth: matchesXsOrSm ? '100%' : '600px',
        '& .MuiPaper-root': {
          overflow: 'auto',
          maxWidth: matchesXsOrSm ? '100%' : '600px',
        },
      }}
    >
      {player !== undefined ? (
        <Card sx={{ overflow: 'auto', userSelect: 'none' }}>
          <CardHeader
            title={player?.name}
            titleTypographyProps={{
              variant: 'h6',
              textAlign: 'center',

            }}
            action={(
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <Stack spacing={2} sx={{ pt: 2 }}>
              <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                <Face
                  body={player?.face?.body}
                  hairStyle={player?.face?.hairStyle}
                  hairColor={player?.face?.hairColor}
                  eyeShape={player?.face?.eyeShape}
                  eyeColor={player?.face?.eyeColor}
                  noseShape={player?.face?.noseShape}
                  mouthShape={player?.face?.mouthShape}
                  beard={player?.face?.beard}
                  eyebrows={player?.face?.eyebrows}
                  width={120}
                  height={120}
                />
                <Box sx={{ width: '120px' }}>
                  <Shirt number={player?.shirtNumber} />
                </Box>
              </Stack>

              {player?.enteredDuringMatch ? (
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={1}>
                  <img src="/assets/icons/entered-match.svg" alt={t('entered_during_match') || ''} width="45" />
                  <Typography variant="h6">{t('entered_during_match')}</Typography>
                </Stack>
              ) : null}

              {player?.substituted && player?.injured ? (
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={2}>
                  <img src="/assets/icons/left-match.svg" alt={t('was_subbed') || ''} width="45" />
                  <Typography variant="body1">{t('was_subbed')}</Typography>
                  {player.injured ? (
                    <>
                      <Divider orientation="vertical" sx={{ height: '50px' }} />
                      <img src="/assets/icons/injury.svg" alt="" width="50" />
                      <Typography variant="body1">{t('injured')}</Typography>
                    </>
                  ) : null}
                </Stack>
              ) : null}
              {player?.injured && !player?.substituted ? (
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={1}>
                  <img src="/assets/icons/injury.svg" alt={t('injured') || ''} width="45" />
                  <Typography variant="body1">{t('injured')}</Typography>
                </Stack>
              ) : null}

              <Stack>
                <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('energy')}</Typography>
                <EnergyBar energy={player?.energy || 0} width="100%" showNumber />
              </Stack>

              <Grid container spacing={0.5} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                  <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('overall_stats')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stats icon="/assets/icons/rank.svg" description={t('performance')} stat={player?.performance} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stats icon="/assets/icons/ball.svg" description={t('goals')} stat={player?.stats?.goalsScored} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stats icon="/assets/icons/cleats.svg" description={t('assists')} stat={player?.stats?.assistsGiven} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stats icon="/assets/icons/yellow-card.svg" description={t('yellow_cards')} stat={player?.stats?.yellowCards} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stats icon="/assets/icons/red-card.svg" description={t('red_cards')} stat={player?.stats?.redCards} />
                </Grid>

                {player?.lineupPosition !== LineUpPosition.GOALKEEPER ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('pass_stats')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass.png"
                        description={t('passes')}
                        stat={`${player?.stats?.successfulPasses}/${player?.stats?.totalPasses}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass.png"
                        description={t('passes_received')}
                        stat={`${player?.stats?.successfulPassesReception}/${player?.stats?.totalPassesReception}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass-block.png"
                        description={t('blocked_passes')}
                        stat={`${player?.stats?.successfulPassesBlocking}/${player?.stats?.totalPassesBlocking}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass-block.png"
                        description={t('passes_intercepted')}
                        stat={`${player?.stats?.successfulPassesInterception}/${player?.stats?.totalPassesInterception}`}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass.png"
                        description={t('crosses')}
                        stat={`${player?.stats?.successfulCrosses}/${player?.stats?.totalCrosses}`}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass.png"
                        description={t('blocked_crosses')}
                        stat={`${player?.stats?.successfulCrossesBlocking}/${player?.stats?.totalCrossesBlocking}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/pass.png"
                        description={t('intercepted_crosses')}
                        stat={`${player?.stats?.successfulCrossesInterception}/${player?.stats?.totalCrossesInterception}`}
                      />
                    </Grid>
                  </>
                ) : null}

                {player?.lineupPosition !== LineUpPosition.GOALKEEPER ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('dribbles_and_tackles_stats')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/dribbling.png"
                        description={t('dribbles')}
                        stat={`${player?.stats?.successfulDribbles}/${player?.stats?.totalDribbles}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/tackle.png"
                        description={t('tackles')}
                        stat={`${player?.stats?.successfulTackles}/${player?.stats?.totalTackles}`}
                      />
                    </Grid>
                  </>
                ) : null}

                {player?.lineupPosition !== LineUpPosition.GOALKEEPER ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('shoots_stats')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/shoot.png"
                        description={t('shoots_on_target')}
                        stat={`${player?.stats?.onTargetShots}/${player?.stats?.totalShots}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/shoot-block.png"
                        description={t('blocked_shoots')}
                        stat={`${player?.stats?.successfulShootsBlocking}/${player?.stats?.totalShootsBlocking}`}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('saves_stats')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/goalkeeper-coach.png"
                        description={t('saves')}
                        stat={`${player?.stats?.successfulSaves}/${player?.stats?.totalSaves}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/goalkeeper-coach.png"
                        description={t('intercepted_crosses')}
                        stat={`${player?.stats?.successfulCrossesInterception}/${player?.stats?.totalCrossesInterception}`}
                      />
                    </Grid>
                  </>
                )}

                {player?.lineupPosition !== LineUpPosition.GOALKEEPER ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('fouls_stats')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/tackle.png"
                        description={t('committed_fouls')}
                        stat={player?.stats?.foulsCommitted}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stats
                        icon="/assets/icons/tackle.png"
                        description={t('suffered_fouls')}
                        stat={player?.stats?.foulsSuffered}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Stack>
          </CardContent>
        </Card>

      ) : null}
    </SwipeableDrawer>
  );
};

import { ReconstructionType } from './ReconstructionType';
import { Status } from './Status';

export enum Size {
  NONE = 'NONE',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
  ULTRA_BIG = 'ULTRA_BIG',
  HUGE = 'HUGE',
}

export type SocialClub = {
  size: Size;
  newSize: Size;
  status: Status;
  maintenanceCost: number;
  underMaintenanceUntil: string;
  reconstructionType: ReconstructionType;
};

import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import styled from 'styled-components';

const LiveIcon = styled.img`
    transform: scale(1);
    animation: pulse 2s infinite;

    @keyframes pulse {
        0% {
            transform: scale(0.80);
        }
    
        70% {
            transform: scale(1);
        }
    
        100% {
            transform: scale(0.80);
        }
    }
`;

type Props = {
  currentMinute?: number
};
export const LiveMatchIcon: FC<Props> = (props: Props) => {
  const { currentMinute } = props;

  return (
    <Stack spacing={0.5} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <LiveIcon src="/assets/icons/streaming.svg" alt="Live" className="live-icon" width="30" />
      <Typography variant="body2" textAlign="center">{t('live_match')}</Typography>
      <Typography variant="caption" textAlign="center">{currentMinute ? `${currentMinute}'` : ''}</Typography>
    </Stack>
  );
};

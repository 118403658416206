import { FC } from 'react';
import {
  Grid,
} from '@mui/material';
import { t } from 'i18next';
import { Group } from '../../data/types/seasons/Group';
import { Standings } from '../Standings';
import { Fixtures } from '../Fixtures/Fixtures';
import { Match } from '../../data/types/seasons/Match';
import { getMatchLink } from '../links';

type Props = {
  group?: Group;
  loading: boolean;
};

export const GroupDetails: FC<Props> = (props: Props) => {
  const { group, loading } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Standings
          standings={group?.standings || []}
          loading={loading}
          placeholderSize={4}
        />
      </Grid>
      <Grid item xs={12}>
        <Fixtures
          fixtures={group?.pastFixtures || []}
          loading={loading}
          loadingPlaceholders={2}
          getMatchCardLink={(match: Match) => getMatchLink(match)}
          jumpToLastFixture
          headerText={t('last_fixtures')}
        />
      </Grid>
      <Grid item xs={12}>
        <Fixtures
          fixtures={group?.nextFixtures || []}
          loading={loading}
          loadingPlaceholders={2}
          getMatchCardLink={(match: Match) => getMatchLink(match)}
          headerText={t('next_fixtures')}
        />
      </Grid>
    </Grid>
  );
};

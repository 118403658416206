import {
  FC, useEffect, useState,
} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { Standing } from '../../data/types/seasons/Standings';
import { getUserClubLeagueStandings } from '../../data/api/season';
import { Standings } from '../../common/Standings';

type Props = {
  triggerRefresh: boolean;
};

export const StandingsSummary: FC<Props> = (props: Props) => {
  const { triggerRefresh } = props;
  const [standings, setStandings] = useState<Standing[]>([]);
  const [leagueName, setLeagueName] = useState<string>('');
  const [leagueId, setLeagueId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getUserClubLeagueStandings().then((response) => {
      setStandings(response?.data?.standings ?? [] as Standing[]);
      setLeagueName(response?.data?.leagueName);
      setLeagueId(response?.data?.leagueId);
      // TODO: handle error
    }).finally(() => {
      setLoading(false);
    });
  }, [triggerRefresh]);

  return (
    <Card sx={{ position: 'relative' }} variant="outlined">
      <CardHeader
        title={loading
          ? (<Skeleton variant="text" />)
          : (
            <Link to={`/league/${leagueId}`} style={{ color: 'inherit', textDecoration: 'none' }}>
              <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h6" sx={{ padding: '0.60rem' }}>
                  {leagueName}
                </Typography>
                <OpenInNewIcon sx={{ marginLeft: 0.5 }} />
              </Stack>
            </Link>
          )}
        sx={{
          paddingBottom: 0,
        }}
      />
      <CardContent>
        <Standings standings={standings} placeholderSize={5} loading={loading} />
      </CardContent>
    </Card>
  );
};

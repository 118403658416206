import { useState } from 'react';
import {
  Box, Button, Menu, MenuItem,
} from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { auth } from '../Firebase';

export const Account = (props: any): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const onAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { sx } = props;
  const onClose = () => {
    setAnchorEl(null);
  };
  const onLogout = () => {
    // here we need only to sign out.
    // Check for user login status is in app.tsx and from there navigation will happen
    signOut(auth).then(() => {
      onClose();
    });
  };

  return (
    <Box sx={sx}>
      <Button
        startIcon={<AccountCircleOutlined />}
        onClick={onAccountClick}
        color="inherit"
      >
        {auth.currentUser?.email}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem onClick={onLogout}>{t('logout')}</MenuItem>
      </Menu>
    </Box>
  );
};

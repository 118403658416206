import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import {
  ExtraPositions, LineupPlayer, LineupType, ProgrammedSubs,
} from '../data/types/match';

type Actions = {
  setFormation: (f: string) => void;
  setExtraPosition: (p: ExtraPositions, id: string) => void;
  setFirstElevenPlayers: (p: LineupPlayer[]) => void;
  setStore: (s: Partial<LineupType>) => void;
  setSquad: (s: {}) => void;
  setBenchPlayers: (p: string[]) => void;
  setSubstitution: (s: ProgrammedSubs, index: number) => void;
  removeSubstitution: (index: number) => void;
  resetStore: () => void;
};

type Store = LineupType & {
  actions: Actions;
};

const initialStoreData: LineupType = {
  formation: '',
  squad: {},
  firstElevenPlayersReady: false,
  firstElevenPlayers: [],
  captain: '',
  penaltyTaker: '',
  freeKickTaker: '',
  cornerKickTaker: '',
  benchPlayers: [],
  programmedSubstitutions: [],
};

const useLineupStore = create<Store>()((set) => ({
  formation: initialStoreData.formation,
  squad: { ...initialStoreData.squad },
  firstElevenPlayersReady: initialStoreData.firstElevenPlayersReady,
  firstElevenPlayers: initialStoreData.firstElevenPlayers,
  captain: initialStoreData.captain,
  penaltyTaker: initialStoreData.penaltyTaker,
  freeKickTaker: initialStoreData.freeKickTaker,
  cornerKickTaker: initialStoreData.cornerKickTaker,
  benchPlayers: initialStoreData.benchPlayers,
  programmedSubstitutions: initialStoreData.programmedSubstitutions,
  actions: {
    setFormation: (f) => set(() => ({
      formation: f,
      squad: { ...initialStoreData.squad },
      firstElevenPlayersReady: initialStoreData.firstElevenPlayersReady,
      firstElevenPlayers: initialStoreData.firstElevenPlayers,
      captain: initialStoreData.captain,
      penaltyTaker: initialStoreData.penaltyTaker,
      freeKickTaker: initialStoreData.freeKickTaker,
      cornerKickTaker: initialStoreData.cornerKickTaker,
    })),
    setExtraPosition: (p, id) => set(() => ({
      [p]: id,
    })),
    setFirstElevenPlayers: (p) => set(() => ({
      firstElevenPlayers: p,
      firstElevenPlayersReady: p.length === 11,
    })),
    setBenchPlayers: (p) => set(() => ({
      benchPlayers: p,
    })),
    setStore: (s) => set(() => ({ ...s })),
    setSquad: (squad) => set(() => ({ squad })),
    setSubstitution: (s, index) => set((prevState) => {
      // check first if index already exists in the array
      if (prevState.programmedSubstitutions?.[index]) {
        return ({
          ...prevState,
          programmedSubstitutions: prevState.programmedSubstitutions.map((sub, i) => {
            if (i === index) {
              return s;
            }

            return sub;
          }),
        });
      }

      // in all other case we treat it as a new substitution and add into the existing
      // subs array
      return ({
        ...prevState,
        programmedSubstitutions: prevState.programmedSubstitutions.concat([s]),
      });
    }),
    removeSubstitution: (index) => set(({ programmedSubstitutions }) => ({
      programmedSubstitutions: programmedSubstitutions.filter((s, i) => i !== index),
    })),
    resetStore: () => set(() => ({
      ...initialStoreData,
    })),
  },
}));

export const useLineupState = (): LineupType => useLineupStore(useShallow((state) => ({
  formation: state.formation,
  squad: state.squad,
  firstElevenPlayers: state.firstElevenPlayers,
  captain: state.captain,
  penaltyTaker: state.penaltyTaker,
  freeKickTaker: state.freeKickTaker,
  cornerKickTaker: state.cornerKickTaker,
  firstElevenPlayersReady: state.firstElevenPlayersReady,
  benchPlayers: state.benchPlayers,
  programmedSubstitutions: state.programmedSubstitutions,
})));

export const useLineupActions = (): Actions => useLineupStore(useShallow((state) => state.actions));

import { Stack, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { t } from 'i18next';
import { FC } from 'react';
import styled from 'styled-components';

const LiveIcon = styled.img`
    transform: scale(1);
    animation: pulse 2s infinite;

    @keyframes pulse {
        0% {
            transform: scale(0.80);
        }
    
        70% {
            transform: scale(1);
        }
    
        100% {
            transform: scale(0.80);
        }
    }
`;

type Props = {
  variant: Variant
};

export const LiveFixture: FC<Props> = (props: Props) => {
  const { variant } = props;
  return (
    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <LiveIcon src="/assets/icons/streaming.svg" alt="Live" className="live-icon" width="30" />
      <Typography variant={variant} sx={{ marginLeft: 1 }}>{t('live_fixture')}</Typography>
    </Stack>
  );
};

import { FC, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Player } from '../../data/types';
import { Loader } from '..';
import { useFirePlayer } from '../../data/hooks';
import { useFireGrassrootsPlayer } from '../../data/hooks/useGrassroots';
import { QueryKeys } from '../../data/hooks/queryKeys';

type Props = {
  open: boolean;
  onClose: () => void;
  player: Player;
};

export const FirePlayerDialog: FC<Props> = (props: Props) => {
  const { open, onClose, player } = props;
  const [userConfirmation, setUserConfirmation] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isLoading: isLoadingPlayerFire, mutateFn: firePlayer } = useFirePlayer();
  const {
    isLoading: isLoadingGrassrootsFire,
    mutateFn: fireGrassrootsPlayer,
  } = useFireGrassrootsPlayer();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserConfirmation(event.target.checked);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = () => {
    if (player.grassroots) {
      fireGrassrootsPlayer(player.id).then(() => {
        onClose();
        queryClient.fetchQuery({
          queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
        });
        queryClient.fetchQuery({
          queryKey: [QueryKeys.GET_ALL_FACILITIES],
        });
        enqueueSnackbar(t('player_fired_successfully'), { variant: 'success' });
      }).catch(() => {
        enqueueSnackbar(t('error_while_firing_player'), { variant: 'error' });
      });
      return;
    }

    firePlayer(player.id).then(() => {
      onClose();
      queryClient.fetchQuery({
        queryKey: ['players'],
      });
      enqueueSnackbar(t('player_fired_successfully'), { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(t('error_while_firing_player'), { variant: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {`${t('fire_player')} ${player.name}?`}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={0} justifyContent="center" alignItems="center" sx={{ paddingTop: 1 }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleOnChange} />}
              componentsProps={{
                typography: {
                  variant: 'h6',
                },
              }}
              label={t('confirm_player_fire')}
            />
          </FormGroup>
          <Typography variant="h6" fontWeight="bold">{t('this_action_cannot_be_undone')}</Typography>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>{t('cancel')}</Button>
        <Button
          onClick={handleOnConfirm}
          variant="contained"
          color="error"
          disabled={isLoadingPlayerFire || isLoadingGrassrootsFire || !userConfirmation}
        >
          {isLoadingPlayerFire || isLoadingGrassrootsFire ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import {
  Card, CardContent, CardHeader, Grid, Skeleton, Stack, Typography,
} from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';

type Props = {
  loading: boolean;
  matchNumber?: number;
};

export const EmptyBracket: FC<Props> = (props: Props) => {
  const { matchNumber, loading } = props;

  return (
    <Card sx={{ height: '100%' }} variant="outlined">
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={loading
          ? (<Skeleton variant="rounded" width={300} />)
          : `${matchNumber ? t('match') : ''} ${matchNumber ?? ''}`}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={9.5}>
            <Stack direction="column">
              {loading ? (
                <Skeleton variant="rounded" width={400} sx={{ m: 1 }} />
              ) : (
                <Typography
                  noWrap
                  variant="h6"
                  sx={{
                    marginLeft: 1,
                    marginTop: 0.5,
                    fontWeight: 'normal',
                    fontSize: '16px',
                  }}
                >
                  {t('to_be_defined')}
                </Typography>
              )}
              {loading ? (
                <Skeleton variant="rounded" width={400} sx={{ m: 1 }} />
              ) : (
                <Typography
                  noWrap
                  variant="h6"
                  sx={{
                    ml: 1,
                    mt: 0.5,
                    fontWeight: 'normal',
                    fontSize: '16px',
                  }}
                >
                  {t('to_be_defined')}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { Stats } from './Stats';
import { Position } from '../../data/types/players/Player';

type Props = {
  player?: Player,
};

export const StatsTab: FC<Props> = (props: Props) => {
  const { player } = props;

  return (
    <Grid item xs={12} sx={{ padding: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('overall_stats')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Stats
            stat={player?.stats?.matchesPlayed || 0}
            icon="/assets/icons/soccer-field.svg"
            description={t('appearances')}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Stats
            stat={player?.stats?.goalsScored || 0}
            icon="/assets/icons/ball.svg"
            description={t('goals')}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Stats
            stat={player?.stats?.assistsGiven || 0}
            icon="/assets/icons/cleats.svg"
            description={t('assists')}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Stats
            stat={player?.stats?.manOfTheMatch || 0}
            icon="/assets/icons/trophy.svg"
            description={t('man_of_the_match')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mt: 1 }}>
        {player?.position !== Position.GOALKEEPER ? (
          <>
            <Grid item xs={12}>
              <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('other_stats_average')}</Typography>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/ball.svg"
                description={t('average_goals_scored')}
                stat={player?.stats?.averageGoalsScored || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/cleats.svg"
                description={t('average_assists_given')}
                stat={player?.stats?.averageAssistsGiven || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/shoot.png"
                description={t('average_shoots')}
                stat={`${player?.stats?.averageShots || 0} (${player?.stats?.shotsOnTargetPercentage}% ${t('success')})`}
                compact
              />
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/pass.png"
                description={t('average_passes')}
                stat={`${player?.stats?.averagePasses || 0} (${player?.stats?.successfulPassesPercentage}% ${t('success')})`}
                compact
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/dribbling.png"
                description={t('average_dribbles')}
                stat={`${player?.stats?.averageDribbles || 0} (${player?.stats?.successfulDribblesPercentage}% ${t('success')})`}
                compact
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/tackle.png"
                description={t('average_tackles')}
                stat={`${player?.stats?.averageTackles || 0} (${player?.stats?.successfulTacklesPercentage}% ${t('success')})`}
                compact
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/tackle.png"
                description={t('average_committed_fouls')}
                stat={player?.stats?.averageFoulsCommitted || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/tackle.png"
                description={t('average_suffered_fouls')}
                stat={player?.stats?.averageFoulsCommitted || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/yellow-card.svg"
                description={t('average_yellow_cards')}
                stat={player?.stats?.averageYellowCards || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Stats
                icon="/assets/icons/red-card.svg"
                description={t('average_red_cards')}
                stat={player?.stats?.averageRedCards || 0}
              />
            </Grid>
          </>
        ) : null}
      </Grid>

      <Grid container spacing={1} sx={{ mt: 1 }}>
        {player?.position === Position.GOALKEEPER ? (
          <>
            <Grid item xs={12}>
              <Typography variant="overline" noWrap sx={{ padding: 0 }}>{t('saves_stats')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stats
                icon="/assets/icons/goalkeeper-coach.png"
                description={t('average_saves')}
                stat={`${player?.stats?.averageSaves || 0} (${player?.stats?.successfulSavesPercentage}% ${t('success')})`}
                compact
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stats
                icon="/assets/icons/goalkeeper-coach.png"
                description={t('average_crosses_interception')}
                stat={`${player?.stats?.averageCrossesInterception || 0}
                (${player?.stats?.successfulCrossesInterceptionPercentage}%
                ${t('success')})`}
                compact
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

import {
  ListItemButton, ListItemAvatar, Avatar, ListItemText, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Face } from '../../../common';
import { Player } from '../../../data/types/players/Player';
import { MatchType } from '../../../data/types/seasons/Match';
import { LineupPlayer } from '../../../data/types/match';

const getTextColor = (
  player: Player,
  matchType: MatchType,
  firstElevenPlayers: LineupPlayer[],
) => {
  if (player?.injuryStatus?.injured) {
    return '#FF6D6D';
  }

  if (firstElevenPlayers.find((p) => p.id === player.id)) {
    return 'grey';
  }

  if (matchType === MatchType.LEAGUE_MATCH && player?.suspensions?.league) {
    return '#FF6D6D';
  }

  if (matchType === MatchType.REGIONAL_TOURNAMENT_MATCH
    && player?.suspensions?.regionalTournament) {
    return '#FF6D6D';
  }

  if (matchType === MatchType.NATIONAL_CUP_MATCH && player?.suspensions?.nationalCup) {
    return '#FF6D6D';
  }

  if (matchType === MatchType.INTERNATIONAL_CUP_MATCH && player?.suspensions?.internationalCup) {
    return '#FF6D6D';
  }

  return 'black';
};

type Props = {
  player: Player;
  onItemSelected?: (id: string) => void;
  matchType: MatchType;
  firstElevenPlayers: LineupPlayer[];
};

export const PlayerWithStatus = ({
  player, onItemSelected, matchType, firstElevenPlayers,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ListItemButton>
      <ListItemAvatar>
        <Avatar>
          <Face
            body={player?.face?.body}
            hairStyle={player?.face?.hairStyle}
            hairColor={player?.face?.hairColor}
            eyeShape={player?.face?.eyeShape}
            eyeColor={player?.face?.eyeColor}
            noseShape={player?.face?.noseShape}
            mouthShape={player?.face?.mouthShape}
            beard={player?.face?.beard}
            eyebrows={player?.face?.eyebrows}
            width={40}
            height={40}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Stack
            gap={2}
            direction="row"
            sx={{ color: getTextColor(player, matchType, firstElevenPlayers) }}
          >
            <Typography
              display="inline"
              sx={{ fontWeight: 'bold' }}
            >
              {t(`${player?.position?.toLocaleLowerCase()}_short`)}
            </Typography>
            <Typography display="inline">{player.name}</Typography>

            {/*
            * we need to show an indicator if the player can play the match or not
            * based on the match type and the player suspensions
          */}

            {matchType === MatchType.LEAGUE_MATCH && player?.suspensions?.league
              ? (<img src="/assets/icons/red-card.svg" alt="red cards" width="25" />)
              : null}

            {matchType === MatchType.REGIONAL_TOURNAMENT_MATCH
                      && player?.suspensions?.regionalTournament
              ? (<img src="/assets/icons/red-card.svg" alt="red cards" width="25" />)
              : null}

            {matchType === MatchType.NATIONAL_CUP_MATCH && player?.suspensions?.nationalCup
              ? (<img src="/assets/icons/red-card.svg" alt="red cards" width="25" />)
              : null}

            {matchType === MatchType.INTERNATIONAL_CUP_MATCH
                      && player?.suspensions?.internationalCup
              ? (<img src="/assets/icons/red-card.svg" alt="red cards" width="25" />)
              : null}

            {player?.injuryStatus?.injured
              ? (<img src="/assets/icons/injury.svg" width="25" alt={t('injury') || ''} />)
              : null}
          </Stack>
                )}
        onClick={() => onItemSelected?.(player.id)}
      />
    </ListItemButton>
  );
};

import { Status } from './Status';

export enum TechnicalTrainingType {
  REFLEXES = 'REFLEXES',
  POSITIONING = 'POSITIONING',
  AERIAL_PLAYING = 'AERIAL_PLAYING',
  TACKLING = 'TACKLING',
  SHOOTING = 'SHOOTING',
  TECHNIQUE = 'TECHNIQUE',
  CREATION = 'CREATION',
  PASSING = 'PASSING',
}

export type TechnicalTraining = {
  id: string;
  date: string;
  type: TechnicalTrainingType;
  status: Status;
};

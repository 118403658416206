import { FC } from 'react';
import { darken, lighten } from '@mui/system';
import { EyeColor } from '../Player/constants';

type Props = {
  shape: number;
  color: EyeColor;
};

const BASE_COLORS = {
  BLACK: '#222222',
  BLUE: '#0088B7',
  BROWN: '#5F382B',
  GREEN: '#828F38',
};

export const Eyes: FC<Props> = (props: Props) => {
  const { shape, color } = props;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 150 200"
      enableBackground="new 0 0 150 200"
      xmlSpace="preserve"
      style={{
        position: 'absolute',
        top: 0,
      }}
    >
      {shape === 1 ? (
        <>
          <defs>
            <path
              id="a"
              d="m62.261 89.199-3.11-1.302c-1.966.045-3.825-.018-5.484.4-1.559.392-3.787 1.902-5.577 2.715.849.35 3.039.533 3.81.756 1.694.488 3.394 1.402 5.108 1.545 1.348.114 2.928-.206 4.312-.735.984-.377 1.583-.687 2.544-.256.727.325 1.914.307 1.182-.686-.477-.644-1.648-1.254-2.785-2.437z"
            />
          </defs>
          <use
            xlinkHref="#a"
            fill="#FFF"
            fillRule="evenodd"
            clipRule="evenodd"
            overflow="visible"
          />
          <clipPath id="b">
            <use xlinkHref="#a" overflow="visible" />
          </clipPath>
          <g fillRule="evenodd" clipPath="url(#b)" clipRule="evenodd">
            <ellipse
              cx={57.196}
              cy={89.879}
              fill={darken(BASE_COLORS[color], 0.5)}
              rx={3.034}
              ry={3.29}
              transform="rotate(-88.149 57.199 89.878)"
            />
            <ellipse
              cx={57.187}
              cy={89.827}
              fill="#0E0708"
              rx={1.422}
              ry={1.542}
              transform="rotate(-88.149 57.19 89.825)"
            />
            <path
              fill={BASE_COLORS[color]}
              d="M54.359 88.887c-.114.278-.18.58-.189.895-.05 1.542 1.266 2.835 2.936 2.889 1.671.055 3.066-1.153 3.117-2.694a2.587 2.587 0 0 0-.053-.613l-1.095-.191c.106.238.16.499.152.772-.033 1.034-.969 1.845-2.092 1.807-1.12-.036-2.003-.904-1.969-1.938.01-.348.124-.671.313-.945l-1.12.018z"
            />
            <ellipse
              cx={59.196}
              cy={89.269}
              fill="#FFF"
              rx={0.642}
              ry={0.696}
              transform="rotate(-88.189 59.198 89.27)"
            />
            <ellipse
              cx={55.539}
              cy={91.011}
              fill={lighten(BASE_COLORS[color], 0.5)}
              rx={0.868}
              ry={0.94}
              transform="rotate(-88.16 55.543 91.011)"
            />
            <ellipse
              cx={55.711}
              cy={90.883}
              fill="#FFF"
              rx={0.431}
              ry={0.467}
              transform="rotate(-88.149 55.714 90.882)"
            />
          </g>
          <path
            fill="#3C1A18"
            fillRule="evenodd"
            d="M60.38 87.888c-.992-.427-2.545-.237-3.661-.221-1.428.02-2.316.094-3.03.25-2.053.447-4.129 2.052-5.875 3.173 1.657-.046 4.097-2.072 6.104-2.536.612-.14 1.439-.23 2.759-.258 1.204-.026 2.516-.203 3.576.221.388.154 1.432.724 2.34 1.392 1.297.952 2.503 2.05 2.699 2 .664-.175-4.063-3.656-4.912-4.021z"
            clipRule="evenodd"
          />
          <path
            fill="#0E0708"
            fillRule="evenodd"
            d="M60.326 88.293c-.991-.426-2.503-.297-3.619-.281-1.476.02-2.249.083-2.846.236-1.32.334-4.588 2.027-5.737 2.766l.245-.012c1.21-.594 4.377-2.177 5.553-2.447.611-.14 1.438-.23 2.755-.258 1.204-.026 2.516-.203 3.576.221 1.76.704 4.784 3.263 4.896 3.28-1.596-1.31-2.85-2.654-4.823-3.505z"
            clipRule="evenodd"
          />
          <path
            fill="#7E381E"
            fillRule="evenodd"
            d="M49.549 91.366c.802.109 1.725.278 2.496.5 1.695.49 3.273 1.358 4.988 1.503 1.348.112 2.928-.207 4.312-.736.708-.271 1.213-.448 1.65-.445.751.004 1.343.49 1.893.327-.624.013-1.107-.448-1.875-.455-.522-.005-1.053.162-1.57.362-.892.343-2.292.962-4.581.761-1.672-.147-3.173-1.013-4.774-1.513-.629-.195-2.036-.496-2.539-.304z"
            clipRule="evenodd"
          />
          <defs>
            <path
              id="c"
              d="m84.417 89.192 3.104-1.299c1.961.045 3.817-.018 5.471.398 1.555.392 3.779 1.899 5.565 2.708-.847.351-3.032.532-3.801.755-1.691.489-3.388 1.399-5.098 1.542-1.344.114-2.921-.205-4.302-.733-.98-.376-1.579-.686-2.538-.257-.726.325-1.909.307-1.178-.684.474-.642 1.641-1.249 2.777-2.43z"
            />
          </defs>
          <use
            xlinkHref="#c"
            fill="#FFF"
            fillRule="evenodd"
            clipRule="evenodd"
            overflow="visible"
          />
          <clipPath id="d">
            <use xlinkHref="#c" overflow="visible" />
          </clipPath>
          <g fillRule="evenodd" clipPath="url(#d)" clipRule="evenodd">
            <ellipse
              cx={89.471}
              cy={89.872}
              fill={darken(BASE_COLORS[color], 0.5)}
              rx={3.027}
              ry={3.283}
              transform="rotate(-91.851 89.467 89.87)"
            />
            <ellipse
              cx={89.479}
              cy={89.818}
              fill="#0E0708"
              rx={1.419}
              ry={1.537}
              transform="rotate(-91.834 89.48 89.817)"
            />
            <path
              fill={BASE_COLORS[color]}
              d="M92.3 88.88c.114.278.181.579.189.894.051 1.538-1.262 2.829-2.929 2.883-1.667.053-3.06-1.151-3.108-2.689a2.593 2.593 0 0 1 .053-.612l1.093-.189a1.737 1.737 0 0 0-.152.769c.034 1.033.968 1.841 2.086 1.805 1.119-.036 1.998-.903 1.966-1.935a1.759 1.759 0 0 0-.313-.943l1.115.017z"
            />
            <ellipse
              cx={87.476}
              cy={89.262}
              fill="#FFF"
              rx={0.641}
              ry={0.696}
              transform="rotate(-91.834 87.475 89.26)"
            />
            <ellipse
              cx={91.124}
              cy={91}
              fill={lighten(BASE_COLORS[color], 0.5)}
              rx={0.866}
              ry={0.939}
              transform="rotate(-91.84 91.126 91)"
            />
            <ellipse
              cx={90.954}
              cy={90.872}
              fill="#FFF"
              rx={0.431}
              ry={0.464}
              transform="rotate(-91.948 90.955 90.874)"
            />
          </g>
          <path
            fill="#3C1A18"
            fillRule="evenodd"
            d="M86.296 87.883c.987-.426 2.538-.237 3.653-.221 1.421.021 2.309.094 3.02.25 2.05.447 4.122 2.048 5.864 3.167-1.653-.047-4.089-2.068-6.092-2.53-.609-.141-1.435-.229-2.752-.258-1.2-.025-2.51-.203-3.568.219-.386.156-1.427.725-2.335 1.391-1.294.95-2.497 2.044-2.693 1.994-.663-.174 4.055-3.646 4.903-4.012z"
            clipRule="evenodd"
          />
          <path
            fill="#0E0708"
            fillRule="evenodd"
            d="M86.349 88.287c.989-.424 2.497-.295 3.61-.279 1.473.02 2.243.083 2.839.234 1.317.335 4.579 2.023 5.727 2.761l-.245-.013c-1.208-.591-4.37-2.172-5.542-2.44-.609-.141-1.436-.229-2.749-.258-1.2-.027-2.51-.203-3.568.219-1.755.703-4.772 3.256-4.884 3.273 1.59-1.305 2.842-2.646 4.812-3.497z"
            clipRule="evenodd"
          />
          <path
            fill="#7E381E"
            fillRule="evenodd"
            d="M97.1 91.354c-.8.108-1.719.278-2.488.499-1.693.49-3.267 1.356-4.978 1.499-1.344.114-2.92-.206-4.301-.734-.707-.271-1.211-.447-1.648-.445-.749.006-1.34.489-1.889.327.623.014 1.106-.446 1.871-.453.521-.005 1.052.162 1.568.36.89.342 2.286.96 4.569.76 1.668-.147 3.165-1.012 4.763-1.509.627-.197 2.031-.496 2.533-.304z"
            clipRule="evenodd"
          />
        </>
      ) : null}

      {shape === 2 ? (
        <>
          <path
            fill="#B79584"
            d="M51.614 90.057s.533-2.328 4.801-3.57c0 0-2.664 1.329-3.363 2.825-.698 1.495-1.438.745-1.438.745zM65.264 91.87s-.357-4.363-5.19-5.462c0 0 3.304 1.24 3.962 4.231l1.228 1.231z"
            opacity={0.3}
          />
          <path
            fill="#E8E1D8"
            d="M64.977 91.211c-1.055-1.23-3.377-3.479-6.152-3.63-3.801-.208-7.111 2.115-7.211 2.476-.1.36 3.678 2.398 6.569 2.556 2.892.158 5.106-.807 6.2-.748.807.042 1.159.007.594-.654z"
          />
          <path
            fill={darken(BASE_COLORS[color], 0.25)}
            d="M56.231 89.712c-.07 1.251.941 2.324 2.262 2.395 1.316.073 2.441-.885 2.514-2.134.07-1.252-.941-2.324-2.26-2.396-1.319-.072-2.444.883-2.516 2.135z"
          />
          <path
            fill={BASE_COLORS[color]}
            d="M56.445 89.623c-.062 1.089.864 2.025 2.067 2.091 1.205.065 2.23-.764 2.293-1.854.062-1.09-.865-2.025-2.068-2.091-1.205-.064-2.231.765-2.292 1.854z"
          />
          <ellipse
            cx={58.629}
            cy={89.672}
            fill="#0C0C0C"
            rx={1.148}
            ry={1.09}
            transform="rotate(-176.861 58.63 89.671)"
          />
          <path
            fill="#E8E8E8"
            d="M58.448 88.536c.17.209.059.563-.25.793-.311.228-.699.243-.871.034-.17-.209-.059-.564.25-.791.31-.231.699-.244.871-.036zM59.784 90.141c.109.135.037.362-.162.51-.201.146-.451.157-.561.021-.11-.134-.037-.364.163-.508.198-.148.448-.158.56-.023z"
          />
          <path
            fill="#5F4F47"
            d="M62.509 92.157c.786-.17 1.429-.317 1.874-.292.807.043 1.159.007.594-.653a14.022 14.022 0 0 0-1.523-1.514c.513.677.853 1.819-.945 2.459zM51.614 90.057c-.049.174 1.316 1.142 2.674 1.571-.688-.217-1.488-2.434-.802-2.846-1.105.55-1.827 1.111-1.872 1.275z"
          />
          <path
            fill="#0C0C0C"
            d="M65.256 91.64c-.445-.717-2.563-2.681-3.973-3.104-1.412-.423-1.623-.658-3.354-.622-1.731.034-3.767.733-6.316 2.143 0 0 1.063-1.321 3.22-2.152 2.156-.835 2.983-.621 4.208-.566 2.626.119 5.29 1.964 6.215 4.301z"
          />
          <path
            fill="#B79584"
            d="M96.248 89.637s-.629-2.508-4.945-3.737c0 0 2.719 1.366 3.477 2.968.759 1.603 1.468.769 1.468.769zM82.674 91.971s.182-4.735 4.967-6.058c0 0-3.254 1.432-3.789 4.691l-1.178 1.367z"
            opacity={0.3}
          />
          <path
            fill="#E8E1D8"
            d="M82.934 91.251c1.002-1.361 3.234-3.86 6.002-4.101 3.793-.329 7.197 2.099 7.313 2.487.113.388-3.58 2.697-6.465 2.946-2.885.25-5.139-.735-6.229-.64-.805.069-1.16.04-.621-.692z"
          />
          <path
            fill={darken(BASE_COLORS[color], 0.25)}
            d="M91.615 89.389c.123 1.354-.846 2.542-2.16 2.656-1.314.115-2.48-.892-2.6-2.245-.123-1.354.844-2.544 2.16-2.658 1.313-.112 2.479.893 2.6 2.247z"
          />
          <path
            fill={BASE_COLORS[color]}
            d="M91.398 89.298c.107 1.179-.779 2.218-1.98 2.323-1.203.103-2.262-.769-2.367-1.946-.105-1.179.781-2.219 1.982-2.322s2.26.768 2.365 1.945z"
          />
          <path
            fill="#0C0C0C"
            d="M90.363 89.313c.057.65-.408 1.221-1.039 1.276s-1.191-.428-1.25-1.079.406-1.221 1.037-1.276c.633-.055 1.192.428 1.252 1.079z"
          />
          <path
            fill="#E8E8E8"
            d="M89.168 88.18c.18.223.082.61-.219.866-.301.255-.689.283-.869.061-.18-.223-.08-.609.221-.865.299-.256.687-.284.867-.062zM90.568 89.882c.115.144.053.393-.143.558-.191.164-.443.181-.559.038-.115-.142-.051-.391.143-.556.192-.165.444-.183.559-.04z"
          />
          <path
            fill="#5F4F47"
            d="M85.441 92.209c-.797-.164-1.443-.305-1.887-.266-.805.069-1.16.04-.621-.692.346-.471.848-1.077 1.463-1.68-.49.746-.781 1.994 1.045 2.638zM96.248 89.637c.055.188-1.27 1.272-2.609 1.775.678-.254 1.389-2.677.686-3.105 1.126.564 1.87 1.155 1.923 1.33z"
          />
          <path
            fill="#0C0C0C"
            d="M82.672 91.723c.414-.789 2.453-2.974 3.848-3.472 1.393-.496 1.594-.758 3.326-.765s3.797.692 6.402 2.151c0 0-1.117-1.4-3.307-2.245-2.189-.845-3.008-.592-4.23-.498-2.621.2-5.207 2.271-6.039 4.829z"
          />
        </>
      ) : null}

      {shape === 3 ? (
        <>
          <path
            fill="#B79584"
            d="M82.44 91.063c-.189-1.56 1.777-4.813 5.346-5.299 0 0-3.634.967-4.696 4.462-.106.347-.55.45-.65.837zM91.714 85.753c1.813.301 5.717 1.512 6.294 4.75 0 0-1.06-1.37-2.004-1.813 0 0-1.429-2.004-4.29-2.937z"
            opacity={0.3}
          />
          <path
            fill="#E8E1D8"
            d="M82.5 90.743c.123-.687 1.526-3.724 7.188-4.014 4.476-.23 8.314 2.586 8.314 3.774-.002.243-.83.831-2.142 1.363-1.312.532-3.063 1.002-4.767 1.002-3.409 0-4.826-.663-6.267-.95-.926-.184-2.512-.146-2.326-1.175z"
          />
          <ellipse
            cx={89.842}
            cy={89.546}
            fill={darken(BASE_COLORS[color], 0.25)}
            rx={2.853}
            ry={2.77}
          />
          <ellipse cx={89.842} cy={89.424} fill={BASE_COLORS[color]} rx={2.605} ry={2.412} />
          <ellipse cx={89.842} cy={89.338} fill="#0C0C0C" rx={1.198} ry={1.165} />
          <path
            fill="#E8E8E8"
            d="M89.498 87.754c-.267.376-.044.977.5 1.343.544.363 1.201.354 1.469-.023.269-.378.044-.978-.5-1.342-.542-.366-1.2-.354-1.469.022zM88.153 89.902c-.12.171-.019.444.229.611.249.166.548.162.668-.01.123-.171.021-.444-.228-.611-.247-.166-.546-.161-.669.01z"
          />
          <path
            fill="#B79584"
            d="M92.417 94.421c-.034-.188-4.515.198-7.42-.999-2.903-1.198-2.901-1.824-2.943-.945s2.279 2.328 4.732 2.656 5.733-.179 5.631-.712z"
            opacity={0.2}
          />
          <path
            fill="#5F4F47"
            d="m84.938 88.478-.472-.339c-1.465.992-1.957 2.201-2.024 2.604-.176 1.03 1.319.991 2.193 1.176.339.072.68.167 1.045.271-2.021-.739-1.387-3.141-.742-3.712z"
          />
          <path
            fill="#5F4F47"
            d="M96.682 89.197c-.199.863-2.152 3.39-4.898 3.536-2.747.148-7.125-.76-7.468-2.348 0 0-.032.249.138 1.472.131.019 1.113.309 1.228.333 1.442.287 2.004.679 5.413.679 1.704 0 3.455-.47 4.767-1.002 1.312-.532 2.14-1.12 2.142-1.363 0-.39-.418-.953-1.128-1.528-.102.068-.175.142-.194.221z"
          />
          <path
            fill="#0C0C0C"
            d="M82.55 91.18c.586-.484 2.511-3.969 6.538-4.168 4.482-.222 7.575 1.86 8.914 3.492 0 0-.406-2.467-5.637-3.788-2.538-.641-4.681-.322-6.303.41-1.722.775-2.857 1.999-3.132 2.421-.535.818-.844 1.488-.38 1.633z"
          />
          <path
            fill="#B79584"
            d="M64.92 91.063c.188-1.56-1.778-4.813-5.346-5.299 0 0 3.633.967 4.695 4.462.106.347.551.45.651.837zM55.646 85.753c-1.813.301-5.716 1.512-6.295 4.75 0 0 1.061-1.37 2.004-1.813 0 0 1.429-2.004 4.291-2.937z"
            opacity={0.3}
          />
          <path
            fill="#E8E1D8"
            d="M64.86 90.743c-.124-.687-1.527-3.724-7.188-4.014-4.477-.23-8.314 2.586-8.313 3.774 0 .243.828.831 2.14 1.363 1.313.532 3.063 1.002 4.767 1.002 3.41 0 4.826-.663 6.27-.95.923-.184 2.511-.146 2.324-1.175z"
          />
          <ellipse
            cx={57.519}
            cy={89.546}
            fill={darken(BASE_COLORS[color], 0.25)}
            rx={2.854}
            ry={2.77}
          />
          <ellipse cx={57.518} cy={89.424} fill={BASE_COLORS[color]} rx={2.605} ry={2.412} />
          <ellipse cx={57.518} cy={89.338} fill="#0C0C0C" rx={1.198} ry={1.165} />
          <path
            fill="#E8E8E8"
            d="M57.176 87.754c-.268.376-.045.977.499 1.343.543.363 1.202.354 1.47-.023.268-.378.043-.978-.5-1.342-.543-.366-1.202-.354-1.469.022zM55.832 89.902c-.122.171-.021.444.227.611.248.166.547.162.668-.01s.02-.444-.227-.611c-.247-.166-.548-.161-.668.01z"
          />
          <path
            fill="#B79584"
            d="M54.943 94.421c.035-.188 4.513.198 7.418-.999 2.905-1.198 2.903-1.824 2.945-.945.041.879-2.279 2.328-4.732 2.656-2.452.329-5.732-.179-5.631-.712z"
            opacity={0.2}
          />
          <path
            fill="#5F4F47"
            d="m62.424 88.478.469-.339c1.467.992 1.957 2.201 2.025 2.604.176 1.03-1.319.991-2.193 1.176-.34.072-.68.167-1.047.271 2.023-.739 1.388-3.141.746-3.712z"
          />
          <path
            fill="#5F4F47"
            d="M50.678 89.197c.201.863 2.152 3.39 4.898 3.536 2.747.148 7.125-.76 7.469-2.348 0 0 .033.249-.139 1.472-.131.019-1.113.309-1.229.333-1.44.287-2.002.679-5.412.679-1.703 0-3.453-.47-4.767-1.002-1.312-.532-2.14-1.12-2.14-1.363-.002-.39.416-.953 1.129-1.528.097.068.171.142.191.221z"
          />
          <path
            fill="#0C0C0C"
            d="M64.811 91.18c-.587-.484-2.51-3.969-6.539-4.168-4.482-.222-7.574 1.86-8.912 3.492 0 0 .404-2.467 5.635-3.788 2.54-.641 4.682-.322 6.305.41 1.723.775 2.855 1.999 3.131 2.421.535.818.842 1.488.38 1.633z"
          />
        </>
      ) : null}

      {shape === 4 ? (
        <>
          <defs>
            <path
              id="a"
              d="m62.261 89.199-3.11-1.302c-1.966.045-3.825-.018-5.484.4-1.559.392-3.787 1.902-5.577 2.715.849.35 3.039.533 3.81.756 1.694.488 3.394 1.402 5.108 1.545 1.348.114 2.928-.206 4.312-.735.984-.377 1.583-.687 2.544-.256.727.325 1.914.307 1.182-.686-.477-.644-1.648-1.254-2.785-2.437z"
            />
          </defs>
          <use
            xlinkHref="#a"
            fill="#FFF"
            fillRule="evenodd"
            clipRule="evenodd"
            overflow="visible"
          />
          <clipPath id="b">
            <use xlinkHref="#a" overflow="visible" />
          </clipPath>
          <ellipse
            cx={57.196}
            cy={89.879}
            fill={BASE_COLORS[color]}
            fillRule="evenodd"
            clipPath="url(#b)"
            clipRule="evenodd"
            rx={3.034}
            ry={3.29}
            transform="rotate(-88.149 57.199 89.878)"
          />
          <ellipse
            cx={57.187}
            cy={89.827}
            fill="#0E0708"
            fillRule="evenodd"
            clipPath="url(#b)"
            clipRule="evenodd"
            rx={1.422}
            ry={1.542}
            transform="rotate(-88.149 57.19 89.825)"
          />
          <path
            fill={darken(BASE_COLORS[color], 0.50)}
            fillRule="evenodd"
            d="M54.359 88.887c-.114.278-.18.58-.189.895-.05 1.542 1.266 2.835 2.936 2.889 1.671.055 3.066-1.153 3.117-2.694a2.587 2.587 0 0 0-.053-.613l-1.095-.191c.106.238.16.499.152.772-.033 1.034-.969 1.845-2.092 1.807-1.12-.036-2.003-.904-1.969-1.938.01-.348.124-.671.313-.945l-1.12.018z"
            clipPath="url(#b)"
            clipRule="evenodd"
          />
          <ellipse
            cx={59.196}
            cy={89.269}
            fill="#FFF"
            fillRule="evenodd"
            clipPath="url(#b)"
            clipRule="evenodd"
            rx={0.642}
            ry={0.696}
            transform="rotate(-88.189 59.198 89.27)"
          />
          <ellipse
            cx={55.539}
            cy={91.011}
            fill={lighten(BASE_COLORS[color], 0.50)}
            fillRule="evenodd"
            clipPath="url(#b)"
            clipRule="evenodd"
            rx={0.868}
            ry={0.94}
            transform="rotate(-88.16 55.543 91.011)"
          />
          <ellipse
            cx={55.711}
            cy={90.883}
            fill="#FFF"
            fillRule="evenodd"
            clipPath="url(#b)"
            clipRule="evenodd"
            rx={0.431}
            ry={0.467}
            transform="rotate(-88.149 55.714 90.882)"
          />
          <path
            fill="#3C1A18"
            fillRule="evenodd"
            d="M60.38 87.888c-.992-.427-2.545-.237-3.661-.221-1.428.02-2.316.094-3.03.25-2.053.447-4.129 2.052-5.875 3.173 1.657-.046 4.097-2.072 6.104-2.536.612-.14 1.439-.23 2.759-.258 1.204-.026 2.516-.203 3.576.221.388.154 1.432.724 2.34 1.392 1.297.952 2.503 2.05 2.699 2 .664-.175-4.063-3.656-4.912-4.021z"
            clipRule="evenodd"
          />
          <path
            fill="#0E0708"
            fillRule="evenodd"
            d="M60.326 88.293c-.991-.426-2.503-.297-3.619-.281-1.476.02-2.249.083-2.846.236-1.32.334-4.588 2.027-5.737 2.766l.245-.012c1.21-.594 4.377-2.177 5.553-2.447.611-.14 1.438-.23 2.755-.258 1.204-.026 2.516-.203 3.576.221 1.76.704 4.784 3.263 4.896 3.28-1.596-1.31-2.85-2.654-4.823-3.505z"
            clipRule="evenodd"
          />
          <path
            fill="#7E381E"
            fillRule="evenodd"
            d="M49.549 91.366c.802.109 1.725.278 2.496.5 1.695.49 3.273 1.358 4.988 1.503 1.348.112 2.928-.207 4.312-.736.708-.271 1.213-.448 1.65-.445.751.004 1.343.49 1.893.327-.624.013-1.107-.448-1.875-.455-.522-.005-1.053.162-1.57.362-.892.343-2.292.962-4.581.761-1.672-.147-3.173-1.013-4.774-1.513-.629-.195-2.036-.496-2.539-.304z"
            clipRule="evenodd"
          />
          <defs>
            <path
              id="c"
              d="m84.417 89.192 3.104-1.299c1.961.045 3.817-.018 5.471.398 1.555.392 3.779 1.899 5.565 2.708-.847.351-3.032.532-3.801.755-1.691.489-3.388 1.399-5.098 1.542-1.344.114-2.921-.205-4.302-.733-.98-.376-1.579-.686-2.538-.257-.726.325-1.909.307-1.178-.684.474-.642 1.641-1.249 2.777-2.43z"
            />
          </defs>
          <use
            xlinkHref="#c"
            fill="#FFF"
            fillRule="evenodd"
            clipRule="evenodd"
            overflow="visible"
          />
          <clipPath id="d">
            <use xlinkHref="#c" overflow="visible" />
          </clipPath>
          <ellipse
            cx={89.48}
            cy={89.911}
            fill={BASE_COLORS[color]}
            fillRule="evenodd"
            clipPath="url(#d)"
            clipRule="evenodd"
            rx={3.034}
            ry={3.289}
            transform="rotate(-88.155 89.482 89.91)"
          />
          <ellipse
            cx={89.472}
            cy={89.858}
            fill="#0E0708"
            fillRule="evenodd"
            clipPath="url(#d)"
            clipRule="evenodd"
            rx={1.422}
            ry={1.541}
            transform="rotate(-88.149 89.474 89.856)"
          />
          <path
            fill={darken(BASE_COLORS[color], 0.50)}
            fillRule="evenodd"
            d="M86.645 88.919c-.113.279-.18.58-.189.895-.05 1.542 1.266 2.835 2.936 2.89 1.671.055 3.066-1.153 3.117-2.695a2.579 2.579 0 0 0-.054-.612l-1.095-.19c.105.238.16.498.152.771-.033 1.034-.969 1.845-2.092 1.807-1.12-.035-2.004-.904-1.969-1.938a1.75 1.75 0 0 1 .313-.946l-1.119.018z"
            clipPath="url(#d)"
            clipRule="evenodd"
          />
          <ellipse
            cx={91.481}
            cy={89.301}
            fill="#FFF"
            fillRule="evenodd"
            clipPath="url(#d)"
            clipRule="evenodd"
            rx={0.642}
            ry={0.696}
            transform="rotate(-88.212 91.481 89.302)"
          />
          <ellipse
            cx={87.824}
            cy={91.043}
            fill={lighten(BASE_COLORS[color], 0.50)}
            fillRule="evenodd"
            clipPath="url(#d)"
            clipRule="evenodd"
            rx={0.868}
            ry={0.94}
            transform="rotate(-88.195 87.824 91.043)"
          />
          <ellipse
            cx={87.996}
            cy={90.915}
            fill="#FFF"
            fillRule="evenodd"
            clipPath="url(#d)"
            clipRule="evenodd"
            rx={0.431}
            ry={0.467}
            transform="rotate(-88.183 87.997 90.915)"
          />
          <path
            fill="#3C1A18"
            fillRule="evenodd"
            d="M86.296 87.883c.987-.426 2.538-.237 3.653-.221 1.421.021 2.309.094 3.02.25 2.05.447 4.122 2.048 5.864 3.167-1.653-.047-4.089-2.068-6.092-2.53-.609-.141-1.435-.229-2.752-.258-1.2-.025-2.51-.203-3.568.219-.386.156-1.427.725-2.335 1.391-1.294.95-2.497 2.044-2.693 1.994-.663-.174 4.055-3.646 4.903-4.012z"
            clipRule="evenodd"
          />
          <path
            fill="#0E0708"
            fillRule="evenodd"
            d="M86.349 88.287c.989-.424 2.497-.295 3.61-.279 1.473.02 2.243.083 2.839.234 1.317.335 4.579 2.023 5.727 2.761l-.245-.013c-1.208-.591-4.37-2.172-5.542-2.44-.609-.141-1.436-.229-2.749-.258-1.2-.027-2.51-.203-3.568.219-1.755.703-4.772 3.256-4.884 3.273 1.59-1.305 2.842-2.646 4.812-3.497z"
            clipRule="evenodd"
          />
          <path
            fill="#7E381E"
            fillRule="evenodd"
            d="M97.1 91.354c-.8.108-1.719.278-2.488.499-1.693.49-3.267 1.356-4.978 1.499-1.344.114-2.92-.206-4.301-.734-.707-.271-1.211-.447-1.648-.445-.749.006-1.34.489-1.889.327.623.014 1.106-.446 1.871-.453.521-.005 1.052.162 1.568.36.89.342 2.286.96 4.569.76 1.668-.147 3.165-1.012 4.763-1.509.627-.197 2.031-.496 2.533-.304z"
            clipRule="evenodd"
          />

        </>
      ) : null}

    </svg>
  );
};

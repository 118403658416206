import { useQuery } from '@tanstack/react-query';
import { getClubFinancialReport } from '../api';
import { FinancialReport } from '../types/clubs/FinancialReport';

export const useFinancialReport = () => {
  const { isLoading, error, data } = useQuery<FinancialReport, Error>({
    queryKey: ['user-club-financial-report'],
    queryFn: () => getClubFinancialReport(),
  });

  return { isLoading, error, data };
};

import { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import StadiumIcon from '@mui/icons-material/Stadium';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Logo } from '../Logo';
import { Account } from './Account';
import { LanguageSwitch } from '../LanguageSwitch';

export const Header = (): JSX.Element => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const pages = [
    { name: t('menu_club'), link: '/', icon: <HomeIcon /> },
    { name: t('menu_matches'), link: '/match/list', icon: <CalendarMonthIcon /> },
    { name: t('menu_players'), link: '/players/list', icon: <GroupsIcon /> },
    { name: t('menu_grassroots'), link: '/grassroots', icon: <GroupsIcon /> },
    { name: t('menu_coaches'), link: '/coaches', icon: <AssignmentIndIcon /> },
    { name: t('menu_training'), link: '/training', icon: <SportsHandballIcon /> },
    { name: t('menu_facilities'), link: '/facilities', icon: <StadiumIcon /> },
    { name: t('menu_transfer_market'), link: '/transfer-market', icon: <TransferWithinAStationIcon /> },
    { name: t('menu_competitions'), link: '/competitions', icon: <CalendarMonthIcon /> },
    { name: t('menu_finances'), link: '/finances', icon: <CurrencyExchangeIcon /> },
  ];

  const handleNavMenu = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar sx={{ height: 40 }}>
          <Logo
            type="yellow"
            width="50px"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
            wrapperWidth="auto"
          />
          <Logo
            type="yellow"
            width="50px"
            sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {pages.map((page) => (
              <Link to={page.link} style={{ textDecoration: 'none', color: 'inherit' }} key={page.link}>
                <Button variant="text" key={page.name} color="inherit">
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor="top"
              onOpen={() => setDrawerOpen(true)}
              onClose={() => setDrawerOpen(false)}
              open={drawerOpen}
            >
              <Box sx={{ width: 'auto' }} role="presentation">
                <List>
                  {pages.map((page) => (
                    <Link to={page.link} key={page.name} onClickCapture={() => setDrawerOpen(false)} style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {page.icon}
                          </ListItemIcon>
                          <ListItemText primary={page.name} />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Box>
            </SwipeableDrawer>
          </Box>
          <Account sx={{ display: { xs: 'none', md: 'flex' } }} />
          <LanguageSwitch />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

import { v4 } from 'uuid';

import { auth } from '../common';

export const authInterceptor = async (config: any) => {
  const token = await auth.currentUser?.getIdToken(true);

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

export const correlationIdInterceptor = (config: any) => ({
  ...config,
  headers: {
    ...config.headers,
    'X-Correlation-Id': v4(),
  },
});

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Sponsor } from '../types/clubs/Sponsor';
import { getAvailableSponsors, chooseSponsor as chooseSponsorRequest } from '../api';

export const useAvailableSponsors = () => {
  const { isFetching, error, data } = useQuery<Sponsor[], Error>({
    queryKey: ['sponsors-available'],
    queryFn: () => getAvailableSponsors(),
  });

  return { isFetching, error, data };
};

export const useChooseSponsor = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: chooseSponsor,
  } = useMutation({
    mutationFn: (data: any) => chooseSponsorRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-user-club'],
        exact: true,
      });
    },
  });

  return {
    isAssigningSponsor: isLoading,
    isError,
    chooseSponsor,
  };
};

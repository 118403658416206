import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Loader } from '../../common';
import { formatShortDate } from '../../common/formatter';
import { useChangePhysicalTraining } from '../../data/hooks/useTraining';
import { Intensity, PhysicalTrainingType } from '../../data/types/training/PhysicalTraining';
import { ChangePhysicalTrainingRequest } from '../../data/api/training';

type Props = {
  open: boolean;
  onClose: () => void;
  nextTrainingDate: string;
  currentTrainingType: PhysicalTrainingType;
  currentIntensity: Intensity;
};

const trainingTypes = [
  PhysicalTrainingType.GENERAL,
  PhysicalTrainingType.STRENGTH,
  PhysicalTrainingType.SPEED,
  PhysicalTrainingType.STAMINA,
  PhysicalTrainingType.FORM,
];

const intensityTypes = [
  Intensity.VERY_HIGH,
  Intensity.HIGH,
  Intensity.MEDIUM,
  Intensity.LOW,
  Intensity.VERY_LOW,
];

export const ChangePhysicalTrainingDialog: FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    nextTrainingDate,
    currentTrainingType,
    currentIntensity,
  } = props;
  const [trainingType, setTrainingType] = useState<PhysicalTrainingType>(currentTrainingType);
  const [intensity, setIntensity] = useState<Intensity>(currentIntensity);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateFn: changeTraining } = useChangePhysicalTraining();

  useEffect(() => {
    setTrainingType(currentTrainingType);
    setIntensity(currentIntensity);
  }, [currentTrainingType, currentIntensity]);

  const handleOnTrainingChange = (type: PhysicalTrainingType) => {
    setTrainingType(type);
  };

  const handleOnIntensityChange = (chosenIntensity: Intensity) => {
    setIntensity(chosenIntensity);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = () => {
    const request = {
      type: trainingType,
      intensity,
    } as ChangePhysicalTrainingRequest;

    changeTraining(request).then(() => {
      onClose();
      queryClient.fetchQuery({
        queryKey: ['training-get-overview'],
      });
      enqueueSnackbar(t('training_setup_successfully'), { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(t('error_while_setting_training'), { variant: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('change_physical_training')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="center" alignItems="center" sx={{ paddingTop: 1 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="training">{t('training')}</InputLabel>
            <Select
              id="training"
              label={t('training')}
              value={trainingType}
              onChange={(e) => handleOnTrainingChange(e.target.value as PhysicalTrainingType)}
            >
              {trainingTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <img src={`/assets/icons/training/${type.toLowerCase()}.svg`} alt={t(type.toLowerCase()) || 'Type'} width="70" height="50" />
                    <Typography variant="h6">{t(type.toLowerCase())}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="intensity">{t('intensity')}</InputLabel>
            <Select
              id="intensity"
              label={t('intensity')}
              value={intensity}
              onChange={(e) => handleOnIntensityChange(e.target.value as Intensity)}
            >
              {intensityTypes.map((intensityLevel) => (
                <MenuItem key={intensityLevel} value={intensityLevel}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <img
                      src={`/assets/icons/training/intensity/${intensityLevel.toLowerCase()}.svg`}
                      alt={t(`intensity_${intensityLevel.toLowerCase()}`) || 'Intensity'}
                      width="70"
                      height="50"
                    />
                    <Typography variant="h6">{t(`intensity_${intensityLevel.toLowerCase()}`)}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="h6">
            {`${t('next_training_will_be')}: ${formatShortDate(nextTrainingDate, i18n.language, false)}`}
          </Typography>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>{t('cancel')}</Button>
        <Button
          onClick={handleOnConfirm}
          variant="contained"
          color="primary"
        // disabled={!isValidNumber(shirtNumber)}
        >
          {isLoading ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { formatCurrency, formatShortDate } from '../../common/formatter';
import { Loader } from '../../common';
import { FacilityType } from '../../data/types/facilities/FacilityType';
import { useGetSocialClubReconstructEstimations, useReconstructFacility } from '../../data/hooks/useFacilities';
import { ApiError } from '../../data/types/ApiError';
import { QueryKeys } from '../../data/hooks/queryKeys';
import { Size } from '../../data/types/facilities/SocialClub';

type Props = {
  size?: Size;
  maintenanceCost: number;
  open: boolean;
  onClose: () => void;
};

const socialClubSizes = [
  Size.SMALL,
  Size.MEDIUM,
  Size.BIG,
  Size.ULTRA_BIG,
  Size.HUGE,
];

export const ReconstructSocialClubDialog: FC<Props> = (props: Props) => {
  const {
    size,
    maintenanceCost,
    open,
    onClose,
  } = props;
  const [newSize, setNewSize] = useState<Size>(size || Size.NONE);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const matchesXsOrSm = theme.breakpoints.down('md');
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading: isLoadingReconstruct,
    mutateFn: reconstructFacility,
  } = useReconstructFacility();
  const {
    isLoading: isLoadingReconstructionEstimation,
    data: reconstructionEstimations,
  } = useGetSocialClubReconstructEstimations(newSize);

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: [QueryKeys.GET_SOCIAL_CLUB_RECONSTRUCTION_COSTS, newSize],
    });
  }, [newSize, queryClient]);

  const handleOnFacilityLevelChange = (s: Size) => {
    setNewSize(s);
  };

  const handleOnClose = () => {
    setNewSize(size || Size.NONE);
    onClose();
  };

  const handleConfirmChangeFacility = () => {
    reconstructFacility({ type: FacilityType.SOCIAL_CLUB, size: newSize }).then(() => {
      enqueueSnackbar(t('facility_reconstruction_started'), { variant: 'success' });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_ALL_FACILITIES],
      });
    }).catch((data: AxiosError) => {
      const errorResponse = data.response?.data as ApiError;
      enqueueSnackbar(t(errorResponse.error || ''), { variant: 'error' });
    }).finally(() => {
      onClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {t('reconstruct_facility')}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="left" alignItems="left" sx={{ paddingTop: 1 }}>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_level')}</Typography>
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
              {t(size?.toLowerCase() || 'None')}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('current_maintenance_cost')}</Typography>
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
              {formatCurrency(maintenanceCost || 0, i18n.language)}
            </Typography>
          </Stack>

          <FormControl fullWidth>
            <InputLabel sx={{ mt: 1 }}>{t('select_new_level')}</InputLabel>
            <Select
              id="facility-level"
              label={t('select_new_level')}
              defaultValue={size}
              value={newSize || size}
              onChange={(e) => handleOnFacilityLevelChange(e.target.value as Size)}
              sx={{ mt: 1 }}
            >
              {socialClubSizes.map((s) => (
                <MenuItem key={s} value={s}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Box
                      component="img"
                      src={`/assets/icons/sizes/${s.toLowerCase()}.svg`}
                      alt={t(s.toLowerCase()) || 'Size'}
                      sx={{
                        width: matchesXsOrSm ? '55px' : '70px',
                        height: matchesXsOrSm ? '45px' : '50px',
                      }}
                    />
                    <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t(s.toLowerCase())}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('reconstruction_costs')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(reconstructionEstimations?.reconstructionCost || 0, i18n.language)}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('new_maintenance_cost')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} height="100%" />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {formatCurrency(reconstructionEstimations?.maintenanceCost || 0, i18n.language)}
              </Typography>
            )}

          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant={matchesXsOrSm ? 'body1' : 'h6'}>{t('reconstruction_finishes_on')}</Typography>
            {isLoadingReconstructionEstimation ? (
              <Skeleton variant="text" width={100} height="100%" />
            ) : (
              <Typography variant={matchesXsOrSm ? 'body1' : 'h6'} fontWeight="bold">
                {reconstructionEstimations?.finishesOn !== undefined && reconstructionEstimations?.finishesOn !== ''
                  ? formatShortDate(reconstructionEstimations.finishesOn, i18n.language, false)
                  : ''}
              </Typography>
            )}

          </Stack>

        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} disabled={false}>{t('cancel')}</Button>
        <Button
          onClick={handleConfirmChangeFacility}
          variant="contained"
          color="primary"
          disabled={
            isLoadingReconstructionEstimation
            || isLoadingReconstruct
            || newSize === size
            || newSize === undefined
          }
        >
          {isLoadingReconstructionEstimation || isLoadingReconstruct ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

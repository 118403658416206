import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { t } from 'i18next';
import { Bracket } from '../../data/types/seasons/Bracket';
import { MatchCard } from '../Match/MatchCard';
import { EmptyBracket } from './EmptyBracket';
import { Match, Status } from '../../data/types/seasons/Match';

type Props = {
  loading: boolean;
  brackets: Bracket[];
  loadingPlaceholders: number,
};

export const Brackets: FC<Props> = (props: Props) => {
  const { loading, loadingPlaceholders, brackets } = props;

  return (
    <Grid container spacing={2}>
      {loading && new Array(loadingPlaceholders).fill(0).map(() => (
        <Grid item xs={12} lg={6} key={Math.random()}>
          <MatchCard match={{} as Match} loading disableHeaderLoading />
        </Grid>
      )) }
      {brackets.map((bracket: Bracket) => (bracket?.match == null ? (
        <Grid item xs={12} md={6} key={Math.random()}>
          <EmptyBracket
            loading={loading}
            matchNumber={bracket?.number}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={6} key={bracket?.match.id}>
          <MatchCard
            match={bracket.match}
            loading={loading}
            link={bracket.match.status !== Status.SCHEDULED ? `/match/details/${bracket.match.id}` : '#'}
            header={(<Box>{`${t('match')} ${bracket?.number}`}</Box>)}
            disableHeaderLoading={false}
            key={Math.random()}
          />
        </Grid>
      )))}
    </Grid>
  );
};

import { Typography, Stack } from '@mui/material';

import { Face } from '../Face';
import { Player } from '../../data/types/players/Player';
import { EyeColor, HairColor } from './constants';

export const PlayerFaceName = ({ player }: { player?: Player }): JSX.Element => (
  <Stack direction="row" gap={1} alignItems="center">
    <Face
      body={player?.face.body || 0}
      hairStyle={player?.face?.hairStyle || 0}
      hairColor={player?.face?.hairColor || HairColor.BLACK}
      eyeShape={player?.face?.eyeShape || 0}
      eyeColor={player?.face?.eyeColor || EyeColor.BLACK}
      noseShape={player?.face?.noseShape || 0}
      mouthShape={player?.face?.mouthShape || 0}
      beard={player?.face?.beard || 0}
      eyebrows={player?.face?.eyebrows || 0}
      width={40}
      height={40}
    />
    <Typography>{player?.name}</Typography>
  </Stack>
);

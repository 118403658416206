import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { FC } from 'react';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { AttributeBar } from './AttributeBar';
import {
  AttributeType,
  getAggressivenessName,
  getAttributeName,
  getHonestyName,
  getTirednessName,
} from './attributes';

type Props = {
  player?: Player;
};

export const AttributesTab: FC<Props> = (props: Props) => {
  const { player } = props;
  const theme = useTheme();
  const matchesMdOrDown = useMediaQuery(theme.breakpoints.down('lg'));
  // const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Stack direction={matchesMdOrDown ? 'column' : 'row'} sx={{ mt: 1 }}>
        {/* First the psychological attributes */}
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('psychological_attributes')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('intelligence')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.intelligence || 0}
              levelName={getAttributeName(player?.psychologicalAttributes?.intelligence || 0)}
              type={AttributeType.PSYCHOLOGICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('aggressiveness')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.aggressiveness || 0}
              levelName={
                getAggressivenessName(player?.psychologicalAttributes?.aggressiveness || 0)
              }
              type={AttributeType.PSYCHOLOGICAL}
              inverted
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('honesty')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.honesty || 0}
              levelName={
                getHonestyName(player?.psychologicalAttributes?.honesty || 0)
              }
              type={AttributeType.PSYCHOLOGICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('leadership')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.leadership || 0}
              levelName={getAttributeName(player?.psychologicalAttributes?.leadership || 0)}
              type={AttributeType.PSYCHOLOGICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('experience')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.experience || 0}
              levelName={getAttributeName(player?.psychologicalAttributes?.experience || 0)}
              type={AttributeType.PSYCHOLOGICAL}
              isExperience
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('integration')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.integration || 0}
              levelName={getAttributeName(player?.psychologicalAttributes?.integration || 0)}
              type={AttributeType.PSYCHOLOGICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('discipline')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.discipline || 0}
              levelName={getAttributeName(player?.psychologicalAttributes?.discipline || 0)}
              type={AttributeType.PSYCHOLOGICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('morale')}</Typography>
            <AttributeBar
              number={player?.psychologicalAttributes?.morale || 0}
              levelName={getAttributeName(player?.psychologicalAttributes?.morale || 0)}
              type={AttributeType.PSYCHOLOGICAL}
            />
          </Grid>
        </Grid>

        {/* Physical attributes */}
        <Grid
          container
          columnSpacing={matchesMdOrDown ? 0 : 2}
          sx={{ ml: matchesMdOrDown ? 0 : 1 }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ paddingTop: matchesMdOrDown ? '1rem' : 0 }}>
              {t('physical_attributes')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('strength')}</Typography>
            <AttributeBar
              number={player?.physicalAttributes?.strength || 0}
              levelName={getAttributeName(player?.physicalAttributes?.strength || 0)}
              type={AttributeType.PHYSICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('stamina')}</Typography>
            <AttributeBar
              number={player?.physicalAttributes?.stamina || 0}
              levelName={getAttributeName(player?.physicalAttributes?.stamina || 0)}
              type={AttributeType.PHYSICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('speed')}</Typography>
            <AttributeBar
              number={player?.physicalAttributes?.speed || 0}
              levelName={getAttributeName(player?.physicalAttributes?.speed || 0)}
              type={AttributeType.PHYSICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('form')}</Typography>
            <AttributeBar
              number={player?.physicalAttributes?.form || 0}
              levelName={getAttributeName(player?.physicalAttributes?.form || 0)}
              type={AttributeType.PHYSICAL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline">{t('tiredness')}</Typography>
            <AttributeBar
              number={(player?.tiredness || 0)}
              levelName={getTirednessName((player?.tiredness || 0))}
              inverted
              type={AttributeType.PHYSICAL}
            />
          </Grid>
        </Grid>
      </Stack>

      {/* Technical attributes */}
      <Typography variant="h6" sx={{ paddingTop: '1rem' }}>
        {t('technical_attributes')}
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('reflexes')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.reflexes || 0}
            levelName={getAttributeName(player?.technicalAttributes?.reflexes || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('positioning')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.positioning || 0}
            levelName={getAttributeName(player?.technicalAttributes?.positioning || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">
            {t('aerial_playing')}
          </Typography>
          <AttributeBar
            number={player?.technicalAttributes?.aerialPlaying || 0}
            levelName={getAttributeName(player?.technicalAttributes?.aerialPlaying || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('tackling')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.tackling || 0}
            levelName={getAttributeName(player?.technicalAttributes?.tackling || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('shooting')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.shooting || 0}
            levelName={getAttributeName(player?.technicalAttributes?.shooting || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('creation')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.creation || 0}
            levelName={getAttributeName(player?.technicalAttributes?.creation || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('technique')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.technique || 0}
            levelName={getAttributeName(player?.technicalAttributes?.technique || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">{t('passing')}</Typography>
          <AttributeBar
            number={player?.technicalAttributes?.passing || 0}
            levelName={getAttributeName(player?.technicalAttributes?.passing || 0)}
            type={AttributeType.TECHNICAL}
          />
        </Grid>
      </Grid>
    </>
  );
};

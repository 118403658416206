import { AxiosResponse } from 'axios';
import { axiosInstance } from '../axios';
import { CreateClubRequest } from '../types';
import {
  ChangeCoachCosts,
  Coach,
  CoachLevel,
  CoachType,
  Coaches,
} from '../types/clubs/Coach';
import { FinancialReport } from '../types/clubs/FinancialReport';
import { Sponsor } from '../types/clubs/Sponsor';
import { Club } from '../types/clubs/Club';

export type ChangeCoachRequest = {
  type: CoachType;
  level: CoachLevel;

};

export const createClub = (data: CreateClubRequest): Promise<AxiosResponse> => (
  axiosInstance.post('/club/create', data)
);

export const getAvailableCountries = (): Promise<AxiosResponse> => (
  axiosInstance.get('/club/country/enabled-for-registration')
);

export const getUserClubInfo = (): Promise<Club> => (
  axiosInstance.get('/club/user').then((response) => response.data as Club)
);

export const getUserClubCoaches = (): Promise<Coaches> => (
  axiosInstance.get('/club/coaches/all')
    .then((response) => ({
      technical: response.data[CoachType.TECHNICAL],
      physical: response.data[CoachType.PHYSICAL],
      goalkeeper: response.data[CoachType.GOALKEEPER_COACH],
      grassroots: response.data[CoachType.GRASSROOTS_COACH],
    } as Coaches))
);

export const getClubFinancialReport = (): Promise<FinancialReport> => (
  axiosInstance.get('/club/finances/report').then((response) => response.data as FinancialReport)
);

export const getAvailableSponsors = (): Promise<Sponsor[]> => (
  axiosInstance.get('/club/sponsors/available').then((response) => response.data as Sponsor[])
);

export const chooseSponsor = (sponsorId: string): Promise<Sponsor> => (
  axiosInstance.patch(`/club/sponsors/assign/${sponsorId}`).then((response) => response.data as Sponsor)
);

export const getCoachChangeCosts = (
  type: CoachType,
  level: CoachLevel,
): Promise<ChangeCoachCosts> => (
  axiosInstance.get('/club/coaches/change-costs', { params: { type, level } })
    .then((response) => response.data as ChangeCoachCosts)
);

export const changeCoach = (data: ChangeCoachRequest): Promise<Coach> => (
  axiosInstance.patch('/club/coaches/change', data)
    .then((response) => response.data as Coach)
);

import { FC } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { t } from 'i18next';
import { KnockoutDetails } from '../../common/Knockout/KnockoutDetails';
import { Bracket } from '../../data/types/seasons/Bracket';
import { TopPlayers } from '../../common/Player/TopPlayers';
import { useNationalCupOverview } from '../../data/hooks/useNationalCupOverview';

export const NationalCupPage: FC = () => {
  const { isLoading, data: nationalCup } = useNationalCupOverview();

  // // Try to find the current club in the group and jump to that tab
  // if (!isLoading && groupTabIndex === -1 && (nationalCup?.groups?.length || 0) > 0) {
  //   nationalCup?.groups.forEach((group: Group) => {
  //     group.standings.forEach((standings: Standing) => {
  //       if (userContext?.clubId === standings?.club?.id) {
  //         setGroupTabIndex((group?.number || 0) - 1);
  //       }
  //     });
  //   });
  // }

  return (
    <Grid container spacing={2} sx={{ padding: 1, userSelect: 'none' }}>
      <Grid item xs={12} md={6}>
        <KnockoutDetails
          competitionName={nationalCup?.name || ''}
          firstPhaseBrackets={nationalCup?.firstPhaseBrackets || []}
          secondPhaseBrackets={nationalCup?.secondPhaseBrackets || []}
          thirdPhaseBrackets={nationalCup?.thirdPhaseBrackets || []}
          roundOf16Brackets={nationalCup?.roundOf16Brackets || []}
          quarterFinalsBrackets={nationalCup?.quarterFinalsBrackets || []}
          semiFinalsBrackets={nationalCup?.semiFinalsBrackets || []}
          finalBracket={nationalCup?.finalBracket || {} as Bracket}
          loading={isLoading}
          loadingPlaceholders={8}
        />

      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={t('top_scorers_and_top_assistants')}
            sx={{ paddingBottom: 0 }}
            titleTypographyProps={{
              variant: 'h6',
              textAlign: 'center',
            }}
          />
          <CardContent>
            <TopPlayers
              loading={isLoading}
              topScorers={nationalCup?.topScorers || []}
              topAssistants={nationalCup?.topAssistants || []}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

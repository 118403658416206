import { Grid } from '@mui/material';
import { FC } from 'react';
import { t } from 'i18next';
import { useCoaches } from '../../data/hooks/useCoaches';
import { CoachCard } from './CoachCard';
import { Coach, CoachType } from '../../data/types/clubs/Coach';

export const CoachesPage: FC = () => {
  const { isLoading, data: coaches } = useCoaches();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <CoachCard
          coach={coaches?.technical || { type: CoachType.TECHNICAL, salary: 0 } as Coach}
          loading={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CoachCard
          coach={coaches?.physical || { type: CoachType.PHYSICAL, salary: 0 } as Coach}
          loading={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CoachCard
          coach={coaches?.goalkeeper || { type: CoachType.GOALKEEPER_COACH, salary: 0 } as Coach}
          loading={isLoading}
          noCoachMessage={t('no_goalkeeper_coach')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CoachCard
          coach={coaches?.grassroots || { type: CoachType.GRASSROOTS_COACH, salary: 0 } as Coach}
          loading={isLoading}
          noCoachMessage={t('no_grassroots_coach')}
        />
      </Grid>
    </Grid>
  );
};

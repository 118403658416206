import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from '@mui/material';
import { FC, useState } from 'react';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useTrainingOverview } from '../../data/hooks/useTraining';
import { PhysicalTrainingOverview } from './PhysicalTrainingOverview';
import { TechnicalTrainingOverview } from './TechnicalTrainingOverview';
import { TechnicalTrainingHistory } from './TechnicalTrainingHistory';
import { PhysicalTrainingHistory } from './PhysicalTrainingHistory';
import { ChangeTechnicalTrainingDialog } from './ChangeTechnicalTrainingDialog';
import { ChangePhysicalTrainingDialog } from './ChangePhysicalTrainingDialog';
import { Intensity, PhysicalTrainingType } from '../../data/types/training/PhysicalTraining';
import { TechnicalTrainingType } from '../../data/types/training/TechnicalTraining';

export const TrainingPage: FC = () => {
  const { isFetching: isLoading, data: trainingOverview } = useTrainingOverview();
  const [changeTechnicalTrainingOpen, setChangeTechnicalTrainingOpen] = useState<boolean>(false);
  const [changePhysicalTrainingOpen, setChangePhysicalTrainingOpen] = useState<boolean>(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={t('technical_training_overview')}
            action={(
              <IconButton onClick={() => setChangeTechnicalTrainingOpen(true)}>
                <EditIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <Stack direction={{ xs: 'column', xl: 'row' }}>
              <Grid container spacing={2}>
                <TechnicalTrainingOverview
                  loading={isLoading}
                  isNextTraining
                  training={trainingOverview?.nextTechnicalTraining}
                />
                <TechnicalTrainingOverview
                  loading={isLoading}
                  training={trainingOverview?.previousTechnicalTrainings[0]}
                />
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={t('physical_training_overview')}
            action={(
              <IconButton onClick={() => setChangePhysicalTrainingOpen(true)}>
                <EditIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <Stack direction={{ xs: 'column', xl: 'row' }}>
              <Grid container spacing={2}>
                <PhysicalTrainingOverview
                  loading={isLoading}
                  isNextTraining
                  training={trainingOverview?.nextPhysicalTraining}
                />
                <PhysicalTrainingOverview
                  loading={isLoading}
                  training={trainingOverview?.previousPhysicalTrainings[0]}
                />
              </Grid>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <TechnicalTrainingHistory
          loading={isLoading}
          trainings={trainingOverview?.previousTechnicalTrainings}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhysicalTrainingHistory
          loading={isLoading}
          trainings={trainingOverview?.previousPhysicalTrainings}
        />
      </Grid>

      <ChangeTechnicalTrainingDialog
        open={changeTechnicalTrainingOpen}
        onClose={() => setChangeTechnicalTrainingOpen(false)}
        nextTrainingDate={trainingOverview?.nextTechnicalTraining?.date || ''}
        currentTrainingType={
          trainingOverview?.nextTechnicalTraining?.type || TechnicalTrainingType.AERIAL_PLAYING
        }
      />

      <ChangePhysicalTrainingDialog
        open={changePhysicalTrainingOpen}
        onClose={() => setChangePhysicalTrainingOpen(false)}
        nextTrainingDate={trainingOverview?.nextPhysicalTraining?.date || ''}
        currentTrainingType={
          trainingOverview?.nextPhysicalTraining?.type || PhysicalTrainingType.GENERAL
        }
        currentIntensity={trainingOverview?.nextPhysicalTraining?.intensity || Intensity.MEDIUM}
      />
    </Grid>
  );
};

import { useEffect, useState, useMemo } from 'react';
import { Container, Box } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import {
  Routes, Route, useNavigate, useLocation, Outlet,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';

import {
  auth, NotFoundPage, PageLoader, Header,
} from '../common';
import { Login } from './Login';
import { Dashboard } from './Dashboard';
import { Lineup } from './Match';
import { Theme } from './Theme';
import { UserContext, UserContextData } from '../context/UserContext';
import { PlayersPage } from './Players/PlayersPage';
import { MatchesListPage } from './Match/MatchesListPage';
import { LeagueOverviewPage } from './Competitions/LeagueOverviewPage';
import { RegionalTournamentPage } from './Competitions/RegionalTournamentPage';
import { NationalCupPage } from './Competitions/NationalCupPage';
import { CoachesPage } from './Coach/CoachesPage';
import { MatchDetailsPage } from './Match/MatchDetailsPage';
import { FacilitiesPage } from './Facilities/FacilitiesPage';
import { FinancesPage } from './Finances/FinancesPage';
import { TrainingPage } from './Training/TrainingPage';
import { GrassrootsPage } from './Grassroots/GrassrootsPage';
import { CountryCompetitionsPage } from './Competitions/CountryCompetitions';

const App = (): JSX.Element => {
  const [isInitialCheckInProgress, setIsInitialCheckInProgress] = useState<boolean>(true);
  const [userContextData, setUserContextData] = useState<UserContextData>({ clubId: '' });

  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = useMemo(() => location.pathname, [location]);
  const { t } = useTranslation();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsInitialCheckInProgress(false);
      if (user) {
        user.getIdTokenResult().then((token) => {
          setUserContextData({ clubId: token.claims.club_id });
          setIsInitialCheckInProgress(false);
        });
        navigate(currentLocation === '/login' ? '/' : currentLocation);
      } else {
        navigate('/login');
      }
    });
  }, [navigate, currentLocation]);

  return (
    <Theme>
      <SnackbarProvider autoHideDuration={3000}>
        <UserContext.Provider value={userContextData}>
          <Container sx={{ height: '100%' }} maxWidth={false} disableGutters>
            {
            isInitialCheckInProgress ? (
              <PageLoader message={t('app_initialization')} />
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={(
                    <>
                      <Header />
                      <Box sx={{ m: 1 }}>
                        <Outlet />
                      </Box>
                    </>
                  )}
                >
                  <Route index element={<Dashboard />} />
                  <Route path="match">
                    <Route
                      path="lineup/:id"
                      element={(
                        <Lineup />
                      )}
                    />
                    <Route path="list" element={<MatchesListPage />} />
                    <Route path="details/:id" element={<MatchDetailsPage />} />
                  </Route>
                  <Route path="/players/list" element={<PlayersPage />} />
                  <Route path="/league/:id" element={<LeagueOverviewPage />} />
                  <Route path="/regional-tournament/:id" element={<RegionalTournamentPage />} />
                  <Route path="/national-cup/:id" element={<NationalCupPage />} />
                  <Route path="/coaches" element={<CoachesPage />} />
                  <Route path="/facilities" element={<FacilitiesPage />} />
                  <Route path="/finances" element={<FinancesPage />} />
                  <Route path="/training" element={<TrainingPage />} />
                  <Route path="/grassroots" element={<GrassrootsPage />} />
                  <Route path="/competitions" element={<CountryCompetitionsPage />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            )
          }
          </Container>
        </UserContext.Provider>
      </SnackbarProvider>
    </Theme>
  );
};

export default App;

import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { useMatchesCurrentSeason } from '../../data/hooks';
import { MatchCard } from '../../common/Match/MatchCard';
import { Match } from '../../data/types/seasons/Match';
import { getCompetitionLink, getMatchLink } from '../../common/links';

export const MatchesListPage: FC = () => {
  const { isLoading, data: matches } = useMatchesCurrentSeason();

  return (
    <Grid container sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Grid item xs={0} lg={2} />
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          {isLoading && [...Array(20)].map(() => (
            <Grid item xs={12} lg={6} key={Math.random()}>
              <MatchCard
                match={{} as Match}
                loading={isLoading}
                link="#"
                disableHeaderLoading={false}
              />
            </Grid>
          ))}
          {matches?.map((match) => (
            <Grid item xs={12} lg={6} key={match.id}>
              <MatchCard
                match={match}
                loading={isLoading}
                header={(
                  <Link to={getCompetitionLink(match)} style={{ color: 'inherit' }}>
                    <Typography variant="body1" sx={{ float: ' left' }}>{match?.competitionName}</Typography>
                    <OpenInNewIcon sx={{ marginLeft: 0.5 }} />
                  </Link>
                )}
                disableHeaderLoading={false}
                link={getMatchLink(match)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={0} lg={2} />
    </Grid>
  );
};

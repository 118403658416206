import { FC } from 'react';

type Props = {
  shape: number;
};

export const Mouth: FC<Props> = (props: Props) => {
  const { shape } = props;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 150 200"
      enableBackground="new 0 0 150 200"
      xmlSpace="preserve"
      style={{
        position: 'absolute',
        top: 0,
      }}
    >
      {shape === 1 ? (
        <g fillRule="evenodd" clipRule="evenodd">
          <path
            fill="#E8A477"
            d="M60.741 122.991c1.631.866 3.513 3.179 4.955 4.206 1.279.911 2.482 1.658 3.613 1.837.879.14 2.866.022 4.645-.045 1.184-.045 3.703.177 4.53.045.84-.133 1.72-.579 2.639-1.173 1.72-1.106 3.924-3.806 5.93-4.87-5.627 1.161-9.285.198-12.75.32-3.754.133-7.307 1.244-13.562-.32z"
          />
          <path
            fill="#EDAC82"
            d="M61.14 123.367c1.633.865 3.17 2.103 4.613 3.129 1.278.912 2.483 1.658 3.613 1.838.879.141 2.865.022 4.645-.045 1.184-.047 3.704.177 4.532.045.838-.133 1.718-.579 2.638-1.172 1.718-1.108 3.58-2.731 5.586-3.795l-3.861-.026c-6.166-.041-11.737-.041-17.903 0l-3.863.026z"
          />
          <path
            fill="#E7A77E"
            d="M61.14 123.367c1.633.865 3.17 2.103 4.613 3.129 1.278.91 2.483 1.658 3.613 1.838.879.141 2.865.022 4.645-.045 1.184-.047 3.704.177 4.532.045.838-.133 1.718-.579 2.638-1.172.468-.302.945-.641 1.433-.998-1.299.83-2.463 1.588-3.803 1.852-1.414.278-2.363-.262-4.768-.174-2.136.077-5.891.649-6.054-1.656-.075-1.071.578-1.6 1.736-1.889.932-.234 2.232.25 4.233.209.658-.013 1.863-.523 3.089-.508 3.763.05 5.521.365 9.281-.683l-3.423.025c-6.166.045-11.737-.041-17.903 0l-3.862.027z"
          />
          <path
            fill="#EDAC82"
            d="M77.876 121.003c2.935.651 4.853 2.063 9.365 2.063-3.962 1.021-6.154.943-9.733.666-1.019-.08-2.303.258-3.583.257-1.265-.001-2.528-.344-3.525-.266-3.647.282-5.844.377-9.848-.657 4.729 0 6.609-1.629 9.795-2.145 1.629-.263 2.33-.276 3.55.731 1.322-1.09 2.226-1.038 3.979-.649z"
          />
          <path
            fill="#E7A77E"
            d="M84.336 122.842c.849.138 1.799.224 2.905.224-3.962 1.021-6.154.943-9.733.666-1.019-.078-2.303.258-3.583.257-1.265-.001-2.528-.344-3.525-.266-3.647.282-5.844.377-9.848-.657 4.729 0 6.609-1.629 9.795-2.145 1.237-.2 1.938-.255 2.738.17l-.028.014a4.792 4.792 0 0 0-2.946.452c-.642.313-1 .848-.616 1.148.072.056.902-.021 1.928.107 1.145.142 2.507.481 2.763.468.438-.023 1.556-.398 3.089-.388 3.801.023 4.806.642 7.061-.05z"
          />
          <path
            fill="#DF9F77"
            d="M85.85 122.947c.436.051.898.078 1.392.078-3.962 1.066-6.154.983-9.733.694-1.019-.081-2.303.27-3.583.269-1.265-.001-2.528-.357-3.525-.277-3.647.295-5.844.392-9.848-.686.495 0 .957-.027 1.394-.078 3.168.707 5.252.6 8.454.342.998-.08 2.26.275 3.525.276 1.28.001 2.564-.349 3.583-.268 3.136.253 5.208.348 8.341-.35z"
          />
          <path
            fill="#F0B188"
            d="M77.467 121.297c2.463.544 4.901 1.431 6.744 1.633-2.152.356-3.635-.101-6.64-.33-.853-.066-2.664.478-3.35.478-1.28-.001-1.875-.331-2.509-.422-.511-.072-1.529.034-1.732-.275-.125-.188-.177-.691 1.052-1.025 1.326-.363 2.012-.007 2.979.514 1.11-.918 1.983-.898 3.456-.573zM84.769 124.056c-3.032.974-5.577.174-8.319.443-1.527.151-1.9.405-2.667.468-.416.034-1.203.012-2.175-.113-.933-.123-1.653-.133-2.142.302-.995.881-.437 2.43 1.403 2.43 1.508 0 2.116-.246 3.091-.312.659-.043 1.317.041 2.167.155 2.868.388 3.361.122 5.573-1.348 1.326-.882 3.052-2.025 3.069-2.025z"
          />
        </g>
      ) : null}

      {shape === 2 ? (
        <>
          <path
            fill="#D9A292"
            d="M64.918 123.717c.945.156 2.657-1.934 5.793-2.275 1.254-.137 2.293.232 3.166.242 2.053.021 3.222-.686 4.74-.137 1.822.658 2.992 2.057 4.543 2.299 0 0-.063 1.793-3.629 2.68-1.289.32-3.186.414-5.493.359-2.509-.061-4.163-.289-5.491-.721-3.005-.982-3.629-2.447-3.629-2.447z"
          />
          <path
            fill="#B17767"
            d="M65.763 123.936s.268-.018.712-.072c.452-.049 1.061-.186 1.822-.336.385-.068.803-.16 1.326-.162.127-.004.267.01.407.023l.37.061c.223.035.407.055.657.078.943.074 1.979.123 3.004.135.511.012 1.024.018 1.521-.004.488-.021.99-.037 1.369-.121.203-.039.449-.119.752-.148.273-.033.545-.025.791-.008.492.037.904.125 1.281.197.754.139 1.379.258 1.83.314.449.061.709.1.709.1s-1.063.086-2.648-.053c-.391-.029-.811-.07-1.217-.078-.201.002-.4.01-.586.049-.166.027-.357.09-.617.162-.549.135-1.068.182-1.605.211-.534.027-1.068.027-1.603.025-1.069-.025-2.126-.086-3.12-.223-.236-.033-.525-.072-.759-.131l-.297-.074c-.087-.014-.174-.031-.271-.035-.38-.025-.801.016-1.186.045-.771.078-1.453.104-1.916.098-.471-.012-.726-.053-.726-.053z"
          />
        </>
      ) : null}

      {shape === 3 ? (
        <>
          <path
            fill="#D9A292"
            d="M65.184 122.988c.914.107 2.566-1.301 5.596-1.529 1.211-.094 2.213.154 3.059.162 1.98.014 3.111-.461 4.578-.094 1.761.445 2.892 1.387 4.388 1.549 0 0-1.18 1.574-4.624 2.172-1.246.215-1.754-.035-3.981-.072-2.423-.041-3.108.119-4.392-.172-2.903-.662-4.624-2.016-4.624-2.016z"
          />
          <path
            fill="#B17767"
            d="M66 123.137s.261-.01.688-.051c.436-.031 1.025-.123 1.759-.223.372-.047.776-.109 1.282-.109.123-.002.258.006.393.014l.359.043c.213.021.393.035.633.051.911.051 1.91.084 2.902.092.493.006.989.012 1.469-.002.471-.016.957-.027 1.322-.08.195-.029.434-.084.725-.104.266-.021.527-.016.766-.004.476.025.872.084 1.237.131.729.096 1.332.176 1.768.213l.686.068s-1.025.057-2.557-.035c-.377-.021-.785-.049-1.177-.055a4.136 4.136 0 0 0-.566.035c-.16.018-.344.059-.596.107-.531.092-1.033.123-1.551.143-.516.018-1.031.018-1.548.018a38.762 38.762 0 0 1-3.015-.152 9.261 9.261 0 0 1-.73-.086l-.288-.051c-.084-.008-.168-.02-.262-.023-.365-.018-.773.01-1.144.029-.747.053-1.404.07-1.853.066-.455-.008-.702-.035-.702-.035z"
          />
        </>
      ) : null}

      {shape === 4 ? (
        <>
          <path
            fill="#AF8162"
            fillRule="evenodd"
            d="M60.741 122.991c1.631.866 3.513 3.179 4.955 4.206 1.279.911 2.482 1.658 3.613 1.837.879.14 2.866.022 4.645-.045 1.184-.045 3.703.177 4.53.045.84-.133 1.72-.579 2.639-1.173 1.72-1.106 3.924-3.806 5.93-4.87-5.627 1.161-9.285.198-12.75.32-3.754.133-7.307 1.244-13.562-.32z"
            clipRule="evenodd"
          />
          <path
            fill="#C08C6F"
            fillRule="evenodd"
            d="M61.14 123.367c1.633.865 3.17 2.103 4.613 3.129 1.278.912 2.483 1.658 3.613 1.838.879.141 2.865.022 4.645-.045 1.184-.047 3.704.177 4.532.045.838-.133 1.718-.579 2.638-1.172 1.718-1.108 3.58-2.731 5.586-3.795l-3.861-.026c-6.166-.041-11.737-.041-17.903 0l-3.863.026z"
            clipRule="evenodd"
          />
          <path
            fill="#A77D65"
            fillRule="evenodd"
            d="M61.14 123.367c1.633.865 3.17 2.103 4.613 3.129 1.278.91 2.483 1.658 3.613 1.838.879.141 2.865.022 4.645-.045 1.184-.047 3.704.177 4.532.045.838-.133 1.718-.579 2.638-1.172.468-.302.945-.641 1.433-.998-1.299.83-2.463 1.588-3.803 1.852-1.414.278-2.363-.262-4.768-.174-2.136.077-5.891.649-6.054-1.656-.075-1.071.578-1.6 1.736-1.889.932-.234 2.232.25 4.233.209.658-.013 1.863-.523 3.089-.508 3.763.05 5.521.365 9.281-.683l-3.423.025c-6.166.045-11.737-.041-17.903 0l-3.862.027z"
            clipRule="evenodd"
          />
          <path
            fill="#BB8A71"
            fillRule="evenodd"
            d="M77.876 121.003c2.935.651 4.853 2.063 9.365 2.063-3.962 1.021-6.154.943-9.733.666-1.019-.08-2.303.258-3.583.257-1.265-.001-2.528-.344-3.525-.266-3.647.282-5.844.377-9.848-.657 4.729 0 6.609-1.629 9.795-2.145 1.629-.263 2.33-.276 3.55.731 1.322-1.09 2.226-1.038 3.979-.649z"
            clipRule="evenodd"
          />
          <path
            fill="#B2856D"
            fillRule="evenodd"
            d="M84.336 122.842c.849.138 1.799.224 2.905.224-3.962 1.021-6.154.943-9.733.666-1.019-.078-2.303.258-3.583.257-1.265-.001-2.528-.344-3.525-.266-3.647.282-5.844.377-9.848-.657 4.729 0 6.609-1.629 9.795-2.145 1.237-.2 1.938-.255 2.738.17l-.028.014a4.792 4.792 0 0 0-2.946.452c-.642.313-1 .848-.616 1.148.072.056.902-.021 1.928.107 1.145.142 2.507.481 2.763.468.438-.023 1.556-.398 3.089-.388 3.801.023 4.806.642 7.061-.05z"
            clipRule="evenodd"
          />
          <path
            fill="#AD8066"
            fillRule="evenodd"
            d="M85.85 122.947c.436.051.898.078 1.392.078-3.962 1.066-6.154.983-9.733.694-1.019-.081-2.303.27-3.583.269-1.265-.001-2.528-.357-3.525-.277-3.647.295-5.844.392-9.848-.686.495 0 .957-.027 1.394-.078 3.168.707 5.252.6 8.454.342.998-.08 2.26.275 3.525.276 1.28.001 2.564-.349 3.583-.268 3.136.253 5.208.348 8.341-.35z"
            clipRule="evenodd"
          />
          <path
            fill="#CB9A79"
            fillRule="evenodd"
            d="M77.467 121.297c2.463.544 4.901 1.431 6.744 1.633-2.152.356-3.635-.101-6.64-.33-.853-.066-2.664.478-3.35.478-1.28-.001-1.875-.331-2.509-.422-.511-.072-1.529.034-1.732-.275-.125-.188-.177-.691 1.052-1.025 1.326-.363 2.012-.007 2.979.514 1.11-.918 1.983-.898 3.456-.573z"
            clipRule="evenodd"
          />
          <path
            fill="#BE9374"
            fillRule="evenodd"
            d="M84.769 124.056c-3.032.974-5.577.174-8.319.443-1.527.151-1.9.405-2.667.468-.416.034-1.203.012-2.175-.113-.933-.123-1.653-.133-2.142.302-.995.881-.437 2.43 1.403 2.43 1.508 0 2.116-.246 3.091-.312.659-.043 1.317.041 2.167.155 2.868.388 3.361.122 5.573-1.348 1.326-.882 3.052-2.025 3.069-2.025z"
            clipRule="evenodd"
          />
        </>
      ) : null}
    </svg>
  );
};

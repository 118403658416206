import { FC } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { Stats } from './Stats';

type Props = {
  player?: Player,
};

export const SuspensionsTab: FC<Props> = (props: Props) => {
  const { player } = props;

  return (
    <Grid container spacing={1} sx={{ padding: 1 }}>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={(player?.suspensions?.league ? t('yes') : t('no')) || ''}
          icon="/assets/icons/red-card.svg"
          description={t('league')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={(player?.suspensions?.regionalTournament ? t('yes') : t('no')) || ''}
          icon="/assets/icons/red-card.svg"
          description={t('regional_tournament')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={(player?.suspensions?.nationalCup ? t('yes') : t('no')) || ''}
          icon="/assets/icons/red-card.svg"
          description={t('national_cup')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={(player?.suspensions?.internationalCup ? t('yes') : t('no')) || ''}
          icon="/assets/icons/red-card.svg"
          description={t('international_cup')}
        />
      </Grid>
    </Grid>
  );
};

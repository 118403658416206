import { t } from 'i18next';

export enum AttributeType {
  PSYCHOLOGICAL = 'PSYCHOLOGICAL',
  PHYSICAL = 'PHYSICAL',
  TECHNICAL = 'TECHNICAL',
}

export const getAttributeName = (value: number): string => {
  switch (value) {
    case 0:
    case 1:
      return t('player_attribute_terrible');
    case 2:
      return t('player_attribute_awful');
    case 3:
      return t('player_attribute_bad');
    case 4:
      return t('player_attribute_weak');
    case 5:
      return t('player_attribute_reasonable');
    case 6:
      return t('player_attribute_good');
    case 7:
      return t('player_attribute_very_good');
    case 8:
      return t('player_attribute_excellent');
    case 9:
      return t('player_attribute_formidable');
    case 10:
      return t('player_attribute_phenomenal');
    case 11:
      return t('player_attribute_incredible');
    case 12:
      return t('player_attribute_brilliant');
    case 13:
      return t('player_attribute_magnificent');
    case 14:
      return t('player_attribute_glorious');
    case 15:
      return t('player_attribute_legendary');
    default:
      return t('player_attribute_divine');
  }
};

export const getAggressivenessName = (value: number) => {
  if (value <= 1) {
    return t('attribute_aggressiveness_pacific');
  }

  if (value >= 2 && value <= 3) {
    return t('attribute_aggressiveness_calm');
  }

  if (value >= 4 && value <= 5) {
    return t('attribute_aggressiveness_temperamental');
  }

  if (value >= 6 && value <= 7) {
    return t('attribute_aggressiveness_irritable');
  }

  return t('attribute_aggressiveness_aggressive');
};

export const getHonestyName = (value: number) => {
  if (value <= 1) {
    return t('attribute_honesty_infamous');
  }

  if (value >= 2 && value <= 3) {
    return t('attribute_honesty_dishonest');
  }

  if (value >= 4 && value <= 5) {
    return t('attribute_honesty_decent');
  }

  if (value >= 6 && value <= 7) {
    return t('attribute_honesty_honest');
  }

  return t('attribute_honesty_incorrupt');
};

export const getTirednessName = (value: number) => {
  if (value === 0) {
    return t('attribute_tiredness_rested');
  }

  if (value >= 1 && value <= 2) {
    return t('attribute_tiredness_light');
  }

  if (value >= 3 && value <= 4) {
    return t('attribute_tiredness_regular');
  }

  if (value >= 5 && value <= 7) {
    return t('attribute_tiredness_heavy');
  }

  return t('attribute_tiredness_risky');
};

import { useQuery } from '@tanstack/react-query';
import { CountryCompetitions } from '../types/seasons/CountryCompetition';
import { QueryKeys } from './queryKeys';
import { getCountryCompetitions } from '../api';

export const useCountryCompetitions = (countryId: string) => {
  const { isFetching, error, data } = useQuery<CountryCompetitions, Error>({
    queryKey: [QueryKeys.GET_COUNTRY_COMPETITIONS, countryId],
    queryFn: () => getCountryCompetitions(countryId),
  });

  return { isFetching, error, data };
};

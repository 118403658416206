import { useState } from 'react';
import {
  Grid, Button, Box, IconButton, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Edit, Delete } from '@mui/icons-material';

import { useLineupState, useLineupActions } from '../../../store';
import { AddSubstitutionDialog } from './AddSubstitutionDialog';
import { ProgrammedSubs } from '../../../data/types/match';
import { usePlayers } from '../../../data/hooks';
import { PlayerFaceName } from '../../../common';
import { MatchType } from '../../../data/types/seasons/Match';

const MAX_SUBS = 3;

export const ProgrammedSubstitution = (
  { matchType }: { matchType: MatchType },
): JSX.Element => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSubs, setSelectedSubs] = useState<ProgrammedSubs & { index: number }>({
    atMinute: 0,
    inPlayerId: '',
    outPlayerId: '',
    index: -1,
  });
  const { programmedSubstitutions } = useLineupState();
  const { removeSubstitution } = useLineupActions();
  const { data: players } = usePlayers();

  const handleAddSubsSection = () => {
    setSelectedSubs({
      atMinute: 0,
      inPlayerId: '',
      outPlayerId: '',
      index: -1,
    });
    setIsDialogOpen(true);
  };

  return (
    <>
      <Grid container direction="column" gap={2} wrap="nowrap">
        <Grid item>
          <Typography variant="h5">{t('substitutions_setup_explanation')}</Typography>
        </Grid>
        {
          programmedSubstitutions?.map((sub, i) => (
            <Grid item key={sub.atMinute} container alignItems="center" gap={2}>
              <Grid item>
                <Typography>{sub.atMinute}</Typography>
              </Grid>
              <Grid item xs>
                <PlayerFaceName
                  player={players?.find((p) => p.id === sub.outPlayerId)}
                />
              </Grid>
              <Grid item xs>
                <PlayerFaceName
                  player={players?.find((p) => p.id === sub.inPlayerId)}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectedSubs({
                      atMinute: sub.atMinute,
                      inPlayerId: sub.inPlayerId,
                      outPlayerId: sub.outPlayerId,
                      index: i,
                    });
                    setIsDialogOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    removeSubstitution(i);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))
        }
        {
          // If already existing sections and added ones are bigger then allowed => stop
          (programmedSubstitutions?.length) >= MAX_SUBS ? (null) : (
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={handleAddSubsSection}
                  sx={{ width: '100%' }}
                >
                  {t('add_substitution')}
                </Button>
              </Box>
            </Grid>
          )
        }
      </Grid>
      <AddSubstitutionDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        atMinute={selectedSubs.atMinute}
        inPlayerId={selectedSubs.inPlayerId}
        outPlayerId={selectedSubs.outPlayerId}
        index={selectedSubs.index}
        matchType={matchType}
      />
    </>
  );
};

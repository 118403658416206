import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TrainingOverview } from '../types/training/TrainingOverview';
import {
  ChangePhysicalTrainingRequest,
  changePhysicalTraining,
  changeTechnicalTraining,
  getTrainingOverview,
} from '../api/training';
import { TechnicalTrainingType } from '../types/training/TechnicalTraining';

export const useTrainingOverview = () => {
  const { isFetching, error, data } = useQuery<TrainingOverview, Error>({
    queryKey: ['training-get-overview'],
    queryFn: getTrainingOverview,
  });

  return { isFetching, error, data };
};

export const useChangeTechnicalTraining = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (type: TechnicalTrainingType) => changeTechnicalTraining(type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['training-get-overview'],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useChangePhysicalTraining = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (type: ChangePhysicalTrainingRequest) => changePhysicalTraining(type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['training-get-overview'],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrassrootsAcademy } from './GrassrootsAcademy';
import { AvailablePlayers } from './AvailablePlayers';
import { PlayersList } from '../../common/Player/PlayersList';
import { Player } from '../../data/types';
import { useHiredGrassrootsPlayers } from '../../data/hooks/useGrassroots';
import { PlayerDetails } from '../../common/Player';

export const GrassrootsPage: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | undefined>();
  const [selectedPlayerId, setSelectedPlayerId] = useState<string | undefined>();
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { isLoading, players } = useHiredGrassrootsPlayers();

  if (isLoading && selectedPlayer !== undefined) {
    // If we are loading the players again, remove the selected player
    setSelectedPlayer(undefined);
  } else if (
    !isLoading
    && selectedPlayer === undefined
    && !selectedPlayerId
    && players !== undefined
    && players.length > 0
  ) {
    // We are done loading things and we don't have a selected player, so we select the first one
    setSelectedPlayer(players[0]);
    setSelectedPlayerId(players[0]?.id);
  } else if (
    !isLoading
    && selectedPlayer === undefined
    && selectedPlayerId
    && players !== undefined
    && players.length > 0
  ) {
    // We are done loading things and we have a selected player from before, so we select it
    const player = players.find((p) => p.id === selectedPlayerId);

    // We need this check because the player might have been fired
    if (player) {
      setSelectedPlayer(player);
      setSelectedPlayerId(player?.id);
    } else {
      setSelectedPlayer(players[0]);
      setSelectedPlayerId(players[0].id);
    }
  }

  const handleOnPlayerClick = (player: Player) => {
    setSelectedPlayer(player);
    setSelectedPlayerId(player.id);
    setDrawerOpen(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5} xl={4}>
        <GrassrootsAcademy />
      </Grid>
      <Grid item xs={12} lg={7} xl={8}>
        <AvailablePlayers />
      </Grid>
      <Grid
        item
        xs={12}
        md={selectedPlayer !== undefined ? 6 : 12}
        lg={selectedPlayer !== undefined ? 4 : 12}
      >
        <Card variant="outlined">
          <CardHeader title={<Typography variant="h6">{t('hired_players')}</Typography>} />
          <CardContent>
            <PlayersList
              players={players || []}
              loading={isLoading}
              onPlayerClick={handleOnPlayerClick}
            />
          </CardContent>
        </Card>
      </Grid>
      {matchesXsOrSm ? (
        <SwipeableDrawer
          anchor="bottom"
          onOpen={() => { }}
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          disableBackdropTransition
          sx={{
            '& .MuiPaper-root': {
              overflowY: 'scroll',
            },
          }}
        >
          <PlayerDetails
            player={selectedPlayer}
            isGrassroots
            closeDrawerCallback={() => {
              setDrawerOpen(false);
            }}
          />
        </SwipeableDrawer>
      ) : (
        <Grid item xs={12} md={6} lg={8}>
          {selectedPlayer !== undefined ? (
            <PlayerDetails isGrassroots player={selectedPlayer || undefined} />
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Commentary, MatchDetails, MoveResult } from '../../data/types/match/MatchDetails';
import { Stats } from './Stats';
import { formatCommentary } from '../../common/Commentaries/commentaries';

type Props = {
  match: MatchDetails;
  commentaries: Commentary[];
  loading: boolean;
};

const resultsWithIcons = [
  MoveResult.GOAL,
  MoveResult.YELLOW_CARD,
  MoveResult.RED_CARD,
  MoveResult.SECOND_YELLOW_CARD,
  MoveResult.OFFSIDE,
  MoveResult.FOULED,
  MoveResult.SUBSTITUTION,
];

const sortCommentaries = (c1: Commentary, c2: Commentary) => c1.atMinute - c2.atMinute;

export const StatsAndCommentaries: FC<Props> = (props: Props) => {
  const { match, commentaries, loading } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    swiper?.slideTo(newValue);
  };
  const commentariesEnd = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (commentariesEnd.current && tabIndex === 1) {
      commentariesEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [commentariesEnd, commentaries, tabIndex]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Tabs
          value={tabIndex}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          sx={{ paddingBottom: 1 }}
        >
          <Tab label={t('statistics')} />
          <Tab label={t('commentaries')} />
        </Tabs>
        <Swiper
          autoHeight
          slidesPerView={1}
          onSwiper={(swiperInstance: SwiperClass) => setSwiper(swiperInstance)}
          onSlideChange={(swiperInstance: SwiperClass) => setTabIndex(swiperInstance.activeIndex)}
        >
          <SwiperSlide>
            <List>
              <ListItem>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'left',
                    width: '50%',
                  }}
                  spacing={1}
                >
                  {loading ? (
                    <>
                      <Skeleton variant="circular" width="30px" height="30px" />
                      <Skeleton variant="rounded" width="100%" />
                    </>
                  ) : (
                    <>
                      <img src="/assets/crest.png" alt="Crest" width="30" />

                      <Typography variant="body1" noWrap>
                        {matchesXsOrSm ? match?.homeClub?.abbreviation : match?.homeClub?.name}
                      </Typography>
                    </>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'right',
                    width: '50%',
                    marginLeft: 1,
                  }}
                  spacing={1}
                >
                  {loading ? (
                    <>
                      <Skeleton variant="rounded" width="95%" />
                      <Skeleton variant="circular" width="30px" height="30px" />
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" noWrap>
                        {matchesXsOrSm ? match?.awayClub?.abbreviation : match?.awayClub?.name}
                      </Typography>
                      <img src="/assets/crest.png" alt="Crest" width="30" />
                    </>
                  )}
                </Stack>
              </ListItem>
              <ListItem>
                <Stats
                  name={t('ball_possession') || ''}
                  homeStat={match?.statistics?.home?.possession || 0}
                  awayStat={match?.statistics?.away?.possession || 0}
                  statSuffix="%"
                  loading={loading}
                />
              </ListItem>
              <ListItem>
                <Stats
                  name={t('goal_chances') || ''}
                  homeStat={match?.statistics?.home?.goalChances || 0}
                  awayStat={match?.statistics?.away?.goalChances || 0}
                  loading={loading}
                />
              </ListItem>
              <ListItem>
                <Stats
                  name={t('corners') || ''}
                  homeStat={match?.statistics?.home?.corners || 0}
                  awayStat={match?.statistics?.away?.corners || 0}
                  loading={loading}
                />
              </ListItem>
              <ListItem>
                <Stats
                  name={t('committed_fouls') || ''}
                  homeStat={match?.statistics?.home?.fouls || 0}
                  awayStat={match?.statistics?.away?.fouls || 0}
                  loading={loading}
                />
              </ListItem>
              <ListItem>
                <Stats
                  name={t('yellow_cards') || ''}
                  homeStat={match?.statistics?.home?.yellowCards || 0}
                  awayStat={match?.statistics?.away?.yellowCards || 0}
                  loading={loading}
                />
              </ListItem>
              <ListItem>
                <Stats
                  name={t('red_cards') || ''}
                  homeStat={match?.statistics?.home?.redCards || 0}
                  awayStat={match?.statistics?.away?.redCards || 0}
                  loading={loading}
                />
              </ListItem>
              <ListItem>
                <Stats
                  name={t('offsides') || ''}
                  homeStat={match?.statistics?.home?.offsides || 0}
                  awayStat={match?.statistics?.away?.offsides || 0}
                  loading={loading}
                />
              </ListItem>
            </List>
          </SwiperSlide>
          <SwiperSlide>
            {loading ? (
              [...Array(10)].map(() => (
                <Box
                  sx={{
                    height: '50px',
                    my: 0,
                    mx: 1,
                  }}
                  key={Math.random()}
                >
                  <Skeleton variant="rounded" height={42} width="100%" />
                </Box>
              ))
            ) : null}
            <List sx={{ maxHeight: '505px', overflowX: 'auto' }}>
              {commentaries?.sort(sortCommentaries).map((commentary, index) => (
                <ListItem key={commentary.id} sx={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#eee' }}>

                  <Stack direction="row" spacing={1}>
                    {commentary.result === MoveResult.GOAL ? (
                      <img src="/assets/icons/ball.svg" alt="goal" width="20px" />
                    ) : null}

                    {commentary.result === MoveResult.YELLOW_CARD ? (
                      <img src="/assets/icons/yellow-card.svg" alt="yellow card" width="20px" />
                    ) : null}

                    {
                      commentary.result === MoveResult.SECOND_YELLOW_CARD
                        || commentary?.result === MoveResult.RED_CARD
                        ? (<img src="/assets/icons/red-card.svg" alt="red card" width="20px" />)
                        : null
                    }

                    {commentary.result === MoveResult.OFFSIDE ? (
                      <img src="/assets/icons/match/offside.jpg" alt="offside" width="20px" />
                    ) : null}

                    {commentary.result === MoveResult.FOULED ? (
                      <img src="/assets/icons/match/foul.svg" alt="offside" width="20px" />
                    ) : null}

                    {!resultsWithIcons.includes(commentary.result) ? (<img src="/assets/icons/match/empty.png" alt="" />) : null}
                    <Typography variant="body2">
                      {formatCommentary(commentary)}
                    </Typography>
                    <Box component="div" id="commentaries-end" ref={commentariesEnd} />
                  </Stack>
                </ListItem>
              ))}
            </List>

          </SwiperSlide>
        </Swiper>

      </CardContent>
    </Card>
  );
};

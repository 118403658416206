import { Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  name: string;
  homeStat: number;
  awayStat: number;
  statSuffix?: string;
  loading: boolean;
};

export const Stats: FC<Props> = (props: Props) => {
  const {
    name,
    homeStat,
    awayStat,
    statSuffix,
    loading,
  } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="body1" sx={{ width: '15%', textAlign: 'left' }}>
        {loading ? (<Skeleton variant="rounded" />) : `${homeStat}${statSuffix || ''}`}
      </Typography>
      <Typography variant="body1" sx={{ width: '70%', textAlign: 'center' }} noWrap>
        {loading ? (<Skeleton variant="rounded" />) : name}
      </Typography>
      <Typography variant="body1" sx={{ width: '15%', textAlign: 'right' }}>
        {loading ? (<Skeleton variant="rounded" />) : `${awayStat}${statSuffix || ''}`}
      </Typography>
    </Stack>
  );
};

import {
  useMutation, useQuery, useQueryClient,
} from '@tanstack/react-query';

import {
  createLineup as createLineupAPI,
  getLineup as getLineupAPI,
} from '../api';
import { formations, QueryKeys } from '../../constants';
import { Player } from '../types/players/Player';
import { LineupType } from '../types/match';

/**
 * Generate squad structure for SoccerLineup component because it expects specific structure.
 * This is the only place which generates the structure - for matches which already is set and
 * for new match setup
 * @param formation
 * @param players
 * @param onPositionClick
 * @returns
 */
export const generateSquadStructure = (
  formation: string,
  players?: Partial<Player>[],
  onPositionClick?: any,
) => {
  // first we find proper squad structure based on formation
  const formationSquad = formations.find((f) => f.value === formation)?.squad ?? {};

  // now we create a squad structure which SoccerLineup component expect
  const sq: any = {};
  players?.forEach((p) => {
    if (p.position === 'GOALKEEPER') {
      sq.gk = {
        id: p.id,
        number: p.shirtNumber,
        name: p.name,
        positionName: p.position,
        color: 'green',
        onClick: (e: any) => onPositionClick(e, {
          position: 'GOALKEEPER',
        }),
      };
    }
  });

  // In case goalkeeper was not found in the players list
  // for this match => make it an empty position
  if (!sq?.gk) {
    sq.gk = {
      color: '#d6cb65',
      onClick: (e: any) => onPositionClick(e, {
        position: 'GOALKEEPER',
      }),
    };
  }

  Object.keys(formationSquad).forEach((k) => {
    if (k === 'gk') {
      return;
    }
    // @ts-expect-error
    sq[k] = formationSquad[k].map((position) => {
      // find player for this position
      const player = players?.find((p) => p.position === position);
      // if player was found - generate position mark setup
      if (player) {
        return {
          id: player?.id,
          number: player?.shirtNumber,
          positionName: position,
          name: player?.name,
          color: 'green',
          onClick: (e: any) => onPositionClick(e, {
            position,
          }),
        };
      }

      // in any other case - use default empty setup for position mark
      return ({
        positionName: position,
        onClick: (e: any) => onPositionClick(e, {
          position,
        }),
      });
    });
  });

  return sq;
};

type LineupResponse = {
  isLoading: boolean;
  isError: boolean;
  data?: Omit<LineupType, 'benchPlayers'> & {
    benchPlayers: { id: string }[]
  };
};

export const useLineup = (matchId?: string): LineupResponse => {
  const { isLoading, isError, data } = useQuery({
    queryKey: [QueryKeys.LINEUP, matchId],
    queryFn: () => getLineupAPI(matchId),
    enabled: !!matchId,
  });

  return {
    isLoading, isError, data,
  };
};

export const useCreateLineup = () => {
  const queryClient = useQueryClient();
  const { isLoading, isError, mutateAsync: createLineup } = useMutation({
    mutationFn: (data: any) => createLineupAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.LINEUP],
        exact: false,
      });
    },
  });

  return {
    isCreating: isLoading, isError, createLineup,
  };
};

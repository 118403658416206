import { FC, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { LineUpPosition, Player } from '../../data/types/match/MatchDetails';
import { PlayerStats } from './PlayerStats';
import { CompositeMatchPlayer } from './CompositeMatchPlayer';
import { MatchPlayer } from './MatchPlayer';

type Props = {
  lineUpPlayers: Player[];
  benchPlayers: Player[];
  homeClub: boolean;
  loading: boolean;
  clubName: string;
};

const positions = Object.keys(LineUpPosition);
const sortPlayer = (p1: Player, p2: Player) => positions.indexOf(p1.lineupPosition)
  - positions.indexOf(p2.lineupPosition);

export const MatchClubPlayers: FC<Props> = (props: Props) => {
  const {
    lineUpPlayers, benchPlayers, homeClub, loading, clubName,
  } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedPlayer, setSelectedPlayer] = useState<Player>(lineUpPlayers[0]);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const drawerPosition = homeClub ? 'left' : 'right';

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    swiper?.slideTo(newValue);
  };

  const handlePlayerSelected = (player: Player) => {
    setSelectedPlayer(player);
    setDrawerOpen(true);
  };

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={loading ? (
            <Skeleton variant="rounded" width="100%" />
          ) : (
            <Stack
              direction="row"
              spacing={1}
              sx={{

                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src="/assets/crest.png" alt="Crest" width="30" />
              <Typography variant="h6">{clubName}</Typography>
            </Stack>
          )}
          sx={{ paddingBottom: 0 }}
        />
        <CardContent>
          <Tabs
            value={tabIndex}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
            sx={{ paddingBottom: 1 }}
          >
            <Tab label={t('lineup')} />
            <Tab label={t('bench')} />
          </Tabs>
          <Swiper
            slidesPerView={1}
            onSwiper={(swiperInstance: SwiperClass) => setSwiper(swiperInstance)}
            onSlideChange={(swiperInstance: SwiperClass) => setTabIndex(swiperInstance.activeIndex)}
          >
            <SwiperSlide>
              <List>
                {loading && new Array(11).fill(0).map((_, index) => (
                  <ListItem
                    key={Math.random()}
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'transparent' : '#eee',
                    }}
                  >
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Skeleton variant="circular" width="45px" height="45px" />
                      <Skeleton variant="circular" width="45px" height="45px" />
                      <Skeleton variant="rounded" width="130px" />
                      <Skeleton variant="rounded" width="50px" />
                      <Skeleton variant="rounded" width="50px" />
                    </Stack>
                  </ListItem>
                ))}
                {!loading && lineUpPlayers.length === 0 ? (
                  <ListItem>
                    <Typography
                      variant="h6"
                      textAlign="center"
                      sx={{ width: '100%' }}
                    >
                      {t('no_players')}

                    </Typography>
                  </ListItem>
                ) : null}
                {lineUpPlayers.sort(sortPlayer).map((player, index) => (
                  <CompositeMatchPlayer
                    key={player.id}
                    player={player}
                    index={index}
                    onClick={handlePlayerSelected}
                    lineUpPlayers={lineUpPlayers}
                    firstEleven
                  />
                ))}
              </List>
            </SwiperSlide>
            <SwiperSlide>
              <List>
                {benchPlayers.sort(sortPlayer).map((player, index) => (
                  <ListItem
                    key={player.id}
                    divider
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'transparent' : '#eee',
                      cursor: 'pointer',
                    }}
                    onClick={() => handlePlayerSelected(player)}
                  >
                    <MatchPlayer player={player} />
                  </ListItem>
                ))}
                {!loading && benchPlayers.length === 0 ? (
                  <ListItem>
                    <Typography
                      variant="h6"
                      textAlign="center"
                      sx={{ width: '100%' }}
                    >
                      {t('no_players')}

                    </Typography>
                  </ListItem>
                ) : null}
              </List>
            </SwiperSlide>
          </Swiper>

        </CardContent>
      </Card>
      <PlayerStats
        player={selectedPlayer}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        drawerPosition={drawerPosition}
      />
    </>
  );
};

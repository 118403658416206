import { FC } from 'react';
import {
  Box, Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { LineUpPosition, Player } from '../../data/types/match/MatchDetails';
import { Face, Shirt } from '../../common/Player';
import { EnergyBar } from './EnergyBar';

type Props = {
  player: Player;
  firstEleven?: boolean;
};

export const MatchPlayer: FC<Props> = (props: Props) => {
  const { player, firstEleven } = props;

  const theme = useTheme();
  // const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLgOrLow = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Grid
      container
      alignItems="center"
      spacing={0.5}
      sx={{
        opacity: (player.playing && firstEleven) || (!player.playing && !firstEleven) ? 1 : 0.5,
      }}
    >
      <Grid item xs={2} md={1.5}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            width: '100%',
            userSelect: 'none',
          }}
        >
          {player.substituted ? (
            <Box
              component="img"
              src="/assets/icons/left-match.svg"
              alt="left match"
              sx={{
                width: 20,
              }}
            />
          ) : null}
          <Box sx={{ width: '35px' }}>
            <Shirt number={player?.shirtNumber} />
          </Box>
          {!matchesLgOrLow ? (
            <Face
              body={player.face?.body}
              hairStyle={player.face?.hairStyle}
              hairColor={player.face?.hairColor}
              eyeShape={player.face?.eyeShape}
              eyeColor={player.face?.eyeColor}
              noseShape={player.face?.noseShape}
              mouthShape={player.face?.mouthShape}
              beard={player.face?.beard}
              eyebrows={player.face?.eyebrows}
              width={40}
              height={40}
              sx={{
                float: 'right',
              }}
            />
          ) : null}
        </Stack>
      </Grid>
      <Grid item xs={3} md={3.5}>
        <Typography variant="body1" noWrap>
          {matchesLgOrLow ? player?.name.split(' ')[0] : player?.name}
        </Typography>
      </Grid>

      <Grid item xs={2} md={1} alignItems="center">
        {player?.lineupPosition !== LineUpPosition.UNKNOWN && player.lineupPosition !== null
          ? (
            <Typography variant="body2">
              {t(`${player.lineupPosition?.toLowerCase()}_short`)}
            </Typography>
          )
          : null}
      </Grid>

      <Grid item xs={2} md={1.5}>
        <EnergyBar energy={player?.energy || 0} width="100%" />
      </Grid>

      <Grid item xs={3} md={4.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {
            player?.performance > 0 ? (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <img src="/assets/icons/rank.svg" alt="performance" width="20" />
                <Typography variant="body1" fontWeight="bold">{player?.performance}</Typography>
              </Stack>
            )
              : null
          }
          {player.enteredDuringMatch ? (<img src="/assets/icons/entered-match.svg" alt="goals" width="20" />) : null}
          {
            player?.stats?.goalsScored > 0
              ? (
                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={0.5}>
                  <img src="/assets/icons/ball.svg" alt="goals" width="20" />
                  <Typography variant="body1">{player?.stats?.goalsScored}</Typography>
                </Stack>
              )
              : null
          }
          {
            player.stats?.assistsGiven > 0 ? (
              <Stack direction="row" sx={{ alignItems: 'center' }} spacing={0.5}>
                <img src="/assets/icons/cleats.svg" alt="assists" width="20" />
                <Typography variant="body1">{player?.stats?.assistsGiven}</Typography>
              </Stack>
            ) : null
          }
          {
            player.stats?.redCards > 0 || player.sentOff ? (
              <img src="/assets/icons/red-card.svg" alt="red cards" width="20" />
            ) : null
          }
          {
            player.stats?.yellowCards > 0 && (player.stats?.redCards === 0 || !player.sentOff) ? (
              <img src="/assets/icons/yellow-card.svg" alt="yellow card" width="20" />
            ) : null
          }
          {
            player.injured && !player.substituted ? (
              <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                <Box
                  component="img"
                  src="/assets/icons/injury.svg"
                  alt=""
                  sx={{
                    width: 20,
                  }}
                />
                <Box
                  component="img"
                  src="/assets/icons/left-match.svg"
                  alt="left match"
                  sx={{
                    width: 20,
                  }}
                />
              </Stack>
            ) : null
          }

          {
            player.injured && player.substituted ? (
              <Box
                component="img"
                src="/assets/icons/injury.svg"
                alt=""
                sx={{
                  width: 20,
                }}
              />
            ) : null
          }
        </Stack>
      </Grid>
    </Grid>
  );
};

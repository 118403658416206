import { FC, useState } from 'react';
import {
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { t } from 'i18next';
import { TopAssistant } from '../../data/types/seasons/TopAssistant';
import { TopScorer } from '../../data/types/seasons/TopScorer';
import { Face } from '../Face/Face';

type Props = {
  loading: boolean;
  topScorers: TopScorer[];
  topAssistants: TopAssistant[];
};

export const TopPlayers: FC<Props> = (props: Props) => {
  const { topScorers, topAssistants, loading } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const theme = useTheme();
  const matchesXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    swiper?.slideTo(newValue);
  };

  return (
    <>
      <Tabs
        value={tabIndex}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleTabChange}
      >
        <Tab label={t('top_scorers')} />
        <Tab label={t('top_assistants')} />
      </Tabs>
      <Swiper
        slidesPerView={1}
        onSwiper={(swiperInstance: SwiperClass) => setSwiper(swiperInstance)}
        onSlideChange={(swiperInstance: SwiperClass) => setTabIndex(swiperInstance.activeIndex)}
      >
        <SwiperSlide>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell align="center">{t('goals_scored')}</TableCell>
                  <TableCell align="center">{t('club')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { loading ? new Array(10).fill(0).map(() => (
                  <TableRow key={Math.random()}>
                    <TableCell>
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Skeleton variant="rounded" width="200px" height="20px" sx={{ marginLeft: 1 }} />
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Skeleton variant="rounded" width="50px" height="20px" sx={{ marginLeft: 1 }} />
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Skeleton variant="rounded" width="200px" height="20px" sx={{ marginLeft: 1 }} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                  : topScorers?.map((topScorer: TopScorer) => (
                    <TableRow key={topScorer?.player?.id}>
                      <TableCell>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                          {!matchesXsOnly ? (
                            <Face
                              body={topScorer?.player?.face?.body}
                              hairStyle={topScorer?.player?.face?.hairStyle}
                              hairColor={topScorer?.player?.face?.hairColor}
                              eyeShape={topScorer?.player?.face?.eyeShape}
                              eyeColor={topScorer?.player?.face?.eyeColor}
                              noseShape={topScorer?.player?.face?.noseShape}
                              mouthShape={topScorer?.player?.face?.mouthShape}
                              beard={topScorer?.player?.face?.beard}
                              eyebrows={topScorer?.player?.face?.eyebrows}
                              width={40}
                              height={40}
                              sx={{
                                float: 'right',
                              }}
                            />
                          ) : null}
                          <Typography variant="body1" sx={{ marginLeft: 1 }}>
                            {topScorer?.player?.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                          <img
                            src="/assets/icons/ball.svg"
                            width="25"
                            height="25"
                            alt="Ball"
                            style={{
                              float: 'left',
                            }}
                          />
                          <Typography variant="h6" sx={{ marginLeft: 1 }}>
                            {topScorer?.goalsScored}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                          <img src="/assets/crest.png" alt={topScorer?.club?.name} width="30" />
                          <Typography variant="body1" sx={{ marginLeft: 1 }}>{topScorer?.club?.name}</Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && topScorers?.length === 0 ? (
            <Typography variant="h3" sx={{ textAlign: 'center', padding: 1 }}>
              {t('top_scorers_not_available')}
            </Typography>
          ) : null}
        </SwiperSlide>
        <SwiperSlide>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell align="center">{t('assists_given')}</TableCell>
                  <TableCell align="center">{t('club')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { loading ? new Array(10).fill(0).map(() => (
                  <TableRow key={Math.random()}>
                    <TableCell>
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Skeleton variant="rounded" width="200px" height="20px" sx={{ marginLeft: 1 }} />
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Skeleton variant="rounded" width="50px" height="20px" sx={{ marginLeft: 1 }} />
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Skeleton variant="circular" width="40px" height="40px" />
                        <Skeleton variant="rounded" width="200px" height="20px" sx={{ marginLeft: 1 }} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                  : topAssistants?.map((topAssistant: TopAssistant) => (
                    <TableRow key={topAssistant?.player?.id}>
                      <TableCell>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                          {!matchesXsOnly ? (
                            <Face
                              body={topAssistant?.player?.face?.body}
                              hairStyle={topAssistant?.player?.face?.hairStyle}
                              hairColor={topAssistant?.player?.face?.hairColor}
                              eyeShape={topAssistant?.player?.face?.eyeShape}
                              eyeColor={topAssistant?.player?.face?.eyeColor}
                              noseShape={topAssistant?.player?.face?.noseShape}
                              mouthShape={topAssistant?.player?.face?.mouthShape}
                              beard={topAssistant?.player?.face?.beard}
                              eyebrows={topAssistant?.player?.face?.eyebrows}
                              width={40}
                              height={40}
                              sx={{
                                float: 'right',
                              }}
                            />
                          ) : null}
                          <Typography variant="body1" sx={{ marginLeft: 1 }}>{topAssistant?.player?.name}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                          <img
                            src="/assets/icons/cleats.svg"
                            width="30"
                            height="30"
                            alt="Cleats"
                            style={{
                              float: 'left',
                            }}
                          />
                          <Typography variant="h6" sx={{ marginLeft: 1 }}>{topAssistant?.assistsGiven}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                          <img src="/assets/crest.png" alt={topAssistant?.club?.name} width="30" />
                          <Typography variant="body1" sx={{ marginLeft: 1 }}>{topAssistant?.club?.name}</Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && topAssistants?.length === 0 ? (
            <Typography variant="h3" sx={{ textAlign: 'center', padding: 1 }}>
              {t('top_assistants_not_available')}
            </Typography>
          ) : null}
        </SwiperSlide>
      </Swiper>
    </>
  );
};

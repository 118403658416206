import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart } from '@mui/x-charts';
import { formatCurrency, formatShortDate } from '../../common/formatter';

type Props = {
  loading: boolean;
  currentBalance?: number;
  balanceOneWeekAgo?: number;
  dates?: string[];
  amounts?: number[];
};

export const BalanceCard: FC<Props> = (props: Props) => {
  const {
    loading,
    currentBalance,
    balanceOneWeekAgo,
    dates,
    amounts,
  } = props;
  // const matchesLgAndDown = useMediaQuery(theme.breakpoints.down('lg'));
  const { i18n } = useTranslation();

  return (
    <Card variant="outlined">
      <CardHeader title={t('balance')} />
      <CardContent>
        <Stack direction="column">
          <Grid container spacing={2}>
            {loading ? (
              <Grid item xs={12} lg={6}>
                <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
                <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
              </Grid>
            ) : (
              <Grid item xs={12} lg={6}>
                <Typography variant="h5">
                  {t('club_info.current_money_amount')}
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: (currentBalance || 0) > 0 ? 'green' : 'red' }}
                >
                  {formatCurrency(currentBalance || 0, i18n.language)}
                </Typography>
              </Grid>
            )}

            {loading ? (
              <Grid item xs={12} lg={6}>
                <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
                <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
              </Grid>
            ) : (
              <Grid item xs={12} lg={6} justifyItems={{ xs: 'start', xl: 'end' }}>
                <Typography variant="h6" sx={{ textAlign: { xs: 'left', xl: 'right' } }}>
                  {t('balance_one_week_ago')}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: { xs: 'left', xl: 'right' },
                    color: (balanceOneWeekAgo || 0) > 0 ? 'green' : 'red',
                  }}
                >
                  {formatCurrency(balanceOneWeekAgo || 0, i18n.language)}
                </Typography>
              </Grid>
            )}
          </Grid>

          {loading ? (
            <Skeleton variant="rectangular" sx={{ height: 385, mt: 2 }} />
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>{t('balance_over_last_four_weeks')}</Typography>
              <LineChart
                xAxis={[{
                  scaleType: 'utc',
                  data: dates?.map((date) => new Date(date)) || [],
                  valueFormatter: (value) => formatShortDate(value.toISOString(), i18n.language),
                  disableTicks: true,
                  disableLine: true,
                }]}
                series={[
                  {
                    data: amounts,
                    valueFormatter: (value) => formatCurrency(value, i18n.language),
                  },
                ]}
                // width={800}
                height={385}
              />
            </Grid>
          )}

        </Stack>
      </CardContent>
    </Card>
  );
};

import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

export const GlobalStyles = () => (
  <MUIGlobalStyles
    styles={`
    html {
      height: 100%;
    }

    body {
      height: 100%;
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    #root {
      height: 100%;
    }

    /* Remove generally arrows and spinners from text fields */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .swiper-horizontal {
      width: 100%;
    }

    @font-face {
      font-family: 'Credit Card';
      src: local('Credit Card'), url(/assets/fonts/credit-card.ttf) format('truetype');
      /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    }

    @font-face {
      font-family: 'Space Mono';
      src: local('Space Mono'), url(/assets/fonts/SpaceMono-Regular.ttf) format('truetype');
      /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    }

    // #field-wrapper {
    //   height: 115vw;
    //   padding: 10vw;

    //   .pitch {
    //     transform: rotate(-90deg);
    //     height: 45vw;
    //     width: 130vw;
    //     left: -29vw;
    //     top: 14vw;
    //   }
    // }
  `}
  />
);

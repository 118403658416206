import { Box, SxProps } from '@mui/material';
import { FC } from 'react';
import { EyeColor, HairColor } from '../Player/constants';
import { Beard } from './Beard';
import { Base } from './Base';
import { Uniform } from './Uniform';
import { Hair } from './Hair';
import { Eyebrows } from './Eyebrows';
import { Eyes } from './Eyes';
import { Mouth } from './Mouth';
import { Nose } from './Nose';

type Props = {
  body: number;
  hairStyle: number;
  hairColor: HairColor;
  eyeShape: number;
  eyeColor: EyeColor;
  noseShape: number;
  mouthShape: number;
  beard: number;
  eyebrows: number;
  width: number;
  height: number;
  sx?: SxProps;
};

export const Face: FC<Props> = (props: Props) => {
  const {
    body,
    hairStyle,
    hairColor,
    eyeShape,
    eyeColor,
    noseShape,
    mouthShape,
    beard,
    eyebrows,
    width,
    height,
    sx,
  } = props;

  return (
    <Box sx={{
      overflow: 'hidden',
      width: `${width + 10}px`,
      height: `${height + 2}px`,
      ...sx,
    }}
    >

      <Box sx={{
        position: 'relative',
        float: 'left',
        borderRadius: '25rem',
        border: '1px solid',
        backgroundImage: 'url(/assets/player-bg.jpeg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        overflow: 'hidden',
        width: `${width}px`,
        height: `${height}px`,
      }}
      >
        <Box sx={{
          width: `${width}px`,
          height: `${height}px`,
          transform: 'scale(1.25)',
        }}
        >
          <Base type={body} />
          <Uniform />
          {hairStyle > 0 ? (<Hair type={hairStyle} color={hairColor} />) : null}
          {beard > 0 ? (<Beard type={beard} color={hairColor} />) : null}
          <Eyebrows type={eyebrows} color={hairColor} />
          <Eyes shape={eyeShape} color={eyeColor} />
          <Nose shape={noseShape} />
          <Mouth shape={mouthShape} />
        </Box>
      </Box>
    </Box>
  );
};

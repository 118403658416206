import { FC, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MoreVertOutlined } from '@mui/icons-material';
import { Coach } from '../../data/types/clubs/Coach';
import { Face } from '../../common/Player';
import { formatCurrency } from '../../common/formatter';
import { ChangeCoachDialog } from './ChangeCoachDialog';

type Props = {
  coach: Coach;
  loading: boolean;
  noCoachMessage?: string | null;
};

const coachTypeIcons = {
  TECHNICAL: '/assets/icons/technical-coach.png',
  PHYSICAL: '/assets/icons/physical-coach.png',
  GOALKEEPER_COACH: '/assets/icons/goalkeeper-coach.png',
  GRASSROOTS_COACH: '/assets/icons/grassroots-coach.png',
};

export const CoachCard: FC<Props> = (props: Props) => {
  const { coach, loading, noCoachMessage } = props;
  const [optionsMenuOpen, setOptionMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const matchesXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  const { i18n } = useTranslation();
  const [openCoachDialog, setOpenCoachDialog] = useState<boolean>(false);

  const handleCloseMenu = () => {
    setOptionMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOptionMenuOpen(true);
  };

  const handleOpenChangeCoachDialog = () => {
    setOpenCoachDialog(true);
    handleCloseMenu();
  };

  return (
    <Card variant="outlined">
      {loading ? (
        <CardHeader title={(<Skeleton variant="rounded" width="50%" />)} />
      ) : (
        <CardHeader
          title={coach?.name ? `${coach?.name}, ${coach?.age} ${!matchesXsOrSm ? t('years_old') : ''}` : ''}
          action={coach?.id ? (
            <>
              <IconButton
                aria-label="settings"
                onClick={handleOpenMenu}
                aria-controls={optionsMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsMenuOpen ? 'true' : undefined}
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={optionsMenuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleOpenChangeCoachDialog}>{t('change_coach')}</MenuItem>
              </Menu>
            </>
          ) : null}
        />
      )}

      {loading ? (
        <CardContent>
          <Stack
            spacing={2}
            direction={matchesXsOrSm ? 'column' : 'row'}
            sx={{
              alignItems: 'center',
              justifyContent: matchesXsOrSm ? 'center' : 'left',
            }}
          >
            <Skeleton variant="circular" width="120px" height="120px" />
            <Stack sx={{ width: '40%' }} spacing={2}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Skeleton variant="rounded" width="35px" height="35px" />
                <Skeleton variant="rounded" width="100%" />
              </Stack>
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
            </Stack>
          </Stack>
        </CardContent>
      ) : (
        <CardContent>
          {coach?.id === undefined ? (
            <Stack spacing={2}>
              <Typography variant="h5" textAlign="center" noWrap>{noCoachMessage}</Typography>
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                onClick={() => setOpenCoachDialog(true)}
              >
                {t('hire_new_coach')}
              </Button>
            </Stack>
          ) : (
            <Stack
              spacing={2}
              direction={matchesXsOrSm ? 'column' : 'row'}
              sx={{
                alignItems: 'center',
                justifyContent: matchesXsOrSm ? 'center' : 'left',
              }}
            >
              <Face
                body={coach?.face?.body}
                hairStyle={coach?.face?.hairStyle}
                hairColor={coach?.face?.hairColor}
                eyeShape={coach?.face?.eyeShape}
                eyeColor={coach?.face?.eyeColor}
                noseShape={coach?.face?.noseShape}
                mouthShape={coach?.face?.mouthShape}
                beard={coach?.face?.beard}
                eyebrows={coach?.face?.eyebrows}
                width={120}
                height={120}
              />
              <Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: 'center',
                    justifyContent: matchesXsOrSm ? 'center' : 'left',
                  }}
                >
                  <img src={coachTypeIcons[coach?.type]} alt={t(coach?.type.toLowerCase()) || ''} width="35" />
                  <Typography variant="h6" fontWeight="bold">{t(coach?.type.toLowerCase())}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    justifyContent: matchesXsOrSm ? 'center' : 'left',
                  }}
                >
                  <Typography variant="h6">{`${t('coach_level')}:`}</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <img
                      src={`/assets/icons/levels/${coach?.level.toLowerCase()}.svg`}
                      alt={t(coach?.level.toLowerCase()) || 'Type'}
                      width="35"
                    />
                    <Typography variant="h6" fontWeight="bold">{t(coach?.level.toLowerCase())}</Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    justifyContent: matchesXsOrSm ? 'center' : 'left',
                  }}
                >
                  <Typography variant="h6">{`${t('salary_per_week')}:`}</Typography>
                  <Typography variant="h6" fontWeight="bold">{formatCurrency(coach?.salary || 0, i18n.language)}</Typography>
                </Stack>
              </Stack>
            </Stack>
          )}

        </CardContent>
      )}

      <ChangeCoachDialog
        type={coach.type}
        coach={coach}
        open={openCoachDialog}
        onClose={() => setOpenCoachDialog(false)}
      />
    </Card>
  );
};

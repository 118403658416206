import { FC, MouseEvent, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { auth, Loader } from '../../common';
import { FacebookLogin } from './FacebookLogin';
import { GoogleLogin } from './GoogleLogin';

type Props = {
  onError: (code: string | undefined) => void;
  goToSignup: () => void;
};

export const LoginForm: FC<Props> = ({ onError, goToSignup }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const { t } = useTranslation();

  const onLogin = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsInProgress(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsInProgress(false);
      })
      .catch((err) => {
        onError(err?.code);
        setIsInProgress(false);
      });
  };

  return (
    <Grid
      item
      container
      direction="column"
      gap={2}
      justifyContent="center"
      sx={{
        maxWidth: 400,
      }}
    >
      <Grid item>
        <TextField
          required
          label={t('email')}
          variant="outlined"
          value={email}
          fullWidth
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label={t('password')}
          variant="outlined"
          value={password}
          fullWidth
          onChange={(e: any) => setPassword(e.target.value)}
          InputProps={{
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  // color="warning"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => {}}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined /> // sx={{ fill: '#ffcd00' }} />
                  ) : (
                    <VisibilityOutlined /> // sx={{ fill: '#ffcd00' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Button
              onClick={goToSignup}
              variant="text"
              disabled={isInProgress}
            >
              {t('register_now')}
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              onClick={onLogin}
              disabled={!email || !password || isInProgress}
              variant="contained"
              data-testid="login-form-login-btn"
            >
              { isInProgress
                ? (<Loader variant="small" color="#000E59" />)
                : t('login')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ textAlign: 'center', /* color: '#FFCD00', */textTransform: 'uppercase' }}>
        {t('or')}
      </Grid>
      <Grid item>
        <FacebookLogin />
      </Grid>
      <Grid item>
        <GoogleLogin />
      </Grid>
    </Grid>
  );
};

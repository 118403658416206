import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const SUPPORTED_LANGUAGES = ['en-GB', 'en-US', 'pt-BR'];

const detectLanguage = (): string => {
  const localeRegEx = /(^[a-zA-Z\-A-Z]{5,5})/;
  let lng: string = SUPPORTED_LANGUAGES[0];

  if (navigator.language) {
    const localeCheck = navigator.language.match(localeRegEx);

    if (localeCheck) {
      const localeLng = localeCheck[0];
      lng = SUPPORTED_LANGUAGES.includes(localeLng) ? localeLng : lng;
    }
  }

  return lng;
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: detectLanguage(),
    fallbackLng: 'en-GB',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: SUPPORTED_LANGUAGES,
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FC, useState } from 'react';
import { MoreVertOutlined } from '@mui/icons-material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatShortDate } from '../../common/formatter';
import { Level } from '../../data/types/facilities/Level';
import { Status } from '../../data/types/facilities/Status';
import { ReconstructionType } from '../../data/types/facilities/ReconstructionType';
import { FacilityType } from '../../data/types/facilities/FacilityType';
import { ReconstructFacilityDialog } from './ReconstructFacilityDialog';

type Props = {
  type: FacilityType.GRASSROOTS_ACADEMY
  | FacilityType.GYM
  | FacilityType.TRAINING_CENTER
  | FacilityType.MEDICAL_DEPARTMENT;

  loading: boolean;
  level?: Level;
  newLevel?: Level;
  totalCapacity?: number;
  currentOccupation?: number;
  status: Status;
  maintenanceCost: number;
  underMaintenanceUntil: string;
  reconstructionType: ReconstructionType;
  noFacilityMessage: string;
};

const facilityTypeIcons = {
  TRAINING_CENTER: {
    NONE: '/assets/facilities/no-facility.svg',
    BAD: '/assets/facilities/training-center/1.svg',
    WEAK: '/assets/facilities/training-center/2.svg',
    REGULAR: '/assets/facilities/training-center/3.svg',
    GOOD: '/assets/facilities/training-center/4.svg',
    EXCELLENT: '/assets/facilities/training-center/5.svg',
    FORMIDABLE: '/assets/facilities/training-center/6.svg',
  },
  GYM: {
    NONE: '/assets/facilities/no-facility.svg',
    BAD: '/assets/facilities/gym/1.svg',
    WEAK: '/assets/facilities/gym/2.svg',
    REGULAR: '/assets/facilities/gym/3.svg',
    GOOD: '/assets/facilities/gym/4.svg',
    EXCELLENT: '/assets/facilities/gym/5.svg',
    FORMIDABLE: '/assets/facilities/gym/6.svg',
  },
  GRASSROOTS_ACADEMY: {
    NONE: '/assets/facilities/no-facility.svg',
    BAD: '/assets/facilities/grassroots-academy/1.svg',
    WEAK: '/assets/facilities/grassroots-academy/2.svg',
    REGULAR: '/assets/facilities/grassroots-academy/3.svg',
    GOOD: '/assets/facilities/grassroots-academy/4.svg',
    EXCELLENT: '/assets/facilities/grassroots-academy/5.svg',
    FORMIDABLE: '/assets/facilities/grassroots-academy/6.svg',
  },
  MEDICAL_DEPARTMENT: {
    NONE: '/assets/facilities/no-facility.svg',
    BAD: '/assets/facilities/medical-department/1.svg',
    WEAK: '/assets/facilities/medical-department/2.svg',
    REGULAR: '/assets/facilities/medical-department/3.svg',
    GOOD: '/assets/facilities/medical-department/4.svg',
    EXCELLENT: '/assets/facilities/medical-department/5.svg',
    FORMIDABLE: '/assets/facilities/medical-department/6.svg',
  },
};

export const FacilityDetails: FC<Props> = (props: Props) => {
  const {
    type,
    loading,
    level,
    totalCapacity,
    currentOccupation,
    status,
    maintenanceCost,
    underMaintenanceUntil,
    reconstructionType,
    noFacilityMessage,
    newLevel,
  } = props;

  const [optionsMenuOpen, setOptionMenuOpen] = useState<boolean>(false);
  const [openReconstructionDialog, setOpenReconstructionDialog] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const matchesLgAndDown = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesXsOrSm = theme.breakpoints.down('md');
  const { i18n } = useTranslation();

  const handleCloseMenu = () => {
    setOptionMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOptionMenuOpen(true);
  };

  return (
    <Card variant="outlined">
      {loading ? (
        <CardHeader title={<Skeleton variant="rounded" width="50%" />} />
      ) : (
        <CardHeader
          title={t(type.toLowerCase())}
          action={
            level !== Level.NONE && status !== Status.UNDER_MAINTENANCE ? (
              <>
                <IconButton
                  aria-label="settings"
                  onClick={handleOpenMenu}
                  aria-controls={optionsMenuOpen ? 'facility-basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={optionsMenuOpen ? 'true' : undefined}
                >
                  <MoreVertOutlined />
                </IconButton>
                <Menu
                  id="facility-basic-menu"
                  anchorEl={anchorEl}
                  open={optionsMenuOpen}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => setOpenReconstructionDialog(true)}>{t('reconstruct_facility')}</MenuItem>
                </Menu>
              </>
            ) : null
          }
        />
      )}

      {loading ? (
        <CardContent>
          <Stack
            spacing={2}
            direction={matchesLgAndDown ? 'column' : 'row'}
            sx={{
              alignItems: 'center',
              justifyContent: matchesLgAndDown ? 'center' : 'left',
            }}
          >
            <Skeleton variant="rounded" width="240px" height="240px" />
            <Stack sx={{ width: '40%' }} spacing={2}>
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
              <Skeleton variant="rounded" width="100%" />
            </Stack>
          </Stack>
        </CardContent>
      ) : (
        <CardContent>
          {level === Level.NONE && status !== Status.UNDER_MAINTENANCE ? (
            <Stack spacing={2} alignItems="center">
              <img src="/assets/facilities/no-facility.svg" alt="no-facility" width="300rem" />
              <Typography variant="h5" textAlign="center" noWrap>
                {t(noFacilityMessage)}
              </Typography>
              <Button variant="contained" sx={{ width: '100%' }} onClick={() => setOpenReconstructionDialog(true)}>
                {t('build_new_facility')}
              </Button>
            </Stack>
          ) : (
            <Stack
              spacing={2}
              direction={matchesLgAndDown ? 'column' : 'row'}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Stack
                direction={matchesLgAndDown ? 'column' : 'row'}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Stack
                  direction={matchesLgAndDown ? 'column' : 'row'}
                  spacing={matchesLgAndDown ? 2 : 6}
                  alignItems="center"
                  justifyContent={matchesLgAndDown ? 'center' : 'left'}
                >
                  <img
                    src={facilityTypeIcons[type][level || 'NONE'] || '/assets/facilities/no-facility.svg'}
                    alt={t(type.toLowerCase()) || ''}
                    width="300rem"
                  />

                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        justifyContent: matchesLgAndDown ? 'center' : 'left',
                      }}
                    >
                      <Typography variant="h6">
                        {`${t('facility_level')}:`}
                      </Typography>
                      {level !== Level.NONE ? (
                        <Box
                          component="img"
                          src={`/assets/icons/levels/${level?.toLowerCase()}.svg`}
                          alt={t(level?.toLowerCase() || 'Type')}
                          sx={{
                            width: matchesXsOrSm ? '25px' : '50px',
                            height: matchesXsOrSm ? '25px' : '30px',
                          }}
                        />
                      ) : null}
                      <Typography variant="h6">
                        {t(`facility_level_${level?.toLowerCase() || ''}`)}
                      </Typography>
                      {status === Status.UNDER_MAINTENANCE ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <ArrowForwardIcon />
                          <Box
                            component="img"
                            src={`/assets/icons/levels/${newLevel?.toLowerCase()}.svg`}
                            alt={t(newLevel?.toLowerCase() || 'Type')}
                            sx={{
                              width: matchesXsOrSm ? '25px' : '50px',
                              height: matchesXsOrSm ? '25px' : '30px',
                            }}
                          />
                          <Typography variant="h6">
                            {t(`facility_level_${newLevel?.toLowerCase() || ''}`)}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        justifyContent: matchesLgAndDown ? 'center' : 'left',
                      }}
                    >
                      <Typography variant="h6">{`${t('maintenance_cost')}:`}</Typography>
                      <Typography variant="h6">
                        {`${formatCurrency(maintenanceCost || 0, i18n.language)}`}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        justifyContent: matchesLgAndDown ? 'center' : 'left',
                      }}
                    >
                      <Typography variant="h6">{`${t('facility_status')}:`}</Typography>
                      <Typography variant="h6">
                        {t(`facility_status_${status.toLowerCase()}`)}
                      </Typography>
                    </Stack>

                    {reconstructionType !== ReconstructionType.NONE ? (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          alignItems: 'center',
                          justifyContent: matchesLgAndDown ? 'center' : 'left',
                        }}
                      >
                        <Typography variant="h6">{`${t('under_maintenance_until')}:`}</Typography>
                        <Typography variant="h6">
                          {`${formatShortDate(underMaintenanceUntil, i18n.language)}`}
                        </Typography>
                      </Stack>
                    ) : null}
                    {reconstructionType !== ReconstructionType.NONE ? (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          alignItems: 'center',
                          justifyContent: matchesLgAndDown ? 'center' : 'left',
                        }}
                      >
                        <Typography variant="h6">{`${t('reconstruction_type')}:`}</Typography>
                        <Typography variant="h6">
                          {t(`facility_reconstruction_type_${reconstructionType.toLowerCase()}`)}
                        </Typography>
                      </Stack>
                    ) : null}
                    {type === FacilityType.GRASSROOTS_ACADEMY ? (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          alignItems: 'center',
                          justifyContent: matchesLgAndDown ? 'center' : 'left',
                        }}
                      >
                        <Typography variant="h6">
                          {`${t('grassroots_academy_occupation')}:`}
                        </Typography>
                        <Typography variant="h6">
                          {`${currentOccupation} ${t('of')} ${totalCapacity}`}
                        </Typography>
                      </Stack>
                    ) : null}
                    {/* TODO: Add a link to view the grassroots
                    players when the facility is a grassroots academy */}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </CardContent>
      )}

      <ReconstructFacilityDialog
        type={type}
        level={level}
        maintenanceCost={maintenanceCost}
        open={openReconstructionDialog}
        onClose={() => setOpenReconstructionDialog(false)}
      />
    </Card>
  );
};

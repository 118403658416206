import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Player } from '../../data/types';
import { Loader } from '..';
import { Shirt } from './Shirt';
import { useChangeShirtNumber } from '../../data/hooks';
import { QueryKeys } from '../../data/hooks/queryKeys';

type Props = {
  open: boolean;
  onClose: () => void;
  player: Player;
};

const isValidNumber = (shirtNumber: string) => {
  const value = parseInt(shirtNumber, 10);
  return value >= 1 && value <= 99;
};

export const ChangeShirtNumberDialog: FC<Props> = (props: Props) => {
  const { open, onClose, player } = props;
  const [shirtNumber, setShirtNumber] = useState<string>('');
  const { t } = useTranslation();
  const { isLoading, mutateFn: changeShirtNumber } = useChangeShirtNumber();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShirtNumber(event.target.value);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = () => {
    const request = {
      playerId: player.id,
      shirtNumber: parseInt(shirtNumber, 10),
    };

    changeShirtNumber(request).then(() => {
      onClose();
      queryClient.fetchQuery({
        queryKey: ['players'],
      });
      queryClient.fetchQuery({
        queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
      });
      enqueueSnackbar(t('shirt_number_changed_successfully'), { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(t('error_while_updating_shirt_number'), { variant: 'error' });
    });
  };

  useEffect(() => {
    setShirtNumber(player.shirtNumber.toString());
  }, [player]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle id="alert-dialog-title">
        {`${t('changing_shirt_number_for')} ${player.name}`}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1} justifyContent="center" alignItems="center" sx={{ paddingTop: 1 }}>
          <FormControl fullWidth variant="outlined" error={!isValidNumber(shirtNumber)}>
            <InputLabel htmlFor="new-shirt-number">
              {t('shirt_number')}
            </InputLabel>
            <OutlinedInput
              id="new-shirt-number"
              type="number"
              value={shirtNumber}
              onChange={handleOnChange}
              label={t('shirt_number')}
              autoFocus
              sx={{ fontSize: '20px' }}
            />
            <FormHelperText id="new-shirt-number-text">{t('number_must_be_between_1_and_99')}</FormHelperText>
          </FormControl>

          <Stack direction="row" spacing={1} justifyContent="space-evenly" alignItems="center">
            <Box sx={{ width: '35%' }}>
              <Shirt number={player.shirtNumber} />
            </Box>
            <Box sx={{ width: '30%' }}>
              <LoopIcon sx={{ width: '100%', fontSize: 'calc(7em + 1vw)' }} />
            </Box>
            <Box sx={{ width: '35%' }}>
              <Shirt
                number={isValidNumber(shirtNumber)
                  ? parseInt(shirtNumber, 10)
                  : player.shirtNumber}
              />
            </Box>
          </Stack>

          <Typography variant="body1">{t('warning_if_shirt_is_already_assigned')}</Typography>
        </Stack>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>{t('cancel')}</Button>
        <Button
          onClick={handleOnConfirm}
          variant="contained"
          color="primary"
          disabled={isLoading || !isValidNumber(shirtNumber)}
        >
          {isLoading ? <Loader /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { FC, useContext } from 'react';
import {
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { t } from 'i18next';
import { Standing } from '../../data/types/seasons/Standings';
import { UserContext } from '../../context/UserContext';
import { StandingsPlaceholders } from './StandingsPlaceholder';

type Props = {
  standings: Standing[],
  placeholderSize: number,
  sx?: SxProps<Theme>,
  loading?: boolean,
};

export const Standings: FC<Props> = (props: Props) => {
  const {
    standings,
    sx,
    loading,
    placeholderSize,
  } = props;
  const userContext = useContext(UserContext);

  return (
    <TableContainer>
      <Table sx={sx} aria-label="Standings Summary" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('standings.position')}</TableCell>
            <TableCell>{t('standings.club')}</TableCell>
            <TableCell align="center">{t('standings.points')}</TableCell>
            <TableCell align="center">{t('standings.matches')}</TableCell>
            <TableCell align="center">{t('standings.victories')}</TableCell>
            <TableCell align="center">{t('standings.draws')}</TableCell>
            <TableCell align="center">{t('standings.losses')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (<StandingsPlaceholders size={placeholderSize} />)}
          {standings.map((row: Standing) => (
            <TableRow
              key={row.club.id}
              sx={{
                backgroundColor: userContext?.clubId === row?.club?.id ? '#1976d2' : 'transparent',
                '& .MuiTableCell-root': {
                  color: userContext?.clubId === row?.club?.id ? 'white !important' : 'black',
                  fontWeight: 'bold',
                },
              }}
            >
              <TableCell component="th" scope="row" align="center">
                {`${row.position}.`}
              </TableCell>
              <TableCell sx={{ minWidth: '200px' }}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <img src="/assets/crest.png" alt={row?.club?.name} width="25" style={{ marginRight: '8px' }} />
                  {row?.club?.name}
                </Stack>
              </TableCell>
              <TableCell align="center">{row?.points}</TableCell>
              <TableCell align="center">{row?.matches}</TableCell>
              <TableCell align="center">{row?.victories}</TableCell>
              <TableCell align="center">{row?.draws}</TableCell>
              <TableCell align="center">{row?.losses}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { formatLongDate } from '../../common/formatter';
import i18n from '../../i18n';
import { PhysicalTraining } from '../../data/types/training/PhysicalTraining';

type Props = {
  loading: boolean;
  trainings?: PhysicalTraining[];
};

export const LoadingSkeleton: FC = () => (
  <>
    {[...Array(10)].map(() => (
      <Box
        sx={{
          height: '50px',
          my: 0,
          mx: 1,
        }}
        key={Math.random()}
      >
        <Skeleton variant="rounded" height={42} />
      </Box>
    ))}
  </>
);

export const PhysicalTrainingHistory: FC<Props> = (props: Props) => {
  const { loading, trainings } = props;
  const columnsDefinition: GridColDef[] = [
    {
      field: 'date',
      headerName: t('date') || 'Date',
      editable: false,
      sortable: true,
      pinnable: true,
      minWidth: 150,
      renderCell: ({ value }) => (
        <Typography variant="body1" textTransform="capitalize">
          {formatLongDate(value, i18n.language, false)}
        </Typography>
      ),
    },
    {
      field: 'type',
      headerName: t('type') || 'Type',
      editable: false,
      minWidth: 200,
      align: 'left',
      renderCell: ({ value }) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <img src={`assets/icons/training/${value.toLowerCase()}.svg`} alt="" width="50" />
          <Typography variant="body1">
            {t(value.toLowerCase())}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'intensity',
      headerName: t('intensity') || 'Intensity',
      editable: false,
      sortable: true,
      pinnable: true,
      minWidth: 150,
      renderCell: ({ value }) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <img src={`assets/icons/training/intensity/${value.toLowerCase()}.svg`} alt="" width="50" />
          <Typography variant="body1" textTransform="capitalize">
            {t(`intensity_${value.toLowerCase()}`)}
          </Typography>
        </Stack>
      ),
    },
  ];

  return (
    <Card variant="outlined">
      <CardHeader title={t('physical_training_history')} />
      <CardContent>
        <DataGrid
          columns={columnsDefinition}
          rows={trainings || []}
          rowHeight={50}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pageSizeOptions={[10, 20]}
          loading={loading}
          sx={{
            '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-virtualScroller': {
              minHeight: '350px',
              marginTop: 1,
            },
          }}
          slots={{
            loadingOverlay: LoadingSkeleton,
          }}
          disableColumnFilter
          disableColumnMenu
        />
      </CardContent>
    </Card>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ChangeCoachRequest,
  changeCoach,
  getCoachChangeCosts,
  getUserClubCoaches,
} from '../api';
import {
  ChangeCoachCosts,
  CoachLevel,
  CoachType,
  Coaches,
} from '../types/clubs/Coach';

export const useCoaches = () => {
  const { isFetching, error, data } = useQuery<Coaches, Error>({
    queryKey: ['user-club-coaches'],
    queryFn: () => getUserClubCoaches(),
  });

  return { isLoading: isFetching, error, data };
};

export const useGetCoachChangeCosts = (type: CoachType, level: CoachLevel) => {
  const { isFetching, error, data } = useQuery<ChangeCoachCosts, Error>({
    queryKey: ['get-coach-change-costs', type, level],
    queryFn: () => getCoachChangeCosts(type, level),
    cacheTime: 0, // No cache for this call
  });

  return { isLoading: isFetching, error, data };
};

export const useChangeCoach = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (request: ChangeCoachRequest) => changeCoach(request),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-club-coaches'],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

import { FC } from 'react';

export const Uniform: FC = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 150 200"
    version="1.1"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
      position: 'absolute',
      top: '-2px',
    }}
  >
    <g transform="matrix(1,0,0,1,0,1)">
      <path
        id="player-t-shirt"
        d="M37.891,157.302C37.891,157.302 53.458,172.378 73.04,172.666C92.622,172.954 109.311,157.084 109.311,157.084C109.311,157.084 132.212,165.717 138.566,169.191C144.92,172.665 150,175.48 150,177.073L150,200L0,200L0,177.084C0,177.084 5.058,172.176 6.868,171.098C8.678,170.02 35.293,158.426 37.891,157.302Z"
        style={{
          fill: 'rgb(51,51,51)',
        }}
      />
    </g>
  </svg>
);

import { Match, MatchType, Status } from '../data/types/seasons/Match';

export const getMatchLink = (match: Match): string => {
  if (match?.status === Status.SCHEDULED) {
    return `/match/lineup/${match?.id}`;
  }
  return `/match/details/${match?.id}`;
};

export const getCompetitionLink = (match: Match): string => {
  switch (match?.matchType) {
    case MatchType.LEAGUE_MATCH:
      return `/league/${match?.competitionId}`;
    case MatchType.REGIONAL_TOURNAMENT_MATCH:
      return `/regional-tournament/${match?.competitionId}`;
    case MatchType.NATIONAL_CUP_MATCH:
      return `/national-cup/${match?.competitionId}`;
    case MatchType.INTERNATIONAL_CUP_MATCH:
      return `/international-cup/${match?.competitionId}`;
    default:
      return '#';
  }
};

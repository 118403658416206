import { AxiosResponse } from 'axios';

import { axiosInstance } from '../axios';
import { Player } from '../types/players/Player';
import { GrassrootsSearchType } from '../types/players/GrassrootsSearchType';

export const getPlayers = (): Promise<AxiosResponse> => (
  axiosInstance.get('/player/club')
);

export const getUserClubProfessionalPlayers = (): Promise<Player[]> => axiosInstance.get('/player/club')
  .then((response) => response.data);

export const getAvailableGrassrootsPlayers = (): Promise<Player[]> => (
  axiosInstance.get('/player/grassroots/available').then((response) => response.data as Player[])
);

export const getGrassrootsPlayers = (): Promise<Player[]> => (
  axiosInstance.get('/player/grassroots').then((response) => response.data as Player[])
);

export const changeShirtNumber = (request: any): Promise<Player> => (
  axiosInstance.patch(`/player/change-shirt-number/${request.playerId}`, { shirtNumber: request.shirtNumber })
    .then((response) => response.data)
);

export const firePlayer = (playerId: string): Promise<AxiosResponse> => (
  axiosInstance.delete(`/player/fire/${playerId}`)
);

export const fireGrassrootsPlayer = (playerId: string): Promise<AxiosResponse> => (
  axiosInstance.delete(`/player/grassroots/fire/${playerId}`)
);

export const searchGrassrootsPlayers = (type: GrassrootsSearchType): Promise<Player[]> => (
  axiosInstance.post('/player/grassroots/search', { type }).then((response) => response.data as Player[])
);

export const hireGrassrootsPlayer = (id: string): Promise<Player> => (
  axiosInstance.post('/player/grassroots/hire', { playerId: id })
    .then((response) => response.data)
);

export const rejectGrassrootsPlayer = (playerId: string): Promise<AxiosResponse> => (
  axiosInstance.delete(`/player/grassroots/reject/${playerId}`)
);

export const promoteGrassrootsPlayer = (id: string): Promise<Player> => (
  axiosInstance.patch('/player/grassroots/promote', { playerId: id })
    .then((response) => response.data)
);

import { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../common/formatter';
import { useUserClub } from '../../data/hooks/useClub';

export const ClubInfo: FC = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.only('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.only('sm'));
  const { i18n } = useTranslation();

  const { isFetching, data: club } = useUserClub();

  let clubNameMarginTop = '4rem';
  if (matchesXs) {
    clubNameMarginTop = '0';
  } else if (matchesSm) {
    clubNameMarginTop = '1rem';
  }

  return (
    <Card sx={{ height: '100%' }} variant="outlined">
      <CardContent sx={{ height: '100%' }}>
        <Grid container spacing={1} sx={{ height: '100%' }}>
          <Grid item xs={12} sm={5} sx={{ textAlign: 'center' }}>
            {isFetching ? (
              <Skeleton
                variant="circular"
                width={matchesSm || matchesXs ? 160 : 260}
                height={matchesSm || matchesXs ? 160 : 260}
                sx={{
                  marginTop: matchesSm || matchesXs ? '1rem' : '2rem',
                  marginLeft: matchesXs ? '20%' : '0%',
                }}
              />
            ) : (
              <img
                src="/assets/crest.png"
                alt={club?.name}
                width={matchesSm || matchesXs ? '160' : '260'}
                style={{
                  marginTop: matchesSm || matchesXs ? '1rem' : '2rem',
                  maxWidth: '100%',
                }}
              />
            )}

          </Grid>

          <Grid item xs={12} sm={7}>
            <Grid container sx={{ marginTop: clubNameMarginTop }}>
              <Grid item xs={12}>
                {isFetching ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      marginTop: '0.5rem',
                      height: '3rem',
                    }}
                  />
                ) : (
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign: matchesXs ? 'center' : 'left',
                    }}
                  >
                    {club?.abbreviation}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetching ? (
                  <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '3rem' }} />
                ) : (
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: matchesXs ? 'center' : 'left',
                    }}
                  >
                    {club?.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetching ? (
                  <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
                ) : (
                  <Typography variant="h6" sx={{ textAlign: matchesXs ? 'center' : 'left', marginTop: '1rem' }}>
                    {t('club_info.current_money_amount')}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {isFetching ? (
                  <Skeleton variant="rounded" sx={{ marginTop: '0.5rem', height: '2rem' }} />
                ) : (
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: matchesXs ? 'center' : 'left',
                      color: (club?.balance || 0) > 0 ? 'green' : 'red',
                    }}
                  >
                    {formatCurrency(club?.balance || 0, i18n.language)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
};

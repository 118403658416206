import { t } from 'i18next';
import {
  Commentary,
  CommentaryType,
  MoveResult,
  MoveType,
  PlayerAction,
} from '../../data/types/match/MatchDetails';

export const formatCommentary = (commentary: Commentary): string => {
  let commentaryFormatted: string = `${commentary.atMinute}. `;

  if (commentary.moveType === MoveType.SHORT_PASS) {
    if (commentary.result === MoveResult.SHORT_PASS_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_pass_but_is_blocked_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.SHORT_PASS_DONE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.passes_the_ball',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    } else if (commentary.result === MoveResult.SHORT_PASS_INTERCEPTED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_pass_but_is_intercepted_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.SHORT_PASS_DONE_FREE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.passes_the_ball_freely',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    }
  }

  if (commentary.moveType === MoveType.LONG_PASS) {
    if (commentary.result === MoveResult.LONG_PASS_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_a_long_pass_but_is_blocked_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.LONG_PASS_DONE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.goes_for_a_long_ball',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    } else if (commentary.result === MoveResult.LONG_PASS_INTERCEPTED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_a_long_pass_but_is_intercepted_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.LONG_PASS_DONE_FREE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.goes_for_a_long_ball_freely',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    }
  }

  if (commentary.moveType === MoveType.LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER) {
    if (commentary.result === MoveResult.LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_a_long_pass_face_2_face_but_is_blocked_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_DONE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.goes_for_a_long_ball_face_2_face',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    } else if (commentary.result === MoveResult.LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_INTERCEPTED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_a_long_pass_face_2_face_but_is_intercepted_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_DONE_FREE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.goes_for_a_long_ball_face_2_face_freely',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    }
  }

  if (
    commentary.moveType === MoveType.RECEIVE_SHORT_PASS
    || commentary.moveType === MoveType.RECEIVE_LONG_PASS
  ) {
    if (
      commentary.result === MoveResult.RECEIVED_SHORT_PASS
      || commentary.result === MoveResult.RECEIVED_LONG_PASS
    ) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_the_ball',
      )} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    } else if (
      commentary.result === MoveResult.RECEIVED_SHORT_PASS_FREE
      || commentary.result === MoveResult.RECEIVED_LONG_PASS_FREE
    ) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_the_ball_freely',
      )} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    } else if (commentary.result === MoveResult.OFFSIDE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_the_ball_but_offside',
      )}!`;
    } else if (
      commentary.result === MoveResult.SHORT_PASS_INTERCEPTED
      || commentary.result === MoveResult.LONG_PASS_INTERCEPTED
    ) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_receive_ball_but_is_intercepted_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.FOULED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_the_ball_but_is_fouled_by',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.NO_ATTACKER_TO_PLAY) {
      commentaryFormatted += t('commentaries.no_attacker_to_play');
    }
  }

  if (commentary.moveType === MoveType.RECEIVE_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER) {
    if (commentary.result === MoveResult.LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER_NOT_TRAPPED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_long_pass_face_2_face_but_is_intercepted',
      )}`;
    } else if (commentary.result === MoveResult.RECEIVED_LONG_PASS_FACE_2_FACE_WITH_GOALKEEPER) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_long_pass_face_2_face',
      )}...`;
    } else if (commentary.result === MoveResult.OFFSIDE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.receives_a_long_pass_face_2_face_but_is_offside',
      )}!`;
    }
  }

  if (commentary.moveType === MoveType.DRIBBLE_DEFENDER) {
    if (commentary.result === MoveResult.DRIBBLED_DEFENDER) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.dribbled_defender',
      )} ${commentary?.defender?.name}...`;
    } else if (commentary.result === MoveResult.LOST_THE_BALL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_drib_but_loses_ball_to',
      )} ${commentary?.defender?.name}`;
    } else if (commentary.result === MoveResult.FOULED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.dribbles_defender_but_is_fouled_by',
      )} ${commentary?.defender?.name}`;
    }
  }

  if (commentary.moveType === MoveType.CARRY_THE_BALL) {
    commentaryFormatted += `${commentary?.attacker?.name} ${t(
      'commentaries.carried_the_ball_free',
    )} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
  }

  if (commentary.moveType === MoveType.CROSS) {
    if (commentary.result === MoveResult.CROSS_DONE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.crosses_the_ball',
      )} ${t('commentaries.from_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}...`;
    } else if (commentary.result === MoveResult.CROSS_INTERCEPTED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_crosses_the_ball_but_is_intercepted_by',
      )} ${commentary?.defender !== undefined && commentary?.defender != null
        ? commentary?.defender?.name
        : commentary?.goalkeeper?.name}...`;
    } else if (commentary.result === MoveResult.BAD_CROSS) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_crosses_the_ball_but_fails',
      )}`;
    }
  }

  if (commentary.moveType === MoveType.FOUL) {
    if (commentary.result === MoveResult.FOULED) {
      commentaryFormatted += `${commentary?.defender?.name} ${t('commentaries.fouls_attacker')}
      ${commentary?.attacker?.name} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}.
      ${t('commentaries.no_yellow_card')}...`;
    } else if (commentary.result === MoveResult.YELLOW_CARD) {
      commentaryFormatted += `${commentary?.defender?.name} ${t('commentaries.fouls_attacker')}
      ${commentary?.attacker?.name} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}.
      ${t('commentaries.defender_is_booked')}...`;
    } else if (commentary.result === MoveResult.SECOND_YELLOW_CARD) {
      commentaryFormatted += `${commentary?.defender?.name} ${t('commentaries.fouls_attacker')}
      ${commentary?.attacker?.name} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}.
      ${t('commentaries.defender_is_booked_second_yellow')}...`;
    } else if (commentary.result === MoveResult.RED_CARD) {
      commentaryFormatted += `${commentary?.defender?.name} ${t('commentaries.fouls_attacker')}
      ${commentary?.attacker?.name} ${t('commentaries.at_zone')} ${t(`commentaries.${commentary.zone.toLowerCase()}`)}.
      ${t('commentaries.defender_is_sent_off')}...`;
    }
  }

  if (commentary.moveType === MoveType.SHOOT_ON_GOAL_FROM_LONG_RANGE) {
    if (commentary.result === MoveResult.GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_long_range',
      )} ${t('commentaries.and_he_scores')}!`;
    } else if (commentary.result === MoveResult.MISSED_GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_long_range',
      )} ${t('commentaries.and_he_misses_the_target')}.`;
    } else if (commentary.result === MoveResult.SHOOT_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_shoot_but_is_blocked_by',
      )} ${commentary?.defender?.name}.`;
    } else if (commentary.result === MoveResult.GOALKEEPER_SAVED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_long_range_and_goalkeeper_saves',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK
      && commentary?.goalkeeper?.action === PlayerAction.PALMED_TO_CORNER_KICK) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_long_range',
      )}, ${commentary?.goalkeeper?.name} ${t('commentaries.palms_to_corner_kick')}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK
      && commentary?.defender?.action === PlayerAction.BLOCKED_SHOOT) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_long_range',
      )}, ${commentary?.defender?.name} ${t('commentaries.defender_blocked_but_corner_kick')}.`;
    }
  }

  if (commentary.moveType === MoveType.SHOOT_ON_GOAL_FROM_MID_RANGE) {
    if (commentary.result === MoveResult.GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_mid_range',
      )}... ${t('commentaries.and_he_scores')}!`;
    } else if (commentary.result === MoveResult.MISSED_GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_mid_range',
      )} ${t('commentaries.and_he_misses_the_target')}.`;
    } else if (commentary.result === MoveResult.SHOOT_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_shoot_but_is_blocked_by',
      )} ${commentary?.defender?.name}.`;
    } else if (commentary.result === MoveResult.GOALKEEPER_SAVED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_mid_range_and_goalkeeper_saves',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK
      && commentary?.goalkeeper?.action === PlayerAction.PALMED_TO_CORNER_KICK) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_mid_range',
      )}, ${commentary?.goalkeeper?.name} ${t('commentaries.palms_to_corner_kick')}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK
      && commentary?.defender?.action === PlayerAction.BLOCKED_SHOOT) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_mid_range',
      )}, ${commentary?.defender?.name} ${t('commentaries.defender_blocked_but_corner_kick')}.`;
    }
  }

  if (commentary.moveType === MoveType.SHOOT_ON_GOAL_FROM_SHORT_RANGE) {
    if (commentary.result === MoveResult.GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_short_range',
      )}... ${t('commentaries.and_he_scores')}!`;
    } else if (commentary.result === MoveResult.MISSED_GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_short_range',
      )} ${t('commentaries.and_he_misses_the_target')}.`;
    } else if (commentary.result === MoveResult.SHOOT_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_shoot_but_is_blocked_by',
      )} ${commentary?.defender?.name}.`;
    } else if (commentary.result === MoveResult.GOALKEEPER_SAVED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_short_range_and_goalkeeper_saves',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK
      && commentary?.goalkeeper?.action === PlayerAction.PALMED_TO_CORNER_KICK) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_short_range',
      )}, ${commentary?.goalkeeper?.name} ${t('commentaries.palms_to_corner_kick')}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK
      && commentary?.defender?.action === PlayerAction.BLOCKED_SHOOT) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_short_range',
      )}, ${commentary?.defender?.name} ${t('commentaries.defender_blocked_but_corner_kick')}.`;
    } else if (commentary.result === MoveResult.OFFSIDE
      && commentary?.attacker?.action === PlayerAction.OFFSIDE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.shoots_from_short_range',
      )}, ${commentary?.defender?.name} ${t('commentaries.shoots_from_short_range_on_goal_but_its_offside')}.`;
    }
  }

  if (commentary.moveType === MoveType.HEADER_ON_GOAL) {
    if (commentary.result === MoveResult.GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.headers_on_goal',
      )}... ${t('commentaries.and_he_scores')}!`;
    } else if (commentary.result === MoveResult.MISSED_GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.headers_on_goal',
      )} ${t('commentaries.and_he_misses_the_target')}.`;
    } else if (commentary.result === MoveResult.SHOOT_BLOCKED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_header_on_goal_but_is_blocked_by',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.GOALKEEPER_SAVED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.headers_on_goal_and_goalkeeper_saves',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t('commentaries.headers_on_goal')},
      ${commentary?.goalkeeper?.name} ${t('commentaries.palms_to_corner_kick')}.`;
    } else if (commentary.result === MoveResult.OFFSIDE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.headers_on_goal_but_its_offside',
      )}.`;
    } else if (commentary.result === MoveResult.CROSS_INTERCEPTED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_header_on_goal_but_is_intercepted_by',
      )} ${commentary?.goalkeeper?.name}.`;
    }
  }

  if (commentary.moveType === MoveType.CORNER_KICK) {
    if (commentary.result === MoveResult.CROSS_DONE) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_corner_kick',
      )}...`;
    } else if (commentary.result === MoveResult.CROSS_INTERCEPTED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_corner_kick_but_is_intercepted_by',
      )} ${commentary?.defender?.name}.`;
    } else if (commentary.result === MoveResult.BAD_CROSS) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.tries_to_take_corner_kick_but_fails',
      )}.`;
    }
  }

  if (commentary.moveType === MoveType.FREE_KICK) {
    if (commentary.result === MoveResult.GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_free_kick',
      )} ${t('commentaries.and_he_scores')}!`;
    } else if (commentary.result === MoveResult.MISSED_GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_free_kick',
      )} ${t('commentaries.and_he_misses_the_target')}.`;
    } else if (commentary.result === MoveResult.GOALKEEPER_SAVED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_free_kick_and_goalkeeper_saves',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t('commentaries.takes_free_kick')},
      ${commentary?.goalkeeper?.name} ${t('commentaries.palms_to_corner_kick')}.`;
    }
  }

  if (commentary.moveType === MoveType.PENALTY_KICK) {
    commentaryFormatted += `${t('commentaries.its_penalty_for')}
    ${commentary.attackingClub.name}!`;
    if (commentary.result === MoveResult.GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_penalty_kick',
      )} ${t('commentaries.and_he_scores')}!`;
    } else if (commentary.result === MoveResult.MISSED_GOAL) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_penalty_kick',
      )} ${t('commentaries.and_he_misses_the_target')}.`;
    } else if (commentary.result === MoveResult.GOALKEEPER_SAVED) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_free_penalty_and_goalkeeper_saves',
      )} ${commentary?.goalkeeper?.name}.`;
    } else if (commentary.result === MoveResult.CORNER_KICK) {
      commentaryFormatted += `${commentary?.attacker?.name} ${t(
        'commentaries.takes_penalty_kick',
      )}, ${commentary?.goalkeeper?.name} ${t('commentaries.palms_to_corner_kick')}.`;
    }
  }

  if (commentary.moveType === MoveType.COUNTER_ATTACK) {
    commentaryFormatted += `${commentary?.attacker?.name} ${t(
      'commentaries.starts_the_counter_attack_for',
    )} ${commentary.attackingClub.name}!`;
  }

  if (commentary.type === CommentaryType.SUBSTITUTION) {
    if (commentary?.inPlayer !== null) {
      commentaryFormatted += `${t('commentaries.substitution_on')}
      ${commentary?.attackingClub?.name}. ${commentary?.outPlayer?.name} ${t('commentaries.leaves_the_match_and')}
      ${commentary?.inPlayer?.name} ${t('commentaries.enters_the_match')}!`;
    } else {
      commentaryFormatted += `${commentary?.attacker?.name} ${t('commentaries.no_one_to_sub')}!`;
    }
  }

  if (commentary.type === CommentaryType.INJURY) {
    commentaryFormatted += `${commentary?.attacker?.name} ${t('commentaries.player_injured')}!`;
  }

  if (commentary.type === CommentaryType.END_OF_MATCH) {
    commentaryFormatted += t('commentaries.end_of_match');
  }

  if (commentary.type === CommentaryType.END_BY_WALKOVER) {
    commentaryFormatted += t('commentaries.match_ended_by_wo', {
      club: commentary.attackingClub.name,
    });
  }

  return commentaryFormatted;
};

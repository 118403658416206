import { FC } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Player } from '../../data/types';
import { Stats } from './Stats';

type Props = {
  player?: Player,
};

export const YellowCardsTab: FC<Props> = (props: Props) => {
  const { player } = props;

  return (
    <Grid container spacing={1} sx={{ padding: 1 }}>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={player?.yellowCards?.league || 0}
          icon="/assets/icons/yellow-card.svg"
          description={t('league')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={player?.yellowCards?.regionalTournament || 0}
          icon="/assets/icons/yellow-card.svg"
          description={t('regional_tournament')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={player?.yellowCards?.nationalCup || 0}
          icon="/assets/icons/yellow-card.svg"
          description={t('national_cup')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stats
          stat={player?.yellowCards?.internationalCup || 0}
          icon="/assets/icons/yellow-card.svg"
          description={t('international_cup')}
        />
      </Grid>
    </Grid>
  );
};

import { FC, useState, MouseEvent } from 'react';
import {
  TextField, Grid, InputAdornment, IconButton, Button,
} from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { auth, Loader } from '../../common';
import { syncUser } from '../../data/api/user';

type Props = {
  onError: (code: string | undefined) => void;
  backToLogin: () => void;
};

export const SignupForm: FC<Props> = ({ onError, backToLogin }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('+55');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const { t } = useTranslation();

  const onSignup = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsInProgress(true);

    if (email && password) {
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const { user } = userCredential;

          return updateProfile(user, {
            displayName: name,
          });
        })
        .then(() => (
          syncUser({
            id: auth.currentUser?.uid!,
            email: auth.currentUser?.email!,
            name: auth.currentUser?.displayName!,
            phoneNumber: `${countryCode}${phoneNumber}`,
          })
        ))
        .then(() => {
          setIsInProgress(false);
        })
        .catch(({ code }) => {
          onError(code);
          setIsInProgress(false);
        });
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      gap={2}
      justifyContent="center"
      sx={{
        maxWidth: 400,
      }}
    >
      <Grid item>
        <TextField
          label={t('email')}
          variant="outlined"
          value={email}
          required
          fullWidth
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          label={t('password')}
          variant="outlined"
          required
          value={password}
          fullWidth
          onChange={(e: any) => setPassword(e.target.value)}
          InputProps={{
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  // color="warning"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => {}}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined /> // sx={{ fill: '#ffcd00' }} />
                  ) : (
                    <VisibilityOutlined /> // sx={{ fill: '#ffcd00' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          label={t('user_name')}
          variant="outlined"
          value={name}
          fullWidth
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item container wrap="nowrap" gap={1}>
        <Grid item>
          <TextField
            required
            label={t('phone_code')}
            variant="outlined"
            value={countryCode}
            disabled
            onChange={(e) => setCountryCode(e.target.value)}
            sx={{ width: 100 }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            required
            type="number"
            label={t('phone_number')}
            variant="outlined"
            value={phoneNumber}
            fullWidth
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Button
              onClick={backToLogin}
              variant="text"
              disabled={isInProgress}
            >
              {t('back_to_login')}
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              onClick={onSignup}
              disabled={
                !email || !password || !phoneNumber || !name || isInProgress
              }
              variant="outlined"
            >
              { isInProgress ? (<Loader variant="small" />) : t('sign_up')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Grid } from '@mui/material';
import { FC } from 'react';
import { useAllFacilities } from '../../data/hooks/useFacilities';
import { FacilityDetails } from './FacilityDetails';
import { Status } from '../../data/types/facilities/Status';
import { ReconstructionType } from '../../data/types/facilities/ReconstructionType';
import { FacilityType } from '../../data/types/facilities/FacilityType';
import { StadiumDetails } from './StadiumDetails';
import { StoresDetails } from './StoresDetails';
import { SocialClubDetails } from './SocialClubDetails';

export const FacilitiesPage: FC = () => {
  const { isLoading, data: facilities } = useAllFacilities();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StadiumDetails loading={isLoading} stadium={facilities?.stadium} />
      </Grid>
      <Grid item xs={12} md={4}>
        <FacilityDetails
          type={FacilityType.TRAINING_CENTER}
          loading={isLoading}
          level={facilities?.trainingCenter?.level}
          newLevel={facilities?.trainingCenter?.newLevel}
          status={facilities?.trainingCenter?.status || Status.RUNNING}
          maintenanceCost={facilities?.trainingCenter?.maintenanceCost || 0}
          underMaintenanceUntil={facilities?.trainingCenter?.underMaintenanceUntil || ''}
          reconstructionType={
            facilities?.trainingCenter?.reconstructionType || ReconstructionType.NONE
          }
          noFacilityMessage="no_training_center"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FacilityDetails
          type={FacilityType.GYM}
          loading={isLoading}
          level={facilities?.gym?.level}
          newLevel={facilities?.gym?.newLevel}
          status={facilities?.gym?.status || Status.RUNNING}
          maintenanceCost={facilities?.gym?.maintenanceCost || 0}
          underMaintenanceUntil={facilities?.gym?.underMaintenanceUntil || ''}
          reconstructionType={facilities?.gym?.reconstructionType || ReconstructionType.NONE}
          noFacilityMessage="no_gym"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FacilityDetails
          type={FacilityType.GRASSROOTS_ACADEMY}
          loading={isLoading}
          level={facilities?.grassrootsAcademy?.level}
          newLevel={facilities?.grassrootsAcademy?.newLevel}
          status={facilities?.grassrootsAcademy?.status || Status.RUNNING}
          maintenanceCost={facilities?.grassrootsAcademy?.maintenanceCost || 0}
          underMaintenanceUntil={facilities?.grassrootsAcademy?.underMaintenanceUntil || ''}
          currentOccupation={facilities?.grassrootsAcademy?.currentOccupation || 0}
          totalCapacity={facilities?.grassrootsAcademy?.totalCapacity || 0}
          reconstructionType={
            facilities?.grassrootsAcademy?.reconstructionType || ReconstructionType.NONE
          }
          noFacilityMessage="no_grassroots_academy"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FacilityDetails
          type={FacilityType.MEDICAL_DEPARTMENT}
          loading={isLoading}
          level={facilities?.medicalDepartment?.level}
          newLevel={facilities?.medicalDepartment?.newLevel}
          status={facilities?.medicalDepartment?.status || Status.RUNNING}
          maintenanceCost={facilities?.medicalDepartment?.maintenanceCost || 0}
          underMaintenanceUntil={facilities?.medicalDepartment?.underMaintenanceUntil || ''}
          reconstructionType={
            facilities?.medicalDepartment?.reconstructionType || ReconstructionType.NONE
          }
          noFacilityMessage="no_medical_department"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SocialClubDetails
          loading={isLoading}
          size={facilities?.socialClub?.size}
          newSize={facilities?.socialClub?.newSize}
          status={facilities?.socialClub?.status || Status.RUNNING}
          maintenanceCost={facilities?.socialClub?.maintenanceCost || 0}
          underMaintenanceUntil={facilities?.socialClub?.underMaintenanceUntil || ''}
          reconstructionType={facilities?.socialClub?.reconstructionType || ReconstructionType.NONE}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StoresDetails loading={isLoading} stores={facilities?.stores} />
      </Grid>
    </Grid>
  );
};

import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { NoRowsOverlayPropsOverrides } from '@mui/x-data-grid/models';
import { useTranslation } from 'react-i18next';

declare module '@mui/x-data-grid' {
  interface NoRowsOverlayPropsOverrides {
    onNewSearchClick: () => void;
  }
}

export const NoPlayersAvailableOverlay: FC<NoRowsOverlayPropsOverrides> = (
  { onNewSearchClick }: NoRowsOverlayPropsOverrides,
) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
      <Typography variant="h6">{t('no_players_available')}</Typography>
      <Button variant="contained" color="primary" onClick={onNewSearchClick}>
        {t('search_new_players')}
      </Button>
    </Stack>
  );
};

import {
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { FC } from 'react';

type Props = {
  size: number
};

export const StandingsPlaceholders: FC<Props> = (props: Props) => {
  const { size } = props;

  return (
    <>
      {new Array(size).fill(0).map(() => (
        <TableRow key={Math.random()}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell sx={{ minWidth: '200px' }}>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Skeleton variant="circular" width="30px" height="30px" />
              <Skeleton variant="text" width="200px" sx={{ marginLeft: 1 }} />
            </Stack>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

import { FC } from 'react';

type Props = {
  type: number;
};

export const Base: FC<Props> = (props: Props) => {
  const { type } = props;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 150 200"
      enableBackground="new 0 0 150 200"
      xmlSpace="preserve"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        //   transform: 'scale(1.2)',
      }}
    >
      {type === 1 ? (
        <g fillRule="evenodd" clipRule="evenodd">
          <path
            fill="#E8A477"
            d="m47.223 110.898 1.328-15.854c-2.352-2.314-4.041-3.841-5.267-5.744-3.37-5.229-5.486-6.432-7.534-4.907-.83.617-1.124 2.578-.638 5.042.849 4.303 3.564 10.723 4.417 14.49.685 3.034 4.652 13.42 7.694 6.973z"
          />
          <path
            fill="#F1B387"
            d="m100.376 110.898-1.33-15.854c2.353-2.314 4.041-3.841 5.268-5.744 3.369-5.229 5.487-6.432 7.535-4.907.829.617 1.123 2.578.637 5.042-.849 4.303-3.564 10.723-4.416 14.49-.688 3.034-4.654 13.42-7.694 6.973z"
          />
          <path
            fill="#D39166"
            d="M43.845 98.502c-1.739-4.557-2.415-5.58-2.492-4.883-.082.764.558 3.6 1.308 6.109.171.569-.497 1.958-.029 3.636.43 1.545 1.729 3.441 2.442 1.706.251-.613.057-1.69-.274-3.188-.17-.77-.35-1.792-.955-3.38zM40.559 87.736c-1.033-1.136-2.326-2.308-3.339-2.114-2.385.457-1.251 4.894.194 7.918 2.475 5.178 2.49 4.89 3.339 10.833-.054-3.769-.602-7.041-2.829-11.479-1.288-2.567-2.264-6.072-.521-6.528 1.11-.292 2.829 1.924 3.5 3.426l-.344-2.056zM104.395 98.502c1.739-4.557 2.417-5.58 2.492-4.883.083.764-.557 3.6-1.308 6.109-.17.569.496 1.958.029 3.636-.431 1.545-1.73 3.441-2.441 1.706-.252-.613-.058-1.69.274-3.188.169-.77.348-1.792.954-3.38z"
          />
          <path
            fill="#E8A477"
            d="M107.136 87.639c1.03-1.136 2.324-2.308 3.338-2.114 2.385.457 1.251 4.894-.194 7.918-2.475 5.178-2.49 4.89-3.34 10.833.056-3.77.604-7.041 2.831-11.479 1.288-2.567 2.264-6.074.52-6.529-1.109-.292-2.828 1.924-3.498 3.425l.343-2.054z"
          />
          <path
            fill="#F1B387"
            d="M97.878 149.708c3.517 9.324 37.432 12.869 52.122 27.365l-29.751-.081h-92.9L.532 176.77c6.554-10.944 47.117-20.05 49.671-27.062 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442c-1.327 5.934-1.303 14.956.857 20.675z"
          />
          <path
            fill="#E8A477"
            d="M45.362 154.014c1.939-.991 4.098-2.266 4.841-4.306 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442a29.825 29.825 0 0 0-.375 1.943c-.128.822-.227 1.777-.27 2.033-1.006 1.201-2.063 2.401-3.234 3.382-1.277 1.067-2.468 2.128-3.491 3.349-1.82 2.176-3.313 5.461-5.096 7.005-3.676 3.188-9.177 5.007-15.63 6.474-7.757 1.765-18.147 2.468-23.563.795z"
          />
          <path
            fill="#D39166"
            d="M51.635 132.507c1.756 1.498 2.659 2.898 4.47 4.172 3.919 2.761 6.58 6.182 7.76 6.487 3.849.994 16.232.994 20.081 0 1.182-.306 3.891-3.286 6.782-6.291 1.525-1.585 3.957-2.851 5.71-4.34.147-1.243.343-2.423.584-3.502l-22.981.442-22.983-.442c.239 1.071.432 2.241.577 3.474z"
          />
          <path
            fill="#F1B387"
            d="M74.396 142.826c-.13.01-.26.01-.39.012-4.776.029-7.15-.047-9.746-1.143-2.507-1.068-3.723-3.531-7.174-6.854-2.936-2.825-8.203-5.706-9.487-8.785-1.758-4.219-1.069-8.865-2.162-15.41-.873-5.217-3.328-9.85-3.615-15.07-.301-5.644.605-11.321.412-17.696-.189-6.231-.363-11.388.4-15.764.771-4.416 2.502-8.08 6.1-11.301v-.009c1.994-1.765 4.251-3.271 6.707-4.509l.08-.031c.159-.071.326-.155.485-.236.092-.042.178-.083.272-.134.125-.062.26-.125.385-.176.127-.052.253-.114.379-.166.14-.071.279-.123.413-.175a4.67 4.67 0 0 1 .367-.166c.172-.071.346-.144.526-.206.081-.03.167-.071.254-.104.253-.102.519-.194.78-.289l.006-.009c6.544-2.371 13.968-3.034 21.002-1.993 7.01 1.037 13.612 3.772 18.522 8.151 3.583 3.216 5.297 6.854 6.071 11.252.767 4.357.612 9.496.433 15.705-.183 6.362.738 12.013.44 17.634-.279 5.202-2.725 9.836-3.578 15.042-1.069 6.531-.385 11.143-2.122 15.376-1.28 3.092-6.508 6.01-9.423 8.864-3.437 3.364-4.646 5.831-7.14 6.938-2.491 1.085-4.78 1.233-9.197 1.252z"
          />
          <path
            fill="#F5BC94"
            d="M106.02 94.398c-.291 5.315-2.743 10.133-3.598 15.42-1.08 6.686-.39 11.404-2.146 15.727-1.278 3.146-6.768 6.167-9.702 9.082-3.447 3.425-4.234 5.653-6.74 6.77-.093.043-.199.073-.298.126 1.775-1.885 1.116-3.167 2.315-5.355 3.684-6.678 6.129-11.433 8.584-18.365 1.421-4.016 5.766-9.198 7.88-13.061 1.049-1.918 2.706-6.209 3.705-10.344z"
          />
          <path
            fill="#E8A477"
            d="M41.646 94.492c.298 5.324 2.902 10.255 3.777 15.565 1.104 6.679.416 11.436 2.183 15.74 1.292 3.147 5.354 4.871 8.314 7.772 3.479 3.401 4.568 5.719 7.089 6.804.102.042.199.09.305.121-1.794-1.855-2.131-3.667-3.346-5.867-3.717-6.668-3.648-11.087-6.12-18.013-1.435-4.018-4.976-6.279-7.109-10.135-1.06-1.917-4.083-7.841-5.093-11.987z"
          />
        </g>
      ) : null}

      {type === 2 ? (
        <g>
          <path
            fill="#E9BC9D"
            fillRule="evenodd"
            d="m47.223 110.898 1.328-15.854c-2.352-2.314-4.041-3.841-5.267-5.744-3.37-5.229-5.486-6.432-7.534-4.907-.83.617-1.124 2.578-.638 5.042.849 4.303 3.564 10.723 4.417 14.49.685 3.034 4.652 13.42 7.694 6.973zM100.376 110.898l-1.33-15.854c2.353-2.314 4.041-3.841 5.268-5.744 3.369-5.229 5.487-6.432 7.535-4.907.829.617 1.123 2.578.637 5.042-.849 4.303-3.564 10.723-4.416 14.49-.688 3.034-4.654 13.42-7.694 6.973z"
            clipRule="evenodd"
          />
          <path
            fill="#D39166"
            fillRule="evenodd"
            d="M43.845 98.502c-1.739-4.557-2.415-5.58-2.492-4.883-.082.764.558 3.6 1.308 6.109.171.569-.497 1.958-.029 3.636.43 1.545 1.729 3.441 2.442 1.706.251-.613.057-1.69-.274-3.188-.17-.77-.35-1.792-.955-3.38zM40.559 87.736c-1.033-1.136-2.326-2.308-3.339-2.114-2.385.457-1.251 4.894.194 7.918 2.475 5.178 2.49 4.89 3.339 10.833-.054-3.769-.602-7.041-2.829-11.479-1.288-2.567-2.264-6.072-.521-6.528 1.11-.292 2.829 1.924 3.5 3.426l-.344-2.056zM104.395 98.502c1.739-4.557 2.417-5.58 2.492-4.883.083.764-.557 3.6-1.308 6.109-.17.569.496 1.958.029 3.636-.431 1.545-1.73 3.441-2.441 1.706-.252-.613-.058-1.69.274-3.188.169-.77.348-1.792.954-3.38z"
            clipRule="evenodd"
          />
          <path
            fill="#E8A477"
            fillRule="evenodd"
            d="M107.136 87.639c1.03-1.136 2.324-2.308 3.338-2.114 2.385.457 1.251 4.894-.194 7.918-2.475 5.178-2.49 4.89-3.34 10.833.056-3.77.604-7.041 2.831-11.479 1.288-2.567 2.264-6.074.52-6.529-1.109-.292-2.828 1.924-3.498 3.425l.343-2.054z"
            clipRule="evenodd"
          />
          <path
            fill="#F4C7A7"
            fillRule="evenodd"
            d="M97.878 149.708c3.517 9.324 37.432 12.869 52.122 27.365l-29.751-.081h-92.9L.532 176.77c6.554-10.944 47.117-20.05 49.671-27.062 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442c-1.327 5.934-1.303 14.956.857 20.675z"
            clipRule="evenodd"
          />
          <path
            fill="#E9BC9D"
            fillRule="evenodd"
            d="M45.362 154.014c1.939-.991 4.098-2.266 4.841-4.306 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442a29.825 29.825 0 0 0-.375 1.943c-.128.822-.227 1.777-.27 2.033-1.006 1.201-2.063 2.401-3.234 3.382-1.277 1.067-2.468 2.128-3.491 3.349-1.82 2.176-3.313 5.461-5.096 7.005-3.676 3.188-9.177 5.007-15.63 6.474-7.757 1.765-18.147 2.468-23.563.795z"
            clipRule="evenodd"
          />
          <path
            fill="#DDB192"
            fillRule="evenodd"
            d="M51.635 132.507c1.756 1.498 2.659 2.898 4.47 4.172 3.919 2.761 6.58 6.182 7.76 6.487 3.849.994 16.232.994 20.081 0 1.182-.306 3.891-3.286 6.782-6.291 1.525-1.585 3.957-2.851 5.71-4.34.147-1.243.343-2.423.584-3.502l-22.981.442-22.983-.442c.239 1.071.432 2.241.577 3.474z"
            clipRule="evenodd"
          />
          <path
            fill="#F4C7A7"
            fillRule="evenodd"
            d="M74.396 142.826c-.13.01-.26.01-.39.012-4.776.029-7.15-.047-9.746-1.143-2.507-1.068-3.723-3.531-7.174-6.854-2.936-2.825-8.203-5.706-9.487-8.785-1.758-4.219-1.069-8.865-2.162-15.41-.873-5.217-3.328-9.85-3.615-15.07-.301-5.644.605-11.321.412-17.696-.189-6.231-.363-11.388.4-15.764.771-4.416 2.502-8.08 6.1-11.301v-.009c1.994-1.765 4.251-3.271 6.707-4.509l.08-.031c.159-.071.326-.155.485-.236.092-.042.178-.083.272-.134.125-.062.26-.125.385-.176.127-.052.253-.114.379-.166.14-.071.279-.123.413-.175a4.67 4.67 0 0 1 .367-.166c.172-.071.346-.144.526-.206.081-.03.167-.071.254-.104.253-.102.519-.194.78-.289l.006-.009c6.544-2.371 13.968-3.034 21.002-1.993 7.01 1.037 13.612 3.772 18.522 8.151 3.583 3.216 5.297 6.854 6.071 11.252.767 4.357.612 9.496.433 15.705-.183 6.362.738 12.013.44 17.634-.279 5.202-2.725 9.836-3.578 15.042-1.069 6.531-.385 11.143-2.122 15.376-1.28 3.092-6.508 6.01-9.423 8.864-3.437 3.364-4.646 5.831-7.14 6.938-2.491 1.085-4.78 1.233-9.197 1.252z"
            clipRule="evenodd"
          />
          <path
            fill="#F4D2BF"
            fillRule="evenodd"
            d="M106.02 94.398c-.291 5.315-2.743 10.133-3.598 15.42-1.08 6.686-.39 11.404-2.146 15.727-1.278 3.146-6.768 6.167-9.702 9.082-3.447 3.425-4.234 5.653-6.74 6.77-.093.043-.199.073-.298.126 1.775-1.885 1.116-3.167 2.315-5.355 3.684-6.678 6.129-11.433 8.584-18.365 1.421-4.016 5.766-9.198 7.88-13.061 1.049-1.918 2.706-6.209 3.705-10.344z"
            clipRule="evenodd"
          />
          <path
            fill="#E9BC9D"
            fillRule="evenodd"
            d="M41.646 94.492c.298 5.324 2.902 10.255 3.777 15.565 1.104 6.679.416 11.436 2.183 15.74 1.292 3.147 5.354 4.871 8.314 7.772 3.479 3.401 4.568 5.719 7.089 6.804.102.042.199.09.305.121-1.794-1.855-2.131-3.667-3.346-5.867-3.717-6.668-3.648-11.087-6.12-18.013-1.435-4.018-4.976-6.279-7.109-10.135-1.06-1.917-4.083-7.841-5.093-11.987z"
            clipRule="evenodd"
          />
        </g>
      ) : null}

      {type === 3 ? (
        <g>
          <path
            fill="#BE977C"
            fillRule="evenodd"
            d="m47.223 110.898 1.328-15.854c-2.352-2.314-4.041-3.841-5.267-5.744-3.37-5.229-5.486-6.432-7.534-4.907-.83.617-1.124 2.578-.638 5.042.849 4.303 3.564 10.723 4.417 14.49.685 3.034 4.652 13.42 7.694 6.973zM100.376 110.898l-1.33-15.854c2.353-2.314 4.041-3.841 5.268-5.744 3.369-5.229 5.487-6.432 7.535-4.907.829.617 1.123 2.578.637 5.042-.849 4.303-3.564 10.723-4.416 14.49-.688 3.034-4.654 13.42-7.694 6.973z"
            clipRule="evenodd"
          />
          <path
            fill="#D39166"
            fillRule="evenodd"
            d="M43.845 98.502c-1.739-4.557-2.415-5.58-2.492-4.883-.082.764.558 3.6 1.308 6.109.171.569-.497 1.958-.029 3.636.43 1.545 1.729 3.441 2.442 1.706.251-.613.057-1.69-.274-3.188-.17-.77-.35-1.792-.955-3.38z"
            clipRule="evenodd"
          />
          <path
            fill="#AF846D"
            fillRule="evenodd"
            d="M40.559 87.736c-1.033-1.136-2.326-2.308-3.339-2.114-2.385.457-1.251 4.894.194 7.918 2.475 5.178 2.49 4.89 3.339 10.833-.054-3.769-.602-7.041-2.829-11.479-1.288-2.567-2.264-6.072-.521-6.528 1.11-.292 2.829 1.924 3.5 3.426l-.344-2.056z"
            clipRule="evenodd"
          />
          <path
            fill="#B9896E"
            fillRule="evenodd"
            d="M104.395 98.502c1.739-4.557 2.417-5.58 2.492-4.883.083.764-.557 3.6-1.308 6.109-.17.569.496 1.958.029 3.636-.431 1.545-1.73 3.441-2.441 1.706-.252-.613-.058-1.69.274-3.188.169-.77.348-1.792.954-3.38z"
            clipRule="evenodd"
          />
          <path
            fill="#B98E70"
            fillRule="evenodd"
            d="M107.136 87.639c1.03-1.136 2.324-2.308 3.338-2.114 2.385.457 1.251 4.894-.194 7.918-2.475 5.178-2.49 4.89-3.34 10.833.056-3.77.604-7.041 2.831-11.479 1.288-2.567 2.264-6.074.52-6.529-1.109-.292-2.828 1.924-3.498 3.425l.343-2.054z"
            clipRule="evenodd"
          />
          <path
            fill="#CC9E7F"
            fillRule="evenodd"
            d="M97.878 149.708c3.517 9.324 37.432 12.869 52.122 27.365l-29.751-.081h-92.9L.532 176.77c6.554-10.944 47.117-20.05 49.671-27.062 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442c-1.327 5.934-1.303 14.956.857 20.675z"
            clipRule="evenodd"
          />
          <path
            fill="#BE977C"
            fillRule="evenodd"
            d="M45.362 154.014c1.939-.991 4.098-2.266 4.841-4.306 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442a29.825 29.825 0 0 0-.375 1.943c-.128.822-.227 1.777-.27 2.033-1.006 1.201-2.063 2.401-3.234 3.382-1.277 1.067-2.468 2.128-3.491 3.349-1.82 2.176-3.313 5.461-5.096 7.005-3.676 3.188-9.177 5.007-15.63 6.474-7.757 1.765-18.147 2.468-23.563.795z"
            clipRule="evenodd"
          />
          <path
            fill="#AD8B74"
            fillRule="evenodd"
            d="M51.635 132.507c1.756 1.498 2.659 2.898 4.47 4.172 3.919 2.761 6.58 6.182 7.76 6.487 3.849.994 16.232.994 20.081 0 1.182-.306 3.891-3.286 6.782-6.291 1.525-1.585 3.957-2.851 5.71-4.34.147-1.243.343-2.423.584-3.502l-22.981.442-22.983-.442c.239 1.071.432 2.241.577 3.474z"
            clipRule="evenodd"
          />
          <path
            fill="#CC9E7F"
            fillRule="evenodd"
            d="M74.396 142.826c-.13.01-.26.01-.39.012-4.776.029-7.15-.047-9.746-1.143-2.507-1.068-3.723-3.531-7.174-6.854-2.936-2.825-8.203-5.706-9.487-8.785-1.758-4.219-1.069-8.865-2.162-15.41-.873-5.217-3.328-9.85-3.615-15.07-.301-5.644.605-11.321.412-17.696-.189-6.231-.363-11.388.4-15.764.771-4.416 2.502-8.08 6.1-11.301v-.009c1.994-1.765 4.251-3.271 6.707-4.509l.08-.031c.159-.071.326-.155.485-.236.092-.042.178-.083.272-.134.125-.062.26-.125.385-.176.127-.052.253-.114.379-.166.14-.071.279-.123.413-.175a4.67 4.67 0 0 1 .367-.166c.172-.071.346-.144.526-.206.081-.03.167-.071.254-.104.253-.102.519-.194.78-.289l.006-.009c6.544-2.371 13.968-3.034 21.002-1.993 7.01 1.037 13.612 3.772 18.522 8.151 3.583 3.216 5.297 6.854 6.071 11.252.767 4.357.612 9.496.433 15.705-.183 6.362.738 12.013.44 17.634-.279 5.202-2.725 9.836-3.578 15.042-1.069 6.531-.385 11.143-2.122 15.376-1.28 3.092-6.508 6.01-9.423 8.864-3.437 3.364-4.646 5.831-7.14 6.938-2.491 1.085-4.78 1.233-9.197 1.252z"
            clipRule="evenodd"
          />
          <path
            fill="#D3AA8D"
            fillRule="evenodd"
            d="M106.02 94.398c-.291 5.315-2.743 10.133-3.598 15.42-1.08 6.686-.39 11.404-2.146 15.727-1.278 3.146-6.768 6.167-9.702 9.082-3.447 3.425-4.234 5.653-6.74 6.77-.093.043-.199.073-.298.126 1.775-1.885 1.116-3.167 2.315-5.355 3.684-6.678 6.129-11.433 8.584-18.365 1.421-4.016 5.766-9.198 7.88-13.061 1.049-1.918 2.706-6.209 3.705-10.344z"
            clipRule="evenodd"
          />
          <path
            fill="#BE977C"
            fillRule="evenodd"
            d="M41.646 94.492c.298 5.324 2.902 10.255 3.777 15.565 1.104 6.679.416 11.436 2.183 15.74 1.292 3.147 5.354 4.871 8.314 7.772 3.479 3.401 4.568 5.719 7.089 6.804.102.042.199.09.305.121-1.794-1.855-2.131-3.667-3.346-5.867-3.717-6.668-3.648-11.087-6.12-18.013-1.435-4.018-4.976-6.279-7.109-10.135-1.06-1.917-4.083-7.841-5.093-11.987z"
            clipRule="evenodd"
          />
        </g>
      ) : null}

      {type === 4 ? (
        <g>
          <path
            fill="#8E6D5B"
            fillRule="evenodd"
            d="m47.223 110.898 1.328-15.854c-2.352-2.314-4.041-3.841-5.267-5.744-3.37-5.229-5.486-6.432-7.534-4.907-.83.617-1.124 2.578-.638 5.042.849 4.303 3.564 10.723 4.417 14.49.685 3.034 4.652 13.42 7.694 6.973zM100.376 110.898l-1.33-15.854c2.353-2.314 4.041-3.841 5.268-5.744 3.369-5.229 5.487-6.432 7.535-4.907.829.617 1.123 2.578.637 5.042-.849 4.303-3.564 10.723-4.416 14.49-.688 3.034-4.654 13.42-7.694 6.973z"
            clipRule="evenodd"
          />
          <path
            fill="#7D5F51"
            fillRule="evenodd"
            d="M43.845 98.502c-1.739-4.557-2.415-5.58-2.492-4.883-.082.764.558 3.6 1.308 6.109.171.569-.497 1.958-.029 3.636.43 1.545 1.729 3.441 2.442 1.706.251-.613.057-1.69-.274-3.188-.17-.77-.35-1.792-.955-3.38z"
            clipRule="evenodd"
          />
          <path
            fill="#73594E"
            fillRule="evenodd"
            d="M40.559 87.736c-1.033-1.136-2.326-2.308-3.339-2.114-2.385.457-1.251 4.894.194 7.918 2.475 5.178 2.49 4.89 3.339 10.833-.054-3.769-.602-7.041-2.829-11.479-1.288-2.567-2.264-6.072-.521-6.528 1.11-.292 2.829 1.924 3.5 3.426l-.344-2.056zM104.395 98.502c1.739-4.557 2.417-5.58 2.492-4.883.083.764-.557 3.6-1.308 6.109-.17.569.496 1.958.029 3.636-.431 1.545-1.73 3.441-2.441 1.706-.252-.613-.058-1.69.274-3.188.169-.77.348-1.792.954-3.38z"
            clipRule="evenodd"
          />
          <path
            fill="#73594E"
            fillRule="evenodd"
            d="M107.136 87.639c1.03-1.136 2.324-2.308 3.338-2.114 2.385.457 1.251 4.894-.194 7.918-2.475 5.178-2.49 4.89-3.34 10.833.056-3.77.604-7.041 2.831-11.479 1.288-2.567 2.264-6.074.52-6.529-1.109-.292-2.828 1.924-3.498 3.425l.343-2.054z"
            clipRule="evenodd"
          />
          <path
            fill="#9B7861"
            fillRule="evenodd"
            d="M97.878 149.708c3.517 9.324 37.432 12.869 52.122 27.365l-29.751-.081h-92.9L.532 176.77c6.554-10.944 47.117-20.05 49.671-27.062 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442c-1.327 5.934-1.303 14.956.857 20.675z"
            clipRule="evenodd"
          />
          <path
            fill="#7B5F57"
            fillRule="evenodd"
            d="M45.362 154.014c1.939-.991 4.098-2.266 4.841-4.306 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442a29.825 29.825 0 0 0-.375 1.943c-.128.822-.227 1.777-.27 2.033-1.006 1.201-2.063 2.401-3.234 3.382-1.277 1.067-2.468 2.128-3.491 3.349-1.82 2.176-3.313 5.461-5.096 7.005-3.676 3.188-9.177 5.007-15.63 6.474-7.757 1.765-18.147 2.468-23.563.795z"
            clipRule="evenodd"
          />
          <path
            fill="#73594E"
            fillRule="evenodd"
            d="M51.635 132.507c1.756 1.498 2.659 2.898 4.47 4.172 3.919 2.761 6.58 6.182 7.76 6.487 3.849.994 16.232.994 20.081 0 1.182-.306 3.891-3.286 6.782-6.291 1.525-1.585 3.957-2.851 5.71-4.34.147-1.243.343-2.423.584-3.502l-22.981.442-22.983-.442c.239 1.071.432 2.241.577 3.474z"
            clipRule="evenodd"
          />
          <path
            fill="#9B7861"
            fillRule="evenodd"
            d="M74.396 142.826c-.13.01-.26.01-.39.012-4.776.029-7.15-.047-9.746-1.143-2.507-1.068-3.723-3.531-7.174-6.854-2.936-2.825-8.203-5.706-9.487-8.785-1.758-4.219-1.069-8.865-2.162-15.41-.873-5.217-3.328-9.85-3.615-15.07-.301-5.644.605-11.321.412-17.696-.189-6.231-.363-11.388.4-15.764.771-4.416 2.502-8.08 6.1-11.301v-.009c1.994-1.765 4.251-3.271 6.707-4.509l.08-.031c.159-.071.326-.155.485-.236.092-.042.178-.083.272-.134.125-.062.26-.125.385-.176.127-.052.253-.114.379-.166.14-.071.279-.123.413-.175a4.67 4.67 0 0 1 .367-.166c.172-.071.346-.144.526-.206.081-.03.167-.071.254-.104.253-.102.519-.194.78-.289l.006-.009c6.544-2.371 13.968-3.034 21.002-1.993 7.01 1.037 13.612 3.772 18.522 8.151 3.583 3.216 5.297 6.854 6.071 11.252.767 4.357.612 9.496.433 15.705-.183 6.362.738 12.013.44 17.634-.279 5.202-2.725 9.836-3.578 15.042-1.069 6.531-.385 11.143-2.122 15.376-1.28 3.092-6.508 6.01-9.423 8.864-3.437 3.364-4.646 5.831-7.14 6.938-2.491 1.085-4.78 1.233-9.197 1.252z"
            clipRule="evenodd"
          />
          <path
            fill="#967261"
            fillRule="evenodd"
            d="M106.02 94.398c-.291 5.315-2.743 10.133-3.598 15.42-1.08 6.686-.39 11.404-2.146 15.727-1.278 3.146-6.768 6.167-9.702 9.082-3.447 3.425-4.234 5.653-6.74 6.77-.093.043-.199.073-.298.126 1.775-1.885 1.116-3.167 2.315-5.355 3.684-6.678 6.129-11.433 8.584-18.365 1.421-4.016 5.766-9.198 7.88-13.061 1.049-1.918 2.706-6.209 3.705-10.344z"
            clipRule="evenodd"
          />
          <path
            fill="#8E6D5B"
            fillRule="evenodd"
            d="M41.646 94.492c.298 5.324 2.902 10.255 3.777 15.565 1.104 6.679.416 11.436 2.183 15.74 1.292 3.147 5.354 4.871 8.314 7.772 3.479 3.401 4.568 5.719 7.089 6.804.102.042.199.09.305.121-1.794-1.855-2.131-3.667-3.346-5.867-3.717-6.668-3.648-11.087-6.12-18.013-1.435-4.018-4.976-6.279-7.109-10.135-1.06-1.917-4.083-7.841-5.093-11.987z"
            clipRule="evenodd"
          />
        </g>
      ) : null}

      {type === 5 ? (
        <g>
          <path
            fill="#42342E"
            fillRule="evenodd"
            d="m47.223 110.898 1.328-15.854c-2.352-2.314-4.041-3.841-5.267-5.744-3.37-5.229-5.486-6.432-7.534-4.907-.83.617-1.124 2.578-.638 5.042.849 4.303 3.564 10.723 4.417 14.49.685 3.034 4.652 13.42 7.694 6.973zM100.376 110.898l-1.33-15.854c2.353-2.314 4.041-3.841 5.268-5.744 3.369-5.229 5.487-6.432 7.535-4.907.829.617 1.123 2.578.637 5.042-.849 4.303-3.564 10.723-4.416 14.49-.688 3.034-4.654 13.42-7.694 6.973z"
            clipRule="evenodd"
          />
          <path
            fill="#2B221F"
            fillRule="evenodd"
            d="M43.845 98.502c-1.739-4.557-2.415-5.58-2.492-4.883-.082.764.558 3.6 1.308 6.109.171.569-.497 1.958-.029 3.636.43 1.545 1.729 3.441 2.442 1.706.251-.613.057-1.69-.274-3.188-.17-.77-.35-1.792-.955-3.38zM40.559 87.736c-1.033-1.136-2.326-2.308-3.339-2.114-2.385.457-1.251 4.894.194 7.918 2.475 5.178 2.49 4.89 3.339 10.833-.054-3.769-.602-7.041-2.829-11.479-1.288-2.567-2.264-6.072-.521-6.528 1.11-.292 2.829 1.924 3.5 3.426l-.344-2.056zM104.395 98.502c1.739-4.557 2.417-5.58 2.492-4.883.083.764-.557 3.6-1.308 6.109-.17.569.496 1.958.029 3.636-.431 1.545-1.73 3.441-2.441 1.706-.252-.613-.058-1.69.274-3.188.169-.77.348-1.792.954-3.38z"
            clipRule="evenodd"
          />
          <path
            fill="#2B221F"
            fillRule="evenodd"
            d="M107.136 87.639c1.03-1.136 2.324-2.308 3.338-2.114 2.385.457 1.251 4.894-.194 7.918-2.475 5.178-2.49 4.89-3.34 10.833.056-3.77.604-7.041 2.831-11.479 1.288-2.567 2.264-6.074.52-6.529-1.109-.292-2.828 1.924-3.498 3.425l.343-2.054z"
            clipRule="evenodd"
          />
          <path
            fill="#4A3626"
            fillRule="evenodd"
            d="M97.878 149.708c3.517 9.324 37.432 12.869 52.122 27.365l-29.751-.081h-92.9L.532 176.77c6.554-10.944 47.117-20.05 49.671-27.062 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442c-1.327 5.934-1.303 14.956.857 20.675z"
            clipRule="evenodd"
          />
          <path
            fill="#352925"
            fillRule="evenodd"
            d="M45.362 154.014c1.939-.991 4.098-2.266 4.841-4.306 2.091-5.743 2.181-14.741.854-20.675l22.983.442 22.981-.442a29.825 29.825 0 0 0-.375 1.943c-.128.822-.227 1.777-.27 2.033-1.006 1.201-2.063 2.401-3.234 3.382-1.277 1.067-2.468 2.128-3.491 3.349-1.82 2.176-3.313 5.461-5.096 7.005-3.676 3.188-9.177 5.007-15.63 6.474-7.757 1.765-18.147 2.468-23.563.795z"
            clipRule="evenodd"
          />
          <path
            fill="#281E1C"
            fillRule="evenodd"
            d="M51.635 132.507c1.756 1.498 2.659 2.898 4.47 4.172 3.919 2.761 6.58 6.182 7.76 6.487 3.849.994 16.232.994 20.081 0 1.182-.306 3.891-3.286 6.782-6.291 1.525-1.585 3.957-2.851 5.71-4.34.147-1.243.343-2.423.584-3.502l-22.981.442-22.983-.442c.239 1.071.432 2.241.577 3.474z"
            clipRule="evenodd"
          />
          <path
            fill="#4A3626"
            fillRule="evenodd"
            d="M74.396 142.826c-.13.01-.26.01-.39.012-4.776.029-7.15-.047-9.746-1.143-2.507-1.068-3.723-3.531-7.174-6.854-2.936-2.825-8.203-5.706-9.487-8.785-1.758-4.219-1.069-8.865-2.162-15.41-.873-5.217-3.328-9.85-3.615-15.07-.301-5.644.605-11.321.412-17.696-.189-6.231-.363-11.388.4-15.764.771-4.416 2.502-8.08 6.1-11.301v-.009c1.994-1.765 4.251-3.271 6.707-4.509l.08-.031c.159-.071.326-.155.485-.236.092-.042.178-.083.272-.134.125-.062.26-.125.385-.176.127-.052.253-.114.379-.166.14-.071.279-.123.413-.175a4.67 4.67 0 0 1 .367-.166c.172-.071.346-.144.526-.206.081-.03.167-.071.254-.104.253-.102.519-.194.78-.289l.006-.009c6.544-2.371 13.968-3.034 21.002-1.993 7.01 1.037 13.612 3.772 18.522 8.151 3.583 3.216 5.297 6.854 6.071 11.252.767 4.357.612 9.496.433 15.705-.183 6.362.738 12.013.44 17.634-.279 5.202-2.725 9.836-3.578 15.042-1.069 6.531-.385 11.143-2.122 15.376-1.28 3.092-6.508 6.01-9.423 8.864-3.437 3.364-4.646 5.831-7.14 6.938-2.491 1.085-4.78 1.233-9.197 1.252z"
            clipRule="evenodd"
          />
          <path
            fill="#473833"
            fillRule="evenodd"
            d="M106.02 94.398c-.291 5.315-2.743 10.133-3.598 15.42-1.08 6.686-.39 11.404-2.146 15.727-1.278 3.146-6.768 6.167-9.702 9.082-3.447 3.425-4.234 5.653-6.74 6.77-.093.043-.199.073-.298.126 1.775-1.885 1.116-3.167 2.315-5.355 3.684-6.678 6.129-11.433 8.584-18.365 1.421-4.016 5.766-9.198 7.88-13.061 1.049-1.918 2.706-6.209 3.705-10.344z"
            clipRule="evenodd"
          />
          <path
            fill="#42342E"
            fillRule="evenodd"
            d="M41.646 94.492c.298 5.324 2.902 10.255 3.777 15.565 1.104 6.679.416 11.436 2.183 15.74 1.292 3.147 5.354 4.871 8.314 7.772 3.479 3.401 4.568 5.719 7.089 6.804.102.042.199.09.305.121-1.794-1.855-2.131-3.667-3.346-5.867-3.717-6.668-3.648-11.087-6.12-18.013-1.435-4.018-4.976-6.279-7.109-10.135-1.06-1.917-4.083-7.841-5.093-11.987z"
            clipRule="evenodd"
          />
        </g>
      ) : null}
    </svg>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  changeShirtNumber,
  firePlayer,
  getUserClubProfessionalPlayers,
} from '../api';
import { QueryKeys } from './queryKeys';

// import { players } from '../fixtures';

export const usePlayers = () => {
  // const queryClient = useQueryClient();

  const {
    isFetching,
    error,
    data,
  } = useQuery({
    queryKey: ['players'],
    queryFn: getUserClubProfessionalPlayers,
  });

  // if (!data) {
  //   queryClient.setQueryData(['players'], players);
  // }

  return {
    isFetching,
    error,
    data,
  };
};

export const useChangeShirtNumber = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (request: any) => changeShirtNumber(request),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['players'],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_HIRED_GRASSROOTS_PLAYERS],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

export const useFirePlayer = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateFn,
  } = useMutation({
    mutationFn: (playerId: string) => firePlayer(playerId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['players'],
        exact: true,
      });
    },
  });

  return {
    isLoading,
    isError,
    mutateFn,
  };
};

import { AxiosResponse } from 'axios';

import { axiosInstance } from '../axios';
import { LineupType } from '../types/match';
import { Commentary, MatchDetails } from '../types/match/MatchDetails';

export const getMatchDetails = (id: string): Promise<MatchDetails> => (
  axiosInstance.get(`/match/${id}`)
    .then((response) => response.data as MatchDetails)
);

export const createLineup = (data: any): Promise<AxiosResponse> => (
  axiosInstance.put('/match/line-up', data)
);

type Response = Omit<LineupType, 'benchPlayers'> & {
  benchPlayers: { id: string }[]
};

export const getLineup = (matchId: string | undefined): Promise<Response> => (
  axiosInstance.get(`/match/line-up/${matchId}`)
    .then((response) => response.data)
);

export const getMatchCommentaries = (id: string): Promise<Commentary[]> => (
  axiosInstance.get(`/match/${id}/commentaries`)
    .then((response) => response.data)
);

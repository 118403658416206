export const PT_BR: string = 'pt-BR';
export const EN_GB: string = 'en-GB';
export const EN_US: string = 'en-US';
export const DATETIME_LONG_FORMAT: any = {
  'pt-BR': 'ddd, DD MMM YYYY\nHH:mm',
  'en-GB': 'ddd, DD MMM YYYY\nHH:mm',
  'en-US': 'ddd, MMM DD YYYY\nhh:mm A',
};

export const DATE_LONG_FORMAT: any = {
  'pt-BR': 'ddd, DD MMM YYYY',
  'en-GB': 'ddd, DD MMM YYYY',
  'en-US': 'ddd, MMM DD YYYY',
};

export const DATE_SHORT_FORMAT: any = {
  'pt-BR': 'DD/MM/YYYY',
  'en-GB': 'DD-MM-YYYY',
  'en-US': 'MM-DD-YYYY',
};

export const DATE_TIME_SHORT_FORMAT: any = {
  'pt-BR': 'DD/MM/YYYY - HH:mm',
  'en-GB': 'DD-MM-YYYY - HH:mm',
  'en-US': 'MM-DD-YYYY - hh:mm A',
};

export const CURRENCIES: any = {
  'pt-BR': 'BRL',
  'en-GB': 'GBP',
  'en-US': 'USD',
};

export const TIMEZONES: any = {
  'pt-BR': 'America/Sao_Paulo',
  'en-GB': 'Europe/London',
  'en-US': 'America/New_York',
};

import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useTranslation } from 'react-i18next';

export const FacebookLogin = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<FacebookIcon />}
      fullWidth
      variant="contained"
      disabled
      sx={{
        backgroundColor: '#375796 !important',
        '&:hover': {
          boxShadow: 'none',
        },
      }}
    >
      {t('login_with_facebook')}
    </Button>
  );
};
